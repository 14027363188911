import { OktaAuth } from '@okta/okta-auth-js';

// Instantiating `OktaAuth` here will allow us access outside the context of a React component (api.js)
let oktaAuth;

// eslint-disable-next-line import/prefer-default-export
export function getOktaAuth(appConfig) {
  if (oktaAuth) {
    return oktaAuth;
  }

  const { oktaIssuer, oktaCallbackPath, oktaScopes, oktaClientId } = appConfig;
  // https://developer.okta.com/docs/guides/sign-into-spa/react/configure-the-sdk/
  const oktaConfig = {
    issuer: oktaIssuer,
    redirectUri: window.location.origin + oktaCallbackPath,
    scopes: oktaScopes.split(/\s+/),
    clientId: oktaClientId,
    pkce: true,
  };

  oktaAuth = new OktaAuth(oktaConfig);
  return oktaAuth;
}
