import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Select, MenuItem } from '@material-ui/core';
import { MTablePagination } from 'material-table';

function DataTablePagination({ onChangePage, page, count, rowsPerPage, icons, dataTableRef, viewAllCount }) {
  const { tableFooter, rowSelect } = useStyles();
  const [visibleRows, setVisibleRows] = useState(rowsPerPage);

  /**
   * `material-table` does not offer an API to modify the `select` option
   * labels (use case is to add a `View all` pagination option). Therefore our
   * custom `select` must manually call the method which adjusts page size.
   */
  const handlePaginationRowsClick = useCallback(
    e => {
      if (dataTableRef) {
        setVisibleRows(e.target.value);
        dataTableRef.onChangeRowsPerPage(e);
      }
    },
    [dataTableRef]
  );

  return (
    <td className={tableFooter}>
      <Select
        disableUnderline
        value={visibleRows}
        renderValue={val => <div className={rowSelect}>{val === viewAllCount ? `View All` : `${val} rows`}</div>}
        onChange={handlePaginationRowsClick}
      >
        <MenuItem value={25}>25</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
        <MenuItem value={viewAllCount}>View all</MenuItem>
      </Select>
      <MTablePagination onChangePage={onChangePage} page={page} count={count} rowsPerPage={rowsPerPage} icons={icons} />
    </td>
  );
}

const useStyles = makeStyles(theme => ({
  tableFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rowSelect: {
    fontSize: `${14 / 16}rem`,
    paddingLeft: theme.spacing(1),
  },
}));

DataTablePagination.propTypes = {
  // props passed in by material-table
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  icons: PropTypes.object.isRequired,
  // custom props
  dataTableRef: PropTypes.object,
  viewAllCount: PropTypes.number,
};

DataTablePagination.defaultProps = {
  dataTableRef: null,
  viewAllCount: 100,
};

export default DataTablePagination;
