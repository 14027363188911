import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import { DAY_NUMBER, MONTH_ABBREVIATION } from 'common/dates/dates';

function DateCircle({ dateTime }) {
  const { day, month } = useMemo(() => {
    const parsedDateTime = moment(dateTime);
    return {
      day: parsedDateTime.format(DAY_NUMBER),
      month: parsedDateTime.format(MONTH_ABBREVIATION),
    };
  }, [dateTime]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bgcolor="common.white"
      borderRadius="50%"
      width="5.25rem"
      height="5.25rem"
    >
      <Typography color="primary" variant="h5">
        {day}
      </Typography>
      <Typography color="primary" variant="h6">
        {month}
      </Typography>
    </Box>
  );
}

DateCircle.propTypes = {
  // ex: `2020-05-22T15:56:13.000Z` or `2020-05-22`
  dateTime: PropTypes.string.isRequired,
};

export default DateCircle;
