import React from 'react';
import PropTypes from 'prop-types';
import CuriDialogComponent from 'common/components/curiDialog.component';
import CuriButton from 'common/buttons/curiButton.component';

function UnsavedChangesConfirmDialogComponent({ open, onCancel, onSubmit }) {
  return (
    <CuriDialogComponent
      open={open}
      title="Changes Will Be Lost"
      text="You have unsaved changes to this page that will be lost if you leave the page."
      onClose={onCancel}
    >
      <CuriButton color="secondary" variant="outlined" onClick={onCancel} autoFocus>
        Stay On Page
      </CuriButton>
      <CuriButton color="secondary" onClick={onSubmit}>
        Leave Page
      </CuriButton>
    </CuriDialogComponent>
  );
}

UnsavedChangesConfirmDialogComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default UnsavedChangesConfirmDialogComponent;
