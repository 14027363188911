import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StreamChat } from 'stream-chat';
import { Channel, Chat, MessageInput, MessageList, Window } from 'stream-chat-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import SectionContainer, { BORDER_RADIUS } from 'modules/layout/section.container';
import {
  useSelectedOpportunity,
  useSelectedOpportunityIsReadOnly,
} from 'modules/opportunities/opportunities.selectors';
import { useDiscussion } from 'modules/discussion/discussion.selectors';
import { selectAppConfig } from 'modules/appConfig/appConfig.selectors';

//  Import stream chat styles and a few overrides
import './chat.css';

const SECTION_TITLE = 'Discussion';

function OpportunityDiscussionSection() {
  const {
    data: { streamChatApiKey },
  } = useSelector(selectAppConfig);

  const [streamChat, setStreamChat] = useState(null);
  const [channel, setChannel] = useState(null);
  const opportunity = useSelectedOpportunity();
  const isReadOnly = useSelectedOpportunityIsReadOnly();
  const { discussion } = useDiscussion();

  const opportunityUsesDiscussion = opportunity && opportunity.submittedId;
  const needsStreamInit = discussion && streamChatApiKey && !streamChat && opportunityUsesDiscussion;
  const allowInput = channel && channel.data && !channel.data.frozen;

  useEffect(() => {
    async function init() {
      const { token, user } = discussion;
      const chat = new StreamChat(streamChatApiKey);

      await chat.connectUser({ id: user.id, name: user.name }, token);
      setStreamChat(chat);

      const channelResponse = chat.channel('messaging', opportunity.submittedId);
      setChannel(channelResponse);
    }

    if (needsStreamInit) {
      init();
    }

    return () => {
      if (streamChat) {
        streamChat.disconnectUser();
      }
    };
  }, [discussion, streamChatApiKey, streamChat, needsStreamInit]);

  if (!opportunityUsesDiscussion) {
    return null;
  }

  return (
    <SectionContainer borderRadius={BORDER_RADIUS.ROUNDED_BOTTOM} title={SECTION_TITLE} reduceTitleMargin>
      {isReadOnly && (
        <Typography gutterBottom>
          This submission is closed. You will not be able to send new messages. The discussion transcript is available
          in the documents section.
        </Typography>
      )}
      {streamChat ? (
        <Chat client={streamChat}>
          <Channel channel={channel}>
            <Window>
              <MessageList />
              {allowInput && <MessageInput />}
            </Window>
          </Channel>
        </Chat>
      ) : (
        <CircularProgress />
      )}
    </SectionContainer>
  );
}

export default OpportunityDiscussionSection;
