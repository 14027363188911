import Symbol from 'es6-symbol';

import { getOktaAuth } from 'okta/config';

function callApi(token, endpoint, authenticated, payload, method = 'GET') {
  console.log('Requesting', endpoint);
  let config = { headers: {} };

  if (authenticated) {
    console.log('Auth required');
    if (token) {
      config = {
        headers: { Authorization: `Bearer ${token}` },
      };
    } else {
      console.log('No token available.');
      throw new Error('No token saved!');
    }
  }
  config.method = method;
  if (typeof payload !== 'undefined') {
    // Is this form data?
    if (Object.prototype.toString.call(payload) === '[object FormData]') {
      config.body = payload;
    } else {
      config.body = JSON.stringify(payload);
      config.headers['Content-Type'] = 'application/json';
    }
  }

  console.log('Fetching', endpoint);
  return fetch(endpoint, config)
    .then(response => {
      return response.json().then(json => {
        return { json, response };
      });
    })
    .then(({ json, response }) => {
      if (!response.ok) {
        console.log('Bad Response', response);
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ ...json, status: response.status });
      }
      const metadata = {};
      if (response.headers.get('X-Total-Count')) {
        metadata.totalCount = Number(response.headers.get('X-Total-Count'));
      }
      return { json, metadata };
    });
}

export const CALL_API = Symbol('Call API');

export default ({ getState }) => next => async action => {
  const callAPI = action[CALL_API];

  // So the middleware doesn't get applied to every single action
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  const { endpoint, types, authenticated, method, payload, options, actionMetadata } = callAPI;

  const [requestType, successType, errorType] = types;

  const state = getState();
  const { apiDomain } = state.appConfig.data;
  if (!apiDomain) {
    console.error('API has not been configured, rejecting request');
    return next({
      type: errorType,
      actionMetadata,
    });
  }

  const protocol = apiDomain.startsWith('localhost') ? 'http' : 'https';
  const fullEndpoint = `${protocol}://${state.appConfig.data.apiDomain}/${endpoint}`;
  const oktaAuth = getOktaAuth(state.appConfig.data);

  next({
    type: requestType,
    payload,
    actionMetadata,
  });

  let token;
  if (authenticated) {
    try {
      // `oktaAuth.getAccessToken()` will return the existing access token from local state given it has not
      // expired. But if it has expired, it will automatically fetch and return an updated access token using a
      // refresh token. `undefined` will be returned when session is expired, and reauthentication is required.
      // https://devforum.okta.com/t/do-i-need-to-manually-refresh-tokens-in-my-spa/8064/2
      token = oktaAuth.getAccessToken();
      if (!token) {
        console.log('SESSION EXPIRED');
        oktaAuth.signOut();
      }
    } catch (e) {
      console.log('ERROR GETTING TOKEN', e);
      return next({
        type: errorType,
        actionMetadata,
      });
    }
  }

  // Passing the authenticated boolean back in our data will let us distinguish
  // between normal and secret quotes
  return callApi(token, fullEndpoint, authenticated, payload, method).then(
    response => {
      return next({
        response: response.json,
        metadata: response.metadata,
        authenticated,
        payload,
        type: successType,
        options,
        actionMetadata,
      });
    },
    error => {
      const resp = {
        type: errorType,
        actionMetadata,
        status: error.status,
      };
      if (error.messages) {
        resp.messages = error.messages;
      } else if (error.message) {
        resp.messages = [error.message];
      } else {
        resp.messages = ['There was an error.'];
      }

      if (error.status === 401) {
        oktaAuth.signOut();
      }

      return next(resp);
    }
  );
};
