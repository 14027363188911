import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles, Box, Tooltip } from '@material-ui/core';
import SectionContainer, { BORDER_RADIUS } from 'modules/layout/section.container';
import CuriButton from 'common/buttons/curiButton.component';
import { handleToastMessage, TOAST_TYPES } from 'modules/layout/layout.actions';
import {
  createOasisQuote,
  getIndicationIndividualRisks,
  CREATE_OASIS_QUOTE_FAILURE,
  getIndicationOrganizationRisks,
  getIndicationRiskSpecialties,
  getIndicationStates,
  getOpportunityIndications,
} from 'modules/indication/indication.actions';
import IndicationStatus from 'modules/indication/indicationStatus';
import {
  selectIsLoadingIndicationStatus,
  selectIsCreatingOasisQuote,
  useSelectedIndication,
  selectIndividualRisks,
  selectOrganizationRisks,
  selectIsLoadingIndividualRisks,
  selectIsLoadingOrganizationRisks,
  selectIsLoadingRiskSpecialties,
  selectKeyedIndicationStates,
  selectRiskSpecialties,
  selectIndicationStates,
} from 'modules/indication/indication.selectors';
import { useAuthz } from 'okta/authz';
import Permissions from 'okta/permissions';
import OasisQuoteVersionsTable from 'modules/indication/oasisQuoteVersionsTable.component';
import SectionHeader from 'modules/layout/sectionHeader.component';
import { isValidAddress } from 'utilities/validators';
import { uniq } from 'lodash';
import { useSelectedOpportunityIsReadOnly } from 'modules/opportunities/opportunities.selectors';
import IndicationRiskCountWarningDialog from 'modules/indication/indicationRiskCountWarningDialog.component';

const RISK_WARNING_THRESHOLD = 100;

function IndicationSummary() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { opportunityId, indicationId } = useParams();
  const isLoadingIndicationStatus = useSelector(selectIsLoadingIndicationStatus);
  const isLoadingIndividualRisks = useSelector(selectIsLoadingIndividualRisks);
  const isLoadingOrganizationRisks = useSelector(selectIsLoadingOrganizationRisks);
  const isLoadingRiskSpecialties = useSelector(selectIsLoadingRiskSpecialties);
  const keyedIndicationStates = useSelector(selectKeyedIndicationStates);
  const riskSpecialties = useSelector(selectRiskSpecialties);
  const states = useSelector(selectIndicationStates);
  const isCreatingOasisQuote = useSelector(selectIsCreatingOasisQuote);
  const individualRisks = useSelector(selectIndividualRisks);
  const organizationalRisks = useSelector(selectOrganizationRisks);
  const { selectedIndication } = useSelectedIndication();
  const { userHas } = useAuthz();
  const isReadonly = useSelectedOpportunityIsReadOnly();
  const [showRiskCountWarning, setShowRiskCountWarning] = useState(false);

  const numRisks =
    (Array.isArray(individualRisks) ? individualRisks.length : 0) +
    (Array.isArray(organizationalRisks) ? organizationalRisks.length : 0);

  const handleCreateOasisQuote = useCallback(async () => {
    const response = await dispatch(createOasisQuote(opportunityId, indicationId));
    if (response.type === CREATE_OASIS_QUOTE_FAILURE) {
      dispatch(handleToastMessage('Unable to create Oasis quote. Please try again.', TOAST_TYPES.ERROR));
      // refresh indications
      // TODO should only need to fetch the current one
      dispatch(getOpportunityIndications(opportunityId));
    } else {
      dispatch(handleToastMessage('Quote created in Oasis.', TOAST_TYPES.SUCCESS));
      if (numRisks >= RISK_WARNING_THRESHOLD) {
        setShowRiskCountWarning(true);
      }
    }
  }, [dispatch, opportunityId, indicationId, numRisks]);

  const isCurrentStatus = useCallback(status => selectedIndication && selectedIndication.activeStatusCode === status, [
    selectedIndication,
  ]);

  const hasBeenSentToOasis = useMemo(
    () =>
      isCurrentStatus(IndicationStatus.ReadyToBeDelivered) ||
      isCurrentStatus(IndicationStatus.SentForExperienceRating) ||
      isCurrentStatus(IndicationStatus.CreatedInOasis),
    [isCurrentStatus]
  );

  // Fetch States
  useEffect(() => {
    if (!hasBeenSentToOasis && !states.length) {
      dispatch(getIndicationStates());
    }
  }, [dispatch, states, hasBeenSentToOasis]);

  // Fetch individual risks
  const [individualRisksFetched, setIndividualRisksFetched] = useState(false);
  useEffect(() => {
    if (!hasBeenSentToOasis && !isLoadingIndividualRisks && !individualRisksFetched) {
      setIndividualRisksFetched(true);
      dispatch(getIndicationIndividualRisks(opportunityId, indicationId));
    }
  }, [
    dispatch,
    individualRisks,
    opportunityId,
    indicationId,
    individualRisksFetched,
    isLoadingIndividualRisks,
    hasBeenSentToOasis,
  ]);

  // Fetch organizational risks
  const [organizationalRisksFetched, setOrganizationalRisksFetched] = useState(false);
  useEffect(() => {
    if (!hasBeenSentToOasis && !isLoadingOrganizationRisks && !organizationalRisksFetched) {
      setOrganizationalRisksFetched(true);
      dispatch(getIndicationOrganizationRisks(opportunityId, indicationId));
    }
  }, [
    dispatch,
    individualRisks,
    opportunityId,
    indicationId,
    isLoadingOrganizationRisks,
    organizationalRisksFetched,
    hasBeenSentToOasis,
  ]);

  const specialtiesAlreadyFetched = useCallback(
    stateCode =>
      selectedIndication &&
      !!stateCode &&
      !!riskSpecialties[selectedIndication.id] &&
      !!riskSpecialties[selectedIndication.id][stateCode],
    [riskSpecialties, selectedIndication]
  );

  const specialtyStatesToFetch = useMemo(() => {
    if (!individualRisksFetched) return [];
    return uniq(individualRisks.filter(r => r.indicationId === indicationId).map(r => r.stateCode)).filter(
      s => !!keyedIndicationStates[s] && !specialtiesAlreadyFetched(s)
    );
  }, [indicationId, individualRisks, individualRisksFetched, keyedIndicationStates, specialtiesAlreadyFetched]);

  // Fetch specialties
  const [specialtiesFetched, setSpecialtiesFetched] = useState(false);
  useEffect(() => {
    if (
      !hasBeenSentToOasis &&
      !isLoadingRiskSpecialties &&
      selectedIndication &&
      !specialtiesFetched &&
      specialtyStatesToFetch.length
    ) {
      const { id, issueCompanyId, policyTypeCode } = selectedIndication;
      setSpecialtiesFetched(true);
      dispatch(getIndicationRiskSpecialties(specialtyStatesToFetch, issueCompanyId, policyTypeCode, id));
    }
  }, [
    dispatch,
    isLoadingRiskSpecialties,
    selectedIndication,
    specialtiesFetched,
    specialtyStatesToFetch,
    hasBeenSentToOasis,
  ]);

  const riskSpecialtiesValid = useMemo(() => {
    if (!indicationId || !individualRisksFetched) return false;
    return individualRisks.every(({ stateCode, specialtyCode }) => {
      if (!stateCode || !specialtyCode || !riskSpecialties[indicationId] || !riskSpecialties[indicationId][stateCode])
        return false;
      return !!riskSpecialties[indicationId][stateCode].find(s => s.code === specialtyCode);
    });
  }, [indicationId, individualRisks, individualRisksFetched, riskSpecialties]);

  /**
   * Business rule - Must be disabled when:
   *   - Already in this status
   *   - When not in the correct status (still enabled when in `OfferOfCoverageReadyToBeDelivered` status)
   *   - When `policyHolderId` has not been set
   *   - When no ind or org risks exist
   *   - When ind or org risks don't have an oasis client id set, or aren't yet confirmed to be new
   *   - When `policyId` exists (already sent to Oasis)
   */
  const sendToOasisDisabled = useMemo(
    () =>
      isReadonly ||
      isLoadingIndicationStatus ||
      isCreatingOasisQuote ||
      isCurrentStatus(IndicationStatus.ReadyToBeDelivered) ||
      isCurrentStatus(IndicationStatus.SentForExperienceRating) ||
      isCurrentStatus(IndicationStatus.CreatedInOasis) ||
      !selectedIndication ||
      !!selectedIndication.policyId ||
      !selectedIndication.policyHolderId ||
      !isValidAddress({
        addressStreet: selectedIndication.policyHolderAddressStreet,
        addressCity: selectedIndication.policyHolderAddressCity,
        addressStateCode: selectedIndication.policyHolderAddressStateCode,
        addressZipCode: selectedIndication.policyHolderAddressZipCode,
      }) ||
      individualRisks.length + organizationalRisks.length === 0 ||
      !individualRisks.every(
        ({ isConfirmedNew, oasisClientId, oasisNumberId }) => isConfirmedNew || oasisClientId || oasisNumberId
      ) ||
      !riskSpecialtiesValid ||
      !organizationalRisks.every(
        ({ isConfirmedNew, oasisClientId, oasisNumberId }) => isConfirmedNew || oasisClientId || oasisNumberId
      ),
    [
      isReadonly,
      isLoadingIndicationStatus,
      isCurrentStatus,
      isCreatingOasisQuote,
      individualRisks,
      organizationalRisks,
      selectedIndication,
      riskSpecialtiesValid,
    ]
  );

  const sendToOasisButton = (
    <CuriButton
      onClick={handleCreateOasisQuote}
      className={classes.button}
      color="secondary"
      disabled={sendToOasisDisabled}
      isLoading={isCreatingOasisQuote}
    >
      Send Indication to Oasis
    </CuriButton>
  );

  const disabledSendToOasisButton = (
    <Tooltip
      title={
        <>
          <span>Make sure you have:</span>
          <ul className={classes.disabledTooltipList}>
            <li>Selected a policyholder on the Risks page</li>
            <li>Entered a New Client Address on the Policy Information page</li>
            <li>Confirmed all risks are new or have an Oasis Client ID</li>
          </ul>
        </>
      }
    >
      {/* span required for hover events when button is disabled */}
      <span>{sendToOasisButton}</span>
    </Tooltip>
  );

  return (
    <>
      <SectionContainer borderRadius={BORDER_RADIUS.ROUNDED}>
        <Box mb={2} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <SectionHeader noTitleMargin noUnderline>
            Indication/Quote Versions in Oasis
          </SectionHeader>
          {userHas(Permissions.SEND_TO_OASIS) && (sendToOasisDisabled ? disabledSendToOasisButton : sendToOasisButton)}
        </Box>
        <OasisQuoteVersionsTable opportunityId={opportunityId} indication={selectedIndication} />
      </SectionContainer>
      <IndicationRiskCountWarningDialog open={showRiskCountWarning} onSubmit={() => setShowRiskCountWarning(false)} />
    </>
  );
}

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(2),
  },
  disabledTooltipList: {
    marginTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
  },
  progress: {
    marginLeft: theme.spacing(1),
  },
}));

export default IndicationSummary;
