import produce from 'immer';

import { GET_APP_CONFIG_FAILURE, GET_APP_CONFIG_REQUEST, GET_APP_CONFIG_SUCCESS } from './appConfig.actions';

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

// eslint-disable-next-line default-param-last
export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_APP_CONFIG_REQUEST:
      draft.isLoading = true;
      draft.error = null;
      break;

    case GET_APP_CONFIG_SUCCESS:
      draft.isLoading = false;
      draft.data = action.payload;
      break;

    case GET_APP_CONFIG_FAILURE:
      draft.isLoading = false;
      draft.error = action.payload;
      break;

    default:
      break;
  }
}, initialState);
