// eslint-disable-next-line import/prefer-default-export
export function isValidAddress(addressData) {
  if (!addressData) {
    return false;
  }

  const { addressStreet, addressCity, addressCountyCode, addressStateCode, addressZipCode, riskCounties } = addressData;
  const countyCodeIsValid =
    !addressCountyCode ||
    (riskCounties[addressStateCode] && riskCounties[addressStateCode].find(c => c.code === addressCountyCode));
  return Boolean(
    addressStreet &&
      addressCity &&
      addressStateCode &&
      addressZipCode &&
      addressZipCode.length === 5 &&
      countyCodeIsValid
  );
}
