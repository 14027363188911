import React from 'react';
import PropTypes from 'prop-types';
import { Typography, CircularProgress, withStyles } from '@material-ui/core';

function Loading({ classes, text, disableShrink }) {
  return (
    <div className={classes.loadingWrapper}>
      <CircularProgress size={60} className={classes.spinner} disableShrink={disableShrink} />
      {text && <Typography variant="h6">{text}</Typography>}
    </div>
  );
}

const styles = () => ({
  loadingWrapper: {
    textAlign: 'center',
    margin: 'auto',
  },
  spinner: {
    marginBottom: '2rem',
  },
});

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
  disableShrink: PropTypes.bool,
  text: PropTypes.string,
};

Loading.defaultProps = {
  text: '',
  disableShrink: false,
};

export default withStyles(styles)(Loading);
