export default Object.freeze({
  Open: 'OPEN',
  InReview: 'IN_REVIEW',
  Paused: 'PAUSED',
  Sent: 'SENT',
  Archived: 'ARCHIVED',
});

// special case
export const OpportunityStatusCodeUnpaused = 'UNPAUSED';

export const OpportunityStage = Object.freeze({
  Active: 'ACTIVE',
  Archived: 'ARCHIVED',
});

export const OpportunityAssignmentStatus = Object.freeze({
  Assigned: 'ASSIGNED',
  Unassigned: 'UNASSIGNED',
});

export const CURRENT_OPPORTUNITIES = 'CURRENT_OPPORTUNITIES';
