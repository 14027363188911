export const GET_APP_CONFIG_FAILURE = 'GET_APP_CONFIG_FAILURE';
export const GET_APP_CONFIG_REQUEST = 'GET_APP_CONFIG_REQUEST';
export const GET_APP_CONFIG_SUCCESS = 'GET_APP_CONFIG_SUCCESS';

export const getAppConfig = () => async dispatch => {
  dispatch({
    type: GET_APP_CONFIG_REQUEST,
  });

  try {
    const response = await fetch('/config.json');
    if (response.status !== 200) {
      throw new Error(`Failed request, status=${response.status}`);
    }

    const appConfig = await response.json();
    dispatch({
      type: GET_APP_CONFIG_SUCCESS,
      payload: appConfig,
    });
  } catch (err) {
    console.error('Failed to fetch app config', err);
    dispatch({ type: GET_APP_CONFIG_FAILURE, payload: 'Failed to fetch application configuration' });
  }
};
