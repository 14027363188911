import './wdyr'; // needs to be the first import

import 'core-js/stable';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import './styles/index.css';

import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Router } from 'react-router-dom';
import { render } from 'react-dom';
import * as serviceWorker from 'serviceWorker';
import React from 'react';
import MomentUtils from '@date-io/moment';

import theme from 'styles/theme';
import Root from 'redux/root.container';
import store from 'redux/store';
import history from './browserHistory';

console.log('version:', process.env.REACT_APP_VERSION);
const BASENAME = process.env.PUBLIC_URL || '/';

startApp();

function startApp() {
  render(
    <Router basename={BASENAME} history={history}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Root store={store} />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </Router>,
    // eslint-disable-next-line no-undef
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
