import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip, Button, Icon, Box } from '@material-ui/core';
import { Check, ErrorOutline, AddCircleOutline } from '@material-ui/icons';

const COPY = 'Copy Oasis Client ID';
const COPIED = 'Copied!';
const COPY_ERROR = 'Could not copy ID!';

function OasisPartyIconButton({ indicationRisk, handleClick, disabled }) {
  const [copyTooltipText, setCopyTooltipText] = useState(COPY);

  const handleCopyClientId = useCallback(async oasisId => {
    try {
      await navigator.clipboard.writeText(oasisId);
      setCopyTooltipText(COPIED);
    } catch (error) {
      setCopyTooltipText(COPY_ERROR);
    }
  }, []);

  if (indicationRisk) {
    const { isConfirmedNew, oasisClientId, oasisSearchResults } = indicationRisk;

    let tooltipContent;
    let tooltipText;
    let displayOasisClientId = oasisClientId;

    if (oasisClientId && !isConfirmedNew) {
      // An Oasis client id has been selected and already existed in Oasis
      tooltipText = `An Oasis Client ID has been matched: ${oasisClientId}`;
    } else if (oasisClientId && isConfirmedNew) {
      // An Oasis client id was created by CPQ
      tooltipText = `An Oasis Client ID was created by CPQ: ${oasisClientId}`;
      displayOasisClientId = `${oasisClientId}*`;
    } else if (isConfirmedNew) {
      // An Oasis client id has been confirmed to be a new Oasis party
      tooltipContent = <Check color="primary" />;
      tooltipText = 'Confirmed to be a new Oasis Client.';
    } else if (!Array.isArray(oasisSearchResults)) {
      // Search results for Oasis parties have not yet been fetched, hide icon
      tooltipContent = null;
      tooltipText = '';
    } else if (oasisSearchResults.length) {
      // Search results for Oasis parties have been fetched, possible matches exist
      tooltipContent = <ErrorOutline color="error" />;
      tooltipText = 'Exact match not found. Please review search results.';
    } else {
      // Search results for Oasis parties have been fetched, no possible matches exist
      tooltipContent = <AddCircleOutline color="action" />;
      tooltipText = 'An Oasis Client ID needs to be created';
    }

    return (
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        {oasisClientId ? (
          <>
            <Tooltip title={tooltipText} placement="top-start" arrow>
              <span>
                <Button disabled={disabled} color="primary" onClick={handleClick} variant="text" size="small">
                  {displayOasisClientId}
                </Button>
              </span>
            </Tooltip>
            <Tooltip
              title={copyTooltipText}
              placement="top"
              arrow
              // Allow fade animation to complete before resetting text
              onClose={() => setTimeout(() => setCopyTooltipText(COPY), 150)}
            >
              <IconButton size="small" onClick={() => handleCopyClientId(oasisClientId)}>
                <Icon className="fal fa-clipboard" />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <Tooltip title={tooltipText} placement="top-start" arrow>
            <span>
              <IconButton disabled={disabled} size="small" onClick={handleClick}>
                {tooltipContent}
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Box>
    );
  }
  return null;
}

OasisPartyIconButton.propTypes = {
  indicationRisk: PropTypes.object,
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
};

OasisPartyIconButton.defaultProps = {
  indicationRisk: null,
  handleClick: () => undefined,
  disabled: false,
};

export default OasisPartyIconButton;
