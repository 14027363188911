import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

export const selectDiscussionState = createSelector(
  state => state.discussion,
  discussionState => discussionState
);

export const selectDiscussion = createSelector(selectDiscussionState, discussionState =>
  discussionState.result ? discussionState.result : null
);

export const selectIsLoadingDiscussion = createSelector(
  selectDiscussionState,
  discussionState => discussionState.isLoading
);

export const useDiscussion = () => {
  const discussion = useSelector(selectDiscussion);
  const isLoadingDiscussion = useSelector(selectIsLoadingDiscussion);
  return {
    discussion,
    isLoadingDiscussion,
  };
};
