/* eslint-disable */
import qs from 'query-string';

import { CALL_API } from 'middleware/api';
import OpportunityStatus, {
  OpportunityAssignmentStatus,
  OpportunityStage,
  OpportunityStatusCodeUnpaused,
} from 'modules/opportunities/opportunityStatus';

export const ASSIGNED_TAB = 'ASSIGNED';
export const UNASSIGNED_TAB = 'UNASSIGNED';
export const SET_OPPORTUNITIES_CURRENT_TAB = 'SET_OPPORTUNITIES_CURRENT_TAB';

export const SET_ASSIGNED_UNDERWRITER_FILTER = 'SET_ASSIGNED_UNDERWRITER_FILTER';
export const SET_ASSIGNED_STATUS_FILTER = 'SET_ASSIGNED_STATUS_FILTER';
export const SET_UNASSIGNED_STATUS_FILTER = 'SET_UNASSIGNED_STATUS_FILTER';

export const GET_OPPORTUNITIES_FAILURE = 'GET_OPPORTUNITIES_FAILURE';
export const GET_OPPORTUNITIES_REQUEST = 'GET_OPPORTUNITIES_REQUEST';
export const GET_OPPORTUNITIES_SUCCESS = 'GET_OPPORTUNITIES_SUCCESS';

export const GET_RELATED_OPPORTUNITIES_FAILURE = 'GET_RELATED_OPPORTUNITIES_FAILURE';
export const GET_RELATED_OPPORTUNITIES_REQUEST = 'GET_RELATED_OPPORTUNITIES_REQUEST';
export const GET_RELATED_OPPORTUNITIES_SUCCESS = 'GET_RELATED_OPPORTUNITIES_SUCCESS';

export const RESET_RELATED_OPPORTUNITIES = 'RESET_RELATED_OPPORTUNITIES';

export const GET_OPPORTUNITY_FAILURE = 'GET_OPPORTUNITY_FAILURE';
export const GET_OPPORTUNITY_REQUEST = 'GET_OPPORTUNITY_REQUEST';
export const GET_OPPORTUNITY_SUCCESS = 'GET_OPPORTUNITY_SUCCESS';

export const GET_OPPORTUNITY_BY_SF_FAILURE = 'GET_OPPORTUNITY_BY_SF_FAILURE';
export const GET_OPPORTUNITY_BY_SF_REQUEST = 'GET_OPPORTUNITY_BY_SF_REQUEST';
export const GET_OPPORTUNITY_BY_SF_SUCCESS = 'GET_OPPORTUNITY_BY_SF_SUCCESS';

export const UPDATE_OPPORTUNITY_FAILURE = 'UPDATE_OPPORTUNITY_FAILURE';
export const UPDATE_OPPORTUNITY_REQUEST = 'UPDATE_OPPORTUNITY_REQUEST';
export const UPDATE_OPPORTUNITY_SUCCESS = 'UPDATE_OPPORTUNITY_SUCCESS';

export const REFRESH_OPPORTUNITY_FAILURE = 'REFRESH_OPPORTUNITY_FAILURE';
export const REFRESH_OPPORTUNITY_REQUEST = 'REFRESH_OPPORTUNITY_REQUEST';
export const REFRESH_OPPORTUNITY_SUCCESS = 'REFRESH_OPPORTUNITY_SUCCESS';

export const UPDATE_OPPORTUNITY_NOTES_FAILURE = 'UPDATE_OPPORTUNITY_NOTES_FAILURE';
export const UPDATE_OPPORTUNITY_NOTES_REQUEST = 'UPDATE_OPPORTUNITY_NOTES_REQUEST';
export const UPDATE_OPPORTUNITY_NOTES_SUCCESS = 'UPDATE_OPPORTUNITY_NOTES_SUCCESS';

export const GET_OPPORTUNITY_METADATA_FAILURE = 'GET_OPPORTUNITY_METADATA_FAILURE';
export const GET_OPPORTUNITY_METADATA_REQUEST = 'GET_OPPORTUNITY_METADATA_REQUEST';
export const GET_OPPORTUNITY_METADATA_SUCCESS = 'GET_OPPORTUNITY_METADATA_SUCCESS';

export const GET_POLICY_HIERARCHY_FAILURE = 'GET_POLICY_HIERARCHY_FAILURE';
export const GET_POLICY_HIERARCHY_REQUEST = 'GET_POLICY_HIERARCHY_REQUEST';
export const GET_POLICY_HIERARCHY_SUCCESS = 'GET_POLICY_HIERARCHY_SUCCESS';

export const GET_OPPORTUNITY_ATTACHMENTS_FAILURE = 'GET_OPPORTUNITY_ATTACHMENTS_FAILURE';
export const GET_OPPORTUNITY_ATTACHMENTS_REQUEST = 'GET_OPPORTUNITY_ATTACHMENTS_REQUEST';
export const GET_OPPORTUNITY_ATTACHMENTS_SUCCESS = 'GET_OPPORTUNITY_ATTACHMENTS_SUCCESS';

export const REFRESH_OPPORTUNITY_ATTACHMENTS_FAILURE = 'REFRESH_OPPORTUNITY_ATTACHMENTS_FAILURE';
export const REFRESH_OPPORTUNITY_ATTACHMENTS_REQUEST = 'REFRESH_OPPORTUNITY_ATTACHMENTS_REQUEST';
export const REFRESH_OPPORTUNITY_ATTACHMENTS_SUCCESS = 'REFRESH_OPPORTUNITY_ATTACHMENTS_SUCCESS';

export const UPDATE_OPPORTUNITY_ATTACHMENT_FAILURE = 'UPDATE_OPPORTUNITY_ATTACHMENT_FAILURE';
export const UPDATE_OPPORTUNITY_ATTACHMENT_REQUEST = 'UPDATE_OPPORTUNITY_ATTACHMENT_REQUEST';
export const UPDATE_OPPORTUNITY_ATTACHMENT_SUCCESS = 'UPDATE_OPPORTUNITY_ATTACHMENT_SUCCESS';

export const GET_OPPORTUNITY_ACTIVITY_FAILURE = 'GET_OPPORTUNITY_ACTIVITY_FAILURE';
export const GET_OPPORTUNITY_ACTIVITY_REQUEST = 'GET_OPPORTUNITY_ACTIVITY_REQUEST';
export const GET_OPPORTUNITY_ACTIVITY_SUCCESS = 'GET_OPPORTUNITY_ACTIVITY_SUCCESS';

export const GET_UNDERWRITERS_FAILURE = 'GET_UNDERWRITERS_FAILURE';
export const GET_UNDERWRITERS_REQUEST = 'GET_UNDERWRITERS_REQUEST';
export const GET_UNDERWRITERS_SUCCESS = 'GET_UNDERWRITERS_SUCCESS';

export const ASSIGN_OPPORTUNITY_FAILURE = 'ASSIGN_OPPORTUNITY_FAILURE';
export const ASSIGN_OPPORTUNITY_REQUEST = 'ASSIGN_OPPORTUNITY_REQUEST';
export const ASSIGN_OPPORTUNITY_SUCCESS = 'ASSIGN_OPPORTUNITY_SUCCESS';

export const REVIEW_OPPORTUNITY_FAILURE = 'REVIEW_OPPORTUNITY_FAILURE';
export const REVIEW_OPPORTUNITY_REQUEST = 'REVIEW_OPPORTUNITY_REQUEST';
export const REVIEW_OPPORTUNITY_SUCCESS = 'REVIEW_OPPORTUNITY_SUCCESS';

export const PAUSE_OPPORTUNITY_FAILURE = 'PAUSE_OPPORTUNITY_FAILURE';
export const PAUSE_OPPORTUNITY_REQUEST = 'PAUSE_OPPORTUNITY_REQUEST';
export const PAUSE_OPPORTUNITY_SUCCESS = 'PAUSE_OPPORTUNITY_SUCCESS';

export const UNPAUSE_OPPORTUNITY_FAILURE = 'UNPAUSE_OPPORTUNITY_FAILURE';
export const UNPAUSE_OPPORTUNITY_REQUEST = 'UNPAUSE_OPPORTUNITY_REQUEST';
export const UNPAUSE_OPPORTUNITY_SUCCESS = 'UNPAUSE_OPPORTUNITY_SUCCESS';

export const ARCHIVE_OPPORTUNITY_FAILURE = 'ARCHIVE_OPPORTUNITY_FAILURE';
export const ARCHIVE_OPPORTUNITY_REQUEST = 'ARCHIVE_OPPORTUNITY_REQUEST';
export const ARCHIVE_OPPORTUNITY_SUCCESS = 'ARCHIVE_OPPORTUNITY_SUCCESS';

export const SEND_OPPORTUNITY_NOTIFICATION_FAILURE = 'SEND_OPPORTUNITY_NOTIFICATION_FAILURE';
export const SEND_OPPORTUNITY_NOTIFICATION_REQUEST = 'SEND_OPPORTUNITY_NOTIFICATION_REQUEST';
export const SEND_OPPORTUNITY_NOTIFICATION_SUCCESS = 'SEND_OPPORTUNITY_NOTIFICATION_SUCCESS';

export const RESET_OPPORTUNITY = 'RESET_OPPORTUNITY';

export const SET_SIDEBAR_OPEN = 'SET_SIDEBAR_OPEN';

export const GET_OPPORTUNITY_ATTACHMENT_DOWNLOAD_URL_REQUEST = 'GET_OPPORTUNITY_ATTACHMENT_DOWNLOAD_URL_REQUEST';
export const GET_OPPORTUNITY_ATTACHMENT_DOWNLOAD_URL_SUCCESS = 'GET_OPPORTUNITY_ATTACHMENT_DOWNLOAD_URL_SUCCESS';
export const GET_OPPORTUNITY_ATTACHMENT_DOWNLOAD_URL_FAILURE = 'GET_OPPORTUNITY_ATTACHMENT_DOWNLOAD_URL_FAILURE';

export const COPY_OPPORTUNITY_ATTACHMENT_TO_INDICATION_REQUEST = 'COPY_OPPORTUNITY_ATTACHMENT_TO_INDICATION_REQUEST';
export const COPY_OPPORTUNITY_ATTACHMENT_TO_INDICATION_SUCCESS = 'COPY_OPPORTUNITY_ATTACHMENT_TO_INDICATION_SUCCESS';
export const COPY_OPPORTUNITY_ATTACHMENT_TO_INDICATION_FAILURE = 'COPY_OPPORTUNITY_ATTACHMENT_TO_INDICATION_FAILURE';

export const setOpportunitiesCurrentTab = currentTab => ({
  type: SET_OPPORTUNITIES_CURRENT_TAB,
  payload: currentTab,
});

export const setAssigneeFilter = assigneeIds => ({
  type: SET_ASSIGNED_UNDERWRITER_FILTER,
  payload: assigneeIds,
});

export const setAssignedStatusFilter = opportunityStatus => ({
  type: SET_ASSIGNED_STATUS_FILTER,
  payload: opportunityStatus,
});

export const setUnassignedStatusFilter = opportunityStatus => ({
  type: SET_UNASSIGNED_STATUS_FILTER,
  payload: opportunityStatus,
});

export const getOpportunities = params => {
  const stage = params && params.stage ? params.stage : OpportunityStage.Active;
  const assigneeIds =
    params && Array.isArray(params.assigneeIds) && params.assigneeIds.length > 0 ? params.assigneeIds : undefined;
  const assignmentStatus =
    params && params.assignmentStatus ? params.assignmentStatus : OpportunityAssignmentStatus.Assigned;

  const queryString = qs.stringify(
    {
      stage,
      assigneeIds,
      assignmentStatus,
    },
    { arrayFormat: 'comma' }
  );

  return {
    [CALL_API]: {
      types: [GET_OPPORTUNITIES_REQUEST, GET_OPPORTUNITIES_SUCCESS, GET_OPPORTUNITIES_FAILURE],
      authenticated: true,
      endpoint: `v2/opportunities?${queryString}`,
      method: 'GET',
    },
  };
};

export const getRelatedOpportunities = opportunityId => {
  return {
    [CALL_API]: {
      types: [GET_RELATED_OPPORTUNITIES_REQUEST, GET_RELATED_OPPORTUNITIES_SUCCESS, GET_RELATED_OPPORTUNITIES_FAILURE],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/related`,
      method: 'GET',
    },
  };
};

export const resetRelatedOpportunities = () => ({
  type: RESET_RELATED_OPPORTUNITIES,
});

export const getOpportunity = opportunityId => {
  return {
    [CALL_API]: {
      types: [GET_OPPORTUNITY_REQUEST, GET_OPPORTUNITY_SUCCESS, GET_OPPORTUNITY_FAILURE],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}`,
      method: 'GET',
    },
  };
};

export const getOpportunityBySalesforceId = salesforceId => {
  return {
    [CALL_API]: {
      types: [GET_OPPORTUNITY_BY_SF_REQUEST, GET_OPPORTUNITY_BY_SF_SUCCESS, GET_OPPORTUNITY_BY_SF_FAILURE],
      authenticated: true,
      endpoint: `v1/sf-opportunities/${salesforceId}`,
      method: 'GET',
    },
  };
};

export const updateOpportunity = (opportunityId, nextOpportunity) => {
  return {
    [CALL_API]: {
      types: [UPDATE_OPPORTUNITY_REQUEST, UPDATE_OPPORTUNITY_SUCCESS, UPDATE_OPPORTUNITY_FAILURE],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}`,
      method: 'PATCH',
      payload: nextOpportunity,
    },
  };
};

export const refreshOpportunity = opportunityId => {
  return {
    [CALL_API]: {
      types: [REFRESH_OPPORTUNITY_REQUEST, REFRESH_OPPORTUNITY_SUCCESS, REFRESH_OPPORTUNITY_FAILURE],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/refresh`,
      method: 'POST',
    },
  };
};

export const updateOpportunityNotes = (opportunityId, notes) => {
  return {
    [CALL_API]: {
      types: [UPDATE_OPPORTUNITY_NOTES_REQUEST, UPDATE_OPPORTUNITY_NOTES_SUCCESS, UPDATE_OPPORTUNITY_NOTES_FAILURE],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/notes`,
      method: 'PUT',
      payload: { notes },
    },
  };
};

export const getMetadata = () => {
  return {
    [CALL_API]: {
      types: [GET_OPPORTUNITY_METADATA_REQUEST, GET_OPPORTUNITY_METADATA_SUCCESS, GET_OPPORTUNITY_METADATA_FAILURE],
      authenticated: true,
      endpoint: `v1/referenceData/metadata`,
      method: 'GET',
    },
  };
};

export const getPolicyHierarchy = () => {
  return {
    [CALL_API]: {
      types: [GET_POLICY_HIERARCHY_REQUEST, GET_POLICY_HIERARCHY_SUCCESS, GET_POLICY_HIERARCHY_FAILURE],
      authenticated: true,
      endpoint: `v1/referenceData/policyHierarchy`,
      method: 'GET',
    },
  };
};

export const getOpportunityAttachments = opportunityId => {
  return {
    [CALL_API]: {
      types: [
        GET_OPPORTUNITY_ATTACHMENTS_REQUEST,
        GET_OPPORTUNITY_ATTACHMENTS_SUCCESS,
        GET_OPPORTUNITY_ATTACHMENTS_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/attachments`,
      method: 'GET',
    },
  };
};

export const refreshOpportunityAttachments = opportunityId => {
  return {
    [CALL_API]: {
      types: [
        REFRESH_OPPORTUNITY_ATTACHMENTS_REQUEST,
        REFRESH_OPPORTUNITY_ATTACHMENTS_SUCCESS,
        REFRESH_OPPORTUNITY_ATTACHMENTS_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/attachments/refresh`,
      method: 'POST',
    },
  };
};

export const getOpportunityAttachmentDownloadUrl = (opportunityId, attachmentId) => {
  return {
    [CALL_API]: {
      types: [
        GET_OPPORTUNITY_ATTACHMENT_DOWNLOAD_URL_REQUEST,
        GET_OPPORTUNITY_ATTACHMENT_DOWNLOAD_URL_SUCCESS,
        GET_OPPORTUNITY_ATTACHMENT_DOWNLOAD_URL_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/attachments/${attachmentId}/downloadUrl`,
      method: 'GET',
    },
  };
};

export const updateOpportunityAttachment = (opportunityId, attachment) => {
  return {
    [CALL_API]: {
      types: [
        UPDATE_OPPORTUNITY_ATTACHMENT_REQUEST,
        UPDATE_OPPORTUNITY_ATTACHMENT_SUCCESS,
        UPDATE_OPPORTUNITY_ATTACHMENT_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/attachments/${attachment.id}`,
      method: 'PATCH',
      payload: attachment,
    },
  };
};

export const copyOpportunityAttachmentToIndication = (opportunityId, attachmentId, indicationId) => {
  return {
    [CALL_API]: {
      types: [
        COPY_OPPORTUNITY_ATTACHMENT_TO_INDICATION_REQUEST,
        COPY_OPPORTUNITY_ATTACHMENT_TO_INDICATION_SUCCESS,
        COPY_OPPORTUNITY_ATTACHMENT_TO_INDICATION_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/attachments/${attachmentId}/copy`,
      method: 'POST',
      payload: { indicationId },
      actionMetadata: { attachmentId },
    },
  };
};

export const getOpportunityActivity = opportunityId => {
  return {
    [CALL_API]: {
      types: [GET_OPPORTUNITY_ACTIVITY_REQUEST, GET_OPPORTUNITY_ACTIVITY_SUCCESS, GET_OPPORTUNITY_ACTIVITY_FAILURE],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/activity`,
      method: 'GET',
    },
  };
};

export const getUnderwriters = () => {
  return {
    [CALL_API]: {
      types: [GET_UNDERWRITERS_REQUEST, GET_UNDERWRITERS_SUCCESS, GET_UNDERWRITERS_FAILURE],
      authenticated: true,
      endpoint: 'v1/users/assignable',
      method: 'GET',
    },
  };
};

export const assignOpportunity = (opportunityId, assigneeIds) => {
  return {
    [CALL_API]: {
      types: [ASSIGN_OPPORTUNITY_REQUEST, ASSIGN_OPPORTUNITY_SUCCESS, ASSIGN_OPPORTUNITY_FAILURE],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/assign`,
      method: 'PUT',
      payload: { assigneeIds },
      actionMetadata: {
        opportunityId,
        assigneeIds,
      },
    },
  };
};

export const reviewOpportunity = opportunityId => {
  return {
    [CALL_API]: {
      types: [REVIEW_OPPORTUNITY_REQUEST, REVIEW_OPPORTUNITY_SUCCESS, REVIEW_OPPORTUNITY_FAILURE],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/status`,
      method: 'POST',
      payload: {
        code: OpportunityStatus.InReview,
      },
      actionMetadata: {
        opportunityId,
      },
    },
  };
};

export const pauseOpportunity = opportunityId => {
  return {
    [CALL_API]: {
      types: [PAUSE_OPPORTUNITY_REQUEST, PAUSE_OPPORTUNITY_SUCCESS, PAUSE_OPPORTUNITY_FAILURE],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/status`,
      method: 'POST',
      payload: {
        code: OpportunityStatus.Paused,
      },
      actionMetadata: {
        opportunityId,
      },
    },
  };
};

export const unpauseOpportunity = opportunityId => {
  return {
    [CALL_API]: {
      types: [UNPAUSE_OPPORTUNITY_REQUEST, UNPAUSE_OPPORTUNITY_SUCCESS, UNPAUSE_OPPORTUNITY_FAILURE],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/status`,
      method: 'POST',
      payload: {
        code: OpportunityStatusCodeUnpaused,
      },
      actionMetadata: {
        opportunityId,
      },
    },
  };
};

export const archiveOpportunity = (opportunityId, notes) => {
  return {
    [CALL_API]: {
      types: [ARCHIVE_OPPORTUNITY_REQUEST, ARCHIVE_OPPORTUNITY_SUCCESS, ARCHIVE_OPPORTUNITY_FAILURE],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/status`,
      method: 'POST',
      payload: {
        code: OpportunityStatus.Archived,
        notes,
      },
      actionMetadata: {
        opportunityId,
      },
    },
  };
};

export const sendOpportunityNotification = (opportunityId, message) => {
  return {
    [CALL_API]: {
      types: [
        SEND_OPPORTUNITY_NOTIFICATION_REQUEST,
        SEND_OPPORTUNITY_NOTIFICATION_SUCCESS,
        SEND_OPPORTUNITY_NOTIFICATION_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/notification`,
      method: 'POST',
      payload: { message },
    },
  };
};

export const resetOpportunity = () => {
  return dispatch => {
    dispatch({
      type: RESET_OPPORTUNITY,
    });
  };
};

export const setSidebarOpen = isSidebarOpen => ({
  type: SET_SIDEBAR_OPEN,
  payload: isSidebarOpen,
});
