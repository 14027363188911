import produce from 'immer';
import OpportunityStatus, { CURRENT_OPPORTUNITIES } from 'modules/opportunities/opportunityStatus';
import {
  ASSIGNED_TAB,
  SET_OPPORTUNITIES_CURRENT_TAB,
  SET_ASSIGNED_UNDERWRITER_FILTER,
  SET_ASSIGNED_STATUS_FILTER,
  SET_UNASSIGNED_STATUS_FILTER,
  GET_OPPORTUNITIES_FAILURE,
  GET_OPPORTUNITIES_REQUEST,
  GET_OPPORTUNITIES_SUCCESS,
  GET_RELATED_OPPORTUNITIES_FAILURE,
  GET_RELATED_OPPORTUNITIES_REQUEST,
  GET_RELATED_OPPORTUNITIES_SUCCESS,
  RESET_RELATED_OPPORTUNITIES,
  GET_OPPORTUNITY_FAILURE,
  GET_OPPORTUNITY_REQUEST,
  GET_OPPORTUNITY_SUCCESS,
  GET_OPPORTUNITY_BY_SF_FAILURE,
  GET_OPPORTUNITY_BY_SF_REQUEST,
  GET_OPPORTUNITY_BY_SF_SUCCESS,
  UPDATE_OPPORTUNITY_FAILURE,
  UPDATE_OPPORTUNITY_REQUEST,
  UPDATE_OPPORTUNITY_SUCCESS,
  REFRESH_OPPORTUNITY_FAILURE,
  REFRESH_OPPORTUNITY_REQUEST,
  REFRESH_OPPORTUNITY_SUCCESS,
  UPDATE_OPPORTUNITY_NOTES_FAILURE,
  UPDATE_OPPORTUNITY_NOTES_REQUEST,
  UPDATE_OPPORTUNITY_NOTES_SUCCESS,
  GET_OPPORTUNITY_METADATA_FAILURE,
  GET_OPPORTUNITY_METADATA_REQUEST,
  GET_OPPORTUNITY_METADATA_SUCCESS,
  GET_POLICY_HIERARCHY_FAILURE,
  GET_POLICY_HIERARCHY_REQUEST,
  GET_POLICY_HIERARCHY_SUCCESS,
  GET_OPPORTUNITY_ATTACHMENTS_FAILURE,
  GET_OPPORTUNITY_ATTACHMENTS_REQUEST,
  GET_OPPORTUNITY_ATTACHMENTS_SUCCESS,
  REFRESH_OPPORTUNITY_ATTACHMENTS_FAILURE,
  REFRESH_OPPORTUNITY_ATTACHMENTS_REQUEST,
  REFRESH_OPPORTUNITY_ATTACHMENTS_SUCCESS,
  UPDATE_OPPORTUNITY_ATTACHMENT_SUCCESS,
  GET_OPPORTUNITY_ACTIVITY_FAILURE,
  GET_OPPORTUNITY_ACTIVITY_REQUEST,
  GET_OPPORTUNITY_ACTIVITY_SUCCESS,
  GET_UNDERWRITERS_FAILURE,
  GET_UNDERWRITERS_REQUEST,
  GET_UNDERWRITERS_SUCCESS,
  ASSIGN_OPPORTUNITY_FAILURE,
  ASSIGN_OPPORTUNITY_REQUEST,
  ASSIGN_OPPORTUNITY_SUCCESS,
  REVIEW_OPPORTUNITY_FAILURE,
  REVIEW_OPPORTUNITY_REQUEST,
  REVIEW_OPPORTUNITY_SUCCESS,
  PAUSE_OPPORTUNITY_FAILURE,
  PAUSE_OPPORTUNITY_REQUEST,
  PAUSE_OPPORTUNITY_SUCCESS,
  UNPAUSE_OPPORTUNITY_FAILURE,
  UNPAUSE_OPPORTUNITY_REQUEST,
  UNPAUSE_OPPORTUNITY_SUCCESS,
  ARCHIVE_OPPORTUNITY_FAILURE,
  ARCHIVE_OPPORTUNITY_REQUEST,
  ARCHIVE_OPPORTUNITY_SUCCESS,
  SEND_OPPORTUNITY_NOTIFICATION_FAILURE,
  SEND_OPPORTUNITY_NOTIFICATION_REQUEST,
  SEND_OPPORTUNITY_NOTIFICATION_SUCCESS,
  RESET_OPPORTUNITY,
  SET_SIDEBAR_OPEN,
  COPY_OPPORTUNITY_ATTACHMENT_TO_INDICATION_FAILURE,
  COPY_OPPORTUNITY_ATTACHMENT_TO_INDICATION_REQUEST,
  COPY_OPPORTUNITY_ATTACHMENT_TO_INDICATION_SUCCESS,
} from 'modules/opportunities/opportunities.actions';

const initialState = {
  isSidebarOpen: true,
  initialLoad: false,
  opportunitiesCurrentTab: ASSIGNED_TAB,
  assignedUnderwriterFilter: [],
  assignedStatusFilter: CURRENT_OPPORTUNITIES,
  unassignedStatusFilter: OpportunityStatus.Open,
  isLoading: false,
  isLoadingAttachments: false,
  isLoadingNotes: false,
  opportunities: {},
  isLoadingRelatedOpportunities: false,
  relatedOpportunities: null,
  attachments: null,
  isUploadingAttachment: false,
  isLoadingActivities: false,
  activities: {
    emailActivity: null,
    tasks: null,
  },
  isLoadingUnderwriters: false,
  underwriters: [],
  isAssigning: false,
  isUpdatingStatus: false,
  isLoadingMetadata: false,
  metadata: {
    opportunityStatuses: [],
    opportunityTypes: [],
    indicationStatuses: [],
    policyTypes: [],
    suffixes: [],
    attachmentCategories: [],
  },
  isLoadingPolicyHierarchy: false,
  policyHierarchy: null,
  isRefreshingAttachments: false,
  newAttachmentCount: 0,
  isSendingNotification: false,
  copyingAttachmentId: null,
};

// eslint-disable-next-line default-param-last
export default produce((draft = initialState, action) => {
  switch (action.type) {
    case SET_OPPORTUNITIES_CURRENT_TAB:
      draft.opportunitiesCurrentTab = action.payload;
      break;

    case SET_ASSIGNED_UNDERWRITER_FILTER:
      draft.assignedUnderwriterFilter = action.payload;
      break;

    case SET_ASSIGNED_STATUS_FILTER:
      draft.assignedStatusFilter = action.payload;
      break;

    case SET_UNASSIGNED_STATUS_FILTER:
      draft.unassignedStatusFilter = action.payload;
      break;

    case GET_OPPORTUNITIES_SUCCESS:
      draft.opportunities = { ...draft.opportunities, ...action.response.reduce((a, c) => ({ ...a, [c.id]: c }), {}) };
      draft.initialLoad = true;
      draft.isLoading = false;
      break;

    case GET_OPPORTUNITIES_REQUEST:
      draft.isLoading = true;
      break;

    case GET_OPPORTUNITIES_FAILURE:
      draft.initialLoad = true;
      draft.isLoading = false;
      break;

    case GET_RELATED_OPPORTUNITIES_FAILURE:
      draft.isLoadingRelatedOpportunities = false;
      break;

    case GET_RELATED_OPPORTUNITIES_REQUEST:
      draft.isLoadingRelatedOpportunities = true;
      break;

    case GET_RELATED_OPPORTUNITIES_SUCCESS:
      draft.relatedOpportunities = action.response;
      draft.isLoadingRelatedOpportunities = false;
      break;

    case RESET_RELATED_OPPORTUNITIES:
      draft.isLoadingRelatedOpportunities = false;
      draft.relatedOpportunities = {
        archived: null,
      };
      break;

    case GET_OPPORTUNITY_FAILURE:
    case GET_OPPORTUNITY_BY_SF_FAILURE:
      draft.isLoading = false;
      draft.initialLoad = true;
      break;

    case GET_OPPORTUNITY_REQUEST:
    case GET_OPPORTUNITY_BY_SF_REQUEST:
      draft.isLoading = true;
      break;

    case GET_OPPORTUNITY_SUCCESS:
    case GET_OPPORTUNITY_BY_SF_SUCCESS: {
      draft.isLoading = false;
      draft.initialLoad = true;
      const { response } = action;
      draft.opportunities = { ...draft.opportunities, [response.id]: response };
      break;
    }

    case UPDATE_OPPORTUNITY_FAILURE:
    case REFRESH_OPPORTUNITY_FAILURE:
      draft.isLoading = false;
      break;

    case UPDATE_OPPORTUNITY_REQUEST:
    case REFRESH_OPPORTUNITY_REQUEST:
      draft.isLoading = true;
      break;

    case UPDATE_OPPORTUNITY_SUCCESS:
    case REFRESH_OPPORTUNITY_SUCCESS: {
      draft.isLoading = false;
      const { response } = action;
      draft.opportunities = { ...draft.opportunities, [response.id]: response };
      break;
    }

    case UPDATE_OPPORTUNITY_NOTES_FAILURE:
      draft.isLoadingNotes = false;
      break;

    case UPDATE_OPPORTUNITY_NOTES_REQUEST:
      draft.isLoadingNotes = true;
      break;

    case UPDATE_OPPORTUNITY_NOTES_SUCCESS: {
      draft.isLoadingNotes = false;
      const { response } = action;
      draft.opportunities = { ...draft.opportunities, [response.id]: response };
      break;
    }

    case GET_OPPORTUNITY_METADATA_FAILURE:
      draft.isLoadingMetadata = false;
      break;

    case GET_OPPORTUNITY_METADATA_REQUEST:
      draft.isLoadingMetadata = true;
      break;

    case GET_OPPORTUNITY_METADATA_SUCCESS: {
      draft.isLoadingMetadata = false;
      draft.metadata = action.response;
      break;
    }

    case GET_POLICY_HIERARCHY_FAILURE:
      draft.isLoadingPolicyHierarchy = false;
      draft.policyHierarchy = [];
      break;

    case GET_POLICY_HIERARCHY_REQUEST:
      draft.isLoadingPolicyHierarchy = true;
      break;

    case GET_POLICY_HIERARCHY_SUCCESS:
      draft.isLoadingPolicyHierarchy = false;
      draft.policyHierarchy = action.response;
      break;

    case GET_OPPORTUNITY_ATTACHMENTS_SUCCESS:
      draft.attachments = action.response;
      draft.isLoadingAttachments = false;
      break;

    case GET_OPPORTUNITY_ATTACHMENTS_REQUEST:
      draft.isLoadingAttachments = true;
      break;

    case GET_OPPORTUNITY_ATTACHMENTS_FAILURE:
      draft.attachments = [];
      draft.isLoadingAttachments = false;
      break;

    case UPDATE_OPPORTUNITY_ATTACHMENT_SUCCESS: {
      const attachment = action.response;
      draft.attachments = draft.attachments.map(a => (a.id === attachment.id ? attachment : a));
      break;
    }

    case COPY_OPPORTUNITY_ATTACHMENT_TO_INDICATION_REQUEST: {
      draft.copyingAttachmentId = action.actionMetadata.attachmentId;
      break;
    }

    case COPY_OPPORTUNITY_ATTACHMENT_TO_INDICATION_SUCCESS:
    case COPY_OPPORTUNITY_ATTACHMENT_TO_INDICATION_FAILURE: {
      draft.copyingAttachmentId = null;
      break;
    }

    case GET_OPPORTUNITY_ACTIVITY_SUCCESS:
      draft.activities = action.response;
      draft.isLoadingActivities = false;
      break;

    case GET_OPPORTUNITY_ACTIVITY_REQUEST:
      draft.isLoadingActivities = true;
      break;

    case GET_OPPORTUNITY_ACTIVITY_FAILURE:
      draft.activities = {
        emailActivity: [],
        tasks: [],
      };
      draft.isLoadingActivities = false;
      break;

    case GET_UNDERWRITERS_FAILURE:
      draft.isLoadingUnderwriters = false;
      break;

    case GET_UNDERWRITERS_REQUEST:
      draft.isLoadingUnderwriters = true;
      break;

    case GET_UNDERWRITERS_SUCCESS:
      draft.isLoadingUnderwriters = false;
      draft.underwriters = action.response;
      break;

    case ASSIGN_OPPORTUNITY_FAILURE:
      draft.isAssigning = false;
      break;

    case ASSIGN_OPPORTUNITY_REQUEST:
      draft.isAssigning = true;
      break;

    case ASSIGN_OPPORTUNITY_SUCCESS: {
      draft.isAssigning = false;
      const { opportunityId } = action.actionMetadata;
      const opportunity = draft.opportunities[opportunityId];
      if (opportunity) {
        opportunity.assignees = action.response.assignees;
      }
      break;
    }

    case REVIEW_OPPORTUNITY_FAILURE:
    case PAUSE_OPPORTUNITY_FAILURE:
    case UNPAUSE_OPPORTUNITY_FAILURE:
    case ARCHIVE_OPPORTUNITY_FAILURE:
      draft.isUpdatingStatus = false;
      break;

    case REVIEW_OPPORTUNITY_REQUEST:
    case PAUSE_OPPORTUNITY_REQUEST:
    case UNPAUSE_OPPORTUNITY_REQUEST:
    case ARCHIVE_OPPORTUNITY_REQUEST:
      draft.isUpdatingStatus = true;
      break;

    case REVIEW_OPPORTUNITY_SUCCESS:
    case PAUSE_OPPORTUNITY_SUCCESS:
    case UNPAUSE_OPPORTUNITY_SUCCESS:
    case ARCHIVE_OPPORTUNITY_SUCCESS: {
      console.log('reducer', action.response);
      draft.isUpdatingStatus = false;
      const { opportunityId } = action.actionMetadata;
      const opportunity = draft.opportunities[opportunityId];
      if (opportunity) {
        opportunity.status = action.response;
      }
      break;
    }

    case REFRESH_OPPORTUNITY_ATTACHMENTS_FAILURE:
      draft.isRefreshingAttachments = false;
      break;

    case REFRESH_OPPORTUNITY_ATTACHMENTS_REQUEST:
      draft.isRefreshingAttachments = true;
      draft.newAttachmentCount = 0;
      break;

    case REFRESH_OPPORTUNITY_ATTACHMENTS_SUCCESS:
      draft.isRefreshingAttachments = false;
      draft.newAttachmentCount = action.response.attachmentCount;
      break;

    case SEND_OPPORTUNITY_NOTIFICATION_FAILURE:
    case SEND_OPPORTUNITY_NOTIFICATION_SUCCESS:
      draft.isSendingNotification = false;
      break;

    case SEND_OPPORTUNITY_NOTIFICATION_REQUEST:
      draft.isSendingNotification = true;
      break;

    case RESET_OPPORTUNITY:
      draft.attachments = null;
      draft.activities = null;
      break;

    case SET_SIDEBAR_OPEN:
      draft.isSidebarOpen = action.payload;
      break;

    default:
      break;
  }
}, initialState);
