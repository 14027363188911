import produce from 'immer';
import {
  HIDE_TOAST_MESSAGE,
  SET_PAGE_TITLE,
  SHOW_TOAST_MESSAGE,
  SET_INITIAL_ROUTE,
  TOAST_TYPES,
  TOGGLE_HIGH_CONTRAST_MODE,
} from 'modules/layout/layout.actions';

const initialState = {
  pageTitle: 'Home',
  isShowToast: false,
  toastMessage: '',
  toastType: TOAST_TYPES.SUCCESS,
  initialRoute: null,
  highContrastModeEnabled: false,
};

// eslint-disable-next-line default-param-last
export default produce((draft = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_TITLE:
      draft.pageTitle = action.payload;
      draft.breadcrumb = action.breadcrumb;
      draft.breadcrumbTitle = action.breadcrumbTitle;
      break;

    case SHOW_TOAST_MESSAGE:
      draft.isShowToast = true;
      draft.toastMessage = action.message;
      draft.toastType = action.toastType;
      break;

    case HIDE_TOAST_MESSAGE:
      draft.isShowToast = false;
      break;

    case SET_INITIAL_ROUTE: {
      let newInitialRoute = action.route;
      if (action.query) {
        newInitialRoute += `${action.query}`;
      }
      draft.initialRoute = newInitialRoute;
      break;
    }

    case TOGGLE_HIGH_CONTRAST_MODE: {
      draft.highContrastModeEnabled = action.payload;
      break;
    }

    default:
      break;
  }
}, initialState);
