import { Button, CircularProgress, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';

function CuriButton({ classes, className, children, customColor, isLoading, ...rest }) {
  return (
    <Button
      className={classNames(classes.root, className, {
        [classes[customColor]]: !isNil(customColor),
      })}
      {...rest}
    >
      {children}
      {isLoading && <CircularProgress className={classes.progress} size={18} />}
    </Button>
  );
}

const styles = theme => ({
  root: {
    boxShadow: 'none',
    borderRadius: 0,
  },
  success: {
    borderColor: theme.palette.success.main,
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  successOutline: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
    },
  },
  tertiary: {
    borderColor: theme.palette.tertiary.main,
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.tertiary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.dark,
    },
  },
  tertiaryOutline: {
    borderColor: theme.palette.tertiary.main,
    color: theme.palette.tertiary.main,
    '&:hover': {
      color: theme.palette.tertiary.contrastText,
      backgroundColor: theme.palette.tertiary.light,
    },
  },
  error: {
    borderColor: theme.palette.error.main,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  progress: {
    marginLeft: theme.spacing(1),
  },
});

CuriButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  isLoading: PropTypes.bool,
  /*
    Overrides:
    - className: custom classnames passed in as propr for any styling
    - color: these are the 4 default material-ui button options (ie. primary, secondary, etc.)
    - customColor: these are styles added to the styles object in this component
                   (you'll need to add the prop and new styles if new option other than yellow)
    - variant: these are the default material-ui button options (ie. contained, outlined, etc.)
  */
  className: PropTypes.string,
  color: PropTypes.string,
  customColor: PropTypes.string,
  variant: PropTypes.string,
};

CuriButton.defaultProps = {
  className: null,
  color: 'inherit',
  customColor: null,
  variant: 'contained',
  isLoading: false,
};

export default withStyles(styles)(CuriButton);
