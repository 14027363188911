import React from 'react';
import { Grid } from '@material-ui/core';

export function Container(props) {
  return <Grid container {...props} />;
}

export function Item(props) {
  return <Grid item {...props} />;
}
