/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { throttle } from 'lodash';
import { getAlerts } from 'modules/alerts/alerts.actions';
import {
  selectIsLoadingAlerts,
  selectSortedUnacknowledgedAlerts,
  selectIsAcknowledging,
} from 'modules/alerts/alerts.selectors';
import { isInitiallyLoaded } from 'modules/opportunities/opportunities.selectors';

const POLL_INTERVAL_MILLIS = 60 * 1000;

/**
 * A custom hook that handles the details of polling for alerts.
 *
 * As a convenience, this hook returns an object with properties:
 *   - `isPolling`, a boolean which indicates if a poll is currently running as controlled by the hook
 *   - `isLoadingAlerts`, a boolean which indicates if alerts are being loaded in any context
 *   - `alerts`, an array of sorted/filtered alerts
 *
 * Note: As currently implemented, using this hook in more than one component will result in duplicate polling
 *
 * ## Usage
 *
 * ```jsx
 * import { useAlertsPoll } from 'modules/alerts/alertsPoll';
 *
 * export const MyComponent = () => (
 *   const { isPolling, isLoadingAlerts, alerts } = useAlertsPoll();
 * );
 * ```
 */
export const useAlertsPoll = () => {
  const dispatch = useDispatch();
  const isLoadingAlerts = useSelector(selectIsLoadingAlerts);
  const isAcknowledging = useSelector(selectIsAcknowledging);
  const alerts = useSelector(selectSortedUnacknowledgedAlerts);
  const hasInitiallyLoaded = useSelector(isInitiallyLoaded);
  const [isPolling, setIsPolling] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  // Handle polling of alerts
  useEffect(() => {
    // Define throttled polling function
    const pollAlerts = throttle(
      () => {
        if (document.visibilityState === 'visible') {
          setIsPolling(true);
          dispatch(getAlerts());
        }
      },
      POLL_INTERVAL_MILLIS,
      { leading: true, trailing: false }
    );

    if (hasInitiallyLoaded && !intervalId) {
      // Call throttled polling function at short intervals (as opposed to the `POLL_INTERVAL_MILLIS`) to ensure the
      // poll runs at no more than a 5 second delay after it has been over 60 seconds since the last poll. This helps
      // the poll resume quickly and predictably when returning from an inactive to an active tab.
      const id = setInterval(() => {
        if (!isLoadingAlerts) {
          pollAlerts();
        }
      }, 5 * 1000);

      setIntervalId(id);

      // Initiate first polling call on load
      if (!isLoadingAlerts) {
        pollAlerts();
      }
    }

    return () => {
      clearInterval(intervalId);
      setIntervalId(null);
      pollAlerts.cancel();
    };
    // Callback should only re-run if `hasInitiallyLoaded` changed to `true`
    // eslint-disable-next-line
  }, [hasInitiallyLoaded]);

  // Update local state
  useEffect(() => {
    if (!isLoadingAlerts) {
      setIsPolling(false);
    }
  }, [isLoadingAlerts]);

  return {
    isPolling,
    isLoadingAlerts,
    isAcknowledging,
    alerts,
  };
};
