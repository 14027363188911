import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  makeStyles,
  ListItem,
  ListItemIcon,
  Icon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import { flatMap } from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import classNames from 'classnames';
import moment from 'moment';

import CuriButton from 'common/buttons/curiButton.component';
import CuriSelect from 'common/formFields/curiSelect.component';
import CuriText from 'common/formFields/curiText.component';
import {
  selectAttachmentCategories,
  selectAttachmentSubtypes,
  selectDocumentTypes,
  selectIsLoadingMetadata,
  selectSuffixes,
} from 'modules/opportunities/opportunities.selectors';
import {
  getOpportunityAttachmentDownloadUrl,
  GET_OPPORTUNITY_ATTACHMENT_DOWNLOAD_URL_FAILURE,
} from 'modules/opportunities/opportunities.actions';
import formatIndividualRiskName from 'utilities/formatIndividualRiskName';
import {
  getIndicationAttachmentDownloadUrl,
  GET_INDICATION_ATTACHMENT_DOWNLOAD_URL_FAILURE,
} from 'modules/indication/indication.actions';
import { handleToastMessage, TOAST_TYPES } from 'modules/layout/layout.actions';
import { downloadFileWithProgress } from 'utilities/downloadFile';
import DropdownButton from 'common/buttons/dropdownButton.component';
import CuriSelectValidator from 'common/formFields/curiSelectValidator.component';
import { mapToSelectOptions } from 'utilities/mappers';

export function getFileIcon(filename) {
  const fileExt = filename.split('.').pop();
  switch (fileExt) {
    case 'pdf':
      return 'fa-file-pdf';
    case 'doc':
    case 'docx':
      return 'fa-file-word';
    case 'xls':
    case 'xlsx':
    case 'csv':
      return 'fa-file-excel';
    case 'png':
    case 'jpeg':
    case 'jpg':
      return 'fa-file-image';
    default:
      return 'fa-file';
  }
}

export const DOCUMENT_DATE_FORMAT = 'MMMM DD, YYYY, h:mma';
const DocumentType = Object.freeze({
  Opportunity: 'OPPORTUNITY',
  Application: 'APPLICATION',
});

const DOWNLOAD_FAILURE_MESSAGE = 'Unable to download document. Please try again.';

const indicationHasMatchingAttachment = (indication, attachmentId) =>
  Array.isArray(indication.attachments) && indication.attachments.some(a => a.clonedFromAttachmentId === attachmentId);

export default function AttachmentListItem({
  opportunityId,
  indicationId,
  attachment: { id: attachmentId, name, createdDate, type, category, subtype, description, riskId, onBaseId },
  onDelete,
  showDelete,
  disableDelete,
  isReadOnly,
  onChange,
  individualRisks,
  organizationalRisks,
  indications,
  onCopyToIndication,
  isCopyingToIndication,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isLoadingMetadata = useSelector(selectIsLoadingMetadata);
  const documentTypes = useSelector(selectDocumentTypes);
  const attachmentCategories = useSelector(selectAttachmentCategories);
  const attachmentSubtypes = useSelector(selectAttachmentSubtypes);
  const suffixes = useSelector(selectSuffixes);

  const typeOptions = useMemo(() => mapToSelectOptions(documentTypes), [documentTypes]);
  const categoryOptions = useMemo(() => mapToSelectOptions(attachmentCategories), [attachmentCategories]);
  const subtypeOptions = useMemo(() => mapToSelectOptions(attachmentSubtypes), [attachmentSubtypes]);

  const [isDownloading, setIsDownloading] = useState(false);
  const [localDescription, setLocalDescription] = useState(description || '');

  useEffect(() => {
    setLocalDescription(description || '');
  }, [description]);

  const groups = useMemo(() => {
    const groupedOptions = [];
    if (organizationalRisks.length > 0) {
      groupedOptions.push({
        name: 'Organizational Risks',
        items: organizationalRisks.map(risk => ({ name: risk.name, value: risk.id })),
      });
    }
    if (individualRisks.length > 0) {
      groupedOptions.push({
        name: 'Individual Risks',
        items: individualRisks.map(risk => ({ name: formatIndividualRiskName(risk, suffixes), value: risk.id })),
      });
    }
    return groupedOptions;
  }, [individualRisks, organizationalRisks, suffixes]);

  const selectedRisk = useMemo(() => flatMap(groups, 'items').find(r => r.value === riskId), [riskId, groups]);

  const copyToIndicationOptions = useMemo(
    () =>
      indications
        .map(i => ({
          id: i.id,
          name: i.name,
          isDisabled: indicationHasMatchingAttachment(i, attachmentId),
          createdDate: i.createdDate,
        }))
        .sort((a, b) => moment(a.createdDate).diff(b.createdDate)),
    [indications, attachmentId]
  );

  const handleDownload = useCallback(async () => {
    setIsDownloading(true);

    let downloadUrlResponse;
    if (indicationId) {
      downloadUrlResponse = await dispatch(
        getIndicationAttachmentDownloadUrl(opportunityId, indicationId, attachmentId)
      );
      if (downloadUrlResponse.type === GET_INDICATION_ATTACHMENT_DOWNLOAD_URL_FAILURE) {
        dispatch(handleToastMessage(DOWNLOAD_FAILURE_MESSAGE, TOAST_TYPES.ERROR));
        setIsDownloading(false);
        return;
      }
    } else {
      downloadUrlResponse = await dispatch(getOpportunityAttachmentDownloadUrl(opportunityId, attachmentId));
      if (downloadUrlResponse.type === GET_OPPORTUNITY_ATTACHMENT_DOWNLOAD_URL_FAILURE) {
        dispatch(handleToastMessage(DOWNLOAD_FAILURE_MESSAGE, TOAST_TYPES.ERROR));
        setIsDownloading(false);
        return;
      }
    }

    const downloadResponse = await downloadFileWithProgress(name, downloadUrlResponse.response.url);
    if (!downloadResponse) {
      dispatch(handleToastMessage(DOWNLOAD_FAILURE_MESSAGE, TOAST_TYPES.ERROR));
    }

    setIsDownloading(false);
  }, [dispatch, opportunityId, indicationId, attachmentId, name]);

  const handleTypeChange = useCallback(
    e => {
      const newValue = e.target.value || null;
      if (newValue !== type) {
        onChange({ type: newValue, category: null, subtype: null });
      }
    },
    [type, onChange]
  );

  const handleCategoryChange = useCallback(
    e => {
      const newValue = e.target.value || null;
      if (newValue !== category) {
        onChange({ category: newValue, subtype: null });
      }
    },
    [category, onChange]
  );

  const handleSubtypeChange = useCallback(
    e => {
      const newValue = e.target.value || null;
      if (newValue !== subtype) {
        onChange({ category: null, subtype: newValue });
      }
    },
    [subtype, onChange]
  );

  const handleRiskChange = useCallback(
    e => {
      const newValue = e.target.value || null;
      if ((!selectedRisk && newValue) || (selectedRisk && selectedRisk.value !== newValue)) {
        onChange({ riskId: newValue });
      }
    },
    [selectedRisk, onChange]
  );

  const handleDescriptionChange = useCallback(() => {
    const newValue = localDescription || null;
    if (newValue !== description) {
      onChange({ description: newValue });
    }
  }, [localDescription, description, onChange]);

  const handleDescriptionClear = useCallback(() => {
    if (description !== null) {
      onChange({ description: null });
    }
  }, [description, onChange]);

  return (
    <ListItem>
      <ListItemIcon>
        <Icon className={classNames('fal', getFileIcon(name))} />
      </ListItemIcon>
      <ListItemText
        primary={
          <CuriButton
            className={classes.downloadButton}
            variant="text"
            onClick={handleDownload}
            disabled={isDownloading}
            startIcon={
              onBaseId ? (
                <Icon className={classNames(classes.onBaseIndicatorIcon, 'fas', 'fa-file-check')} />
              ) : (
                undefined
              )
            }
            endIcon={<DownloadIcon className={classes.downloadIcon} />}
          >
            {name}
          </CuriButton>
        }
        secondary={isDownloading ? 'Downloading...' : moment(createdDate).format(DOCUMENT_DATE_FORMAT)}
      />
      {indicationId && (
        <div className={classes.actionsContainer}>
          <div className={classes.actionsRow}>
            <CuriSelect
              className={classes.select}
              label="Document Type"
              onChange={handleTypeChange}
              value={type || ''}
              options={typeOptions}
              disabled={isLoadingMetadata}
              readOnly={isReadOnly}
              noBlankOption
            />
            {type === DocumentType.Opportunity && (
              <CuriSelect
                className={classes.select}
                label="Category"
                onChange={handleCategoryChange}
                value={category || ''}
                options={categoryOptions}
                disabled={isLoadingMetadata}
                readOnly={isReadOnly}
              />
            )}
            {type === DocumentType.Application && (
              <CuriSelectValidator
                name="subtype"
                validators={['required']}
                className={classes.selectValidator}
                validatorClasses={{ formControl: classes.noMargin }}
                label="Sub-Type"
                onChange={handleSubtypeChange}
                value={subtype || ''}
                options={subtypeOptions}
                disabled={isLoadingMetadata}
                readOnly={isReadOnly}
                noBlankOption
              />
            )}
            {!type && (
              <CuriSelect
                className={
                  classes.select // show placeholder
                }
                label="Category/Sub-Type"
                value=""
                options={[]}
                disabled
              />
            )}
            <CuriSelect
              className={classes.select}
              label="Risk"
              onChange={handleRiskChange}
              value={selectedRisk ? selectedRisk.value : ''}
              groups={groups}
              disabled={isLoadingMetadata}
              readOnly={isReadOnly}
            />
          </div>
          <div className={classes.actionsRow}>
            <CuriText
              className={classes.text}
              label="Brief Description"
              fullWidth
              value={localDescription}
              onChange={e => setLocalDescription(e.target.value)}
              onBlur={handleDescriptionChange}
              onClear={handleDescriptionClear}
              disabled={isLoadingMetadata}
              readOnly={isReadOnly}
            />
          </div>
        </div>
      )}
      {!isReadOnly && indications.length > 0 && (
        <DropdownButton
          isLoading={isCopyingToIndication}
          label={indications.length === 1 ? `Copy to ${indications[0].name}` : 'Copy to indication'}
          options={copyToIndicationOptions}
          onClick={onCopyToIndication}
          disabled={indications.length === 1 && indicationHasMatchingAttachment(indications[0], attachmentId)}
        />
      )}
      {showDelete && (
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={onDelete} disabled={isLoadingMetadata || isDownloading || disableDelete}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

const useStyles = makeStyles(theme => ({
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    padding: '6px 0', // needed to show floating input labels
  },
  actionsRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  downloadButton: {
    ...theme.typography.body1,
    textTransform: 'none',
    marginLeft: -theme.spacing(), // align text with secondary
    color: theme.palette.grey[700],
    whiteSpace: 'nowrap',
  },
  onBaseIndicatorIcon: {
    color: theme.palette.tertiary.main,
  },
  downloadIcon: {
    color: theme.palette.grey[600],
  },
  select: {
    width: 225,
    minWidth: 225,
    maxWidth: 225,
    '&:last-child': {
      marginRight: 0,
    },
  },
  selectValidator: {
    width: 225,
    minWidth: 225,
    maxWidth: 225,
  },
  noMargin: {
    margin: 0,
  },
  text: {
    marginRight: 0,
  },
}));

AttachmentListItem.propTypes = {
  opportunityId: PropTypes.string.isRequired,
  indicationId: PropTypes.string,
  attachment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    createdDate: PropTypes.string.isRequired,
    type: PropTypes.string,
    category: PropTypes.string,
    subtype: PropTypes.string,
    description: PropTypes.string,
    riskId: PropTypes.string,
    onBaseId: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func,
  showDelete: PropTypes.bool,
  disableDelete: PropTypes.bool,
  onChange: PropTypes.func,
  individualRisks: PropTypes.array,
  organizationalRisks: PropTypes.array,
  isReadOnly: PropTypes.bool,
  indications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  onCopyToIndication: PropTypes.func,
  isCopyingToIndication: PropTypes.bool,
};

AttachmentListItem.defaultProps = {
  indicationId: null,
  onDelete: () => {},
  showDelete: true,
  disableDelete: false,
  onChange: () => {},
  isReadOnly: false,
  individualRisks: [],
  organizationalRisks: [],
  indications: [],
  onCopyToIndication: () => {},
  isCopyingToIndication: false,
};
