import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, TableCell, TableRow, Typography, makeStyles } from '@material-ui/core';
import { Info, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

import CuriButton from 'common/buttons/curiButton.component';

export default function QueryResults({
  isMatchedResults,
  row,
  handleMatchCategoryChange,
  onSelectResult,
  onViewResultDetails,
}) {
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  const handleSelectResult = result => {
    setOpen(false);
    onSelectResult(row.id, result.id);
  };

  if (!Array.isArray(row.results) || row.results.length === 0) {
    return (
      <TableRow className={classes.rowRoot}>
        <TableCell />
        <TableCell component="th" scope="row">
          {row.query}
        </TableCell>
        <TableCell colSpan={7}>
          <Typography color="error">No matches</Typography>
        </TableCell>
      </TableRow>
    );
  }

  const hasMultipleResults = row.results.length > 1;
  const rows = [...row.results]
    .sort(v => (v.isSelected ? -1 : 1))
    .filter(result => open || result.isSelected)
    .map(result => (
      <TableRow key={result.id} className={result.isSelected ? classes.selectedRow : classes.unselectedRow}>
        {result.isSelected ? (
          <>
            <TableCell>
              {hasMultipleResults && (
                <IconButton size="small" onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
              )}
            </TableCell>
            <TableCell component="th" scope="row">
              {row.query}
            </TableCell>
          </>
        ) : (
          <TableCell colSpan={2} />
        )}
        <TableCell>{result.record.fullNameCred}</TableCell>
        <TableCell>{result.record.npi}</TableCell>
        <TableCell>{result.record.organizationName}</TableCell>
        <TableCell>{result.record.preferredLicense ? result.record.preferredLicense.licenseStateCode : ''}</TableCell>
        <TableCell>{result.record.preferredLicense ? result.record.preferredLicense.licenseNumber : ''}</TableCell>
        <TableCell>
          <Box display="flex" flexDirection="row" alignItems="center">
            {result.score}
            <IconButton size="small" className={classes.infoButton} onClick={() => onViewResultDetails(result)}>
              <Info />
            </IconButton>
          </Box>
        </TableCell>
        {!(result.isSelected && isMatchedResults) && (
          <TableCell>
            <CuriButton color="primary" onClick={() => handleSelectResult(result)}>
              Select
            </CuriButton>
          </TableCell>
        )}
      </TableRow>
    ));

  if (isMatchedResults && open) {
    rows.push(
      <TableRow key="no-match" className={classes.unselectedRow}>
        <TableCell colSpan={8} />
        <TableCell>
          <CuriButton onClick={() => handleMatchCategoryChange(row.id)}>No Match</CuriButton>
        </TableCell>
      </TableRow>
    );
  }

  return rows;
}

const useRowStyles = makeStyles(theme => ({
  selectedRow: {
    '& > *': {
      borderBottomColor: theme.palette.grey[200],
      borderBottomWidth: 1,
    },
  },
  unselectedRow: {
    backgroundColor: theme.palette.grey[200],
  },
  sourceTableCellRoot: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  infoButton: {
    marginLeft: theme.spacing(),
  },
}));

QueryResults.propTypes = {
  isMatchedResults: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    query: PropTypes.string.isRequired,
    results: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        score: PropTypes.number.isRequired,
        record: PropTypes.object,
        source: PropTypes.object,
        isSelected: PropTypes.bool.isRequired,
      })
    ).isRequired,
  }).isRequired,
  handleMatchCategoryChange: PropTypes.func.isRequired,
  onSelectResult: PropTypes.func.isRequired,
  onViewResultDetails: PropTypes.func.isRequired,
};
