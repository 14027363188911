import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import DateCircle from 'common/dates/dateCircle.component';
import TimelineCardLayout from 'modules/opportunities/opportunity/timelineCardLayout.component';
import TimelineEmailListItem from 'modules/opportunities/opportunity/timelineEmailListItem.component';

const iconMap = {
  email: 'fa-envelope-square',
  task: 'fa-star',
};

function TimelineDayListItems({ dateTime, items, isLastDay, handleOpenEmail }) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      minHeight="8.25rem"
      mb="0.25rem"
    >
      {/* Date circle with vertical line */}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        alignSelf="stretch"
        width="5.25rem"
        mr="2.5rem"
      >
        <DateCircle dateTime={dateTime} />
        {!isLastDay && (
          <Box flexGrow={1} borderLeft={3} borderColor="text.hint" my="0.5rem">
            {/* Vertical line */}
          </Box>
        )}
      </Box>

      {/* Main content */}
      <Box paddingBottom="1rem" flexGrow={1} overflow="auto">
        {items.map((item, i) => (
          <TimelineCardLayout
            // No unique id exists, will not be reordered
            // eslint-disable-next-line react/no-array-index-key
            key={`${item.title}-${i}`}
            icon={iconMap[item.type] || 'fa-star'}
            title={item.title}
            description={item.description}
            timestamp={item.timestamp}
          >
            {item.type === 'email' &&
              item.emails.map(e => <TimelineEmailListItem key={e.id} email={e} handleOpenEmail={handleOpenEmail} />)}
          </TimelineCardLayout>
        ))}
      </Box>
    </Box>
  );
}

TimelineDayListItems.propTypes = {
  dateTime: PropTypes.string.isRequired,
  isLastDay: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleOpenEmail: PropTypes.func.isRequired,
};

TimelineDayListItems.defaultProps = {
  isLastDay: false,
};

export default TimelineDayListItems;
