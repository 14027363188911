export default Object.freeze({
  New: {
    name: 'New Business',
    value: 'NEW',
  },
  Rewrite: {
    name: 'Rewrite',
    value: 'REWRITE',
  },
});
