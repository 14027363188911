import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TableCell, makeStyles } from '@material-ui/core';
import { isNil } from 'lodash';
import { selectOpportunityStatuses } from 'modules/opportunities/opportunities.selectors';

function DataTableCell({ columnDef, rowData }) {
  const classes = useStyles();

  return (
    <TableCell style={columnDef.cellStyle}>
      <div className={classes.cellContents}>{DataTableCellContents(columnDef, rowData)}</div>
    </TableCell>
  );
}

const DataTableCellContents = (columnDef, rowData) => {
  const opportunityStatuses = useSelector(selectOpportunityStatuses);

  if (columnDef.render) {
    return columnDef.render(rowData);
  }

  if (columnDef.field !== 'activeStatusCode' && isNil(rowData[columnDef.field])) {
    return 'N/A';
  }

  if (columnDef.field === 'activeStatusCode') {
    const status = opportunityStatuses.find(s => s.code === rowData.activeStatusCode);
    return status && status.label ? status.label : '';
  }

  if (columnDef.field === 'stateCodes') {
    return rowData.stateCodes.join(', ');
  }

  switch (columnDef.type) {
    case 'date':
      return moment(rowData[columnDef.field]).format('MMM DD, YYYY');

    default:
      return rowData[columnDef.field];
  }
};

const useStyles = makeStyles(() => ({
  cellContents: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

DataTableCell.propTypes = {
  columnDef: PropTypes.object.isRequired,
  rowData: PropTypes.object.isRequired,
};

export default DataTableCell;
