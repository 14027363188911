import produce from 'immer';
import {
  GET_ALERTS_REQUEST,
  GET_ALERTS_SUCCESS,
  GET_ALERTS_FAILURE,
  ACKNOWLEDGE_ALERT_REQUEST,
  ACKNOWLEDGE_ALERT_SUCCESS,
  ACKNOWLEDGE_ALERT_FAILURE,
} from './alerts.actions';

const initialState = {
  isLoading: false,
  alerts: {},
  isAcknowledging: false,
};

// eslint-disable-next-line default-param-last
export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_ALERTS_REQUEST:
      draft.isLoading = true;
      break;

    case GET_ALERTS_SUCCESS:
      draft.isLoading = false;
      // Transform array of alerts into an object of alerts which are keyed by id
      draft.alerts = action.response.reduce((a, c) => ({ ...a, [c.id]: c }), {});
      break;

    case GET_ALERTS_FAILURE:
      draft.isLoading = false;
      break;

    case ACKNOWLEDGE_ALERT_REQUEST:
      draft.isAcknowledging = true;
      break;

    case ACKNOWLEDGE_ALERT_SUCCESS: {
      draft.isAcknowledging = false;
      const { id } = action.response;
      draft.alerts = {
        ...draft.alerts,
        [id]: action.response,
      };
      break;
    }

    case ACKNOWLEDGE_ALERT_FAILURE:
      draft.isAcknowledging = false;
      break;

    default:
      break;
  }
}, initialState);
