import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import CuriButton from 'common/buttons/curiButton.component';
import IndicationRiskClassification from 'modules/indication/indicationRiskClassification';
import deduplicatePrimitiveArray from 'utilities/deduplicatePrimitiveArray';
import { useIndicationIsInOasis } from 'modules/indication/indication.selectors';

function OasisData({ children }) {
  return (
    <Typography display="block" variant="body2" component="span">
      {children}
    </Typography>
  );
}

OasisData.propTypes = {
  children: PropTypes.node.isRequired,
};

const formatOasisData = (key, label, value) => [
  <OasisData key={key}>
    {label}
    :&nbsp;
    {value}
  </OasisData>,
];

export const formatOasisClientId = clientId => formatOasisData(clientId, 'Oasis Client ID', clientId);

export const formatIndividualOasisPartyName = searchResultName => {
  const { firstName, otherGivenName, lastName, title, titlePrefix } = searchResultName;
  let displayName = '';
  if (titlePrefix) displayName += `${titlePrefix}. `;
  displayName += `${firstName} ${otherGivenName || ''} ${lastName}`;
  if (title) displayName += `, ${title}`;
  return displayName.replace(/\s+/g, ' ');
};

export const formatOrganizationalOasisPartyName = searchResult => {
  const { name, taxId } = searchResult;
  let displayName = `${name}`;
  if (taxId) displayName += ` (Tax ID: ${taxId})`;
  return displayName;
};

export const formatOasisAddress = address => {
  const { lineOne, lineTwo, lineThree, city, stateCode, postalCode, countryCode } = address;
  let displayAddress = `${lineOne}`;
  if (lineTwo) displayAddress += ` ${lineTwo}`;
  if (lineThree) displayAddress += ` ${lineThree}`;
  displayAddress += ` ${city}, ${stateCode} ${postalCode} ${countryCode}`;

  return displayAddress.replace(/\s+/g, ' ');
};

export const formatIndividualOasisPartySubtext = searchResult => {
  const { addresses, licenses, clientId } = searchResult;
  const formattedAddresses = deduplicatePrimitiveArray(addresses.map(formatOasisAddress));
  return formatOasisClientId(clientId)
    .concat(
      licenses.map(({ licenseNumber, stateCode }) =>
        formatOasisData(licenseNumber, `License No${stateCode ? ` (${stateCode})` : ''}`, licenseNumber)
      )
    )
    .concat(formattedAddresses.map(a => <OasisData key={a}>{a}</OasisData>));
};

export const formatOrganizationalOasisPartySubtext = searchResult => {
  const { addresses, clientId } = searchResult;
  const formattedAddresses = deduplicatePrimitiveArray(addresses.map(formatOasisAddress));
  return formatOasisClientId(clientId)
    .concat(searchResult.fein ? formatOasisData(searchResult.fein, 'Tax ID', searchResult.fein) : null)
    .concat(formattedAddresses.map(a => <OasisData key={a}>{a}</OasisData>));
};

const NEW_CLIENT_ID = 'NEW_CLIENT_ID';

export default function VerifyOasisPartiesModal({
  open,
  loading,
  indicationRisk,
  handleCloseOasisModal,
  handleClearOasisId,
  handleSelectOasisParty,
  handleConfirmNewOasisParty,
  selectedClientIds,
}) {
  const classes = useStyles();
  const indicationIsInOasis = useIndicationIsInOasis();
  const [selectedClientId, setSelectedClientId] = useState(null);

  const oasisPartyOptions = useMemo(
    () => (indicationRisk && Array.isArray(indicationRisk.oasisSearchResults) ? indicationRisk.oasisSearchResults : []),
    [indicationRisk]
  );

  useEffect(() => {
    if (!open) {
      setSelectedClientId(null);
    }
  }, [open]);

  const handleSave = () => {
    if (selectedClientId === NEW_CLIENT_ID) {
      return handleConfirmNewOasisParty();
    }

    const party = oasisPartyOptions.find(option => option.clientId === selectedClientId);
    return party ? handleSelectOasisParty(party) : handleConfirmNewOasisParty();
  };

  const renderOasisPartyOptions = useMemo(() => {
    if (!indicationRisk) return null;

    const isOrg = indicationRisk.classification === IndicationRiskClassification.MedicalGroup.id;
    const options = oasisPartyOptions.map(o => {
      const alreadySelected = selectedClientIds.includes(o.clientId) && indicationRisk.oasisClientId !== o.clientId;
      return (
        <div className="striped-list-item" key={o.clientId}>
          <ListItem button disabled={indicationIsInOasis || alreadySelected}>
            <FormControlLabel
              value={o.clientId}
              control={<Radio />}
              className={classes.optionLabel}
              label={
                <ListItemText
                  primary={isOrg ? formatOrganizationalOasisPartyName(o) : formatIndividualOasisPartyName(o.name)}
                  secondary={isOrg ? formatOrganizationalOasisPartySubtext(o) : formatIndividualOasisPartySubtext(o)}
                />
              }
            />
          </ListItem>
          {alreadySelected && (
            <Typography className={classes.disabledLabel} color="error" gutterBottom>
              <Icon className={classNames('fal fa-exclamation-circle', classes.alertIcon)} />
              Client ID is already in use on this roster, possible duplicate
            </Typography>
          )}
        </div>
      );
    });

    const hasOptions = options.length > 0;
    const createNewLabel = hasOptions
      ? 'None of the above match, create a new Client in Oasis'
      : 'Create a new Client in Oasis';

    return (
      <FormControl component="fieldset" fullWidth>
        <RadioGroup
          className={classes.radioGroup}
          name="oasis-clients"
          defaultValue={indicationRisk.isConfirmedNew ? NEW_CLIENT_ID : indicationRisk.oasisClientId}
          onChange={event => setSelectedClientId(event.target.value)}
        >
          {options}
          <ListItem
            button
            className={classNames(classes.noMatchItem, { [classes.disableBorder]: !hasOptions })}
            disabled={indicationIsInOasis}
          >
            <FormControlLabel
              value={NEW_CLIENT_ID}
              control={<Radio />}
              className={classNames(classes.optionLabel, classes.noMatchOptionLabel)}
              label={<ListItemText primary={createNewLabel} />}
            />
          </ListItem>
        </RadioGroup>
      </FormControl>
    );
  }, [indicationRisk, oasisPartyOptions, indicationIsInOasis, classes, selectedClientIds]);

  const clearOasisIdBtnDisabled = useMemo(
    () => loading || !(indicationRisk && (indicationRisk.oasisClientId || indicationRisk.isConfirmedNew)),
    [loading, indicationRisk]
  );

  return (
    <Dialog fullWidth classes={{ paper: classes.dialogPaper }} open={open} onClose={handleCloseOasisModal}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h5" gutterBottom>
          Verify Oasis Client
        </Typography>
        <Typography variant="body2" gutterBottom>
          Select a Client from the search results to use that Client in this Roster.
        </Typography>
        <Box display="flex" flexDirection="row" justifyContent="start" alignItems="center">
          <Typography variant="h6" component="span">
            Possible matches from Oasis:&nbsp;
          </Typography>
          <Typography variant="h6" component="span" className={classes.resultsCount}>
            {`${oasisPartyOptions.length} result${oasisPartyOptions.length === 1 ? '' : 's'}`}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent className={classNames(classes.dialogContent, classes.forceScrollbar)}>
        <List dense disablePadding className={classes.stripedList}>
          {renderOasisPartyOptions}
        </List>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <CuriButton type="button" variant="text" color="primary" onClick={handleCloseOasisModal} disabled={loading}>
          Cancel
        </CuriButton>
        <CuriButton
          type="button"
          variant="text"
          color="primary"
          onClick={handleClearOasisId}
          disabled={clearOasisIdBtnDisabled || indicationIsInOasis}
        >
          Clear Selection
        </CuriButton>
        <CuriButton
          type="button"
          color="primary"
          onClick={handleSave}
          disabled={loading || indicationIsInOasis || !selectedClientId}
        >
          Save
        </CuriButton>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    maxWidth: 660,
  },
  dialogTitle: {
    borderBottom: '2px solid',
    borderBottomColor: theme.palette.common.black,
    backgroundColor: theme.palette.background.default,
  },
  dialogContent: {
    padding: 0,
  },
  dialogActions: {
    backgroundColor: theme.palette.background.default,
  },
  resultsCount: {
    fontWeight: theme.typography.fontWeightBold,
  },
  radioGroup: {
    flexWrap: 'nowrap', // prevents extra whitespace caused by div around subset of radio buttons
  },
  optionLabel: {
    alignItems: 'start',
    marginRight: 0,
    width: '100%',
  },
  disabledLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  alertIcon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  noMatchItem: {
    backgroundColor: theme.palette.common.white,
    bottom: 0,
    borderTop: '2px solid',
    borderTopColor: theme.palette.common.black,
    position: 'sticky',
    '&:hover': {
      backgroundColor: 'rgb(245,245,245)', // emulate rgba(0,0,0,0.04) without opacity
    },
  },
  disableBorder: {
    border: 'none',
  },
  noMatchOptionLabel: {
    alignItems: 'center',
  },
  stripedList: {
    '& .striped-list-item:nth-child(even)': {
      backgroundColor: theme.palette.common.extraLightGray,
    },
  },
  forceScrollbar: {
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: 7,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0, 0, 0, .5)',
      boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
}));

VerifyOasisPartiesModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  indicationRisk: PropTypes.object,
  handleCloseOasisModal: PropTypes.func.isRequired,
  handleClearOasisId: PropTypes.func.isRequired,
  handleSelectOasisParty: PropTypes.func.isRequired,
  handleConfirmNewOasisParty: PropTypes.func.isRequired,
  selectedClientIds: PropTypes.array,
};

VerifyOasisPartiesModal.defaultProps = {
  open: false,
  loading: false,
  indicationRisk: {
    oasisClientId: null,
    oasisSearchResults: null,
  },
  selectedClientIds: [],
};
