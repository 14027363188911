import { useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { keyBy } from 'lodash';
import { selectIndicationStatuses } from 'modules/opportunities/opportunities.selectors';

export const selectIndicationsState = createSelector(
  state => state.indication,
  indicationState => indicationState
);

export const selectIndications = createSelector(
  selectIndicationsState,
  indicationState => indicationState.indications || []
);

export const selectKeyedIndications = createSelector(selectIndications, indications => keyBy(indications, i => i.id));

export const selectLoadingIndicationOasisPolicies = createSelector(
  selectIndicationsState,
  indicationState => indicationState.loadingOasisPolicies
);

export const selectIndicationOasisPolicies = createSelector(
  selectIndicationsState,
  indicationState => indicationState.oasisPolicies
);

export const selectIsLoadingIndicationStatus = createSelector(
  selectIndicationsState,
  indicationState => indicationState.isLoadingIndicationStatus
);

export const selectIsCreatingOasisQuote = createSelector(
  selectIndicationsState,
  indicationState => indicationState.isCreatingOasisQuote
);

export const selectIsLoadingIndications = createSelector(
  selectIndicationsState,
  indicationState => indicationState.isLoading
);

export const selectIsLoadingIndication = createSelector(
  selectIndicationsState,
  indicationState => indicationState.isLoadingIndication
);

export const selectIndividualRisks = createSelector(
  selectIndicationsState,
  indicationState => indicationState.indicationRiskIndividuals
);

export const selectKeyedRisks = createSelector(selectIndicationsState, ({ indicationRiskIndividuals }) =>
  keyBy(indicationRiskIndividuals, r => r.id)
);

export const selectIsLoadingIndividualRisks = createSelector(
  selectIndicationsState,
  indicationState => indicationState.isLoadingIndicationRiskIndividuals
);

export const selectOrganizationRisks = createSelector(
  selectIndicationsState,
  indicationState => indicationState.indicationRiskOrganizations
);

export const selectIsLoadingOrganizationRisks = createSelector(
  selectIndicationsState,
  indicationState => indicationState.isLoadingIndicationRiskOrganizations
);

export const selectIsLoadingIndicationStates = createSelector(
  selectIndicationsState,
  indicationState => indicationState.isLoadingIndicationStates
);

export const selectIndicationStates = createSelector(
  selectIndicationsState,
  indicationState => indicationState.indicationStates
);

export const selectKeyedIndicationStates = createSelector(selectIndicationStates, indicationStates =>
  keyBy(indicationStates, s => s.code)
);

export const selectIsLoadingRiskCounties = createSelector(
  selectIndicationsState,
  indicationState => indicationState.isLoadingRiskCounties
);

export const selectRiskCounties = createSelector(
  selectIndicationsState,
  indicationState => indicationState.riskCounties
);

export const selectIsLoadingRiskSpecialties = createSelector(
  selectIndicationsState,
  indicationState => indicationState.isLoadingRiskSpecialties
);

export const selectRiskSpecialties = createSelector(
  selectIndicationsState,
  indicationState => indicationState.riskSpecialties
);

export const selectIndicationAttachments = createSelector(
  selectIndicationsState,
  indicationState => indicationState.attachments || []
);

export const selectIsLoadingIndicationAttachments = createSelector(
  selectIndicationsState,
  indicationState => indicationState.isLoadingAttachments
);

export const selectIsUploadingIndicationAttachment = createSelector(
  selectIndicationsState,
  indicationState => indicationState.isUploadingAttachment
);

export const selectIsUpdatingIndicationAttachments = createSelector(
  selectIndicationsState,
  indicationState => indicationState.isUpdatingAttachments
);

export const selectIsLoadingUploadUrl = createSelector(
  selectIndicationsState,
  indicationState => indicationState.isLoadingUploadUrl
);

export const selectIsCreatingIndicationAttachment = createSelector(
  selectIndicationsState,
  indicationState => indicationState.isCreatingIndicationAttachment
);

export const selectIsLoadingDownloadUrl = createSelector(
  selectIndicationsState,
  indicationState => indicationState.isLoadingDownloadUrl
);

export const selectIsDeletingIndicationAttachment = createSelector(
  selectIndicationsState,
  indicationState => indicationState.isDeletingAttachment
);

export const selectIsPushingAttachmentsToOnBase = createSelector(
  selectIndicationsState,
  indicationState => indicationState.isPushingAttachmentsToOnBase
);

export const useIndicationStatusLabel = code => {
  const indicationStatuses = useSelector(selectIndicationStatuses);
  const status = useMemo(() => indicationStatuses.find(s => s.code === code), [indicationStatuses, code]);
  return status ? status.label : '';
};

export const useIndications = () => {
  const indications = useSelector(selectIndications);
  const isLoadingIndications = useSelector(selectIsLoadingIndications);
  return { indications, isLoadingIndications };
};

export const useIndicationAttachments = () => {
  const attachments = useSelector(selectIndicationAttachments);
  const isLoadingAttachments = useSelector(selectIsLoadingIndicationAttachments);
  return { attachments, isLoadingAttachments };
};

/**
 * A custom hook that returns the selected indication as indicated by the `:indicationId` route param
 */
export const useSelectedIndication = () => {
  const indications = useSelector(selectIndications);
  const { indicationId } = useParams();
  const location = useLocation();
  const selectedIndication = useMemo(() => {
    const urlSegments = location.pathname.split('/');
    const index = urlSegments.indexOf('indications');
    const selectedIndicationId = urlSegments[index + 1];
    return indications.find(i => i.id === (indicationId || selectedIndicationId));
  }, [indications, indicationId, location]);
  return { selectedIndication };
};

export const useIndicationIsInOasis = () => {
  const { selectedIndication } = useSelectedIndication();
  return !!selectedIndication && !!selectedIndication.policyId;
};

export const useSelectedIndicationOasisPolicies = () => {
  const { selectedIndication } = useSelectedIndication();
  const oasisPolicies = useSelector(selectIndicationOasisPolicies);
  return selectedIndication && oasisPolicies[selectedIndication.id] ? oasisPolicies[selectedIndication.id] : null;
};
