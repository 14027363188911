import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  makeStyles,
  ListItem,
  ListItemIcon,
  Icon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import classNames from 'classnames';
import moment from 'moment';
import { useOktaAuth } from '@okta/okta-react';

import { handleToastMessage, TOAST_TYPES } from 'modules/layout/layout.actions';
import { downloadPrivateFile } from 'utilities/downloadFile';
import CuriSelect from 'common/formFields/curiSelect.component';
import { selectAttachmentCategories } from 'modules/opportunities/opportunities.selectors';

const getFileIcon = filename => {
  const fileExt = filename.split('.').pop();
  switch (fileExt) {
    case 'pdf':
      return 'fa-file-pdf';
    case 'doc':
    case 'docx':
      return 'fa-file-word';
    case 'xls':
    case 'xlsx':
    case 'csv':
      return 'fa-file-excel';
    case 'png':
    case 'jpeg':
    case 'jpg':
      return 'fa-file-image';
    default:
      return 'fa-file';
  }
};

export const DOCUMENT_DATE_FORMAT = 'MMMM DD, YYYY, h:mma';

function CuriDownloadableListItem({
  href,
  name,
  createdDate,
  isDeleteable,
  handleDelete,
  isDeleting,
  canDelete,
  showAttachmentCategory,
  attachmentCategory,
  canUpdateCategory,
  loadingCategory,
  handleUpdateCategory,
}) {
  const { oktaAuth } = useOktaAuth();
  const classes = useStyles();
  const dispatch = useDispatch();
  const attachmentCategories = useSelector(selectAttachmentCategories);

  const categoryOptions = useMemo(() => attachmentCategories.map(c => ({ name: c.label, value: c.id })), [
    attachmentCategories,
  ]);

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = useCallback(
    async (e, filename, url) => {
      e.preventDefault();
      if (!isDownloading) {
        setIsDownloading(true);
        try {
          const accessToken = oktaAuth.getAccessToken();
          if (!accessToken) {
            oktaAuth.signOut();
          }
          await downloadPrivateFile(filename, url, accessToken);
        } catch (error) {
          dispatch(handleToastMessage('Unable to download document. Please try again.', TOAST_TYPES.ERROR));
        }
        setIsDownloading(false);
      }
    },
    [dispatch, isDownloading, oktaAuth]
  );

  return (
    <ListItem onClick={e => handleDownload(e, name, href)} button disabled={isDownloading}>
      <ListItemIcon>
        {isDownloading ? <CircularProgress size={24} /> : <Icon className={classNames('fal', getFileIcon(name))} />}
      </ListItemIcon>
      <ListItemText
        primary={name}
        secondary={isDownloading ? 'Downloading...' : moment(createdDate).format(DOCUMENT_DATE_FORMAT)}
      />
      {showAttachmentCategory && (
        <ListItemSecondaryAction>
          <CuriSelect
            className={classes.select}
            label="Category"
            onChange={e => handleUpdateCategory(e.target.value)}
            value={attachmentCategory || ''}
            options={categoryOptions}
            disabled={loadingCategory || !canUpdateCategory}
            hasValidation
          />
        </ListItemSecondaryAction>
      )}
      {isDeleteable && canDelete && (
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={handleDelete} disabled={isDeleting || isDownloading}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

const useStyles = makeStyles(() => ({
  select: {
    width: '315px',
  },
}));

CuriDownloadableListItem.propTypes = {
  href: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  createdDate: PropTypes.string.isRequired,
  isDeleteable: PropTypes.bool,
  isDeleting: PropTypes.bool,
  handleDelete: PropTypes.func,
  canDelete: PropTypes.bool.isRequired,
  attachmentCategory: PropTypes.string,
  showAttachmentCategory: PropTypes.bool,
  canUpdateCategory: PropTypes.bool,
  handleUpdateCategory: PropTypes.func,
  loadingCategory: PropTypes.bool,
};

CuriDownloadableListItem.defaultProps = {
  isDeleteable: false,
  isDeleting: false,
  handleDelete: () => {},
  attachmentCategory: null,
  showAttachmentCategory: false,
  canUpdateCategory: false,
  handleUpdateCategory: () => {},
  loadingCategory: false,
};

export default CuriDownloadableListItem;
