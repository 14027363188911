import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectAppConfigData } from 'modules/appConfig/appConfig.selectors';

// eslint-disable-next-line import/prefer-default-export
export const useJiraReportBugWidget = preventWidgetUsage => {
  const appConfig = useSelector(selectAppConfigData);
  const jiraScriptAppended = useRef(preventWidgetUsage);

  useEffect(() => {
    if (!jiraScriptAppended.current && appConfig && appConfig.jiraWidgetKey) {
      jiraScriptAppended.current = true;
      const script = document.createElement('script');
      script.src = 'https://jsd-widget.atlassian.com/assets/embed.js';
      script.setAttribute('data-jsd-embedded', '');
      script.setAttribute('data-key', appConfig.jiraWidgetKey);
      script.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
      document.body.appendChild(script);
      script.addEventListener('load', function loadEventListener() {
        // Jira script listens for this event to launch widget
        document.dispatchEvent(
          new Event('DOMContentLoaded', {
            bubbles: true,
            cancelable: true,
          })
        );
        script.removeEventListener('load', this);

        // Position "Report A Bug" on left side of page
        const widget = document.getElementById('jsd-widget');
        widget.style.left = 0;
      });
    }
  }, [appConfig]);
};
