import { CALL_API } from 'middleware/api';

export const RESET_SEARCH = 'RESET_SEARCH';

export const GET_SEARCH_RESULTS_FAILURE = 'GET_SEARCH_RESULTS_FAILURE';
export const GET_SEARCH_RESULTS_REQUEST = 'GET_SEARCH_RESULTS_REQUEST';
export const GET_SEARCH_RESULTS_SUCCESS = 'GET_SEARCH_RESULTS_SUCCESS';

export const GET_ROSTER_FAILURE = 'GET_ROSTER_FAILURE';
export const GET_ROSTER_REQUEST = 'GET_ROSTER_REQUEST';
export const GET_ROSTER_SUCCESS = 'GET_ROSTER_SUCCESS';

export const SELECT_QUERY_SEARCH_RESULT = 'SELECT_QUERY_SEARCH_RESULT';
export const SET_QUERY_TERM_MATCHED = 'SET_QUERY_TERM_MATCHED';
export const SET_QUERY_TERM_UNMATCHED = 'SET_QUERY_TERM_UNMATCHED';

export const getSearchResults = searchParams => {
  return {
    [CALL_API]: {
      types: [GET_SEARCH_RESULTS_REQUEST, GET_SEARCH_RESULTS_SUCCESS, GET_SEARCH_RESULTS_FAILURE],
      authenticated: true,
      endpoint: `v1/search/query`,
      method: 'POST',
      payload: searchParams,
    },
  };
};

export const getRoster = (npiRecords, unmatchedTerms) => {
  return {
    [CALL_API]: {
      types: [GET_ROSTER_REQUEST, GET_ROSTER_SUCCESS, GET_ROSTER_FAILURE],
      authenticated: true,
      endpoint: `v1/search/roster`,
      method: 'POST',
      payload: { npiRecords, unmatchedTerms },
    },
  };
};

export const resetSearch = () => ({
  type: RESET_SEARCH,
});

export const selectQuerySearchResult = (queryId, resultId) => ({
  type: SELECT_QUERY_SEARCH_RESULT,
  payload: { queryId, resultId },
});

export const setQueryTermMatched = queryId => ({
  type: SET_QUERY_TERM_MATCHED,
  payload: { queryId },
});

export const setQueryTermUnmatched = queryId => ({
  type: SET_QUERY_TERM_UNMATCHED,
  payload: { queryId },
});
