import { createMuiTheme } from '@material-ui/core/styles';

const theme = {
  palette: {
    common: {
      black: '#000',
      white: '#fff',
      lightGray: '#E0E0E0',
      extraLightGray: '#EDEDED',
      greenHighlight: '#AFFF96',
    },
    background: { paper: '#fff', default: '#fafafa', light: '#f8f8f8' },
    primary: {
      main: '#2C4465',
      contrastText: '#fff',
    },
    secondary: {
      main: '#65457B',
      contrastText: '#fff',
    },
    tertiary: {
      main: '#23919C',
      dark: '#00636e',
      light: '#26a7b5',
      contrastText: '#fff',
    },
    success: {
      main: '#FDBF01',
      contrastText: '#fff',
    },
    error: {
      main: '#F25C3A',
      dark: '#a94028',
      light: '#f47c61',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.70)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  typography: {
    fontFamily: 'Poppins, Roboto, Arial, sans-serif',
  },
  props: {
    MuiButton: {
      disableFocusRipple: true,
    },
  },
  overrides: {
    MuiDialogTitle: {
      root: {
        textTransform: 'uppercase',
      },
    },
    MuiButton: {
      root: {
        '&:focus': {
          outline: '#5E9ED6 auto 1px',
          outlineOffset: '1px',
        },
      },
      label: {
        fontWeight: 600,
      },
    },
    MuiTableBody: {
      root: {
        '& tr:first-child': {
          background: '#f8f8f8',
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: '0.5rem',
      },
    },
  },
};

export default createMuiTheme(theme);
