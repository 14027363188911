import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Box, Typography, ButtonGroup } from '@material-ui/core';
import { BORDER_RADIUS } from 'modules/layout/section.container';
import { MAX_SIDEBAR_WIDTH_REM } from 'common/sectionStyles';
import { ROUTABLE_VIEW_HEIGHT } from 'modules/opportunities/opportunity/opportunity.container';
import CuriButton from 'common/buttons/curiButton.component';
import TimelineDayListItems from 'modules/opportunities/opportunity/timelineDayListItems.component';
import OpportunityActivityViewEmail from 'modules/opportunities/opportunity/opportunityInformationStep/opportunityActivityViewEmail.component';
import Loading from 'common/components/loading.component';
import moment from 'moment';
import { API_DATE_PATTERN } from 'common/dates/dates';
import { useActivities, useSelectedOpportunity } from 'modules/opportunities/opportunities.selectors';
import { getOpportunityActivity } from 'modules/opportunities/opportunities.actions';

const FILTER_TOGGLE = {
  ALL: 'ALL',
  EMAILS: 'EMAILS',
  TASKS: 'TASKS',
};

function OpportunityTimeline() {
  const classes = useStyles();
  const { filterButtonToggle } = classes;
  const dispatch = useDispatch();
  const { emailActivity, tasks, isLoadingActivities } = useActivities();
  const selectedOpportunity = useSelectedOpportunity();

  useEffect(
    () => {
      dispatch(getOpportunityActivity(selectedOpportunity.id));
      // eslint-disable-next-line
    }, [/* Intentionally blank */]);

  const [viewEmail, setViewEmail] = useState(null);

  // `-1` reverse chronological order, `1` chronological order
  const [timelineSort /* , setTimelineSort */] = useState(-1);

  const [activeFilter, setActiveFilter] = useState(FILTER_TOGGLE.ALL);

  const timelineDays = useMemo(() => {
    const getDateKey = dateString => moment(dateString).format(API_DATE_PATTERN);

    const days = {
      // ex:
      // '2020-05-26': {
      //   date: '2020-05-26',
      //   items: [
      //     { title: 'Sent for experience rating', type: 'email', emails: [{...}] },
      //     { title: 'Reassigned to...', type: 'task' },
      //   ],
      // },
    };

    const sortedTasks = tasks.slice().sort((a, b) => moment(a.createdDate).diff(b.createdDate) * timelineSort);

    // Compose data structure for tasks
    sortedTasks.forEach(task => {
      const dateKey = getDateKey(task.createdDate);
      const previousItems = days[dateKey] ? days[dateKey].items : [];

      days[dateKey] = {
        date: dateKey,
        items: [
          ...previousItems,
          {
            title: task.subject,
            description: task.description,
            timestamp: task.createdDate,
            type: 'task',
          },
        ],
      };
    });

    // Compose data structure for email activity timeline
    emailActivity.forEach(a => {
      const title = a.subject;
      const emails = a.threads.flat();
      const emailsByDate = {};

      // Group emails from the same date
      emails.forEach(e => {
        const dateKey = getDateKey(e.createdDate);
        const previousEmailsByDate = emailsByDate[dateKey] || [];
        emailsByDate[dateKey] = [
          ...previousEmailsByDate,
          {
            date: dateKey,
            emails: e,
          },
        ];
      });

      // Add emails from same date to top level data structure
      Object.values(emailsByDate).forEach(emailByDate => {
        const dateKey = getDateKey(emailByDate[0].date);
        const previousItems = days[dateKey] ? days[dateKey].items : [];

        days[dateKey] = {
          date: dateKey,
          items: [
            ...previousItems,
            {
              title,
              type: 'email',
              emails: emailByDate
                .map(e => e.emails)
                .sort((y, z) => moment(y.createdDate).diff(z.createdDate) * timelineSort),
            },
          ],
        };
      });
    });

    return Object.values(days);
  }, [emailActivity, tasks, timelineSort]);

  const filteredSortedTimelineDays = useMemo(
    () =>
      timelineDays
        .map(day => ({
          ...day,
          items: day.items.filter(
            item =>
              activeFilter === FILTER_TOGGLE.ALL ||
              (activeFilter === FILTER_TOGGLE.TASKS && item.type === 'task') ||
              (activeFilter === FILTER_TOGGLE.EMAILS && item.type === 'email')
          ),
        }))
        .filter(day => day.items.length > 0)
        .sort((a, b) => moment(a.date).diff(b.date) * timelineSort),
    [timelineDays, activeFilter, timelineSort]
  );

  return (
    <>
      <Box borderRadius={BORDER_RADIUS.ROUNDED} bgcolor="common.lightGray" ml={`${MAX_SIDEBAR_WIDTH_REM + 1}rem`}>
        {isLoadingActivities ? (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            height={ROUTABLE_VIEW_HEIGHT}
          >
            <Loading />
          </Box>
        ) : (
          <Box py={5} px={4} height={ROUTABLE_VIEW_HEIGHT} overflow="hidden">
            {/* Filter Toggle Buttons */}
            <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" mb="2rem">
              <Typography color="textPrimary" component="p">
                Filter by:
              </Typography>
              <ButtonGroup>
                <CuriButton
                  customColor={activeFilter === FILTER_TOGGLE.ALL ? 'tertiary' : 'tertiaryOutline'}
                  onClick={() => setActiveFilter(FILTER_TOGGLE.ALL)}
                  className={filterButtonToggle}
                >
                  All
                </CuriButton>
                <CuriButton
                  customColor={activeFilter === FILTER_TOGGLE.EMAILS ? 'tertiary' : 'tertiaryOutline'}
                  onClick={() => setActiveFilter(FILTER_TOGGLE.EMAILS)}
                  className={filterButtonToggle}
                >
                  Emails
                </CuriButton>
                <CuriButton
                  customColor={activeFilter === FILTER_TOGGLE.TASKS ? 'tertiary' : 'tertiaryOutline'}
                  onClick={() => setActiveFilter(FILTER_TOGGLE.TASKS)}
                  className={filterButtonToggle}
                >
                  Tasks
                </CuriButton>
              </ButtonGroup>
            </Box>

            {/* Filterable content */}
            <Box overflow="auto" maxHeight="100%" pb="2.5rem" pr="0.75rem">
              {filteredSortedTimelineDays.map((day, i) => (
                <TimelineDayListItems
                  key={day.date}
                  dateTime={day.date}
                  items={day.items}
                  isLastDay={i === filteredSortedTimelineDays.length - 1}
                  handleOpenEmail={email => setViewEmail(email)}
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>
      <OpportunityActivityViewEmail handleClose={() => setViewEmail(null)} open={!!viewEmail} email={viewEmail} />
    </>
  );
}

const useStyles = makeStyles(theme => ({
  filterButtonToggle: {
    marginLeft: theme.spacing(1),
    minWidth: '5.25rem',
  },
}));

export default OpportunityTimeline;
