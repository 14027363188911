import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MenuItem, TextField, FormControl, InputLabel, Select, Chip, Box, ListSubheader } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { selectHighContrastModeEnabled } from 'modules/layout/layout.selectors';
import highContrastStyles from 'styles/highContrastStyles';

function CuriSelect({
  hasValidation,
  inputProps,
  noBlankOption,
  blankOption,
  options,
  selectProps,
  validatorRef,
  value,
  disabled,
  multiple,
  fullWidth,
  groups,
  label,
  readOnly, // TODO need to add readOnly classes, currently just disables changes
  ...restProps
}) {
  const classes = useStyles();
  const highContrastModeEnabled = useSelector(selectHighContrastModeEnabled);

  const curiSelectProps = { classes: {}, ...selectProps };

  /* If the select is disabled, add a class to hide the dropdown icon. */
  if (disabled) {
    curiSelectProps.classes = { icon: classes.hideSelectIcon, ...selectProps.classes };
  }

  if (groups.length) {
    return (
      <FormControl variant="outlined" className={classes.selectWidth} disabled={disabled} fullWidth={fullWidth}>
        <InputLabel
          classes={{
            root: classNames({ [classes.highContrastInputLabel]: highContrastModeEnabled }),
          }}
        >
          {label}
        </InputLabel>
        <Select
          readOnly={readOnly}
          ref={validatorRef}
          value={value || ''}
          {...curiSelectProps}
          label={label}
          {...restProps}
        >
          {noBlankOption ? null : (
            <MenuItem key="blankOption" value={blankOption.value} className={classes.blankOption}>
              {blankOption.name}
            </MenuItem>
          )}
          {groups.map(group => [
            <ListSubheader>{group.name}</ListSubheader>,
            group.items.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            )),
          ])}
        </Select>
      </FormControl>
    );
  }

  // Multi-select must use `<Select>` instead of `<TextField select />`
  if (multiple) {
    return (
      <FormControl variant="outlined" fullWidth={fullWidth} disabled={disabled}>
        <InputLabel
          classes={{
            root: highContrastModeEnabled ? classNames(classes.highContrastInputLabel) : undefined,
          }}
        >
          {label}
        </InputLabel>
        <Select
          ref={validatorRef}
          multiple
          classes={{
            selectMenu: classes.multiselect,
          }}
          value={value || []}
          readOnly={readOnly}
          renderValue={selectedValues => (
            <Box display="flex" flexWrap="wrap">
              {selectedValues.map(selectedValue => (
                <Chip
                  key={selectedValue}
                  disabled={disabled}
                  label={options.filter(o => o.value === selectedValue).map(o => o.name)}
                  className={classes.chip}
                  // Workaround disfunctional chips inside multiselect render value
                  // https://github.com/mui-org/material-ui/issues/18658
                  onDelete={() => {
                    /* Intentionally empty */
                  }}
                  deleteIcon={
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      onMouseDown={e => {
                        if (e.button === 0) {
                          e.stopPropagation();
                          restProps.onChange({ target: { value: selectedValues.filter(s => s !== selectedValue) } });
                        }
                      }}
                    >
                      <Close className="MuiChip-deleteIconSmall" />
                    </Box>
                  }
                />
              ))}
            </Box>
          )}
          label={label}
          {...restProps}
        >
          {noBlankOption ? null : <MenuItem value="" className={classes.blankOption} />}
          {options.map(option => (
            <MenuItem key={option.name} value={option.value} disabled={!!option.disabled}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <TextField
      select
      ref={validatorRef}
      variant="outlined"
      classes={{
        // if this component is wrapped in a validation component we dont want
        // a bottom margin added because the validation will add it so the
        // margin includes any error messages
        root: classNames(classes.select, {
          [classes.formControlNoMargin]: hasValidation,
        }),
      }}
      InputProps={{
        ...inputProps,
        readOnly,
      }}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
        ...curiSelectProps,
      }}
      value={value || ''}
      disabled={disabled}
      label={label}
      InputLabelProps={{
        className: highContrastModeEnabled ? classNames(classes.highContrastInputLabel) : undefined,
      }}
      fullWidth={fullWidth}
      {...restProps}
    >
      {noBlankOption ? null : (
        <MenuItem value={blankOption.value} className={classes.blankOption}>
          {blankOption.name}
        </MenuItem>
      )}
      {options.map(option => (
        <MenuItem key={option.name} value={option.value} disabled={!!option.disabled}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
}

CuriSelect.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hasValidation: PropTypes.bool,
  inputProps: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  noBlankOption: PropTypes.bool,
  options: PropTypes.array,
  selectProps: PropTypes.any,
  validatorRef: PropTypes.object,
  value: PropTypes.any,
  multiple: PropTypes.bool,
  fullWidth: PropTypes.bool,
  groups: PropTypes.array,
  onChange: PropTypes.func,
  blankOption: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  readOnly: PropTypes.bool,
};

CuriSelect.defaultProps = {
  className: '',
  disabled: false,
  hasValidation: false,
  inputProps: {},
  label: '',
  noBlankOption: false,
  options: [],
  selectProps: {},
  validatorRef: React.createRef(),
  value: null,
  multiple: false,
  fullWidth: false,
  groups: [],
  onChange: () => {},
  blankOption: {
    name: '',
    value: '',
  },
  readOnly: false,
};

const useStyles = makeStyles(theme => ({
  menu: {
    width: '100%',
  },
  blankOption: {
    height: 36,
    fontStyle: 'italic',
    color: theme.palette.grey[700],
  },
  formControlNoMargin: {
    marginBottom: '0rem !important',
  },
  select: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '5%',
    flex: 1,
    marginBottom: '0.5rem',
    marginRight: '0.5rem',
    minWidth: '10rem',
  },
  hideSelectIcon: {
    display: 'none',
  },
  selectWidth: {
    width: '100%',
  },
  chip: {
    margin: 1,
  },
  multiselect: {
    // Reduce default padding so inline chips don't cause select input to be
    // taller than other inputs
    padding: '0.6rem',
    // Match height of other inputs when no options selected
    minHeight: '36.4px',
  },
  ...highContrastStyles(theme),
}));

export default CuriSelect;
