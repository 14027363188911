import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import CuriButton from 'common/buttons/curiButton.component';
import formatIndividualRiskName from 'utilities/formatIndividualRiskName';

function PropagateRiskDataDialog({
  updatedRiskName,
  oldSpecialty,
  newSpecialty,
  risksToPropagate,
  open,
  handleCancel,
  handleConfirm,
  isLoading,
  suffixes,
}) {
  const { bold, noBottomMargin, confirmButton } = useStyles();

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleConfirm}>
      <DialogTitle>Propagate Risk Data</DialogTitle>
      <DialogContent>
        <DialogContentText>
          &#39;
          <Typography className={bold} component="span">
            {updatedRiskName}
          </Typography>
          &#39;&nbsp;updated.
        </DialogContentText>
        <DialogContentText>
          Do you also want to propagate &#39;
          <Typography className={bold} component="span">
            {newSpecialty}
          </Typography>
          &#39; for all other risks with specialty &#39;
          <Typography className={bold} component="span">
            {oldSpecialty}
          </Typography>
          &#39;&#63;
        </DialogContentText>
        <DialogContentText className={noBottomMargin}>
          {`Affected ${risksToPropagate.length > 1 ? `Risks (${risksToPropagate.length})` : 'Risk'}:`}
        </DialogContentText>
        <List dense disablePadding>
          {risksToPropagate.length &&
            risksToPropagate.map(r => (
              <ListItem key={r.id}>
                <ListItemText primary={formatIndividualRiskName(r, suffixes)} />
              </ListItem>
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <CuriButton variant="text" onClick={handleCancel} disabled={isLoading}>
          Cancel
        </CuriButton>
        <CuriButton onClick={handleConfirm} color="secondary" disabled={isLoading} className={confirmButton}>
          {isLoading ? 'Propagating Risk Data' : 'Propagate Risk Data'}
        </CuriButton>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles({
  confirmButton: {
    width: 215,
  },
  bold: {
    fontWeight: 600,
  },
  noBottomMargin: {
    marginBottom: 0,
  },
});

PropagateRiskDataDialog.propTypes = {
  updatedRiskName: PropTypes.string.isRequired,
  oldSpecialty: PropTypes.string.isRequired,
  newSpecialty: PropTypes.string.isRequired,
  risksToPropagate: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  suffixes: PropTypes.array.isRequired,
};

export default PropagateRiskDataDialog;
