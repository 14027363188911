import React, { useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, List } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';

import SectionContainer from 'modules/layout/section.container';
import Loading from 'common/components/loading.component';
import Empty from 'common/components/empty.component';
import CuriDownloadableListItem from 'common/components/curiDownloadableListItem.component';
import {
  selectCopyingAttachmentId,
  useOpportunityAttachments,
  useSelectedOpportunity,
  useSelectedOpportunityIsReadOnly,
} from 'modules/opportunities/opportunities.selectors';
import {
  copyOpportunityAttachmentToIndication,
  COPY_OPPORTUNITY_ATTACHMENT_TO_INDICATION_SUCCESS,
  updateOpportunityAttachment,
  UPDATE_OPPORTUNITY_ATTACHMENT_SUCCESS,
} from 'modules/opportunities/opportunities.actions';
import { useAuthz } from 'okta/authz';
import Permissions from 'okta/permissions';
import { handleToastMessage, TOAST_TYPES } from 'modules/layout/layout.actions';
import AttachmentListItem from 'common/components/attachmentListItem.component';
import { selectIndications } from 'modules/indication/indication.selectors';
import { updateLocalIndicationAttachment } from 'modules/indication/indication.actions';
import { selectApiServerUrl } from 'modules/appConfig/appConfig.selectors';

const SECTION_TITLE = 'Opportunity Attachments';

function OpportunityAttachmentsSection() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedOpportunity = useSelectedOpportunity();
  const indications = useSelector(selectIndications);
  const copyingAttachmentId = useSelector(selectCopyingAttachmentId);
  const apiServerUrl = useSelector(selectApiServerUrl);

  const isReadOnly = useSelectedOpportunityIsReadOnly();
  const { userHas } = useAuthz();
  const canUpdateCategory = useMemo(() => !isReadOnly && userHas(Permissions.UPDATE_OPPORTUNITY_INFO), [
    userHas,
    isReadOnly,
  ]);
  const { attachments, isLoadingAttachments } = useOpportunityAttachments();
  const sortedAttachments = useMemo(
    () =>
      (attachments || [])
        .slice()
        .sort((a, b) =>
          a.createdDate !== b.createdDate
            ? moment(a.createdDate).diff(b.createdDate) * -1
            : a.name.localeCompare(b.name)
        ),
    [attachments]
  );

  const [loadingCategory, setLoadingCategory] = useState(null);
  const isLegacy = selectedOpportunity && selectedOpportunity.useLegacyDocumentFlow;

  const handleUpdateCategory = useCallback(
    async (attachmentId, category) => {
      setLoadingCategory(attachmentId);

      const { type } = await dispatch(
        updateOpportunityAttachment(selectedOpportunity.id, { id: attachmentId, category: category || null })
      );
      if (type === UPDATE_OPPORTUNITY_ATTACHMENT_SUCCESS) {
        dispatch(handleToastMessage('Attachment updated.', TOAST_TYPES.SUCCESS));
      } else {
        dispatch(handleToastMessage('Unable to update attachment. Please try again.', TOAST_TYPES.ERROR));
      }

      setLoadingCategory(null);
    },
    [dispatch, selectedOpportunity]
  );

  const handleCopyToIndication = useCallback(
    async (attachmentId, indicationId) => {
      const { type, response } = await dispatch(
        copyOpportunityAttachmentToIndication(selectedOpportunity.id, attachmentId, indicationId)
      );
      if (type === COPY_OPPORTUNITY_ATTACHMENT_TO_INDICATION_SUCCESS) {
        dispatch(handleToastMessage('Attachment copied.', TOAST_TYPES.SUCCESS));
        // need to update local data so copying to the same indication is immediately disabled
        dispatch(updateLocalIndicationAttachment(response));
      } else {
        dispatch(handleToastMessage('Unable to copy attachment. Please try again.', TOAST_TYPES.ERROR));
      }
    },
    [dispatch, selectedOpportunity]
  );

  // Handle loading state
  if (isLoadingAttachments) {
    return (
      <SectionContainer title={SECTION_TITLE} reduceTitleMargin>
        <Loading />
      </SectionContainer>
    );
  }

  // Handle no activities
  if (!attachments || attachments.length === 0) {
    return (
      <SectionContainer title={SECTION_TITLE} reduceTitleMargin>
        <Empty icon="fal fa-file-pdf" text="No Attachments" />
      </SectionContainer>
    );
  }

  return (
    <SectionContainer title={SECTION_TITLE} reduceTitleMargin>
      <div className={classNames(classes.documentList, classes.forceScrollbar)}>
        <List>
          {sortedAttachments.map(a =>
            isLegacy ? (
              <CuriDownloadableListItem
                key={a.id}
                href={`${apiServerUrl}/v1/opportunities/${selectedOpportunity.id}/attachments/${a.id}/download`}
                name={a.name}
                createdDate={a.createdDate}
                canDelete={false}
                showAttachmentCategory
                attachmentCategory={a.category}
                canUpdateCategory={canUpdateCategory}
                handleUpdateCategory={categoryId => handleUpdateCategory(a.id, categoryId)}
                loadingCategory={loadingCategory === a.id}
              />
            ) : (
              <AttachmentListItem
                key={a.id}
                opportunityId={selectedOpportunity.id}
                attachment={a}
                showDelete={false}
                isReadOnly={isReadOnly}
                indications={indications || []}
                onCopyToIndication={indication => handleCopyToIndication(a.id, indication.id)}
                isCopyingToIndication={copyingAttachmentId === a.id}
              />
            )
          )}
        </List>
      </div>
    </SectionContainer>
  );
}

const useStyles = makeStyles({
  forceScrollbar: {
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: 7,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0, 0, 0, .5)',
      boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
  documentList: {
    height: 200,
    overflowY: 'scroll',
  },
});

export default OpportunityAttachmentsSection;
