import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Paper } from '@material-ui/core';
import classNames from 'classnames';

import SectionHeader from 'modules/layout/sectionHeader.component';

export const BORDER_RADIUS = {
  ROUNDED: '.25rem',
  ROUNDED_TOP: '.25rem .25rem 0 0',
  ROUNDED_BOTTOM: '0 0 .25rem .25rem',
};

function SectionContainer({ classes, children, className, title, reduceTitleMargin }) {
  return (
    <Paper className={classNames(classes.paper, className)}>
      {title && <SectionHeader reduceTitleMargin={reduceTitleMargin}>{title}</SectionHeader>}
      {children}
    </Paper>
  );
}

const styles = () => ({
  paper: {
    borderRadius: props => props.borderRadius || 0,
    marginBottom: '1rem',
    padding: '1rem',
    boxShadow: '-.1rem .1rem .4rem gray',
  },
});

SectionContainer.propTypes = {
  classes: PropTypes.object.isRequired,

  children: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.node,
  reduceTitleMargin: PropTypes.bool,
};

SectionContainer.defaultProps = {
  children: null,
  className: null,
  reduceTitleMargin: false,
  title: null,
};

export default withStyles(styles)(SectionContainer);
