import { createSelector } from 'reselect';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { isEqual } from 'lodash';

export const selectAlertsState = createSelector(
  state => state.alerts,
  alertsState => alertsState
);

export const selectIsLoadingAlerts = createSelector(selectAlertsState, alertsState => alertsState.isLoading);

export const selectIsAcknowledging = createSelector(selectAlertsState, alertsState => alertsState.isAcknowledging);

export const selectAllAlerts = createSelector(selectAlertsState, alertsState => Object.values(alertsState.alerts));

export const selectUnacknowledgedAlerts = createSelector(selectAlertsState, alertsState =>
  Object.values(alertsState.alerts).filter(a => !a.isAcknowledged)
);

export const selectSortedUnacknowledgedAlerts = createSelector(selectUnacknowledgedAlerts, alerts =>
  alerts.slice().sort((a, b) => moment(b.updatedDate).diff(a.updatedDate))
);

export const selectAlertsTaskIndicationIds = createSelector(selectUnacknowledgedAlerts, alerts =>
  alerts.map(a => a.task.indicationId).filter(id => !!id)
);

const selectAlertsTaskOpportunityIds = createSelector(selectUnacknowledgedAlerts, alerts =>
  alerts.map(a => a.task.opportunityId)
);

// returns the same list array instance if the values didn't change
// prevents re-renders every time the alerts poll executes
export function useAlertTaskOpportunityIds() {
  const ref = useRef(null);
  const alertsTaskOpportunityIds = useSelector(selectAlertsTaskOpportunityIds);

  if (!isEqual(alertsTaskOpportunityIds, ref.current)) {
    ref.current = alertsTaskOpportunityIds;
  }

  return ref.current;
}
