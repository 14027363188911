import { createSelector } from 'reselect';

// eslint-disable-next-line import/prefer-default-export
export const selectAppConfig = createSelector(
  state => state.appConfig,
  appConfigState => appConfigState
);

export const selectAppConfigData = createSelector(selectAppConfig, appConfigState => appConfigState.data);

export const selectApiServerUrl = createSelector(selectAppConfigData, appConfigDataState => {
  if (!appConfigDataState || !appConfigDataState.apiDomain) {
    return null;
  }

  return appConfigDataState.apiDomain.startsWith('localhost')
    ? `http://${appConfigDataState.apiDomain}`
    : `https://${appConfigDataState.apiDomain}`;
});

export const selectShowTestBanner = createSelector(selectAppConfigData, appConfigDataState => {
  return Boolean(appConfigDataState && appConfigDataState.environment === 'test');
});
