import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';
import { useAuthz } from 'okta/authz';
import { getNavigablePage } from 'okta/navigablePages';

const INDICATION_DIMENSION_REM = 1.3;

function CuriSidebarLink({ isActive, text, link, showVerticalLine }) {
  const classes = useStyles();
  const { canNavigateTo } = useAuthz();

  return (
    canNavigateTo(getNavigablePage(link)) && (
      <>
        <Link className={classes.container} component={RouterLink} to={link} underline="none">
          <span className={classNames(classes.indicator, { [classes.activeIndicator]: isActive })} />
          <span className={classNames({ [classes.activeText]: isActive })}>{text}</span>
        </Link>
        {showVerticalLine && <div className={classes.verticalLine} />}
      </>
    )
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    color: theme.palette.primary.main,
  },
  indicator: {
    height: `${INDICATION_DIMENSION_REM}rem`,
    width: `${INDICATION_DIMENSION_REM}rem`,
    marginRight: '0.6rem',
    backgroundColor: theme.palette.primary.main,
    borderRadius: `50%`,
  },
  activeIndicator: {
    backgroundColor: theme.palette.success.main,
  },
  activeText: {
    fontWeight: 600,
  },
  verticalLine: {
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    height: '1rem',
    margin: '5px 0 5px 9.4px',
  },
}));

CuriSidebarLink.propTypes = {
  isActive: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  showVerticalLine: PropTypes.bool.isRequired,
};

export default CuriSidebarLink;
