/* eslint-disable import/prefer-default-export, no-underscore-dangle */
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { NavigablePages } from './navigablePages';
import Permissions from './permissions';

const {
  READ_OPPORTUNITIES_UNASSIGNED,
  READ_OPPORTUNITIES_SELF,
  READ_OPPORTUNITIES_OTHERS,
  READ_OPPORTUNITY_INFO,
  SEARCH_FOR_OPPORTUNITY_RELATED_OPPORTUNITIES,
  READ_OPPORTUNITY_INDICATIONS,
  READ_INDICATION_POLICY_INFO,
  READ_INDICATION_RISKS,
  READ_INDICATION_SUMMARY,
  READ_INDICATION_DOCUMENTS,
  READ_INDICATION_NOTES,
} = Permissions;

const {
  ASSIGNED_OPPORTUNITIES,
  UNASSIGNED_OPPORTUNITIES,
  OPPORTUNITY_INFO,
  OPPORTUNITY_TIMELINE,
  OPPORTUNITY_RELATED_OPPORTUNITIES,
  OPPORTUNITY_INDICATIONS,
  INDICATION_POLICY_INFO,
  INDICATION_RISKS,
  INDICATION_SUMMARY,
  INDICATION_DOCUMENTS_NOTES,
  SEARCH,
  REDIRECT,
  UNKNOWN,
} = NavigablePages;

/**
 * This is a page-to-permissions `Map` for which the key represents the name of
 * a navigable page within the app, and the value represents the permissions
 * required to navigate to that page.
 */
const navigablePagesPermissionsMap = new Map()
  .set(UNASSIGNED_OPPORTUNITIES, [READ_OPPORTUNITIES_UNASSIGNED])
  .set(ASSIGNED_OPPORTUNITIES, [READ_OPPORTUNITIES_SELF, READ_OPPORTUNITIES_OTHERS])
  .set(OPPORTUNITY_INFO, [READ_OPPORTUNITY_INFO])
  .set(OPPORTUNITY_TIMELINE, [READ_OPPORTUNITY_INFO])
  .set(OPPORTUNITY_RELATED_OPPORTUNITIES, [SEARCH_FOR_OPPORTUNITY_RELATED_OPPORTUNITIES])
  .set(OPPORTUNITY_INDICATIONS, [READ_OPPORTUNITY_INDICATIONS])
  .set(INDICATION_POLICY_INFO, [READ_INDICATION_POLICY_INFO])
  .set(INDICATION_RISKS, [READ_INDICATION_RISKS])
  .set(INDICATION_SUMMARY, [READ_INDICATION_SUMMARY])
  .set(INDICATION_DOCUMENTS_NOTES, [READ_INDICATION_DOCUMENTS, READ_INDICATION_NOTES])
  .set(SEARCH, [READ_INDICATION_RISKS])
  .set(REDIRECT, [READ_OPPORTUNITY_INFO]);

const _userPermissionsMap = new Map();
const _userPermissions = createSelector(
  [state => state.login.permissions, state => state.login.permissionsOverride],
  (permissions, permissionsOverride) => [...permissions, ...permissionsOverride]
);

/**
 * A custom hook which returns an object with (4) properties:
 *
 *   1. `userHas` - A helper function which returns a boolean that indicates
 *      if a user possesses a specified permission
 *   2. `canNavigateTo` - A helper function which returns a boolean that
 *      indicates if a user has at least 1 required permission to navigate to
 *      a specified navigable page
 *   3. `permissionsLoaded` - A boolean which indicates when user permissions
 *      have loaded, and consequently whether the `userHas` and `canNavigateTo`
 *      function return values can be trusted
 *   4. `navigablePagesPermissionsMap` - the Map which stores what permissions
 *      are required to view a given page
 *
 * ## Example:
 *
 * ```jsx
 * import { useAuthz } from 'okta/authz';
 * import Permissions from 'okta/permissions';
 * import { getNavigablePage } from 'okta/navigablePages';
 *
 * export MyComponent = () => {
 *   const { userHas, canNavigateTo } = useAuthz();
 *   const { pathname } = useLocation();
 *
 *   return (
 *     <div>Does user have specified permission? { userHas(Permissions.DELETE_ORGANIZATION_RISKS) }</div>
 *     <div>Does user have permission to navigate to page? { canNavigateTo(getNavigablePage(pathname)) }</div>
 *   );
 * };
 * ```
 */
export const useAuthz = () => {
  const userPermissions = useSelector(_userPermissions);

  const [permissionsLoaded, setPermissionsLoaded] = useState(false);

  const userPermissionsMap = useMemo(() => {
    setPermissionsLoaded(false);
    _userPermissionsMap.clear();

    userPermissions.forEach(permission => {
      _userPermissionsMap.set(permission, true);
    });

    setPermissionsLoaded(_userPermissionsMap.size > 0);
    return _userPermissionsMap;
  }, [userPermissions]);

  const userHas = useCallback(permissionTo => userPermissionsMap.has(permissionTo), [userPermissionsMap]);

  const canNavigateTo = useCallback(
    navigablePage =>
      navigablePage === UNKNOWN ||
      navigablePagesPermissionsMap
        .get(navigablePage)
        .some(necessaryPermission => userPermissionsMap.has(necessaryPermission)),
    [userPermissionsMap]
  );

  return {
    navigablePagesPermissionsMap,
    permissionsLoaded,
    userHas,
    canNavigateTo,
  };
};
