import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu, MenuItem, makeStyles, ListItem, ListItemText, ListItemSecondaryAction, Switch } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CuriButton from 'common/buttons/curiButton.component';
import { selectHighContrastModeEnabled } from 'modules/layout/layout.selectors';
import { toggleHighContrastMode } from 'modules/layout/layout.actions';

function UserMenu({ userDisplayName, logout }) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isInHighContrastMode = useSelector(selectHighContrastModeEnabled);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    logout();
  };

  const handleNavigate = url => {
    handleClose();
    history.push(url);
  };

  if (!userDisplayName) {
    return null;
  }

  return (
    <>
      <CuriButton variant="text" endIcon={<ArrowDropDownIcon />} onClick={handleClick} className={classes.button}>
        {userDisplayName}
      </CuriButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ListItem classes={{ root: classes.contrastModeItem }}>
          <ListItemText primary="High Contrast Mode" />
          <ListItemSecondaryAction>
            <Switch
              color="primary"
              edge="end"
              checked={isInHighContrastMode}
              onChange={e => dispatch(toggleHighContrastMode(e.target.checked))}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <MenuItem classes={{ root: classes.textAlignRight }} onClick={() => handleNavigate('/opportunities')}>
          Opportunities
        </MenuItem>
        <MenuItem classes={{ root: classes.textAlignRight }} onClick={() => handleNavigate('/search')}>
          Roster Search
        </MenuItem>
        <MenuItem classes={{ root: classes.textAlignRight }} onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

const useStyles = makeStyles(() => ({
  button: {
    textTransform: 'none',
  },
  contrastModeItem: {
    minWidth: '16rem',
  },
}));

UserMenu.propTypes = {
  userDisplayName: PropTypes.string,
  logout: PropTypes.func.isRequired,
};

UserMenu.defaultProps = {
  userDisplayName: null,
};

export default UserMenu;
