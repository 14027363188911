import React, { useState, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import DataTable from 'common/dataTable/dataTable.component';
import DeleteRisksDialogComponent from 'modules/indication/deleteRisksDialog.component';
import DataTableEditText from 'common/dataTable/dataTableEditText.component';
import CuriButton from 'common/buttons/curiButton.component';
import { normalizeIndicationRisk } from 'common/dataTable/dataTable.utils';
import { useIndicationIsInOasis } from 'modules/indication/indication.selectors';
import DataTablePagination from 'common/dataTable/dataTablePagination.component';
import VerifyOasisPartiesModal from './verifyOasisPartiesModal.component';

export const EmptySelectField = Object.freeze({
  id: 'USER_CLEARED_FIELD',
  description: 'N/A',
});

export const VerifyOasisClientsButton = React.forwardRef(
  ({ handleVerifyOasisParties, verifyOasisClientsDisabled, ...restProps }, ref) => {
    const indicationIsInOasis = useIndicationIsInOasis();
    const classes = useStyles();
    return (
      <div {...restProps} ref={ref}>
        <CuriButton
          className={classes.button}
          customColor="tertiary"
          onClick={handleVerifyOasisParties}
          disabled={verifyOasisClientsDisabled || indicationIsInOasis}
        >
          Verify Oasis Clients
        </CuriButton>
      </div>
    );
  }
);

VerifyOasisClientsButton.propTypes = {
  handleVerifyOasisParties: PropTypes.func.isRequired,
  verifyOasisClientsDisabled: PropTypes.bool.isRequired,
};

function IndicationRiskTable({
  columns,
  risks,
  isLoading,
  isDeletingRisks,
  isLoadingOasisParties,
  uploadedFileName,
  handleAddRisk,
  handleUpdateRisk,
  handleDeleteRisk,
  handleDeleteRisks,
  handleVerifyOasisParties,
  handleCloseOasisModal,
  handleClearOasisId,
  handleSelectOasisParty,
  handleConfirmNewOasisParty,
  globalBlur,
  setGlobalBlur,
  canAdd,
  canEdit,
  canDelete,
  canDeleteAll,
  verifyOasisParty,
  riskIsInvalid,
  children,
}) {
  const classes = useStyles();
  const indicationIsInOasis = useIndicationIsInOasis();

  // Must clone b/c `material-table` mutates data
  const normalizedRisks = useMemo(() => cloneDeep(risks).map(normalizeIndicationRisk), [risks]);

  const selectedClientIds = useMemo(() => normalizedRisks.map(r => r.oasisClientId).filter(id => Boolean(id)), [
    normalizedRisks,
  ]);

  const [showDeleteRisksConfirmation, setShowDeleteRisksConfirmation] = useState(false);

  const tableRef = useRef(null);

  /*
   * `material-table` does not offer an API to directly call `Add Row`
   * programmatically, so instead we call the underlying logic ourselves here.
   */
  const handleAddRiskClick = useCallback(() => {
    if (indicationIsInOasis) return;
    setGlobalBlur(false);
    const dataTable = tableRef.current;
    dataTable.dataManager.changeRowEditing();
    dataTable.setState({
      ...dataTable.dataManager.getRenderState(),
      showAddRow: true,
    });
  }, [setGlobalBlur, indicationIsInOasis]);

  const handleAddRowConfirmed = useCallback(
    newRisk => {
      if (indicationIsInOasis) return Promise.resolve();
      setGlobalBlur(true);
      return handleAddRisk(newRisk);
    },
    [setGlobalBlur, handleAddRisk, indicationIsInOasis]
  );

  const handleDeleteRisksConfirmed = useCallback(async () => {
    if (indicationIsInOasis) return;
    await handleDeleteRisks();
    setShowDeleteRisksConfirmation(false);
  }, [handleDeleteRisks, indicationIsInOasis]);

  const verifyOasisClientsDisabled = useMemo(
    () => !normalizedRisks.length || risks.some(riskIsInvalid) || isLoading || isLoadingOasisParties || isDeletingRisks,
    [normalizedRisks, risks, riskIsInvalid, isLoading, isLoadingOasisParties, isDeletingRisks]
  );

  return (
    <>
      <VerifyOasisPartiesModal
        open={Boolean(verifyOasisParty)}
        loading={isLoading}
        indicationRisk={verifyOasisParty}
        handleCloseOasisModal={handleCloseOasisModal}
        handleClearOasisId={handleClearOasisId}
        handleSelectOasisParty={handleSelectOasisParty}
        handleConfirmNewOasisParty={handleConfirmNewOasisParty}
        selectedClientIds={selectedClientIds}
      />
      <DeleteRisksDialogComponent
        open={!indicationIsInOasis && showDeleteRisksConfirmation}
        handleCancel={() => setShowDeleteRisksConfirmation(false)}
        handleConfirm={handleDeleteRisksConfirmed}
        count={risks.length}
        isLoading={isDeletingRisks}
      />
      <div className={classes.buttonContainer}>
        {uploadedFileName}
        {canEdit && (
          <Tooltip
            title={
              verifyOasisClientsDisabled
                ? 'This action will be enabled once missing information or errors are resolved'
                : ''
            }
            classes={{ tooltip: classes.tooltip }}
            arrow
          >
            <VerifyOasisClientsButton
              handleVerifyOasisParties={handleVerifyOasisParties}
              verifyOasisClientsDisabled={verifyOasisClientsDisabled}
            />
          </Tooltip>
        )}
        <span className={classes.button}>{children}</span>
        {canAdd && (
          <CuriButton
            className={classes.button}
            color="secondary"
            variant={!children ? undefined : 'outlined'}
            onClick={handleAddRiskClick}
            disabled={isLoading || isLoadingOasisParties || isDeletingRisks || indicationIsInOasis}
          >
            Add
          </CuriButton>
        )}
        {canDeleteAll && (
          <CuriButton
            className={classes.button}
            color="secondary"
            variant="outlined"
            onClick={() => setShowDeleteRisksConfirmation(true)}
            disabled={
              risks.length === 0 || isLoading || isLoadingOasisParties || isDeletingRisks || indicationIsInOasis
            }
          >
            Delete All
          </CuriButton>
        )}
      </div>
      <DataTable
        ref={tableRef}
        columns={columns}
        data={normalizedRisks}
        isLoading={isLoading}
        options={{ actionsColumnIndex: columns.length }}
        editable={{
          onRowAdd: canAdd && !indicationIsInOasis ? handleAddRowConfirmed : undefined,
          onRowUpdate: canEdit && !indicationIsInOasis ? handleUpdateRisk : undefined,
          onRowDelete: canDelete && !indicationIsInOasis ? handleDeleteRisk : undefined,
        }}
        components={{
          // eslint-disable-next-line react/prop-types
          EditField: ({ onChange, columnDef: { title, required }, value }) => (
            <DataTableEditText
              title={title}
              onChange={onChange}
              value={value}
              globalBlur={globalBlur}
              required={required}
            />
          ),
          Pagination: props => (
            <DataTablePagination {...props} dataTableRef={tableRef.current} viewAllCount={risks.length} />
          ),
        }}
      />
      <Typography variant="overline">Please note: CPQ does not support including Risks that are Named Slots</Typography>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 0 0.5rem 0',
  },
  button: {
    marginLeft: '1rem',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
  },
  tooltip: {
    maxWidth: '12rem',
  },
}));

IndicationRiskTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  risks: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDeletingRisks: PropTypes.bool.isRequired,
  globalBlur: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  canAdd: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canDeleteAll: PropTypes.bool.isRequired,
  verifyOasisParty: PropTypes.object,
  isLoadingOasisParties: PropTypes.bool,
  uploadedFileName: PropTypes.node,

  handleAddRisk: PropTypes.func.isRequired,
  handleUpdateRisk: PropTypes.func.isRequired,
  handleDeleteRisk: PropTypes.func.isRequired,
  handleDeleteRisks: PropTypes.func.isRequired,
  setGlobalBlur: PropTypes.func.isRequired,
  handleCloseOasisModal: PropTypes.func,
  handleClearOasisId: PropTypes.func,
  handleSelectOasisParty: PropTypes.func,
  handleConfirmNewOasisParty: PropTypes.func,
  handleVerifyOasisParties: PropTypes.func,
  riskIsInvalid: PropTypes.func.isRequired,
};

IndicationRiskTable.defaultProps = {
  children: null,
  verifyOasisParty: null,
  isLoadingOasisParties: false,
  uploadedFileName: null,
  handleCloseOasisModal: () => {},
  handleClearOasisId: () => {},
  handleSelectOasisParty: () => {},
  handleConfirmNewOasisParty: () => {},
  handleVerifyOasisParties: () => {},
};

export default IndicationRiskTable;
