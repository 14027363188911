/* eslint-disable prettier/prettier */
import { useMemo, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { getOpportunity } from './opportunities.actions';

export const selectOpportunitiesState = createSelector(
  state => state.opportunities,
  opportunitiesState => opportunitiesState
);

export const selectIsLoadingOpportunities = createSelector(
  selectOpportunitiesState,
  opportunitiesState => opportunitiesState.isLoading
);

export const selectIsLoadingMetadata = createSelector(
  selectOpportunitiesState,
  opportunitiesState => opportunitiesState.isLoadingMetadata
);

export const selectIsLoadingOpportunityNotes = createSelector(
  selectOpportunitiesState,
  opportunitiesState => opportunitiesState.isLoadingNotes
);

export const selectOpportunitiesCurrentTab = createSelector(
  selectOpportunitiesState,
  opportunitiesState => opportunitiesState.opportunitiesCurrentTab
);

export const selectAssignedUnderwriterFilter = createSelector(
  selectOpportunitiesState,
  opportunitiesState => opportunitiesState.assignedUnderwriterFilter
);

export const selectAssignedStatusFilter = createSelector(
  selectOpportunitiesState,
  opportunitiesState => opportunitiesState.assignedStatusFilter
);

export const selectUnassignedStatusFilter = createSelector(
  selectOpportunitiesState,
  opportunitiesState => opportunitiesState.unassignedStatusFilter
);

export const selectOpportunities = createSelector(
  selectOpportunitiesState,
  opportunitiesState => opportunitiesState.opportunities
);

export const selectIsLoadingRelatedOpportunities = createSelector(
  selectOpportunitiesState,
  opportunitiesState => opportunitiesState.isLoadingRelatedOpportunities
);

export const selectRelatedOpportunities = createSelector(
  selectOpportunitiesState,
  opportunitiesState => opportunitiesState.relatedOpportunities
);

export const selectRelatedArchivedOpportunities = createSelector(
  selectRelatedOpportunities,
  relatedOpportunitiesState =>
    relatedOpportunitiesState && Array.isArray(relatedOpportunitiesState.archived)
      ? relatedOpportunitiesState.archived
      : []
);

export const selectIsAssigning = createSelector(
  selectOpportunitiesState,
  opportunitiesState => opportunitiesState.isAssigning
);

export const selectIsUpdatingStatus = createSelector(
  selectOpportunitiesState,
  opportunitiesState => opportunitiesState.isUpdatingStatus
);

// Returns a function that returns `createSelector` for proper memoization with props
// passed in from a component
// https://react-redux.js.org/api/hooks#using-memoizing-selectors
// https://github.com/reduxjs/reselect#accessing-react-props-in-selectors
export const makeSelectOpportunityById = () =>
  createSelector(
    selectOpportunities,
    (_, opportunityId) => opportunityId,
    (opportunities, opportunityId) =>
      opportunityId && opportunities[opportunityId] ? opportunities[opportunityId] : null
  );

export const selectUser = createSelector(
  state => state.login.user,
  user => user
);

export const isInitiallyLoaded = createSelector(
  selectOpportunitiesState,
  opportunityState => opportunityState.initialLoad
);

export const selectActivities = createSelector(selectOpportunitiesState, opportunityState =>
  !opportunityState || !opportunityState.activities
    ? { emailActivity: [], tasks: [] }
    : {
      emailActivity: opportunityState.activities.emailActivity,
      tasks: opportunityState.activities.tasks,
    }
);

export const selectIsLoadingActivities = createSelector(
  selectOpportunitiesState,
  opportunityState => opportunityState.isLoadingActivities
);

export const selectOpportunityAttachments = createSelector(
  selectOpportunitiesState,
  opportunityState => opportunityState.attachments || []
);

export const selectIsLoadingOpportunityAttachments = createSelector(
  selectOpportunitiesState,
  opportunityState => opportunityState.isLoadingAttachments
);

export const selectIsRefreshingOpportunityAttachments = createSelector(
  selectOpportunitiesState,
  opportunityState => opportunityState.isRefreshingAttachments
);

export const selectOpportunityNewAttachmentCount = createSelector(
  selectOpportunitiesState,
  opportunityState => opportunityState.newAttachmentCount
);

export const selectIsUploadingOpportunityAttachment = createSelector(
  selectOpportunitiesState,
  opportunityState => opportunityState.isUploadingAttachment
);

export const selectCopyingAttachmentId = createSelector(
  selectOpportunitiesState,
  opportunityState => opportunityState.copyingAttachmentId
);

export const selectUnderwriters = createSelector(
  selectOpportunitiesState,
  opportunityState => opportunityState.underwriters
);

export const selectIsLoadingUnderwriters = createSelector(
  selectOpportunitiesState,
  opportunityState => opportunityState.isLoadingUnderwriters
);

export const selectIsSidebarOpen = createSelector(
  selectOpportunitiesState,
  opportunityState => opportunityState.isSidebarOpen
);

export const selectIsSendingNotification = createSelector(
  selectOpportunitiesState,
  opportunityState => opportunityState.isSendingNotification
);

export const selectMetadata = createSelector(selectOpportunitiesState, opportunityState => opportunityState.metadata);

export const selectAttachmentCategories = createSelector(
  selectMetadata,
  metadataState => metadataState.attachmentCategories
);

export const selectDocumentTypes = createSelector(selectMetadata, metadataState => metadataState.documentTypes);

export const selectAttachmentSubtypes = createSelector(
  selectMetadata,
  metadataState => metadataState.attachmentSubtypes
);

export const selectOpportunityStatuses = createSelector(
  selectMetadata,
  metadataState => metadataState.opportunityStatuses
);

export const selectStates = createSelector(selectMetadata, metadataState => metadataState.states);

export const selectProgramCodes = createSelector(selectMetadata, metadataState => metadataState.programCodes);

export const useOpportunityStatusLabel = code => {
  const opportunityStatuses = useSelector(selectOpportunityStatuses);
  const status = opportunityStatuses.find(s => s.code === code);
  return status ? status.label : '';
};

export const selectIndicationStatuses = createSelector(
  selectMetadata,
  metadataState => metadataState.indicationStatuses
);

export const selectPolicyTypes = createSelector(
  selectOpportunityStatuses,
  opportunityStatuses => opportunityStatuses.policyTypes
);

export const selectSuffixes = createSelector(selectMetadata, metadataState => metadataState.suffixes);

export const selectOpportunitiesStates = createSelector(selectOpportunities, opportunities => [
  ...new Set(
    Object.values(opportunities)
      .map(o => o.stateCodes)
      .flat()
      .sort()
  ),
]);

export const useUnderwriters = () => {
  const underwriters = useSelector(selectUnderwriters);
  const isLoadingUnderwriters = useSelector(selectIsLoadingUnderwriters);

  return {
    underwriters,
    isLoadingUnderwriters,
  };
};

export const useActivities = () => {
  const { emailActivity, tasks } = useSelector(selectActivities);
  const isLoadingActivities = useSelector(selectIsLoadingActivities);
  return {
    emailActivity: emailActivity || [],
    tasks: tasks || [],
    isLoadingActivities,
  };
};

export const useOpportunityAttachments = () => {
  const attachments = useSelector(selectOpportunityAttachments);
  const isLoadingAttachments = useSelector(selectIsLoadingOpportunityAttachments);
  return { attachments, isLoadingAttachments };
};

export const selectIsLoadingPolicyHierarchy = createSelector(
  selectOpportunitiesState,
  opportunitiesState => opportunitiesState.isLoadingPolicyHierarchy
);

export const selectPolicyHierarchy = createSelector(
  selectOpportunitiesState,
  opportunitiesState => opportunitiesState.policyHierarchy || []
);

/**
 * A custom hook that will select an array of all opportunites in redux state. This is helpful
 * because opportunities are otherwise stored in an object for an efficient lookup, and sometimes
 * we need the entire collection as an array.
 *
 * ## Example
 *
 * ```jsx
 * import { useOpportunities } from 'modules/opportunities/opportunities.selectors';
 *
 * export MyComponent = () => {
 *   const opportunities = useOpportunities();
 *
 *   return (
 *     <div>Qty. Opportunities: { opportunities.length }</div>
 *   );
 * };
 * ```
 */
export const useOpportunities = () => {
  const opportunitiesObject = useSelector(selectOpportunities);

  const opportunities = useMemo(() => Object.values(opportunitiesObject), [opportunitiesObject]);

  return opportunities;
};

/**
 * A custom hook that will select an opportunity in redux state whose `id` matches
 * the `opportunityId` route parameter.
 *
 * ## Example
 *
 * ```jsx
 * import { useSelectedOpportunity } from 'modules/opportunities/opportunities.selectors';
 *
 * export MyComponent = () => {
 *   const selectedOpportunity = useSelectedOpportunity();
 *
 *   return (
 *     <div>Selected Opportunity: { selectedOpportunity.name }</div>
 *   );
 * };
 * ```
 */
export const useSelectedOpportunity = () => {
  const opportunitiesObject = useSelector(selectOpportunities);
  const { opportunityId } = useParams();

  const selectedOpportunity = useMemo(() => opportunitiesObject[opportunityId], [opportunitiesObject, opportunityId]);

  return selectedOpportunity;
};

/**
 * A custom hook that handles the details of ensuring essential opportunity data
 * is fetched, and indicating when is has been loaded. It is sufficient for this
 * hook to only be used in a single top-level component in the app (auth.container).
 *
 * ## Example
 *
 * ```jsx
 * import { useOpportunityDataIsFetched } from 'modules/opportunities/opportunities.selectors';
 *
 * export MyComponent = () => {
 *   const opportunityDataIsFetched = useOpportunityDataIsFetched();
 *
 *   if (!opportunityDataIsFetched) {
 *     return <Loading />;
 *   }
 *
 *   return (
 *     <div>{children}</div>
 *   );
 * };
 * ```
 */
export const useOpportunityDataIsFetched = () => {
  const dispatch = useDispatch();
  const opportunitiesObject = useSelector(selectOpportunities);
  const user = useSelector(selectUser);
  const initialLoad = useSelector(isInitiallyLoaded);

  const location = useLocation();

  const urlSegments = useMemo(() => location.pathname.split('/'), [location]);

  const opportunityId = useMemo(() => {
    const index = urlSegments.indexOf('opportunities');
    if (index === -1) {
      return null;
    }
    const id = urlSegments[index + 1];
    return !id ? null : id;
  }, [urlSegments]);

  const isLoadingOpportunities = useSelector(selectIsLoadingOpportunities);

  const opportunityIsInStore = useMemo(() => {
    return !opportunityId ? false : !!opportunitiesObject[opportunityId];
  }, [opportunitiesObject, opportunityId]);

  // When an opportunityId exists in the URL, ensure it exists in the store, otherwise fetch it
  useEffect(() => {
    const fetchData = async () => {
      if (
        opportunityId &&
        opportunityId !== 'unassigned' &&
        !isLoadingOpportunities &&
        !opportunityIsInStore &&
        user &&
        !initialLoad
      ) {
        dispatch(getOpportunity(opportunityId));
      }
    };

    fetchData();
  }, [opportunityId, isLoadingOpportunities, dispatch, opportunitiesObject, opportunityIsInStore, user, initialLoad]);

  const dataIsFetched = useMemo(() => !opportunityId || opportunityIsInStore || opportunityId === 'unassigned', [
    opportunityId,
    opportunityIsInStore,
  ]);

  return dataIsFetched;
};

export const useSelectedOpportunityIsReadOnly = () => {
  const opportunity = useSelectedOpportunity();
  return opportunity && opportunity.isArchived;
};
