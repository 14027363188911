import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';

import CuriButton from 'common/buttons/curiButton.component';
import DataView from 'common/components/dataView.component';
import QueryResults from 'common/search/queryResults.component';

export default function SearchResultsTable({ isMatchedResults, results, handleMatchCategoryChange, onSelectResult }) {
  const classes = useStyles();
  const [focusedResult, setFocusedResult] = useState(null);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Search Text</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>NPI</TableCell>
              <TableCell>Organization</TableCell>
              <TableCell>License State</TableCell>
              <TableCell>License Number</TableCell>
              <TableCell>Score</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: classes.tableBodyRoot }}>
            {results.map(row => (
              <QueryResults
                key={row.query}
                isMatchedResults={isMatchedResults}
                row={row}
                handleMatchCategoryChange={handleMatchCategoryChange}
                onSelectResult={onSelectResult}
                onViewResultDetails={result => setFocusedResult(result)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog fullWidth open={Boolean(focusedResult)} onClose={() => setFocusedResult(null)}>
        <DialogTitle>NPI Source</DialogTitle>
        <DialogContent>
          <Typography gutterBottom component="div">
            {`Score: ${focusedResult && focusedResult.score}`}
          </Typography>
          <DataView data={focusedResult && focusedResult.source} />
        </DialogContent>
        <DialogActions>
          <CuriButton onClick={() => setFocusedResult(null)}>Close</CuriButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles(() => ({
  tableBodyRoot: {
    // fix a weird background issue in MUI
    '&> tr:first-child': {
      background: 'inherit',
    },
  },
}));

SearchResultsTable.defaultProps = {
  isMatchedResults: false,
};

SearchResultsTable.propTypes = {
  isMatchedResults: PropTypes.bool,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      query: PropTypes.string.isRequired,
      results: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          score: PropTypes.number.isRequired,
          record: PropTypes.object,
          source: PropTypes.object,
          isSelected: PropTypes.bool.isRequired,
        })
      ).isRequired,
    }).isRequired
  ).isRequired,
  handleMatchCategoryChange: PropTypes.func.isRequired,
  onSelectResult: PropTypes.func.isRequired,
};
