import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import { FormHelperText, withStyles } from '@material-ui/core';

const renderRequiredLabel = label => (
  <span>
    {label}
    <span>&thinsp;*</span>
  </span>
);

// Need to leave this as a class to extend ValidatorComponent
class CustomValidator extends ValidatorComponent {
  constructor(props) {
    super(props);
    this.state = { isDirty: false };

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.renderLabel = this.renderLabel.bind(this);
  }

  handleChange(event) {
    this.setState({ isDirty: true });
    this.props.onChange(event);
  }

  handleBlur(event) {
    if (!this.props.disableBlurValidation) this.validate(event.target.value, true);
    this.props.onBlur(event, this.state.isDirty);
  }

  renderLabel(label) {
    return this.props.validators.includes('required') ? renderRequiredLabel(label) : label;
  }

  render() {
    const {
      children,
      classes,
      disableBlurValidation,
      errorMessages,
      label,
      requiredError,
      validatorListener,
      validators,
      onBlur,
      onChange,
      ...rest
    } = this.props;

    const { isValid } = this.state;
    const renderedLabel = this.renderLabel(label);

    return (
      <div className={classes.formControl}>
        {Children.map(children, child =>
          cloneElement(child, {
            label: renderedLabel,
            error: isValid !== undefined && !isValid,
            ...rest,
            hasValidation: true,
            ref: this.validatorRef,
            onChange: this.handleChange,
            onBlur: this.handleBlur,
          })
        )}
        {isValid !== undefined && !isValid && (
          <FormHelperText classes={{ root: classes.helperText }} error>
            <span>{this.getErrorMessage()}</span>
          </FormHelperText>
        )}
      </div>
    );
  }
}

CustomValidator.propTypes = {
  classes: PropTypes.object.isRequired,

  disableBlurValidation: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
};

CustomValidator.defaultProps = {
  disableBlurValidation: false,
  validatorListener: () => {},
  value: null,
};

const styles = () => ({
  formControl: {
    marginBottom: '1.5rem',
  },
  helperText: {
    margin: '8px 14px 0', // copied from MuiFormHelperText to match build in validation error spacing
  },
});

export default withStyles(styles)(CustomValidator);
