import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { useTheme } from '@material-ui/core';

const DataTable = forwardRef((props, ref) => {
  const { columns, options, ...rest } = props;
  const theme = useTheme();

  return (
    <MaterialTable
      columns={columns}
      tableRef={ref}
      options={{
        pageSize: 50,
        pageSizeOptions: [25, 50, 100],
        draggable: false,
        emptyRowsWhenPaging: false,
        filtering: true,
        headerStyle: { padding: '1rem .5rem', background: theme.palette.background.light },
        paging: true,
        search: false,
        showTitle: false,
        toolbar: false,
        selection: false,
        ...options,
      }}
      {...rest}
    />
  );
});

DataTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  options: PropTypes.object,
};

DataTable.defaultProps = {
  options: {},
};

export default DataTable;
