import JsFileDownloader from 'js-file-downloader';

export async function downloadPrivateFile(filename, url, token) {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw Error('Could not download file');
  }
  const data = await response.blob();
  const objectUrl = URL.createObjectURL(data);
  downloadFile(filename, objectUrl);
  URL.revokeObjectURL(objectUrl);
}

export function downloadFile(filename, url) {
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);
  a.href = url;
  a.setAttribute('download', filename);
  a.click();
  document.body.removeChild(a);
}

export async function downloadFileWithProgress(filename, url, onProgress) {
  try {
    const download = new JsFileDownloader({
      url,
      filename,
      autoStart: false,
      process:
        typeof onProgress === 'function'
          ? event => {
              if (!event.lengthComputable) {
                return;
              }
              onProgress(Math.floor(event.loaded / event.total));
            }
          : undefined,
    });

    return await download.start();
  } catch (err) {
    console.error('Failed to download file', err);
    return null;
  }
}
