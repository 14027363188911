import React, { useState } from 'react';
import CuriText from 'common/formFields/curiText.component';
import PropTypes from 'prop-types';

/**
 * Made explicitly for use as an `editComponent` in a `material-table` when
 * input validation is required for text.
 */
function DataTableEditText({ title, value, onChange, globalBlur, required }) {
  const [blurred, setBlurred] = useState(false);
  return (
    <CuriText
      onChange={e => onChange(e.target.value)}
      hasValidation
      InputProps={{
        style: {
          fontSize: 13,
        },
      }}
      placeholder={title}
      label=""
      value={value}
      variant="standard"
      error={(blurred || globalBlur) && required && !value}
      onBlur={() => setBlurred(true)}
      allowHighContrastMode={false}
    />
  );
}

DataTableEditText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  globalBlur: PropTypes.bool.isRequired,
  required: PropTypes.bool,
};

DataTableEditText.defaultProps = {
  value: '',
  required: false,
};

export default DataTableEditText;
