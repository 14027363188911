import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { find, has, uniq } from 'lodash';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

import CuriSelect from 'common/formFields/curiSelect.component';
import CuriButton from 'common/buttons/curiButton.component';
import SectionContainer, { BORDER_RADIUS } from 'modules/layout/section.container';
import { EmptySelectField } from 'modules/indication/indicationRiskTable.component';
import IndicationRisksOrganizationTable from 'modules/indication/indicationRisksOrganizationTable.container';
import IndicationRisksIndividualTable from 'modules/indication/indicationRisksIndividualTable.container';
import { handleToastMessage, TOAST_TYPES } from 'modules/layout/layout.actions';
import {
  getIndicationIndividualRisks,
  getIndicationOrganizationRisks,
  resetIndicationRisks,
  getIndicationRiskCounties,
  getIndicationRiskSpecialties,
  getIndicationStates,
  updateIndication,
  UPDATE_INDICATION_SUCCESS,
} from 'modules/indication/indication.actions';
import { setSidebarOpen } from 'modules/opportunities/opportunities.actions';
import {
  selectIndividualRisks,
  selectIsLoadingIndividualRisks,
  selectIsLoadingRiskCounties,
  selectOrganizationRisks,
  selectIsLoadingOrganizationRisks,
  selectRiskCounties,
  useSelectedIndication,
  selectIsLoadingIndicationStates,
  selectIndicationStates,
  selectKeyedIndicationStates,
  selectIsLoadingRiskSpecialties,
  selectRiskSpecialties,
  selectIsLoadingIndication,
  useIndicationIsInOasis,
} from 'modules/indication/indication.selectors';
import IndicationRiskClassification from 'modules/indication/indicationRiskClassification';
import { PolicyGroupType } from 'modules/indication/indicationPolicyGroupsTypes';
import {
  selectIsSidebarOpen,
  selectOpportunities,
  selectSuffixes,
  useSelectedOpportunityIsReadOnly,
} from 'modules/opportunities/opportunities.selectors';
import formatIndividualRiskName from 'utilities/formatIndividualRiskName';

export default function IndicationRisks() {
  const classes = useStyles();
  const { opportunityId, indicationId } = useParams();
  const dispatch = useDispatch();
  const suffixes = useSelector(selectSuffixes);
  const isSidebarOpen = useSelector(selectIsSidebarOpen);

  const opportunities = useSelector(selectOpportunities);
  const opportunity = useMemo(() => find(opportunities, { id: opportunityId }) || {}, [opportunities, opportunityId]);

  const { selectedIndication } = useSelectedIndication();
  const individualRisks = useSelector(selectIndividualRisks);
  const isLoadingIndividualRisks = useSelector(selectIsLoadingIndividualRisks);
  const organizationalRisks = useSelector(selectOrganizationRisks);
  const isLoadingOrganizationalRisks = useSelector(selectIsLoadingOrganizationRisks);
  const isLoadingRiskCounties = useSelector(selectIsLoadingRiskCounties);
  const riskCounties = useSelector(selectRiskCounties);
  const isLoadingIndicationStates = useSelector(selectIsLoadingIndicationStates);
  const indicationStates = useSelector(selectIndicationStates);
  const keyedIndicationStates = useSelector(selectKeyedIndicationStates);
  const isLoadingRiskSpecialties = useSelector(selectIsLoadingRiskSpecialties);
  const riskSpecialties = useSelector(selectRiskSpecialties);
  const isLoadingIndication = useSelector(selectIsLoadingIndication);
  const indicationIsInOasis = useIndicationIsInOasis();
  const isReadonly = useSelectedOpportunityIsReadOnly();
  const disableUpdate = useMemo(() => indicationIsInOasis || isReadonly, [indicationIsInOasis, isReadonly]);

  useEffect(() => {
    dispatch(resetIndicationRisks());
    dispatch(getIndicationIndividualRisks(opportunityId, indicationId));
    dispatch(getIndicationOrganizationRisks(opportunityId, indicationId));
    dispatch(setSidebarOpen(true));

    return () => dispatch(setSidebarOpen(true));
  }, [dispatch, opportunityId, indicationId, opportunity]);

  useEffect(() => {
    if (!isLoadingIndicationStates && !indicationStates.length) {
      dispatch(getIndicationStates());
    }
  }, [dispatch, isLoadingIndicationStates, indicationStates]);

  /**
   * Query counties and specialties for only the states:
   *   - Associated with the selected indication
   *   - Associated with one of the individual or organizational risks
   */
  const statesToFetch = useMemo(
    () =>
      // Deduplicate states from query and already in store, verify state code validity
      selectedIndication
        ? [
            ...new Set([
              selectedIndication.stateCode,
              ...individualRisks.map(r => r.stateCode),
              ...organizationalRisks.map(r => r.stateCode),
            ]),
          ].filter(code => code && !!keyedIndicationStates[code] && !has(riskCounties, code))
        : [],
    [selectedIndication, individualRisks, organizationalRisks, riskCounties, keyedIndicationStates]
  );

  const countiesAlreadyFetched = useCallback(stateCode => !!stateCode && !!riskCounties[stateCode], [riskCounties]);

  const specialtiesAlreadyFetched = useCallback(
    stateCode =>
      selectedIndication &&
      !!stateCode &&
      !!riskSpecialties[selectedIndication.id] &&
      !!riskSpecialties[selectedIndication.id][stateCode],
    [riskSpecialties, selectedIndication]
  );

  // Fetch counties for states present in individual and organizational risks
  useEffect(() => {
    if (!isLoadingRiskCounties && selectedIndication && !isLoadingIndividualRisks && !isLoadingOrganizationalRisks) {
      const states = statesToFetch.filter(s => !countiesAlreadyFetched(s));
      if (states.length) {
        dispatch(getIndicationRiskCounties(states));
      }
    }
  }, [
    dispatch,
    selectedIndication,
    individualRisks,
    isLoadingRiskCounties,
    isLoadingIndividualRisks,
    isLoadingOrganizationalRisks,
    riskCounties,
    statesToFetch,
    countiesAlreadyFetched,
  ]);

  // Fetch specialties for states present in individual risks
  useEffect(() => {
    if (!isLoadingRiskSpecialties && selectedIndication && !isLoadingIndividualRisks) {
      const { id, issueCompanyId, policyTypeCode } = selectedIndication;
      const states = uniq(
        [selectedIndication.stateCode].concat(
          individualRisks.filter(r => r.indicationId === selectedIndication.id).map(r => r.stateCode)
        )
      ).filter(s => !!keyedIndicationStates[s] && !specialtiesAlreadyFetched(s));
      if (states.length) {
        dispatch(getIndicationRiskSpecialties(states, issueCompanyId, policyTypeCode, id));
      }
    }
  }, [
    dispatch,
    selectedIndication,
    individualRisks,
    isLoadingRiskSpecialties,
    isLoadingIndividualRisks,
    keyedIndicationStates,
    specialtiesAlreadyFetched,
  ]);

  const indicationStatesOptions = useMemo(
    () => indicationStates.map(s => ({ id: s.code, description: s.description })),
    [indicationStates]
  );

  const isValidStateCode = useCallback(stateCode => !!stateCode && !!keyedIndicationStates[stateCode], [
    keyedIndicationStates,
  ]);

  const riskSpecialtyFilterOptions = useMemo(
    () => (selectedIndication ? Object.values(riskSpecialties[selectedIndication.id] || []).flat() : []),
    [riskSpecialties, selectedIndication]
  );

  const getRiskSpecialtyOptions = useCallback(
    (stateCode, classification) =>
      !!stateCode &&
      !!classification &&
      selectedIndication &&
      !!riskSpecialties[selectedIndication.id] &&
      !!riskSpecialties[selectedIndication.id][stateCode]
        ? riskSpecialties[selectedIndication.id][stateCode]
            .filter(c => c.classification === classification)
            .map(c => ({ id: c.code, description: c.description }))
        : [],
    [riskSpecialties, selectedIndication]
  );

  const getSpecialtyName = useCallback(
    (stateCode, specialtyCode) => {
      if (
        !stateCode ||
        !specialtyCode ||
        !selectedIndication ||
        !riskSpecialties[selectedIndication.id] ||
        !riskSpecialties[selectedIndication.id][stateCode]
      )
        return '';
      const stateSpecialties = riskSpecialties[selectedIndication.id][stateCode];
      if (!stateSpecialties) return '';
      const specialty = stateSpecialties.find(s => s.code === specialtyCode);
      return specialty ? specialty.description : '';
    },
    [riskSpecialties, selectedIndication]
  );

  const riskCountyFilterOptions = useMemo(() => Object.values(riskCounties).flat(), [riskCounties]);

  const getRiskCountyOptions = useCallback(
    stateCode =>
      !!stateCode && !!riskCounties[stateCode]
        ? [EmptySelectField].concat(riskCounties[stateCode].map(c => ({ id: c.code, description: c.description })))
        : [],
    [riskCounties]
  );

  const getCountyName = useCallback(
    (stateCode, countyCode) => {
      if (!stateCode || !countyCode) return '';
      const stateCounties = getRiskCountyOptions(stateCode);
      const county = stateCounties.find(c => c.id === countyCode);
      return county ? county.description : '';
    },
    [getRiskCountyOptions]
  );

  const showOrganizationPolicyHolderSelect = useMemo(
    () => selectedIndication && selectedIndication.policyGroupType === PolicyGroupType.Organization,
    [selectedIndication]
  );
  const organizationPolicyHolderOptions = useMemo(
    () =>
      organizationalRisks.map(risk => ({
        name: risk.name,
        value: risk.id,
      })),
    [organizationalRisks]
  );

  const showIndividualPolicyHolderSelect = useMemo(
    () => selectedIndication && selectedIndication.policyGroupType === PolicyGroupType.Solo,
    [selectedIndication]
  );
  const individualPolicyHolderOptions = useMemo(
    () =>
      individualRisks
        .filter(risk => risk.classification === IndicationRiskClassification.Physician.id)
        .map(risk => ({
          name: formatIndividualRiskName(risk, suffixes),
          value: risk.id,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [individualRisks, suffixes]
  );

  const setPolicyHolderId = useCallback(
    async policyHolderId => {
      const response = await dispatch(
        updateIndication(selectedIndication.opportunityId, selectedIndication.id, {
          policyHolderId: policyHolderId || null,
        })
      );

      if (response.type === UPDATE_INDICATION_SUCCESS) {
        dispatch(handleToastMessage('Indication updated.', TOAST_TYPES.SUCCESS));
      } else {
        dispatch(handleToastMessage('Unable to update indication. Please try again.', TOAST_TYPES.ERROR));
      }
    },
    [dispatch, selectedIndication]
  );

  return (
    <>
      <SectionContainer
        title={
          <div className={classes.sectionTitle}>
            <span>Risks on Roster: Organizations</span>
            {showOrganizationPolicyHolderSelect && (
              <CuriSelect
                name="policyHolder"
                label="Policy Holder"
                className={classes.policyHolder}
                onChange={e => setPolicyHolderId(e.target.value)}
                value={selectedIndication.policyHolderId}
                options={organizationPolicyHolderOptions}
                disabled={isLoadingIndication || disableUpdate || isLoadingOrganizationalRisks}
              />
            )}
          </div>
        }
        borderRadius={BORDER_RADIUS.ROUNDED}
        className={classes.rosterSection}
        reduceTitleMargin
      >
        <IndicationRisksOrganizationTable
          indicationStatesOptions={indicationStatesOptions}
          isValidStateCode={isValidStateCode}
          indication={selectedIndication}
          countiesAlreadyFetched={countiesAlreadyFetched}
          getCountyName={getCountyName}
          riskCountyFilterOptions={riskCountyFilterOptions}
          getRiskCountyOptions={getRiskCountyOptions}
        />
      </SectionContainer>
      <SectionContainer
        title={
          <div className={classes.sectionTitle}>
            <span>Risks on Roster: Individuals</span>
            <div className={classes.titleActionsContainer}>
              {isSidebarOpen ? (
                <CuriButton
                  classes={{ root: classes.fullScreenButton }}
                  onClick={() => dispatch(setSidebarOpen(false))}
                >
                  <FullscreenIcon classes={{ root: classes.fullScreenIcon }} />
                  Fullscreen
                </CuriButton>
              ) : (
                <CuriButton classes={{ root: classes.fullScreenButton }} onClick={() => dispatch(setSidebarOpen(true))}>
                  <FullscreenExitIcon classes={{ root: classes.fullScreenIcon }} />
                  Minimize
                </CuriButton>
              )}
              {showIndividualPolicyHolderSelect && (
                <CuriSelect
                  name="policyHolder"
                  label="Policy Holder"
                  className={classes.policyHolder}
                  onChange={e => setPolicyHolderId(e.target.value)}
                  value={selectedIndication.policyHolderId}
                  options={individualPolicyHolderOptions}
                  disabled={isLoadingIndication || disableUpdate || isLoadingIndividualRisks}
                />
              )}
            </div>
          </div>
        }
        borderRadius={BORDER_RADIUS.ROUNDED}
        className={classes.rosterSection}
        reduceTitleMargin
      >
        <IndicationRisksIndividualTable
          indicationStatesOptions={indicationStatesOptions}
          isValidStateCode={isValidStateCode}
          countiesAlreadyFetched={countiesAlreadyFetched}
          specialtiesAlreadyFetched={specialtiesAlreadyFetched}
          riskSpecialtyFilterOptions={riskSpecialtyFilterOptions}
          getRiskSpecialtyOptions={getRiskSpecialtyOptions}
          riskSpecialties={riskSpecialties}
          getSpecialtyName={getSpecialtyName}
          riskCountyFilterOptions={riskCountyFilterOptions}
          getRiskCountyOptions={getRiskCountyOptions}
          getCountyName={getCountyName}
          indication={selectedIndication}
        />
      </SectionContainer>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  policyHolder: {
    maxWidth: '25rem',
    marginLeft: theme.spacing(2),
    marginRight: 0,
  },
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  titleActionsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  fullScreenButton: {
    marginBottom: theme.spacing(),
  },
  fullScreenIcon: {
    marginRight: theme.spacing(),
  },
}));
