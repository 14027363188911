export const LOGIN_USER = 'LOGIN_USER';
export const PATCH_USER = 'PATCH_USER';
export const LOG_OUT = 'LOG_OUT';

/* Define a login state to use across the app. */
export const LOGIN_STATE = {
  UNDETERMINED: 0,
  NOT_LOGGED_IN: 1,
  LOGGED_IN: 2,
};

export const setUser = user => ({
  type: LOGIN_USER,
  user,
});

export const patchUser = user => ({
  type: PATCH_USER,
  user,
});

export const logout = () => ({ type: LOG_OUT });
