import { InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import CuriText from 'common/formFields/curiText.component';

export default function CuriReadOnlyText({ label, value, multiline, startAdornmentValue }) {
  return (
    <CuriText
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        readOnly: true,
        startAdornment: startAdornmentValue ? (
          <InputAdornment position="start">{startAdornmentValue}</InputAdornment>
        ) : null,
      }}
      label={label}
      readOnly
      value={value}
      variant="standard"
      multiline={multiline}
    />
  );
}

CuriReadOnlyText.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  multiline: PropTypes.bool,
  startAdornmentValue: PropTypes.string,
};

CuriReadOnlyText.defaultProps = {
  multiline: false,
  startAdornmentValue: null,
  value: '',
};
