import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, withStyles } from '@material-ui/core';
import CuriDropzone from 'common/fileUpload/CuriDropzone.component';
import CuriButton from 'common/buttons/curiButton.component';

function UploadRosterDialog({
  classes,
  open,
  handleCancel,
  handleAppend,
  handleReplace,
  handleUpload,
  isLoadingIndicationRisksDryRun,
  indicationRisksDryRun,
  isLoadingIndicationRiskIndividuals,
  indicationRiskIndividuals,
}) {
  const [csvFile, setCsvFile] = useState(null);

  const disabled = useMemo(
    () =>
      !csvFile ||
      isLoadingIndicationRisksDryRun ||
      isLoadingIndicationRiskIndividuals ||
      indicationRisksDryRun.length === 0,
    [csvFile, isLoadingIndicationRisksDryRun, isLoadingIndicationRiskIndividuals, indicationRisksDryRun]
  );

  const risksFound = useMemo(
    () => (!isLoadingIndicationRisksDryRun && csvFile ? `${indicationRisksDryRun.length} risks found in csv` : ''),
    [isLoadingIndicationRisksDryRun, csvFile, indicationRisksDryRun]
  );

  const disableBackdropClick = csvFile || isLoadingIndicationRisksDryRun || isLoadingIndicationRiskIndividuals;

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleCancel} disableBackdropClick={disableBackdropClick}>
      <DialogTitle>Upload Roster</DialogTitle>
      <DialogContent>
        <DialogContentText>Upload a .csv file of the roster members.</DialogContentText>
        <DialogContentText>
          {indicationRiskIndividuals.length > 0
            ? 'Select Append New to add to the current list OR select Replace to remove and add new list.'
            : 'Select Upload to add to the list'}
        </DialogContentText>
        <CuriDropzone
          acceptedFiles={['.csv', 'text/csv']}
          dropzoneText="Drag and drop a .csv file here or click"
          onChange={files => {
            setCsvFile(files[0]);
            handleUpload(files[0]);
          }}
          dropzoneClass="curi-dropzone-roster-container"
          dropzoneParagraphClass="curi-dropzone-roster-paragraph"
        />
        <DialogContentText className={classes.chosenFile}>
          {csvFile && `Chosen File: ${csvFile.name}`}
        </DialogContentText>
        <DialogContentText className={classes.chosenFile}>{risksFound}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <CuriButton
          variant="text"
          onClick={() => {
            handleCancel();
            setCsvFile(null);
          }}
          disabled={isLoadingIndicationRisksDryRun || isLoadingIndicationRiskIndividuals}
        >
          Cancel
        </CuriButton>
        {indicationRiskIndividuals.length > 0 && (
          <CuriButton
            variant="text"
            onClick={() => {
              handleAppend(csvFile);
              setCsvFile(null);
            }}
            disabled={disabled}
          >
            Append New
          </CuriButton>
        )}
        <CuriButton
          variant="text"
          onClick={() => {
            handleReplace(csvFile);
            setCsvFile(null);
          }}
          disabled={disabled}
        >
          {indicationRiskIndividuals.length > 0 ? 'Replace' : 'Upload'}
        </CuriButton>
      </DialogActions>
    </Dialog>
  );
}

UploadRosterDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleAppend: PropTypes.func.isRequired,
  handleReplace: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  isLoadingIndicationRisksDryRun: PropTypes.bool.isRequired,
  indicationRisksDryRun: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoadingIndicationRiskIndividuals: PropTypes.bool.isRequired,
  indicationRiskIndividuals: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const styles = () => ({
  chosenFile: {
    marginTop: '1rem',
    height: '30px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

export default withStyles(styles)(UploadRosterDialog);
