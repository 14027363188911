import React, { useState, useCallback, useMemo } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import SectionContainer, { BORDER_RADIUS } from 'modules/layout/section.container';
import UnsavedChangesConfirmDialogComponent from 'common/components/unsavedChangesConfirmDialog.component';
import IndicationPolicyInfoForm from 'modules/indication/indicationPolicyInfoForm';
import IndicationAddressInfoForm from 'modules/indication/indicationAddressInfoForm';
import CuriButton from 'common/buttons/curiButton.component';
import { selectIsLoadingIndication, useIndicationIsInOasis } from 'modules/indication/indication.selectors';

function IndicationPolicyInfo() {
  const classes = useStyles();
  const history = useHistory();

  const isLoadingUpdatePolicyInfoForm = useSelector(selectIsLoadingIndication);
  const indicationIsInOasis = useIndicationIsInOasis();

  const [policyInfoFormDirty, setPolicyInfoFormDirty] = useState(false);
  const [addressInfoFormDirty, setAddressInfoFormDirty] = useState(false);
  const [policyInfoFormValid, setPolicyInfoFormValid] = useState(false);
  const [addressInfoFormValid, setAddressInfoFormValid] = useState(false);

  // Handle unsaved changes
  const [nextRoute, setNextRoute] = useState(null);
  const [unsavedChangesConfirmed, setUnsavedChangesConfirmed] = useState(false);

  const handleUnsavedChanges = useCallback(
    nextLocation => {
      if (unsavedChangesConfirmed) {
        return true;
      }
      setNextRoute(nextLocation);
      return false;
    },
    [unsavedChangesConfirmed]
  );

  const confirmUnsavedChanges = useCallback(() => {
    setNextRoute(null);
    setUnsavedChangesConfirmed(true);
    setTimeout(() => {
      history.push(nextRoute);
    });
  }, [history, nextRoute]);

  const cancelUnsavedChanges = useCallback(() => {
    setNextRoute(null);
    setUnsavedChangesConfirmed(false);
  }, []);

  const savePolicyInfoIsDisabled = useMemo(
    () => !policyInfoFormValid || !policyInfoFormDirty || isLoadingUpdatePolicyInfoForm,
    [policyInfoFormValid, policyInfoFormDirty, isLoadingUpdatePolicyInfoForm]
  );

  const saveAddressInfoIsDisabled = useMemo(
    () => !addressInfoFormValid || !addressInfoFormDirty || isLoadingUpdatePolicyInfoForm,
    [addressInfoFormValid, addressInfoFormDirty, isLoadingUpdatePolicyInfoForm]
  );

  return (
    <>
      <SectionContainer
        title={
          <div className={classes.sectionTitle}>
            <span>Policy Type Information</span>
            <CuriButton
              className={classes.actionButton}
              customColor="success"
              disabled={savePolicyInfoIsDisabled || indicationIsInOasis}
              type="submit"
              form="indication-policy-form"
              isLoading={isLoadingUpdatePolicyInfoForm}
            >
              Save
            </CuriButton>
          </div>
        }
        borderRadius={BORDER_RADIUS.ROUNDED}
      >
        <IndicationPolicyInfoForm setFormDirty={setPolicyInfoFormDirty} setFormValid={setPolicyInfoFormValid} />
      </SectionContainer>
      <SectionContainer
        title={
          <div className={classes.sectionTitle}>
            <span>New Client Address</span>
            <CuriButton
              className={classes.actionButton}
              customColor="success"
              disabled={saveAddressInfoIsDisabled || indicationIsInOasis}
              type="submit"
              form="indication-address-form"
              isLoading={isLoadingUpdatePolicyInfoForm}
            >
              Save
            </CuriButton>
          </div>
        }
        borderRadius={BORDER_RADIUS.ROUNDED}
      >
        <IndicationAddressInfoForm setFormDirty={setAddressInfoFormDirty} setFormValid={setAddressInfoFormValid} />
      </SectionContainer>
      <Prompt when={policyInfoFormDirty || addressInfoFormDirty} message={handleUnsavedChanges} />
      <UnsavedChangesConfirmDialogComponent
        open={!!nextRoute}
        onCancel={cancelUnsavedChanges}
        onSubmit={confirmUnsavedChanges}
      />
    </>
  );
}

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  actionButton: {
    marginBottom: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
}));

export default IndicationPolicyInfo;
