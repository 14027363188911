import React, { useState, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  IconButton,
  Divider,
} from '@material-ui/core';
import { ChevronRight, KeyboardArrowDown } from '@material-ui/icons';
import OpportunityActivityListEmail from 'modules/opportunities/opportunity/opportunityInformationStep/opportunityActivityListEmail.component';

function OpportunityActivityListEmailThread({ thread, handleOpenEmail }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const hasMany = useMemo(() => thread.length > 1, [thread]);

  return (
    <>
      <Divider className={classes.divider} />
      <ListItem disableGutters>
        {hasMany && (
          <ListItemIcon classes={{ root: classes.icon }}>
            <IconButton size="small" onClick={() => hasMany && setOpen(!open)}>
              {open ? <KeyboardArrowDown /> : <ChevronRight />}
            </IconButton>
          </ListItemIcon>
        )}
        <ListItemText
          inset={!hasMany}
          classes={{ inset: classes.inset }}
          primary={
            <OpportunityActivityListEmail
              email={thread[0]}
              replies={thread.length - 1}
              handleOpenEmail={handleOpenEmail}
            />
          }
        />
      </ListItem>
      {hasMany && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List disablePadding>
            {thread.slice(1).map(email => (
              <Fragment key={email.id}>
                <Divider className={classes.divider} />
                <ListItem disableGutters>
                  <ListItemText
                    inset
                    classes={{ inset: classes.inset }}
                    primary={<OpportunityActivityListEmail email={email} handleOpenEmail={handleOpenEmail} />}
                  />
                </ListItem>
              </Fragment>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

const useStyles = makeStyles(() => ({
  icon: {
    minWidth: '2rem',
    alignSelf: 'flex-start',
  },
  inset: {
    paddingLeft: '2rem',
  },
  divider: {
    marginLeft: '2rem',
  },
}));

OpportunityActivityListEmailThread.propTypes = {
  thread: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleOpenEmail: PropTypes.func.isRequired,
};

export default OpportunityActivityListEmailThread;
