import produce from 'immer';

import { GET_DISCUSSION_FAILURE, GET_DISCUSSION_REQUEST, GET_DISCUSSION_SUCCESS } from './discussion.actions';

const initialState = {
  result: null,
  isLoading: false,
};

// eslint-disable-next-line default-param-last
export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_DISCUSSION_REQUEST:
      draft.isLoading = true;
      draft.result = null;
      break;

    case GET_DISCUSSION_SUCCESS:
      draft.isLoading = false;
      draft.result = action.response;
      break;

    case GET_DISCUSSION_FAILURE:
      draft.isLoading = false;
      break;

    default:
      break;
  }

  return draft;
}, initialState);
