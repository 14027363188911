import React from 'react';
import PropTypes from 'prop-types';
import CustomValidator from 'common/formFields/customValidator.component';
import CuriText from 'common/formFields/curiText.component';

function CuriTextValidator(props) {
  const {
    disableBlurValidation,
    errorMessages,
    label,
    name,
    validators,
    value,
    onBlur,
    onChange,
    ...restProps
  } = props;

  return (
    <CustomValidator
      disableBlurValidation={disableBlurValidation}
      errorMessages={errorMessages}
      label={label}
      name={name}
      validators={validators}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    >
      <CuriText {...restProps} />
    </CustomValidator>
  );
}

CuriTextValidator.propTypes = {
  disableBlurValidation: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessages: PropTypes.array,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  validators: PropTypes.array,
  value: PropTypes.any,

  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

CuriTextValidator.defaultProps = {
  disableBlurValidation: false,
  disabled: false,
  errorMessages: [],
  onBlur: () => {},
  validators: [],
  value: '',
};

export default CuriTextValidator;
