import produce from 'immer';
import { LOGIN_STATE, LOGIN_USER, PATCH_USER, LOG_OUT } from 'modules/login/login.actions';

const initialState = {
  user: null,
  permissions: [],
  permissionsOverride: [],
  loginState: LOGIN_STATE.UNDETERMINED,
};

// eslint-disable-next-line default-param-last
export default produce((draft = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER: {
      const { user } = action;
      draft.loginState = LOGIN_STATE.LOGGED_IN;
      draft.user = user;
      draft.permissions = [...(user.cpqPermissions || [])];
      draft.permissionsOverride = [...(user.cpqPermissionsOverride || [])];
      break;
    }

    case PATCH_USER:
      draft.user = { ...draft.user, ...action.user };
      break;

    case LOG_OUT:
      draft.user = null;
      draft.loginState = LOGIN_STATE.NOT_LOGGED_IN;
      break;

    default:
      break;
  }
}, initialState);
