import { AppBar, Toolbar, useScrollTrigger, Typography, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { DRAWER_WIDTH } from 'modules/layout/drawer/utils';
import BrandLogo from 'assets/images/curi-logo-inverted.png';
import UserMenu from 'modules/layout/userMenu.component';
import { logout } from 'modules/login/login.actions';
import AlertList from 'modules/alerts/alertsList.component';
import { selectShowTestBanner } from 'modules/appConfig/appConfig.selectors';
import { selectUserDisplayName } from 'modules/login/login.selectors';
import { selectIsDrawerMenuOpen } from './layout.selectors';

export const HEADER_HEIGHT = '4rem';

const ElevationScroll = props => {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    // target: window ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

function Header(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isDrawerMenuOpen = useSelector(selectIsDrawerMenuOpen);
  const userDisplayName = useSelector(selectUserDisplayName);
  const showTestBanner = useSelector(selectShowTestBanner);

  const handleLogout = () => dispatch(logout());

  return (
    <ElevationScroll {...props}>
      <AppBar
        ref={() => 'HeaderAppBar'}
        color="primary"
        className={classnames(classes.appBar, isDrawerMenuOpen && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <Link to="/">
            <img className={classes.logo} src={BrandLogo} alt="" />
          </Link>
          <div className={classes.flexGrow}>{/* Intentionally blank spacer */}</div>
          <UserMenu userDisplayName={userDisplayName} logout={handleLogout} />
          <AlertList />
        </Toolbar>
        {showTestBanner && (
          <Toolbar className={classes.notifyBanner}>
            <Typography variant="h4">Test Environment</Typography>
          </Toolbar>
        )}
      </AppBar>
    </ElevationScroll>
  );
}

const useStyles = makeStyles(theme => ({
  containerRoot: {
    padding: 0,
  },
  logo: {
    height: 40,
    marginLeft: '1rem',
  },
  mobileDropDown: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: theme.spacing(3),
  },
  flexGrow: {
    flex: '1 1 auto',
  },
  notifyBanner: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default Header;
