import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, ListItem, ListItemIcon, ListItemText, Typography, Collapse } from '@material-ui/core';
import { ChevronRight, KeyboardArrowDown } from '@material-ui/icons';
import classNames from 'classnames';
import { IndicationPagePaths } from 'okta/navigablePages';
import CuriSidebarLink from './curiSidebarLink.component';

function CuriSidebarIndicationLinks({
  name,
  indicationId,
  urlSegments,
  shouldRestrictToPolicyInfoPage,
  getLinkFullPath,
  isOpen,
  handleOpen,
}) {
  const classes = useStyles();

  // /opportunities/:opportunityId/indications/:indicationsId/<policy | risks | documents | summary>
  const getIndicationLinkFullPath = useCallback(path => `${getLinkFullPath('indications')}/${indicationId}/${path}`, [
    indicationId,
    getLinkFullPath,
  ]);

  const hasActiveIndicationRoute = useMemo(() => urlSegments.includes(indicationId), [indicationId, urlSegments]);

  const isActiveIndicationRoute = useCallback(
    path => urlSegments.includes(indicationId) && urlSegments.includes(path),
    [indicationId, urlSegments]
  );

  const indicationLinks = useMemo(
    () =>
      [
        { text: 'Policy Information', path: IndicationPagePaths.PolicyInfo },
        { text: 'Roster', path: IndicationPagePaths.Risks },
        { text: 'Documents & Notes', path: IndicationPagePaths.DocumentsAndNotes },
        { text: 'Summary', path: IndicationPagePaths.Summary },
      ]
        .map(link => ({ ...link, fullPath: getIndicationLinkFullPath(link.path) }))
        .filter(link => !shouldRestrictToPolicyInfoPage || link.path === IndicationPagePaths.PolicyInfo),
    [getIndicationLinkFullPath, shouldRestrictToPolicyInfoPage]
  );

  return (
    <>
      <ListItem button disableGutters onClick={handleOpen}>
        <ListItemIcon className={classes.dark} classes={{ root: classes.listItemIcon }}>
          {isOpen ? <KeyboardArrowDown /> : <ChevronRight />}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={classNames(classes.dark, { [classes.bold]: hasActiveIndicationRoute })}
              variant="body2"
              noWrap
            >
              {name}
            </Typography>
          }
        />
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit classes={{ wrapperInner: classes.links }}>
        {indicationLinks.map((link, i) => (
          <CuriSidebarLink
            key={link.path}
            text={link.text}
            isActive={isActiveIndicationRoute(link.path)}
            link={link.fullPath}
            showVerticalLine={i !== indicationLinks.length - 1}
          />
        ))}
      </Collapse>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  dark: {
    color: theme.palette.primary.main,
  },
  bold: {
    fontWeight: '600 !important',
  },
  listItemIcon: {
    minWidth: 0,
  },
  links: {
    padding: '0.5rem 0 0.5rem 1rem',
  },
}));

CuriSidebarIndicationLinks.propTypes = {
  name: PropTypes.string.isRequired,
  indicationId: PropTypes.string.isRequired,
  urlSegments: PropTypes.arrayOf(PropTypes.string).isRequired,
  shouldRestrictToPolicyInfoPage: PropTypes.bool.isRequired,
  getLinkFullPath: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
};

export default CuriSidebarIndicationLinks;
