import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { selectHighContrastModeEnabled } from 'modules/layout/layout.selectors';
import highContrastStyles from 'styles/highContrastStyles';

function CuriText({
  disabled,
  hasValidation,
  hideAdornment,
  name,
  allowHighContrastMode,
  readOnly,
  validatorRef,
  value,
  onChange,
  onClear,
  ...restProps
}) {
  const classes = useStyles();
  const highContrastModeEnabled = useSelector(selectHighContrastModeEnabled);

  let highContrastProps = {};

  if (highContrastModeEnabled && allowHighContrastMode) {
    highContrastProps = {
      variant: 'outlined',
    };
  }

  const handleClear = useCallback(() => {
    onChange({ target: { name, value: '' } });
    if (typeof onClear === 'function') {
      onClear();
    }
  }, [onChange, onClear, name]);

  return (
    <TextField
      classes={{
        // if this component is wrapped in a validation component we dont want
        // a bottom margin added because the validation will add it so the
        // margin includes any error messages
        root: classNames({
          [classes.readOnly]: !highContrastModeEnabled && readOnly,
          [classes.highContrastInput]: highContrastModeEnabled && readOnly,
          [classes.formControlNoMargin]: hasValidation,
          [classes.formControl]: !hasValidation,
        }),
      }}
      disabled={disabled}
      name={name}
      ref={validatorRef}
      // This allows for values to be passed in as null and pass the material-ui value check
      value={isNil(value) ? '' : value}
      variant="outlined"
      onChange={onChange}
      InputProps={{
        readOnly,
        className: classes.input,
        endAdornment:
          hideAdornment || readOnly ? null : (
            <InputAdornment position="end">
              {value.length && !disabled ? (
                <IconButton aria-label="clear input" onClick={handleClear} disableRipple>
                  <HighlightOff />
                </IconButton>
              ) : (
                <span className={classes.iconPlaceholder} />
              )}
            </InputAdornment>
          ),
      }}
      InputLabelProps={{
        className: highContrastModeEnabled ? classNames(classes.highContrastInputLabel) : undefined,
      }}
      rowsMax={restProps.multiline ? 8 : undefined}
      {...restProps}
      {...highContrastProps}
    />
  );
}

CuriText.propTypes = {
  disabled: PropTypes.bool,
  hasValidation: PropTypes.bool,
  hideAdornment: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  allowHighContrastMode: PropTypes.bool,
  validatorRef: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
};

CuriText.defaultProps = {
  disabled: false,
  hasValidation: false,
  hideAdornment: false,
  label: '',
  name: '',
  readOnly: false,
  allowHighContrastMode: true,
  validatorRef: React.createRef(),
  value: '',
  onChange: () => {},
  onClear: () => {},
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  input: {
    paddingRight: 0,
  },
  iconPlaceholder: {
    width: '48px',
    height: '1px',
  },
  formControl: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '5%',
    flex: 1,
    marginBottom: '0.5rem',
    marginRight: '0.5rem',
    minWidth: '10rem',
  },
  formControlNoMargin: {
    marginBottom: '0rem',
  },
  readOnly: {
    color: 'rgba(0, 0, 0, 0.54)', // This is the default formlabel color
    '& label.Mui-focused': {
      color: 'inherit',
    },
    '& .MuiInput-underline.Mui-focused:after': {
      transform: `scaleX(0)`,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)', // This is the default borderBottom
    },
  },
  highContrastInput: {
    '& .MuiFormLabel-root': {
      color: theme.palette.primary.main,
      transform: 'translate(9px, -8px) scale(1) !important',
      backgroundColor: theme.palette.common.white,
      padding: '0 0.3rem',
    },
  },
  highContrastInputLabel: {
    color: theme.palette.primary.main,
    transform: 'translate(9px, -8px) scale(1) !important',
    backgroundColor: theme.palette.common.white,
    padding: '0 0.3rem',
  },
  ...highContrastStyles(theme),
}));

export default CuriText;
