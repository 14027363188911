export const PolicyGroupType = Object.freeze({
  Solo: 'SOLO',
  Organization: 'ORG',
});

export default Object.freeze({
  Solo: {
    name: 'Solo',
    value: PolicyGroupType.Solo,
  },
  Organization: {
    name: 'Org',
    value: PolicyGroupType.Organization,
  },
});
