import React from 'react';
import PropTypes from 'prop-types';

export function a11yProps(id) {
  return {
    id: `tab-${id}`,
    'aria-controls': `tabpanel-${id}`,
  };
}

function TabPanel(props) {
  const { children, value, id, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== id} id={`tabpanel-${id}`} aria-labelledby={`tab-${id}`} {...other}>
      {value === id && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  id: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

TabPanel.defaultProps = {
  children: '',
};

export default TabPanel;
