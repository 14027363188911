import React from 'react';
import { makeStyles } from '@material-ui/core';

import AppConfig from 'modules/appConfig/appConfig.container';
import Routes from './routes/routes.component';
import Header from './header.container';
import Toast from './toast.container';

function MainContainer() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.mainContent}>
        <Header />
        <AppConfig>
          <Routes />
        </AppConfig>
      </div>
      <Toast />
    </>
  );
}

const useStyles = makeStyles(theme => ({
  mainContent: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minHeight: '100vh',
    overflowY: 'auto',
  },
}));

export default MainContainer;
