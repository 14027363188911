import produce from 'immer';
import {
  GET_OPPORTUNITY_INDICATIONS_FAILURE,
  GET_OPPORTUNITY_INDICATIONS_REQUEST,
  GET_OPPORTUNITY_INDICATIONS_SUCCESS,
  CREATE_INDICATION_FAILURE,
  CREATE_INDICATION_REQUEST,
  CREATE_INDICATION_SUCCESS,
  CLONE_INDICATION_FAILURE,
  CLONE_INDICATION_REQUEST,
  CLONE_INDICATION_SUCCESS,
  GET_INDICATION_INDIVIDUAL_RISKS_FAILURE,
  GET_INDICATION_INDIVIDUAL_RISKS_REQUEST,
  GET_INDICATION_INDIVIDUAL_RISKS_SUCCESS,
  VERIFY_INDIVIDUAL_RISKS_FAILURE,
  VERIFY_INDIVIDUAL_RISKS_REQUEST,
  VERIFY_INDIVIDUAL_RISKS_SUCCESS,
  CREATE_INDICATION_INDIVIDUAL_RISK_FAILURE,
  CREATE_INDICATION_INDIVIDUAL_RISK_REQUEST,
  CREATE_INDICATION_INDIVIDUAL_RISK_SUCCESS,
  UPDATE_INDICATION_FAILURE,
  UPDATE_INDICATION_REQUEST,
  UPDATE_INDICATION_SUCCESS,
  DELETE_INDICATION_FAILURE,
  DELETE_INDICATION_REQUEST,
  DELETE_INDICATION_SUCCESS,
  CREATE_OASIS_QUOTE_REQUEST,
  CREATE_OASIS_QUOTE_SUCCESS,
  CREATE_OASIS_QUOTE_FAILURE,
  UPDATE_INDICATION_NOTES_FAILURE,
  UPDATE_INDICATION_NOTES_REQUEST,
  UPDATE_INDICATION_NOTES_SUCCESS,
  UPDATE_INDICATION_INDIVIDUAL_RISK_FAILURE,
  UPDATE_INDICATION_INDIVIDUAL_RISK_REQUEST,
  UPDATE_INDICATION_INDIVIDUAL_RISK_SUCCESS,
  UPDATE_INDICATION_INDIVIDUAL_RISKS_FAILURE,
  UPDATE_INDICATION_INDIVIDUAL_RISKS_REQUEST,
  UPDATE_INDICATION_INDIVIDUAL_RISKS_SUCCESS,
  DELETE_INDICATION_INDIVIDUAL_RISK_FAILURE,
  DELETE_INDICATION_INDIVIDUAL_RISK_REQUEST,
  DELETE_INDICATION_INDIVIDUAL_RISK_SUCCESS,
  DELETE_INDICATION_INDIVIDUAL_RISKS_FAILURE,
  DELETE_INDICATION_INDIVIDUAL_RISKS_REQUEST,
  DELETE_INDICATION_INDIVIDUAL_RISKS_SUCCESS,
  GET_INDICATION_ORGANIZATION_RISKS_FAILURE,
  GET_INDICATION_ORGANIZATION_RISKS_REQUEST,
  VERIFY_ORGANIZATIONAL_RISKS_FAILURE,
  VERIFY_ORGANIZATIONAL_RISKS_REQUEST,
  VERIFY_ORGANIZATIONAL_RISKS_SUCCESS,
  GET_INDICATION_ORGANIZATION_RISKS_SUCCESS,
  CREATE_INDICATION_ORGANIZATION_RISK_FAILURE,
  CREATE_INDICATION_ORGANIZATION_RISK_REQUEST,
  CREATE_INDICATION_ORGANIZATION_RISK_SUCCESS,
  UPDATE_INDICATION_ORGANIZATION_RISK_FAILURE,
  UPDATE_INDICATION_ORGANIZATION_RISK_REQUEST,
  UPDATE_INDICATION_ORGANIZATION_RISK_SUCCESS,
  DELETE_INDICATION_ORGANIZATION_RISK_FAILURE,
  DELETE_INDICATION_ORGANIZATION_RISK_REQUEST,
  DELETE_INDICATION_ORGANIZATION_RISK_SUCCESS,
  DELETE_INDICATION_ORGANIZATION_RISKS_FAILURE,
  DELETE_INDICATION_ORGANIZATION_RISKS_REQUEST,
  DELETE_INDICATION_ORGANIZATION_RISKS_SUCCESS,
  UPLOAD_ROSTER_DRYRUN_REQUEST,
  UPLOAD_ROSTER_DRYRUN_SUCCESS,
  UPLOAD_ROSTER_DRYRUN_FAILURE,
  UPLOAD_ROSTER_FAILURE,
  UPLOAD_ROSTER_REQUEST,
  UPLOAD_ROSTER_SUCCESS,
  UPLOAD_INDICATION_ATTACHMENT_FAILURE,
  UPLOAD_INDICATION_ATTACHMENT_REQUEST,
  UPLOAD_INDICATION_ATTACHMENT_SUCCESS,
  GET_INDICATION_ATTACHMENTS_FAILURE,
  GET_INDICATION_ATTACHMENTS_REQUEST,
  GET_INDICATION_ATTACHMENTS_SUCCESS,
  UPDATE_INDICATION_ATTACHMENTS_FAILURE,
  UPDATE_INDICATION_ATTACHMENTS_REQUEST,
  UPDATE_INDICATION_ATTACHMENTS_SUCCESS,
  DELETE_INDICATION_ATTACHMENT_FAILURE,
  DELETE_INDICATION_ATTACHMENT_REQUEST,
  DELETE_INDICATION_ATTACHMENT_SUCCESS,
  RESET_INDICATION_RISKS,
  GET_INDICATION_STATES_REQUEST,
  GET_INDICATION_STATES_SUCCESS,
  GET_INDICATION_STATES_FAILURE,
  GET_INDICATION_RISK_COUNTIES_FAILURE,
  GET_INDICATION_RISK_COUNTIES_REQUEST,
  GET_INDICATION_RISK_COUNTIES_SUCCESS,
  GET_INDICATION_RISK_SPECIALTIES_FAILURE,
  GET_INDICATION_RISK_SPECIALTIES_REQUEST,
  GET_INDICATION_RISK_SPECIALTIES_SUCCESS,
  RESET_INDICATION_RISK_SPECIALTIES,
  GET_OASIS_POLICIES_FAILURE,
  GET_OASIS_POLICIES_REQUEST,
  GET_OASIS_POLICIES_SUCCESS,
  RESET_INDICATION_OASIS_POLICIES,
  RESET_INDICATIONS,
  GET_INDICATION_ATTACHMENT_UPLOAD_URL_REQUEST,
  GET_INDICATION_ATTACHMENT_UPLOAD_URL_SUCCESS,
  GET_INDICATION_ATTACHMENT_UPLOAD_URL_FAILURE,
  UPLOAD_INDICATION_ATTACHMENT_S3_FAILURE,
  UPLOAD_INDICATION_ATTACHMENT_S3_REQUEST,
  UPLOAD_INDICATION_ATTACHMENT_S3_SUCCESS,
  CREATE_INDICATION_ATTACHMENT_FAILURE,
  CREATE_INDICATION_ATTACHMENT_REQUEST,
  CREATE_INDICATION_ATTACHMENT_SUCCESS,
  GET_INDICATION_ATTACHMENT_DOWNLOAD_URL_REQUEST,
  GET_INDICATION_ATTACHMENT_DOWNLOAD_URL_SUCCESS,
  GET_INDICATION_ATTACHMENT_DOWNLOAD_URL_FAILURE,
  PUSH_INDICATION_ATTACHMENTS_TO_ONBASE_REQUEST,
  PUSH_INDICATION_ATTACHMENTS_TO_ONBASE_SUCCESS,
  PUSH_INDICATION_ATTACHMENTS_TO_ONBASE_FAILURE,
  UPDATE_LOCAL_INDICATION_ATTACHMENT,
} from 'modules/indication/indication.actions';
import { groupBy, keyBy } from 'lodash';

const initialState = {
  isLoading: false,
  indications: null,
  isLoadingIndication: false,
  isLoadingIndicationRisksDryRun: false,
  indicationRisksDryRun: [],
  isLoadingIndicationRiskIndividuals: false,
  indicationRiskIndividuals: [],
  isDeletingRiskIndividuals: false,
  isLoadingIndicationRiskOrganizations: false,
  indicationRiskOrganizations: [],
  isDeletingRiskOrganizations: false,
  isLoadingRiskCounties: false,
  riskCounties: {},
  indicationStates: [],
  isLoadingIndicationStates: false,
  isLoadingRiskSpecialties: false,
  riskSpecialties: {},
  isUploadingAttachment: false,
  isLoadingAttachments: false,
  isUpdatingAttachments: false,
  attachments: [],
  isDeletingAttachment: false,
  isLoadingIndicationStatus: false,
  isCreatingOasisQuote: false,
  loadingOasisPolicies: false,
  oasisPolicies: {},
  isLoadingUploadUrl: false,
  isCreatingIndicationAttachment: false,
  isLoadingDownloadUrl: false,
  isPushingAttachmentsToOnBase: false,
};

// eslint-disable-next-line default-param-last
export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_OPPORTUNITY_INDICATIONS_SUCCESS:
      draft.indications = action.response;
      draft.isLoading = false;
      break;

    case GET_OPPORTUNITY_INDICATIONS_REQUEST:
      draft.isLoading = true;
      break;

    case GET_OPPORTUNITY_INDICATIONS_FAILURE:
      draft.isLoading = false;
      break;

    case CREATE_INDICATION_SUCCESS:
      draft.isLoading = false;
      draft.indications = (draft.indications || []).concat(action.response);
      break;

    case CREATE_INDICATION_REQUEST:
      draft.isLoading = true;
      break;

    case CREATE_INDICATION_FAILURE:
      draft.isLoading = false;
      break;

    case CLONE_INDICATION_SUCCESS:
      draft.isLoading = false;
      draft.indications = (draft.indications || []).concat(action.response);
      break;

    case CLONE_INDICATION_REQUEST:
      draft.isLoading = true;
      break;

    case CLONE_INDICATION_FAILURE:
      draft.isLoading = false;
      break;

    case GET_INDICATION_INDIVIDUAL_RISKS_SUCCESS:
    case VERIFY_INDIVIDUAL_RISKS_SUCCESS:
      draft.isLoadingIndicationRiskIndividuals = false;
      draft.indicationRiskIndividuals = action.response;
      break;

    case GET_INDICATION_INDIVIDUAL_RISKS_REQUEST:
    case VERIFY_INDIVIDUAL_RISKS_REQUEST:
      draft.isLoadingIndicationRiskIndividuals = true;
      break;

    case GET_INDICATION_INDIVIDUAL_RISKS_FAILURE:
    case VERIFY_INDIVIDUAL_RISKS_FAILURE:
      draft.isLoadingIndicationRiskIndividuals = false;
      break;

    case CREATE_INDICATION_INDIVIDUAL_RISK_SUCCESS:
      draft.indicationRiskIndividuals.push(action.response);
      draft.isLoadingIndicationRiskIndividuals = false;
      break;

    case CREATE_INDICATION_INDIVIDUAL_RISK_REQUEST:
      draft.isLoadingIndicationRiskIndividuals = true;
      break;

    case CREATE_INDICATION_INDIVIDUAL_RISK_FAILURE:
      draft.isLoadingIndicationRiskIndividuals = false;
      break;

    case UPDATE_INDICATION_FAILURE:
    case DELETE_INDICATION_FAILURE:
    case UPDATE_INDICATION_NOTES_FAILURE:
      draft.isLoadingIndication = false;
      break;

    case UPDATE_INDICATION_REQUEST:
    case DELETE_INDICATION_REQUEST:
    case UPDATE_INDICATION_NOTES_REQUEST:
      draft.isLoadingIndication = true;
      break;

    case UPDATE_INDICATION_SUCCESS:
    case UPDATE_INDICATION_NOTES_SUCCESS: {
      const index = draft.indications.findIndex(i => i.id === action.response.id);
      if (index >= 0) {
        draft.indications[index] = {
          ...draft.indications[index],
          ...action.response,
        };
      }

      draft.isLoadingIndication = false;
      break;
    }

    case DELETE_INDICATION_SUCCESS: {
      draft.indications = draft.indications.filter(i => i.id !== action.actionMetadata.id);
      draft.isLoadingIndication = false;
      break;
    }

    case CREATE_OASIS_QUOTE_REQUEST:
      draft.isCreatingOasisQuote = true;
      break;

    case CREATE_OASIS_QUOTE_SUCCESS: {
      const index = draft.indications.findIndex(i => i.id === action.response.id);
      if (index >= 0) {
        draft.indications[index] = {
          ...draft.indications[index],
          ...action.response,
        };
      }

      draft.isCreatingOasisQuote = false;
      break;
    }

    case CREATE_OASIS_QUOTE_FAILURE:
      draft.isCreatingOasisQuote = false;
      break;

    case UPDATE_INDICATION_INDIVIDUAL_RISK_SUCCESS:
      draft.indicationRiskIndividuals = draft.indicationRiskIndividuals.map(risk =>
        risk.id === action.response.id ? action.response : risk
      );
      draft.isLoadingIndicationRiskIndividuals = false;
      break;

    case UPDATE_INDICATION_INDIVIDUAL_RISK_REQUEST:
      draft.isLoadingIndicationRiskIndividuals = true;
      break;

    case UPDATE_INDICATION_INDIVIDUAL_RISK_FAILURE:
      draft.isLoadingIndicationRiskIndividuals = false;
      break;

    case UPDATE_INDICATION_INDIVIDUAL_RISKS_FAILURE:
      draft.isLoadingIndicationRiskIndividuals = false;
      break;

    case UPDATE_INDICATION_INDIVIDUAL_RISKS_REQUEST:
      draft.isLoadingIndicationRiskIndividuals = true;
      break;

    case UPDATE_INDICATION_INDIVIDUAL_RISKS_SUCCESS: {
      draft.isLoadingIndicationRiskIndividuals = false;
      const keyedPatchedRisks = keyBy(action.response, r => r.id);
      draft.indicationRiskIndividuals = draft.indicationRiskIndividuals.map(r => keyedPatchedRisks[r.id] || r);
      break;
    }

    case DELETE_INDICATION_INDIVIDUAL_RISK_SUCCESS:
      draft.indicationRiskIndividuals = draft.indicationRiskIndividuals.filter(
        risk => risk.id !== action.actionMetadata.id
      );
      draft.isLoadingIndicationRiskIndividuals = false;
      break;

    case DELETE_INDICATION_INDIVIDUAL_RISK_REQUEST:
      draft.isLoadingIndicationRiskIndividuals = true;
      break;

    case DELETE_INDICATION_INDIVIDUAL_RISK_FAILURE:
      draft.isLoadingIndicationRiskIndividuals = false;
      break;

    case DELETE_INDICATION_INDIVIDUAL_RISKS_FAILURE:
      draft.isDeletingRiskIndividuals = false;
      break;

    case DELETE_INDICATION_INDIVIDUAL_RISKS_REQUEST:
      draft.isDeletingRiskIndividuals = true;
      break;

    case DELETE_INDICATION_INDIVIDUAL_RISKS_SUCCESS:
      draft.isDeletingRiskIndividuals = false;
      draft.indicationRiskIndividuals = [];
      break;

    case GET_INDICATION_ORGANIZATION_RISKS_FAILURE:
    case VERIFY_ORGANIZATIONAL_RISKS_FAILURE:
      draft.isLoadingIndicationRiskOrganizations = false;
      break;

    case GET_INDICATION_ORGANIZATION_RISKS_REQUEST:
    case VERIFY_ORGANIZATIONAL_RISKS_REQUEST:
      draft.isLoadingIndicationRiskOrganizations = true;
      break;

    case GET_INDICATION_ORGANIZATION_RISKS_SUCCESS:
    case VERIFY_ORGANIZATIONAL_RISKS_SUCCESS:
      draft.isLoadingIndicationRiskOrganizations = false;
      draft.indicationRiskOrganizations = action.response;
      break;

    case CREATE_INDICATION_ORGANIZATION_RISK_FAILURE:
      draft.isLoadingIndicationRiskOrganizations = false;
      break;

    case CREATE_INDICATION_ORGANIZATION_RISK_REQUEST:
      draft.isLoadingIndicationRiskOrganizations = true;
      break;

    case CREATE_INDICATION_ORGANIZATION_RISK_SUCCESS:
      draft.indicationRiskOrganizations.push(action.response);
      draft.isLoadingIndicationRiskOrganizations = false;
      break;

    case UPDATE_INDICATION_ORGANIZATION_RISK_FAILURE:
      draft.isLoadingIndicationRiskOrganizations = false;
      break;

    case UPDATE_INDICATION_ORGANIZATION_RISK_REQUEST:
      draft.isLoadingIndicationRiskOrganizations = true;
      break;

    case UPDATE_INDICATION_ORGANIZATION_RISK_SUCCESS:
      draft.indicationRiskOrganizations = draft.indicationRiskOrganizations.map(risk =>
        risk.id === action.response.id ? action.response : risk
      );
      draft.isLoadingIndicationRiskOrganizations = false;
      break;

    case DELETE_INDICATION_ORGANIZATION_RISK_FAILURE:
      draft.isLoadingIndicationRiskOrganizations = false;
      break;

    case DELETE_INDICATION_ORGANIZATION_RISK_REQUEST:
      draft.isLoadingIndicationRiskOrganizations = false;
      break;

    case DELETE_INDICATION_ORGANIZATION_RISK_SUCCESS:
      draft.indicationRiskOrganizations = draft.indicationRiskOrganizations.filter(
        risk => risk.id !== action.actionMetadata.id
      );
      draft.isLoadingIndicationRiskOrganizations = false;
      break;

    case DELETE_INDICATION_ORGANIZATION_RISKS_FAILURE:
      draft.isDeletingRiskOrganizations = false;
      break;

    case DELETE_INDICATION_ORGANIZATION_RISKS_REQUEST:
      draft.isDeletingRiskOrganizations = true;
      break;

    case DELETE_INDICATION_ORGANIZATION_RISKS_SUCCESS:
      draft.isDeletingRiskOrganizations = false;
      draft.indicationRiskOrganizations = [];
      break;

    case UPLOAD_ROSTER_DRYRUN_SUCCESS:
      draft.isLoadingIndicationRisksDryRun = false;
      draft.indicationRisksDryRun = action.response;
      break;

    case UPLOAD_ROSTER_DRYRUN_REQUEST:
      draft.isLoadingIndicationRisksDryRun = true;
      draft.indicationRisksDryRun = [];
      break;

    case UPLOAD_ROSTER_DRYRUN_FAILURE:
      draft.isLoadingIndicationRisksDryRun = false;
      break;

    case UPLOAD_ROSTER_SUCCESS:
      draft.isLoadingIndicationRiskIndividuals = false;
      draft.indicationRiskIndividuals = action.response;
      break;

    case UPLOAD_ROSTER_REQUEST:
      draft.isLoadingIndicationRiskIndividuals = true;
      draft.indicationRisksDryRun = [];
      break;

    case UPLOAD_ROSTER_FAILURE:
      draft.isLoadingIndicationRiskIndividuals = false;
      break;

    case UPLOAD_INDICATION_ATTACHMENT_FAILURE:
      draft.isUploadingAttachment = false;
      break;

    case UPLOAD_INDICATION_ATTACHMENT_REQUEST:
      draft.isUploadingAttachment = true;
      break;

    case UPLOAD_INDICATION_ATTACHMENT_SUCCESS:
      draft.isUploadingAttachment = false;
      draft.attachments.push(action.response);
      break;

    case GET_INDICATION_ATTACHMENTS_FAILURE:
      draft.isLoadingAttachments = false;
      break;

    case GET_INDICATION_ATTACHMENTS_REQUEST:
      draft.isLoadingAttachments = true;
      break;

    case GET_INDICATION_ATTACHMENTS_SUCCESS:
      draft.isLoadingAttachments = false;
      draft.attachments = action.response;
      break;

    case UPDATE_INDICATION_ATTACHMENTS_FAILURE:
      draft.isUpdatingAttachments = false;
      break;

    case UPDATE_INDICATION_ATTACHMENTS_REQUEST:
      draft.isUpdatingAttachments = true;
      break;

    case UPDATE_INDICATION_ATTACHMENTS_SUCCESS: {
      draft.isUpdatingAttachments = false;
      action.response.forEach(attachment => {
        const index = draft.attachments.findIndex(a => a.id === attachment.id);
        if (index >= 0) {
          draft.attachments[index] = attachment;
        } else {
          // shouldn't happen, but just in case
          draft.attachments.push(attachment);
        }
      });
      break;
    }

    case UPDATE_LOCAL_INDICATION_ATTACHMENT: {
      const attachment = action.payload;
      // need to update in two locations, unfortunately
      const indication = (draft.indications || []).find(i => i.id === attachment.refId);
      if (indication && indication.attachments) {
        const index = indication.attachments.findIndex(a => a.id === attachment.id);
        if (index >= 0) {
          indication.attachments[index] = attachment;
        } else {
          indication.attachments.push(attachment);
        }
      }

      // TODO should standardize on attachments pattern above,
      // we need to know about attachments other than those on the active indication
      const index = draft.attachments.findIndex(a => a.id === attachment.id);
      if (index >= 0) {
        draft.attachments[index] = attachment;
      } else {
        draft.attachments.push(attachment);
      }
      break;
    }

    case DELETE_INDICATION_ATTACHMENT_FAILURE:
      draft.isDeletingAttachment = false;
      break;

    case DELETE_INDICATION_ATTACHMENT_REQUEST:
      draft.isDeletingAttachment = true;
      break;

    case DELETE_INDICATION_ATTACHMENT_SUCCESS:
      draft.isDeletingAttachment = false;
      draft.attachments = draft.attachments.filter(a => a.id !== action.actionMetadata.id);
      break;

    case RESET_INDICATION_RISKS:
      draft.indicationRiskIndividuals = [];
      draft.indicationRiskOrganizations = [];
      break;

    case GET_INDICATION_STATES_REQUEST:
      draft.isLoadingIndicationStates = true;
      break;

    case GET_INDICATION_STATES_SUCCESS:
      draft.indicationStates = action.response;
      draft.isLoadingIndicationStates = false;
      break;

    case GET_INDICATION_STATES_FAILURE:
      draft.isLoadingIndicationStates = false;
      break;

    case GET_INDICATION_RISK_COUNTIES_FAILURE:
      draft.isLoadingRiskCounties = false;
      break;

    case GET_INDICATION_RISK_COUNTIES_REQUEST:
      draft.isLoadingRiskCounties = true;
      break;

    case GET_INDICATION_RISK_COUNTIES_SUCCESS:
      draft.isLoadingRiskCounties = false;
      draft.riskCounties = {
        ...draft.riskCounties,
        ...groupBy(action.response, c => c.stateCode),
      };
      break;

    case GET_INDICATION_RISK_SPECIALTIES_FAILURE:
      draft.isLoadingRiskSpecialties = false;
      break;

    case GET_INDICATION_RISK_SPECIALTIES_REQUEST:
      draft.isLoadingRiskSpecialties = true;
      break;

    case GET_INDICATION_RISK_SPECIALTIES_SUCCESS: {
      const { id, stateCodes } = action.actionMetadata;
      // Prevents endless loop of fetching when no specialties returned
      const emptySpecialties = {};
      if (!action.response.length) {
        // eslint-disable-next-line no-restricted-syntax
        for (const state of stateCodes) {
          emptySpecialties[state] = [];
        }
      }
      draft.isLoadingRiskSpecialties = false;
      draft.riskSpecialties = {
        ...draft.riskSpecialties,
        [id]: {
          ...draft.riskSpecialties[id],
          ...(action.response.length ? groupBy(action.response, c => c.stateCode) : emptySpecialties),
        },
      };
      break;
    }

    case RESET_INDICATION_RISK_SPECIALTIES:
      draft.riskSpecialties[action.payload] = {};
      break;

    case GET_OASIS_POLICIES_FAILURE:
      draft.loadingOasisPolicies = false;
      break;

    case GET_OASIS_POLICIES_REQUEST:
      draft.loadingOasisPolicies = true;
      break;

    case GET_OASIS_POLICIES_SUCCESS:
      draft.loadingOasisPolicies = false;
      draft.oasisPolicies[action.actionMetadata.id] = action.response;
      break;

    case RESET_INDICATION_OASIS_POLICIES:
      delete draft.oasisPolicies[action.payload];
      break;

    case RESET_INDICATIONS:
      draft.indications = null;
      break;

    case GET_INDICATION_ATTACHMENT_UPLOAD_URL_REQUEST:
      draft.isLoadingUploadUrl = true;
      break;

    case GET_INDICATION_ATTACHMENT_UPLOAD_URL_SUCCESS:
      draft.isLoadingUploadUrl = false;
      break;

    case GET_INDICATION_ATTACHMENT_UPLOAD_URL_FAILURE:
      draft.isLoadingUploadUrl = false;
      break;

    case UPLOAD_INDICATION_ATTACHMENT_S3_REQUEST:
      draft.isUploadingAttachment = true;
      break;

    case UPLOAD_INDICATION_ATTACHMENT_S3_SUCCESS:
    case UPLOAD_INDICATION_ATTACHMENT_S3_FAILURE:
      draft.isUploadingAttachment = false;
      break;

    case CREATE_INDICATION_ATTACHMENT_REQUEST:
      draft.isCreatingIndicationAttachment = true;
      break;

    case CREATE_INDICATION_ATTACHMENT_SUCCESS:
      draft.isCreatingIndicationAttachment = false;
      draft.attachments.push(action.response);
      break;

    case CREATE_INDICATION_ATTACHMENT_FAILURE:
      draft.isCreatingIndicationAttachment = false;
      break;

    case GET_INDICATION_ATTACHMENT_DOWNLOAD_URL_REQUEST:
      draft.isLoadingDownloadUrl = true;
      break;

    case GET_INDICATION_ATTACHMENT_DOWNLOAD_URL_SUCCESS:
    case GET_INDICATION_ATTACHMENT_DOWNLOAD_URL_FAILURE:
      draft.isLoadingDownloadUrl = false;
      break;

    case PUSH_INDICATION_ATTACHMENTS_TO_ONBASE_REQUEST:
      draft.isPushingAttachmentsToOnBase = true;
      break;

    case PUSH_INDICATION_ATTACHMENTS_TO_ONBASE_SUCCESS:
      // do not fully overwrite, some attachments may have failed and won't be in the response
      action.response.forEach(updated => {
        const index = draft.attachments.findIndex(original => original.id === updated.id);
        if (index >= 0) {
          draft.attachments[index] = updated;
        }
      });
      draft.isPushingAttachmentsToOnBase = false;
      break;

    case PUSH_INDICATION_ATTACHMENTS_TO_ONBASE_FAILURE:
      draft.isPushingAttachmentsToOnBase = false;
      break;

    default:
      break;
  }
}, initialState);
