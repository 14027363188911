import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { makeStyles, List, Box, Typography } from '@material-ui/core';
import moment from 'moment';

import { SIDEBAR_WIDTH_REM } from 'modules/opportunities/opportunity/opportunity.container';
import { selectIndications, selectKeyedIndications } from 'modules/indication/indication.selectors';
import { useSelectedOpportunity, useOpportunityStatusLabel } from 'modules/opportunities/opportunities.selectors';
import getAssigneeNames from 'utilities/getAssigneeNames';
import CuriSidebarIndicationLinks from './curiSidebarIndicationLinks.component';
import CuriSidebarLinks from './curiSidebarLinks.component';

function CuriSidebar() {
  const classes = useStyles();
  const { url: basePath } = useRouteMatch('/opportunities/:opportunityId');
  const location = useLocation();
  const urlSegments = useMemo(() => location.pathname.split('/'), [location]);
  const indications = useSelector(selectIndications);
  const keyedIndications = useSelector(selectKeyedIndications);
  const selectedOpportunity = useSelectedOpportunity();
  const opportunityStatus = useOpportunityStatusLabel(selectedOpportunity.activeStatusCode);

  // Provide fallback state for when `selectedIndicationId` is momentarily `null`, which
  // causes sidebar link `collapse` to close between each navigation
  const [nonNullSelectedIndicationId, setNonNullSelectedIndicationId] = useState(null);

  const selectedIndicationId = useMemo(() => {
    const index = urlSegments.indexOf('indications');
    const nextSelectedIndicationId = index === -1 ? null : urlSegments[index + 1];
    if (nextSelectedIndicationId !== null) {
      setNonNullSelectedIndicationId(nextSelectedIndicationId);
    }
    return nextSelectedIndicationId;
  }, [urlSegments]);

  // /opportunities/:opportunityId/<information | related | indications>
  const getLinkFullPath = useCallback(path => `${basePath}/${path}`, [basePath]);

  const [openedSubstepId, setOpenedSubstepId] = useState(null);

  useEffect(() => {
    setOpenedSubstepId(selectedIndicationId);
  }, [selectedIndicationId]);

  const handleOpen = useCallback(id => setOpenedSubstepId(id === openedSubstepId ? null : id), [openedSubstepId]);

  const isOpen = useCallback(
    id => (openedSubstepId === null ? id === nonNullSelectedIndicationId : id === openedSubstepId),
    [openedSubstepId, nonNullSelectedIndicationId]
  );

  const sortedIndications = useMemo(
    () => indications && indications.slice().sort((a, b) => moment(a.createdDate).diff(b.createdDate)),
    [indications]
  );

  const shouldRestrictToPolicyInfoPage = useCallback(
    indicationId => {
      const { issueCompanyId, stateCode, processLocationCode, policyTypeCode } = keyedIndications[indicationId];

      return !issueCompanyId || !stateCode || !processLocationCode || !policyTypeCode;
    },
    [keyedIndications]
  );

  return (
    <Box
      position="absolute"
      top={0}
      bottom={0}
      bgcolor="none"
      borderRadius="1.5rem"
      maxHeight="100%"
      padding="1rem 0 2rem"
      overflow="auto"
      width={`${SIDEBAR_WIDTH_REM}rem`}
    >
      <Box
        overflow="hidden"
        whiteSpace="nowrap"
        width={`${SIDEBAR_WIDTH_REM}rem`}
        padding="0 0.5rem"
        textAlign="center"
        fontWeight={600}
      >
        <Typography noWrap className={classes.sidebarContainer}>
          Status:&nbsp;
          {selectedOpportunity.assignees.length ? opportunityStatus : `Unassigned/${opportunityStatus}`}
        </Typography>
        {!!selectedOpportunity.assignees.length && (
          <Typography className={classes.assigneeNames}>
            Assigned to:&nbsp;
            {getAssigneeNames(selectedOpportunity.assignees)}
          </Typography>
        )}
      </Box>
      <Box padding="1rem 0 0 1.5rem" width="100%">
        <CuriSidebarLinks urlSegments={urlSegments} getLinkFullPath={getLinkFullPath} />
        <List dense disablePadding className={classes.indicationLinks}>
          {sortedIndications.map(({ id, name }) => (
            <CuriSidebarIndicationLinks
              key={id}
              name={name}
              indicationId={id}
              urlSegments={urlSegments}
              shouldRestrictToPolicyInfoPage={shouldRestrictToPolicyInfoPage(id)}
              getLinkFullPath={getLinkFullPath}
              isOpen={isOpen(id)}
              handleOpen={() => handleOpen(id)}
            />
          ))}
        </List>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  sidebarContainer: {
    textAlign: 'left',
    color: theme.palette.primary.main,
  },
  indicationLinks: {
    marginTop: '1rem',
    paddingLeft: '1.3rem',
  },
  assigneeNames: {
    whiteSpace: 'normal',
    textAlign: 'left',
    color: theme.palette.primary.main,
  },
}));

export default CuriSidebar;
