import DeleteIcon from '@material-ui/icons/Delete';
import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Typography, Box, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { useAuthz } from 'okta/authz';
import Permissions from 'okta/permissions';
import SectionContainer, { BORDER_RADIUS } from 'modules/layout/section.container';
import {
  useSelectedIndication,
  useIndicationStatusLabel,
  useIndicationIsInOasis,
  useSelectedIndicationOasisPolicies,
} from 'modules/indication/indication.selectors';
import CuriButton from 'common/buttons/curiButton.component';
import IndicationRenameModal from 'modules/indication/indicationRenameModal.component';
import {
  updateIndication,
  UPDATE_INDICATION_SUCCESS,
  deleteIndication,
  DELETE_INDICATION_SUCCESS,
} from 'modules/indication/indication.actions';
import { handleToastMessage, TOAST_TYPES } from 'modules/layout/layout.actions';
import { useSelectedOpportunityIsReadOnly } from 'modules/opportunities/opportunities.selectors';
import IndicationDeleteModal from './indicationDeleteModal.component';

export const getHeaderText = (dataDescription, dataValue, classes) => (
  <Typography variant="body1" className={classes}>
    {dataDescription}
    :&nbsp;
    {dataValue}
  </Typography>
);

function IndicationStatusHeader() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { userHas } = useAuthz();
  const { selectedIndication } = useSelectedIndication();
  const indicationStatus = useIndicationStatusLabel(selectedIndication && selectedIndication.activeStatusCode);
  const indicationIsInOasis = useIndicationIsInOasis();
  const isReadonly = useSelectedOpportunityIsReadOnly();
  const oasisPolicies = useSelectedIndicationOasisPolicies();

  const [showRenameModal, setShowRenameModal] = useState(false);
  const [loadingRename, setLoadingRename] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const fullPolicy = useMemo(() => {
    if (!Array.isArray(oasisPolicies) || oasisPolicies.length === 0) {
      return null;
    }

    // find indication/quote that was accepted
    const acceptedPolicy = oasisPolicies.find(p => p.policyId.includes('-') && p.policyStatusCode === 'ACCEPTED');
    if (!acceptedPolicy) {
      return null;
    }

    // find non-suffixed policy with matching contract dates
    const matchingPolicy = oasisPolicies.find(
      p =>
        !p.policyId.includes('-') &&
        p.contractStartDate === acceptedPolicy.contractStartDate &&
        p.contractEndDate === acceptedPolicy.contractEndDate
    );

    return matchingPolicy || null;
  }, [oasisPolicies]);

  const handleSubmitRename = useCallback(
    async newName => {
      setLoadingRename(true);
      const { id: indicationId, opportunityId } = selectedIndication;
      const response = await dispatch(updateIndication(opportunityId, indicationId, { name: newName }));
      if (response.type === UPDATE_INDICATION_SUCCESS) {
        dispatch(handleToastMessage('Indication name updated.', TOAST_TYPES.SUCCESS));
      } else {
        dispatch(handleToastMessage('Unable to update indication name. Please try again.', TOAST_TYPES.ERROR));
      }
      setShowRenameModal(false);
      setLoadingRename(false);
    },
    [dispatch, selectedIndication]
  );

  const handleSubmitDelete = useCallback(async () => {
    setLoadingDelete(true);
    const { id: indicationId, opportunityId } = selectedIndication;
    const response = await dispatch(deleteIndication(opportunityId, indicationId));
    if (response.type === DELETE_INDICATION_SUCCESS) {
      dispatch(handleToastMessage('Indication deleted.', TOAST_TYPES.SUCCESS));
      history.replace(`/opportunities/${opportunityId}/indications`);
    } else {
      dispatch(handleToastMessage('Unable to delete indication. Please try again.', TOAST_TYPES.ERROR));
      setShowDeleteModal(false);
      setLoadingDelete(false);
    }
  }, [dispatch, selectedIndication, history]);

  return (
    <>
      <SectionContainer borderRadius={BORDER_RADIUS.ROUNDED} className={classes.margin} reduceTitleMargin>
        <Box display="flex" justifyContent="start" alignItems="center">
          <Typography variant="h6" color="secondary" className={classes.sectionHeading}>
            {selectedIndication ? selectedIndication.name : 'Indication '}
          </Typography>
          {indicationStatus && getHeaderText('Status', indicationStatus, classes.dataWrapper)}
          {fullPolicy && getHeaderText('Oasis Policy ID', fullPolicy.policyId, classes.dataWrapper)}
          <Box display="flex" flex="1" justifyContent="end">
            {userHas(Permissions.UPDATE_INDICATION_INFO) && (
              <Tooltip title="Rename indication, if not pushed to Oasis">
                <span>
                  <CuriButton
                    className={classes.actionButton}
                    color="secondary"
                    onClick={() => setShowRenameModal(true)}
                    disabled={indicationIsInOasis || isReadonly}
                  >
                    Rename
                  </CuriButton>
                </span>
              </Tooltip>
            )}
            {userHas(Permissions.DELETE_INDICATION) && (
              <Tooltip title="Delete indication, if not pushed to Oasis">
                <span>
                  <CuriButton
                    className={classes.actionButton}
                    customColor="error"
                    onClick={() => setShowDeleteModal(true)}
                    disabled={indicationIsInOasis || isReadonly}
                    startIcon={<DeleteIcon />}
                  >
                    Delete
                  </CuriButton>
                </span>
              </Tooltip>
            )}
          </Box>
        </Box>
      </SectionContainer>
      <IndicationRenameModal
        open={showRenameModal}
        loading={loadingRename}
        handleClose={() => setShowRenameModal(false)}
        handleSubmit={handleSubmitRename}
      />
      <IndicationDeleteModal
        open={showDeleteModal}
        loading={loadingDelete}
        handleClose={() => setShowDeleteModal(false)}
        handleSubmit={handleSubmitDelete}
      />
    </>
  );
}

const useStyles = makeStyles(theme => ({
  sectionHeading: {
    color: theme.palette.primary.light,
    fontWeight: 'bold',
  },
  dataWrapper: {
    marginLeft: theme.spacing(8),
  },
  actionButton: {
    marginLeft: theme.spacing(2),
  },
}));

export default IndicationStatusHeader;
