export const MAX_SIDEBAR_WIDTH_REM = 16;

export default theme => ({
  margin: {
    marginLeft: `${MAX_SIDEBAR_WIDTH_REM + 1}rem`,
  },
  padding: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
});
