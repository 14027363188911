export default theme =>
  Object.freeze({
    highContrastInput: {
      '& .MuiFormLabel-root': {
        color: theme.palette.primary.main,
        transform: 'translate(9px, -8px) scale(1) !important',
        backgroundColor: theme.palette.common.white,
        padding: '0 0.3rem',
      },
    },
    highContrastInputLabel: {
      color: theme.palette.primary.main,
      transform: 'translate(9px, -8px) scale(1) !important',
      backgroundColor: theme.palette.common.white,
      padding: '0 0.3rem',
    },
  });
