import React from 'react';
import PropTypes from 'prop-types';

import CustomValidator from 'common/formFields/customValidator.component';
import CuriSelect from 'common/formFields/curiSelect.component';

function CuriSelectValidator(props) {
  const {
    validatorClasses,
    disableBlurValidation,
    errorMessages,
    label,
    name,
    validators,
    value,
    onBlur,
    onChange,
    ...restProps
  } = props;

  return (
    <CustomValidator
      classes={validatorClasses}
      disableBlurValidation={disableBlurValidation}
      errorMessages={errorMessages}
      label={label}
      name={name}
      validators={validators}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    >
      <CuriSelect {...restProps} />
    </CustomValidator>
  );
}

CuriSelectValidator.propTypes = {
  disableBlurValidation: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessages: PropTypes.array,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  validators: PropTypes.array,
  value: PropTypes.any,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  validatorClasses: PropTypes.object,
};

CuriSelectValidator.defaultProps = {
  value: null,
  options: [],
  onBlur: () => {},
  disabled: false,
  disableBlurValidation: false,
  errorMessages: [],
  validators: [],
  validatorClasses: {},
};

export default CuriSelectValidator;
