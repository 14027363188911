import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';

import { API_DATE_PATTERN } from 'common/dates/dates';
import { handleToastMessage, TOAST_TYPES } from 'modules/layout/layout.actions';
import {
  createOrganizationRisk,
  getIndicationRiskCounties,
  updateIndicationOrganizationRisk,
  deleteIndicationOrganizationRisk,
  deleteIndicationOrganizationRisks,
  getIndicationOrganizationRisks,
  verifyOrganizationalRisks,
  CREATE_INDICATION_ORGANIZATION_RISK_FAILURE,
  UPDATE_INDICATION_ORGANIZATION_RISK_FAILURE,
  DELETE_INDICATION_ORGANIZATION_RISK_FAILURE,
  DELETE_INDICATION_ORGANIZATION_RISKS_FAILURE,
  VERIFY_ORGANIZATIONAL_RISKS_FAILURE,
} from 'modules/indication/indication.actions';
import DataTableAutocompleteSelect from 'common/dataTable/dataTableAutocompleteSelect.component';
import DataTableDate from 'common/dataTable/dataTableDate.component';
import DataTableEditDate from 'common/dataTable/dataTableEditDate.component';
import { prepareRiskForApi } from 'common/dataTable/dataTable.utils';
import IndicationRiskTable, { EmptySelectField } from 'modules/indication/indicationRiskTable.component';
import { useAuthz } from 'okta/authz';
import Permissions from 'okta/permissions';
import OasisPartyIconButton from 'modules/indication/oasisPartyIconButton.component';
import DataTableEditText from 'common/dataTable/dataTableEditText.component';
import { useIndicationIsInOasis } from 'modules/indication/indication.selectors';
import { useSelectedOpportunityIsReadOnly } from 'modules/opportunities/opportunities.selectors';

const feinRE = /^\d{9}$/;

function IndicationRisksOrganizationTableContainer({
  isLoadingIndicationRiskOrganizations,
  indicationRiskOrganizations,
  isDeletingRiskOrganizations,
  isLoadingIndicationStates,
  handleToastMessage: handleToast,
  createOrganizationRisk: createRisk,
  updateIndicationOrganizationRisk: updateRisk,
  deleteIndicationOrganizationRisk: deleteRisk,
  deleteIndicationOrganizationRisks: deleteRisks,
  getIndicationOrganizationRisks: getRisks,
  verifyOrganizationalRisks: verifyOasisOrganizationalRisks,
  indicationStatesOptions,
  isValidStateCode,
  indication,
  getCountyName,
  isLoadingRiskCounties,
  riskCountyFilterOptions,
  getRiskCountyOptions,
  getIndicationRiskCounties: getCounties,
  countiesAlreadyFetched,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { userHas } = useAuthz();
  const indicationIsInOasis = useIndicationIsInOasis();
  const isReadonly = useSelectedOpportunityIsReadOnly();
  const disableUpdate = useMemo(() => indicationIsInOasis || isReadonly, [indicationIsInOasis, isReadonly]);

  // toggled `true` when `add risk` input attempted
  const [globalBlur, setGlobalBlur] = useState(false);

  const [verifyOasisParty, setVerifyOasisParty] = useState(null);

  const formatEinWithDash = useCallback(ein => {
    if (!ein) return '';
    const digits = ein.replace(/\D+/g, '');
    if (digits.length === 2) {
      return digits;
    }
    if (digits.length > 1) {
      return `${digits.slice(0, 2)}-${digits.slice(2, 9)}`;
    }
    return digits;
  }, []);

  const [lastStateCode, setLastStateCode] = useState(null);

  /* eslint-disable react/prop-types */
  const columns = useMemo(
    () => [
      {
        field: 'oasisClientId',
        headerStyle: { padding: theme.spacing(1), paddingRight: 0 },
        width: 110,
        cellStyle: { paddingRight: 0 },
        filtering: false,
        sorting: false,
        title: 'Oasis Client ID',
        render: rowData => (
          <OasisPartyIconButton indicationRisk={rowData} handleClick={() => setVerifyOasisParty(rowData)} />
        ),
        editComponent: ({ rowData }) => <OasisPartyIconButton disabled indicationRisk={rowData} />,
      },
      {
        field: 'name',
        headerStyle: { padding: theme.spacing(1) },
        title: 'Name',
        required: true,
      },
      {
        field: 'stateCode',
        headerStyle: { padding: theme.spacing(1) },
        title: 'Practicing State',
        render: rowData => {
          const { stateCode } = rowData;
          const invalidStateCode = !isLoadingIndicationStates && !isValidStateCode(stateCode);
          return (
            <div className={classNames({ [classes.textRed]: invalidStateCode })}>
              {isLoadingIndicationRiskOrganizations || isLoadingIndicationStates ? '' : stateCode || 'Unknown State'}
            </div>
          );
        },
        filterComponent: ({ onFilterChanged, columnDef }) => (
          <DataTableAutocompleteSelect
            isFilter
            inputMinWidth={60}
            value={columnDef.tableData.filterValue}
            loading={isLoadingIndicationStates}
            options={indicationStatesOptions}
            onChange={val => onFilterChanged(columnDef.tableData.id, val)}
          />
        ),
        editComponent: ({ onChange, columnDef, value }) => (
          <DataTableAutocompleteSelect
            required
            value={value}
            placeholder={columnDef.title}
            loading={isLoadingIndicationStates}
            globalBlur={globalBlur}
            options={indicationStatesOptions}
            onChange={stateCode => {
              if (isValidStateCode(stateCode) && !countiesAlreadyFetched(stateCode)) {
                getCounties([stateCode]);
              }
              onChange(stateCode);
            }}
          />
        ),
        initialEditValue: indication ? indication.stateCode : '',
      },
      {
        field: 'countyCode',
        headerStyle: { padding: theme.spacing(1) },
        title: 'Practicing County',
        render: rowData => {
          const { countyCode, stateCode, uploadedCounty } = rowData;
          const countyName = getCountyName(stateCode, countyCode);
          const invalidCounty = !isLoadingRiskCounties && !countyName;
          const isLoading = isLoadingIndicationRiskOrganizations || isLoadingIndicationStates || isLoadingRiskCounties;
          const displayValue = isLoading ? '' : countyName || uploadedCounty || '';
          return <div className={classNames({ [classes.textRed]: invalidCounty })}>{displayValue}</div>;
        },
        filterComponent: ({ onFilterChanged, columnDef }) => (
          <DataTableAutocompleteSelect
            isFilter
            inputMinWidth={120}
            value={columnDef.tableData.filterValue}
            loading={isLoadingRiskCounties}
            options={riskCountyFilterOptions}
            onChange={val => {
              const riskCounty = riskCountyFilterOptions.find(c => c.description === val);
              return onFilterChanged(columnDef.tableData.id, riskCounty ? riskCounty.code : '');
            }}
          />
        ),
        editComponent: ({ onChange, columnDef, value, rowData: { stateCode } }) => {
          const riskCountyOptions = getRiskCountyOptions(stateCode);
          const county = riskCountyOptions.find(c => c.id === value);
          // `value` could be a `countyCode` or text string ('3292' or 'Baltimore')
          const displayValue = county ? county.description : EmptySelectField.description;

          // Anytime a practicing state is changed or a practicing county cannot be found in that state,
          // we should set the practicing county to `N/A`
          if ((!value && displayValue === EmptySelectField.description) || stateCode !== lastStateCode) {
            // Must allow time for component to render before setting state
            setTimeout(() => {
              setLastStateCode(stateCode);
              // Must allow time for state to be set before manually calling `onChange`
              setTimeout(() => {
                onChange(EmptySelectField.id);
              });
            });
          }

          return (
            <DataTableAutocompleteSelect
              value={displayValue}
              placeholder={columnDef.title}
              loading={isLoadingRiskCounties}
              globalBlur={globalBlur}
              options={riskCountyOptions}
              onChange={onChange}
              required={value && !county}
            />
          );
        },
        initialEditValue: indication ? indication.policyHolderAddressCountyCode : EmptySelectField.description,
      },
      {
        field: 'fein',
        headerStyle: { padding: theme.spacing(1) },
        title: 'Tax ID',
        render: ({ fein }) => (
          <div className={classNames({ [classes.textRed]: fein && !feinRE.test(fein) })}>{formatEinWithDash(fein)}</div>
        ),
        editComponent: ({ onChange, columnDef, value }) => (
          <DataTableEditText
            title={columnDef.title}
            onChange={val => onChange(formatEinWithDash(val))}
            value={formatEinWithDash(value)}
            globalBlur={globalBlur}
          />
        ),
      },
      {
        field: 'insuredSinceDate',
        headerStyle: { padding: theme.spacing(1) },
        title: 'Insured Since',
        render: ({ insuredSinceDate }) => <DataTableDate value={insuredSinceDate} />,
        editComponent: ({ onChange, columnDef, value, rowData }) => (
          <DataTableEditDate
            value={value}
            placeholder={columnDef.title}
            loading={isLoadingIndicationStates}
            globalBlur={globalBlur}
            onChange={onChange}
            readOnly={Boolean(rowData.oasisClientId)}
            readOnlyTooltip="Value pulled from Oasis for matched risk, cannot be edited here"
          />
        ),
        initialEditValue: indication ? indication.contractStartDate : '',
        filtering: false,
      },
      {
        field: 'limits',
        headerStyle: { padding: theme.spacing(1) },
        title: 'Limits',
      },
    ],
    [
      classes.textRed,
      globalBlur,
      theme,
      indicationStatesOptions,
      isLoadingIndicationRiskOrganizations,
      isLoadingIndicationStates,
      isValidStateCode,
      indication,
      formatEinWithDash,
      getRiskCountyOptions,
      getCountyName,
      riskCountyFilterOptions,
      isLoadingRiskCounties,
      lastStateCode,
      getCounties,
      countiesAlreadyFetched,
    ]
  );
  /* eslint-enable react/prop-types */

  const riskIsInvalid = useCallback(
    risk => {
      const { name, stateCode, countyCode, fein } = risk;
      const countyIsInvalid =
        countyCode &&
        !getRiskCountyOptions(stateCode).some(c => c.id === countyCode) &&
        countyCode !== EmptySelectField.id;
      const stateCodeIsInvalid = !isValidStateCode(stateCode);
      return !name || stateCodeIsInvalid || countyIsInvalid || (fein && !feinRE.test(fein));
    },
    [isValidStateCode, getRiskCountyOptions]
  );

  const getRiskValidationErrorMessage = useCallback(
    risk => {
      const { name, stateCode, countyCode, fein } = risk;
      const errorFields = [];

      const riskCountyOptions = getRiskCountyOptions(stateCode);
      const countyCodeCleared = countyCode === EmptySelectField.id;

      if (!name) errorFields.push('"Name"');
      if (!stateCode) errorFields.push('"Practicing State"');
      if (countyCode && !riskCountyOptions.find(c => c.id === countyCode) && !countyCodeCleared) {
        errorFields.push('"Practicing County"');
      }
      if (fein && !feinRE.test(fein)) errorFields.push('"Tax ID"');

      return errorFields.length
        ? `Invalid field${errorFields.length > 1 ? 's' : ''}: ${errorFields.join(', ')}.`
        : 'Risk data is incomplete.';
    },
    [getRiskCountyOptions]
  );

  const { opportunityId, indicationId } = useParams();

  const normalizeRisk = useCallback(
    newRisk => {
      try {
        const { stateCode, countyCode, fein, insuredSinceDate = '', ...restProps } = newRisk;

        // `countyCode` could be code or string, e.g. '3292' or `Baltimore`
        const riskCountyOptions = getRiskCountyOptions(stateCode);
        const countyByName = riskCountyOptions.find(c => c.description === countyCode);
        const countyCodeCleared = countyCode === EmptySelectField.id || countyCode === EmptySelectField.description;

        // Remove `Z` so `moment` correctly parses as local date
        const formattedInsuredSinceDate = moment(insuredSinceDate.replace(/Z$/, '')).format(API_DATE_PATTERN);

        return prepareRiskForApi({
          ...restProps,
          fein: !fein ? null : fein.replace(/\D+/g, ''),
          stateCode,
          // eslint-disable-next-line no-nested-ternary
          countyCode: countyCodeCleared ? null : countyByName ? countyByName.id : countyCode,
          insuredSinceDate: formattedInsuredSinceDate === 'Invalid date' ? null : formattedInsuredSinceDate,
          // Org risks are always `MEDGROUP`, no need to set here
          // classification: IndicationRiskClassification.MedicalGroup,
        });
      } catch (err) {
        console.error('failed to normalize risk', newRisk, err);
        throw err;
      }
    },
    [getRiskCountyOptions]
  );

  const handleAddRisk = useCallback(
    async newRisk => {
      if (disableUpdate) return;
      const normalizedRisk = normalizeRisk(newRisk);
      if (riskIsInvalid(normalizedRisk)) {
        handleToast(`${getRiskValidationErrorMessage(normalizedRisk)} Please try again.`, TOAST_TYPES.ERROR);
        // Must throw error to `reject()` promise and keep row in edit mode.
        throw new Error('User input incomplete data');
      } else {
        const response = await createRisk(opportunityId, indicationId, normalizedRisk);
        if (response.type === CREATE_INDICATION_ORGANIZATION_RISK_FAILURE) {
          handleToast('Unable to create risk. Please try again later.', TOAST_TYPES.ERROR);
        }
      }
    },
    [
      createRisk,
      handleToast,
      indicationId,
      opportunityId,
      riskIsInvalid,
      normalizeRisk,
      getRiskValidationErrorMessage,
      disableUpdate,
    ]
  );

  const handleUpdateRisk = useCallback(
    async updatedRisk => {
      if (disableUpdate) return;
      const normalizedRisk = normalizeRisk(updatedRisk);
      if (riskIsInvalid(normalizedRisk)) {
        handleToast(`${getRiskValidationErrorMessage(normalizedRisk)} Please try again.`, TOAST_TYPES.ERROR);
        // Must throw error to `reject()` promise and keep row in edit mode.
        throw new Error('User input incomplete data');
      } else {
        const nextRisk = {};
        const prohibitedKeys = ['id', 'indicationId', 'createdDate', 'updatedDate'];
        Object.keys(normalizedRisk).forEach(key => {
          if (!prohibitedKeys.includes(key) && normalizedRisk[key] !== undefined) {
            nextRisk[key] = normalizedRisk[key];
          }
        });
        const response = await updateRisk(opportunityId, indicationId, updatedRisk.id, nextRisk);
        if (response.type === UPDATE_INDICATION_ORGANIZATION_RISK_FAILURE) {
          handleToast('Unable to update risk. Please try again.', TOAST_TYPES.ERROR);
        }
      }
    },
    [
      updateRisk,
      handleToast,
      indicationId,
      opportunityId,
      riskIsInvalid,
      normalizeRisk,
      getRiskValidationErrorMessage,
      disableUpdate,
    ]
  );

  const handleDeleteRisk = useCallback(
    async deletedRisk => {
      if (disableUpdate) return;
      const response = await deleteRisk(opportunityId, indicationId, deletedRisk.id);
      if (response.type === DELETE_INDICATION_ORGANIZATION_RISK_FAILURE) {
        handleToast('Unable to delete risk. Please try again later.', TOAST_TYPES.ERROR);
      }
    },
    [deleteRisk, handleToast, indicationId, opportunityId, disableUpdate]
  );

  const handleDeleteRisks = useCallback(async () => {
    if (disableUpdate) return;
    const response = await deleteRisks(opportunityId, indicationId);
    if (response.type === DELETE_INDICATION_ORGANIZATION_RISKS_FAILURE) {
      handleToast('Unable to delete risks. Please try again later.', TOAST_TYPES.ERROR);
    }
  }, [deleteRisks, handleToast, opportunityId, indicationId, disableUpdate]);

  const [isLoadingOasisParties, setIsLoadingOasisParties] = useState(false);

  const handleVerifyOasisParties = useCallback(async () => {
    if (disableUpdate) return;
    setIsLoadingOasisParties(true);
    const response = await verifyOasisOrganizationalRisks(opportunityId, indicationId);
    if (response.type === VERIFY_ORGANIZATIONAL_RISKS_FAILURE) {
      handleToast('Unable to verify all Oasis parties. Please try again.', TOAST_TYPES.ERROR);
      // refresh risks
      await getRisks(opportunityId, indicationId);
    }
    setIsLoadingOasisParties(false);
  }, [verifyOasisOrganizationalRisks, handleToast, opportunityId, indicationId, disableUpdate, getRisks]);

  const handleClearOasisId = useCallback(async () => {
    if (disableUpdate) return;
    const response = await updateRisk(opportunityId, indicationId, verifyOasisParty.id, {
      oasisClientId: null,
      oasisNumberId: null,
    });
    if (response.type === UPDATE_INDICATION_ORGANIZATION_RISK_FAILURE) {
      handleToast('Unable to clear Oasis party ID. Please try again.', TOAST_TYPES.ERROR);
    }
    setVerifyOasisParty(null);
  }, [updateRisk, handleToast, opportunityId, indicationId, verifyOasisParty, disableUpdate]);

  const handleConfirmNewOasisParty = useCallback(async () => {
    if (disableUpdate) return;
    const response = await updateRisk(opportunityId, indicationId, verifyOasisParty.id, {
      isConfirmedNew: true,
    });
    if (response.type === UPDATE_INDICATION_ORGANIZATION_RISK_FAILURE) {
      handleToast('Unable to clear Oasis party ID. Please try again.', TOAST_TYPES.ERROR);
    }
    setVerifyOasisParty(null);
  }, [handleToast, opportunityId, indicationId, updateRisk, verifyOasisParty, disableUpdate]);

  const handleSelectOasisParty = useCallback(
    async ({ clientId, oasisNumberId }) => {
      if (disableUpdate) return;
      const response = await updateRisk(opportunityId, indicationId, verifyOasisParty.id, {
        oasisClientId: clientId,
        oasisNumberId,
      });
      if (response.type === UPDATE_INDICATION_ORGANIZATION_RISK_FAILURE) {
        handleToast('Unable to set Oasis party ID. Please try again.', TOAST_TYPES.ERROR);
      }
      setVerifyOasisParty(null);
    },
    [updateRisk, handleToast, opportunityId, indicationId, verifyOasisParty, disableUpdate]
  );

  return (
    <IndicationRiskTable
      columns={columns}
      risks={indicationRiskOrganizations}
      isLoading={isLoadingIndicationRiskOrganizations}
      isDeletingRisks={isDeletingRiskOrganizations}
      isLoadingOasisParties={isLoadingOasisParties}
      handleAddRisk={handleAddRisk}
      handleUpdateRisk={handleUpdateRisk}
      handleDeleteRisk={handleDeleteRisk}
      handleDeleteRisks={handleDeleteRisks}
      globalBlur={globalBlur}
      setGlobalBlur={setGlobalBlur}
      showVerifyOasisPartiesModal={Boolean(verifyOasisParty)}
      verifyOasisParty={verifyOasisParty}
      handleCloseOasisModal={() => setVerifyOasisParty(null)}
      handleClearOasisId={handleClearOasisId}
      handleSelectOasisParty={handleSelectOasisParty}
      handleVerifyOasisParties={handleVerifyOasisParties}
      handleConfirmNewOasisParty={handleConfirmNewOasisParty}
      canAdd={!disableUpdate && userHas(Permissions.CREATE_ORGANIZATION_RISK)}
      canEdit={!disableUpdate && userHas(Permissions.UPDATE_ORGANIZATION_RISK)}
      canDelete={!disableUpdate && userHas(Permissions.DELETE_ORGANIZATION_RISK)}
      canDeleteAll={!disableUpdate && userHas(Permissions.DELETE_ORGANIZATION_RISKS)}
      riskIsInvalid={riskIsInvalid}
    />
  );
}

const useStyles = makeStyles(theme => ({
  textRed: {
    color: theme.palette.error.main,
  },
}));

const mapStateToProps = state => {
  const {
    isLoadingIndicationRiskOrganizations,
    indicationRiskOrganizations,
    isDeletingRiskOrganizations,
    isLoadingIndicationStates,
    isLoadingRiskCounties,
  } = state.indication;

  return {
    isLoadingIndicationRiskOrganizations,
    indicationRiskOrganizations,
    isDeletingRiskOrganizations,
    isLoadingIndicationStates,
    isLoadingRiskCounties,
  };
};

IndicationRisksOrganizationTableContainer.propTypes = {
  isLoadingIndicationRiskOrganizations: PropTypes.bool.isRequired,
  indicationRiskOrganizations: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDeletingRiskOrganizations: PropTypes.bool.isRequired,
  isLoadingIndicationStates: PropTypes.bool.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  createOrganizationRisk: PropTypes.func.isRequired,
  updateIndicationOrganizationRisk: PropTypes.func.isRequired,
  deleteIndicationOrganizationRisk: PropTypes.func.isRequired,
  deleteIndicationOrganizationRisks: PropTypes.func.isRequired,
  getIndicationOrganizationRisks: PropTypes.func.isRequired,
  verifyOrganizationalRisks: PropTypes.func.isRequired,
  indicationStatesOptions: PropTypes.array.isRequired,
  isValidStateCode: PropTypes.func.isRequired,
  indication: PropTypes.object,
  getCountyName: PropTypes.func.isRequired,
  isLoadingRiskCounties: PropTypes.bool.isRequired,
  riskCountyFilterOptions: PropTypes.array.isRequired,
  getRiskCountyOptions: PropTypes.func.isRequired,
  countiesAlreadyFetched: PropTypes.func.isRequired,
  getIndicationRiskCounties: PropTypes.func.isRequired,
};

IndicationRisksOrganizationTableContainer.defaultProps = {
  indication: null,
};

export default connect(mapStateToProps, {
  handleToastMessage,
  createOrganizationRisk,
  updateIndicationOrganizationRisk,
  deleteIndicationOrganizationRisk,
  deleteIndicationOrganizationRisks,
  getIndicationOrganizationRisks,
  verifyOrganizationalRisks,
  getIndicationRiskCounties,
})(IndicationRisksOrganizationTableContainer);
