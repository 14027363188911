import { Breadcrumbs, Link, makeStyles, Tooltip, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link as RouterLink } from 'react-router-dom';
import ArchiveIcon from '@material-ui/icons/Archive';
import EmailIcon from '@material-ui/icons/Email';

import CuriButton from 'common/buttons/curiButton.component';
import { handleToastMessage, TOAST_TYPES } from 'modules/layout/layout.actions';
import {
  getOpportunity,
  getOpportunityActivity,
  getUnderwriters,
  reviewOpportunity,
  REVIEW_OPPORTUNITY_SUCCESS,
  sendOpportunityNotification,
  SEND_OPPORTUNITY_NOTIFICATION_SUCCESS,
  pauseOpportunity,
  PAUSE_OPPORTUNITY_SUCCESS,
  unpauseOpportunity,
  UNPAUSE_OPPORTUNITY_SUCCESS,
  archiveOpportunity,
  ARCHIVE_OPPORTUNITY_SUCCESS,
} from 'modules/opportunities/opportunities.actions';
import {
  selectIsAssigning,
  selectIsSendingNotification,
  selectIsUpdatingStatus,
  useSelectedOpportunity,
  useSelectedOpportunityIsReadOnly,
  useUnderwriters,
  selectOpportunityStatuses,
} from 'modules/opportunities/opportunities.selectors';

import { useAuthz } from 'okta/authz';
import Permissions from 'okta/permissions';
import { selectUserId, selectUserGroups } from 'modules/login/login.selectors';
import AssignOpportunityMenu from 'modules/opportunities/assignOpportunityMenu.component';
import { selectAppConfig } from 'modules/appConfig/appConfig.selectors';
import { useIndications } from 'modules/indication/indication.selectors';
import IndicationStatus from 'modules/indication/indicationStatus';
import TextInputModal from 'common/components/textInputModal.component';
import OpportunityStatus from '../opportunityStatus';

export default function OpportunityTitleBar({ title }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { userHas } = useAuthz();
  const { opportunityId } = useParams();
  const selectedOpportunity = useSelectedOpportunity();
  const userId = useSelector(selectUserId);
  const { underwriters, isLoadingUnderwriters } = useUnderwriters();
  const isAssigning = useSelector(selectIsAssigning);
  const isUpdatingStatus = useSelector(selectIsUpdatingStatus);
  const isReadonly = useSelectedOpportunityIsReadOnly();
  const opportunityStatuses = useSelector(selectOpportunityStatuses);
  const { isLoadingIndications, indications } = useIndications();

  const isSendingNotification = useSelector(selectIsSendingNotification);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [notificationError, setNotificationError] = useState(null);

  const [isArchiveOpportunityModalOpen, setIsArchiveOpportunityModalOpen] = useState(false);
  const [archiveOpportunityError, setArchiveOpportunityError] = useState(null);

  const userGroups = useSelector(selectUserGroups);
  const {
    data: { oktaUnderwritingAssistantGroupName },
  } = useSelector(selectAppConfig);
  const userIsUnderwritingAssistant = userGroups.some(group => group === oktaUnderwritingAssistantGroupName);

  useEffect(
    () => {
      if (userHas(Permissions.READ_UNDERWRITERS) && !underwriters.length) {
        dispatch(getUnderwriters());
      }
    },
    // eslint-disable-next-line
    [/* Intentionally empty */]
  );

  const handleStatusChange = useCallback(
    async (action, expectedResponseType, failureMessage) => {
      if (!selectedOpportunity) {
        return;
      }

      const response = await dispatch(action(selectedOpportunity.id));
      if (response.type !== expectedResponseType) {
        dispatch(handleToastMessage(failureMessage, TOAST_TYPES.ERROR));
      } else {
        const status = opportunityStatuses.find(s => s.code === response.response.code);
        const successMessage =
          status && status.label ? `Opportunity is in status: '${status.label}'` : 'Opportunity status is updated';
        dispatch(handleToastMessage(successMessage, TOAST_TYPES.SUCCESS));
        dispatch(getOpportunityActivity(selectedOpportunity.id));
        // refetch to get latest version
        dispatch(getOpportunity(selectedOpportunity.id));
      }
    },
    [dispatch, selectedOpportunity, opportunityStatuses]
  );

  const handleReview = useCallback(() => {
    handleStatusChange(
      reviewOpportunity,
      REVIEW_OPPORTUNITY_SUCCESS,
      'Unable to review opportunity. Please try again.'
    );
  }, [handleStatusChange]);

  const handlePause = useCallback(() => {
    handleStatusChange(pauseOpportunity, PAUSE_OPPORTUNITY_SUCCESS, 'Unable to pause opportunity. Please try again.');
  }, [handleStatusChange]);

  const handleUnpause = useCallback(() => {
    handleStatusChange(
      unpauseOpportunity,
      UNPAUSE_OPPORTUNITY_SUCCESS,
      'Unable to unpause opportunity. Please try again.'
    );
  }, [handleStatusChange]);

  const handleSendNotification = useCallback(
    async notification => {
      if (!selectedOpportunity) {
        return;
      }

      setNotificationError(null);
      const response = await dispatch(sendOpportunityNotification(selectedOpportunity.id, notification));
      if (response.type !== SEND_OPPORTUNITY_NOTIFICATION_SUCCESS) {
        setNotificationError('Unable to send notification. Please try again.');
      } else {
        setIsNotificationModalOpen(false);
        dispatch(handleToastMessage('Notification sent', TOAST_TYPES.SUCCESS));
      }
    },
    [dispatch, selectedOpportunity]
  );

  const handleCancelNotification = useCallback(() => {
    setIsNotificationModalOpen(false);
    setNotificationError(null);
  }, []);

  const handleArchiveOpportunity = useCallback(
    async notes => {
      if (!selectedOpportunity) {
        return;
      }

      setArchiveOpportunityError(null);
      const response = await dispatch(archiveOpportunity(selectedOpportunity.id, notes));
      if (response.type !== ARCHIVE_OPPORTUNITY_SUCCESS) {
        setArchiveOpportunityError('Unable to archive opportunity. Please try again.');
      } else {
        setArchiveOpportunityError(false);
        const status = opportunityStatuses.find(s => s.code === response.response.code);
        const successMessage =
          status && status.label ? `Opportunity is in status: '${status.label}'` : 'Opportunity status is updated';
        dispatch(handleToastMessage(successMessage, TOAST_TYPES.SUCCESS));
        dispatch(getOpportunityActivity(selectedOpportunity.id));
        // refetch to get latest version
        dispatch(getOpportunity(selectedOpportunity.id));
        setIsArchiveOpportunityModalOpen(false);
      }
    },
    [dispatch, selectedOpportunity]
  );

  const handleCancelArchiveOpportunity = useCallback(() => {
    setIsArchiveOpportunityModalOpen(false);
    setArchiveOpportunityError(null);
  }, []);

  const sendMessageIsDisabled =
    !Array.isArray(selectedOpportunity.assignees) || selectedOpportunity.assignees.length === 0;

  const reassignIsDisabled = isReadonly || isLoadingUnderwriters || isAssigning;

  const statusCode = selectedOpportunity.activeStatusCode;
  const showStatusUpdateButtons = !isReadonly && userHas(Permissions.REVIEW_OPPORTUNITY);
  // TODO review should also be disabled if opportunity is unassigned and current user is unassignable
  const showReview =
    showStatusUpdateButtons && ![OpportunityStatus.InReview, OpportunityStatus.Paused].includes(statusCode);
  const showPause = showStatusUpdateButtons && statusCode !== OpportunityStatus.Paused;
  const showUnpause = showStatusUpdateButtons && statusCode === OpportunityStatus.Paused;
  const showArchiveOpportunity = showStatusUpdateButtons; // don't need to worry about status here, handled by showStatusUpdateButtons

  const userIsAssignedToOpportunity = selectedOpportunity.assignees.map(a => a.id).includes(userId);
  const canAssignOpportunity =
    userHas(Permissions.REASSIGN_OPPORTUNITY) &&
    userHas(Permissions.READ_UNDERWRITERS) &&
    (!userIsUnderwritingAssistant || userIsAssignedToOpportunity);

  const archiveIsDisabled =
    isUpdatingStatus ||
    isLoadingIndications ||
    !Array.isArray(indications) ||
    indications.some(indication => indication.status.code !== IndicationStatus.Open) ||
    !userIsAssignedToOpportunity;

  return (
    <>
      <div className={classes.titleBar}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Link color="primary" to="/opportunities" component={RouterLink}>
            Opportunities
          </Link>
          <Typography noWrap variant="h6" className={classes.title}>
            {title}
          </Typography>
        </Breadcrumbs>
        <div className={classes.buttonWrapper}>
          <div>
            {showArchiveOpportunity && (
              <Tooltip title="Opportunity must be assigned to you and no indications can have been sent to Oasis">
                {/* Needed for disabled hover event */}
                <span>
                  <CuriButton
                    disabled={archiveIsDisabled}
                    className={classes.actionButton}
                    customColor="tertiary"
                    onClick={() => setIsArchiveOpportunityModalOpen(true)}
                  >
                    Archive
                  </CuriButton>
                </span>
              </Tooltip>
            )}
            <CuriButton
              disabled={sendMessageIsDisabled}
              className={classes.actionButton}
              onClick={() => setIsNotificationModalOpen(true)}
            >
              Send Notification
            </CuriButton>
            {canAssignOpportunity && (
              <AssignOpportunityMenu
                className={classes.actionButton}
                opportunityId={opportunityId}
                disabled={reassignIsDisabled}
              />
            )}
            {showReview && (
              <CuriButton
                className={classes.actionButton}
                customColor="success"
                onClick={handleReview}
                disabled={isUpdatingStatus}
              >
                Review
              </CuriButton>
            )}
            {showPause && (
              <CuriButton
                className={classes.actionButton}
                customColor="success"
                onClick={handlePause}
                disabled={isUpdatingStatus}
              >
                Pause
              </CuriButton>
            )}
            {showUnpause && (
              <CuriButton
                className={classes.actionButton}
                customColor="success"
                onClick={handleUnpause}
                disabled={isUpdatingStatus}
              >
                Unpause
              </CuriButton>
            )}
          </div>
        </div>
      </div>
      <TextInputModal
        open={isArchiveOpportunityModalOpen}
        isSubmitting={isUpdatingStatus}
        onCancel={handleCancelArchiveOpportunity}
        onSubmit={handleArchiveOpportunity}
        error={archiveOpportunityError}
        title="Archive Opportunity"
        placeholder="Notes"
        submitIcon={<ArchiveIcon />}
        submitLabel="Archive"
        text="The Submission Team will be notified that this opportunity was manually archived. Please provide additional notes below."
      />
      <TextInputModal
        open={isNotificationModalOpen}
        isSubmitting={isSendingNotification}
        onCancel={handleCancelNotification}
        onSubmit={handleSendNotification}
        error={notificationError}
        title="Send Notification to Assignees"
        placeholder="Notification"
        submitIcon={<EmailIcon />}
        submitLabel="Send"
      />
    </>
  );
}

const useStyles = makeStyles(theme => ({
  titleBar: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '.5rem 0',
    padding: '0 1.5rem',
    width: '100%',
  },
  title: {
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    fontWeight: 600,
  },
  buttonWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexGrow: 1,
    justifyContent: 'flex-end',
    margin: theme.spacing(1, 0),
  },
  actionButton: {
    marginLeft: '1rem',
    whiteSpace: 'nowrap',
  },
}));

OpportunityTitleBar.propTypes = {
  title: PropTypes.string.isRequired,
};
