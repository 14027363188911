export const SHORT_DATE_PATTERN = 'MM/DD/YYYY';
export const API_DATE_PATTERN = 'YYYY-MM-DD';
export const TIME_PATTERN = 'h:mm a';
export const DAY_NUMBER = 'D';
export const MONTH_ABBREVIATION = 'MMM';
export const API_DATE_REGEX = /\d{4}-\d{2}-\d{2}/;
export const SHORT_DATE_REGEX = /\d{2}\/\d{2}\/\d{4}/;

const API_DATE_REGEXP = new RegExp(API_DATE_REGEX);
export const isApiDateFormat = value => API_DATE_REGEXP.test(value);
