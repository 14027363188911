import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import CuriText from 'common/formFields/curiText.component';
import CuriButton from 'common/buttons/curiButton.component';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function TextInputModal({
  open,
  isSubmitting,
  onCancel,
  onSubmit,
  error,
  title,
  placeholder,
  submitIcon,
  submitLabel,
  text,
}) {
  const [inputText, setInputText] = useState('');

  useEffect(() => {
    if (!open) {
      setTimeout(() => setInputText(''), 1000);
    }
  }, [open]);

  return (
    <Dialog fullWidth open={open} disableBackdropClick>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {text && <DialogContentText>{text}</DialogContentText>}
        <CuriText
          fullWidth
          hideAdornment
          label=""
          multiline
          rows={(inputText || '').split('\n').length + 1}
          value={inputText}
          variant="standard"
          onChange={e => setInputText(e.target.value)}
          placeholder={placeholder}
          disabled={isSubmitting}
        />
        {error && <DialogContentText color="error">{error}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        <CuriButton color="secondary" variant="outlined" onClick={onCancel} disabled={isSubmitting}>
          Cancel
        </CuriButton>
        <CuriButton
          color="secondary"
          onClick={() => onSubmit(inputText)}
          disabled={isSubmitting || !inputText}
          startIcon={isSubmitting ? <CircularProgress size={20} /> : submitIcon}
        >
          {submitLabel}
        </CuriButton>
      </DialogActions>
    </Dialog>
  );
}

TextInputModal.propTypes = {
  open: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  submitIcon: PropTypes.element,
  submitLabel: PropTypes.string,
  text: PropTypes.string,
};

TextInputModal.defaultProps = {
  error: null,
  placeholder: '',
  submitIcon: undefined,
  submitLabel: 'Submit',
  text: null,
};
