import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAuthz } from 'okta/authz';
import Permissions from 'okta/permissions';

import Notes from 'common/components/notes.component';
import { updateIndicationNotes, UPDATE_INDICATION_NOTES_SUCCESS } from 'modules/indication/indication.actions';
import { handleToastMessage, TOAST_TYPES } from 'modules/layout/layout.actions';
import {
  useSelectedOpportunity,
  useSelectedOpportunityIsReadOnly,
} from 'modules/opportunities/opportunities.selectors';
import { selectIsLoadingIndication, useSelectedIndication } from 'modules/indication/indication.selectors';

export default function IndicationNotes() {
  const { userHas } = useAuthz();
  const hasUpdatePermission = userHas(Permissions.UPDATE_INDICATION_NOTES);

  const dispatch = useDispatch();
  const opportunity = useSelectedOpportunity();
  const { selectedIndication } = useSelectedIndication();
  const opportunityIsReadonly = useSelectedOpportunityIsReadOnly();
  const isLoading = useSelector(selectIsLoadingIndication);

  const handleSaveNote = useCallback(
    async notes => {
      const trimmedNotes = notes.trim();
      const response = await dispatch(
        updateIndicationNotes(
          opportunity.id,
          selectedIndication.id,
          // Empty string causes validation error, must instead send null
          trimmedNotes === '' ? null : trimmedNotes
        )
      );

      if (response.type === UPDATE_INDICATION_NOTES_SUCCESS) {
        dispatch(handleToastMessage('Indication notes updated.', TOAST_TYPES.SUCCESS));
      } else {
        dispatch(handleToastMessage('Could not save notes. Please try again.', TOAST_TYPES.ERROR));
      }
    },
    [dispatch, opportunity, selectedIndication]
  );

  return (
    <Notes
      previousNotes={selectedIndication ? selectedIndication.notes : ''}
      isLoading={isLoading}
      onSave={handleSaveNote}
      isReadonly={opportunityIsReadonly || !hasUpdatePermission}
    />
  );
}
