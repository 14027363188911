import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Loading from 'common/components/loading.component';
import PageContainer from 'modules/layout/pageContainer.container';
import { Box, Typography } from '@material-ui/core';
import CuriButton from 'common/buttons/curiButton.component';
import { selectAppConfig } from './appConfig.selectors';
import { getAppConfig } from './appConfig.actions';

function AppConfigContainer({ children }) {
  const dispatch = useDispatch();
  const { isLoading, error, data } = useSelector(selectAppConfig);

  useEffect(() => {
    if (!isLoading && !error && !data) {
      dispatch(getAppConfig());
    }
  }, [isLoading, error, data, dispatch]);

  if (data) {
    return children;
  }

  return (
    <PageContainer>
      {error ? (
        <Box margin="auto" textAlign="center">
          <Typography gutterBottom>{error}</Typography>
          <Typography gutterBottom>Please try refreshing the page</Typography>
          <CuriButton color="primary" onClick={() => window.location.reload()}>
            Refresh
          </CuriButton>
        </Box>
      ) : (
        <Loading />
      )}
    </PageContainer>
  );
}

AppConfigContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default AppConfigContainer;
