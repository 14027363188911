import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { makeStyles, Box, Link } from '@material-ui/core';
import moment from 'moment';
import { uniq } from 'lodash';

import SectionContainer, { BORDER_RADIUS } from 'modules/layout/section.container';
import OpportunitiesTable from 'modules/opportunities/opportunitiesTable.component';
import DataTableStateCodeFilter, { customStateFilter } from 'common/dataTable/dataTableStateCodeFilter.component';
import { getDisplayDateValueFromIso } from 'common/dataTable/dataTable.utils';
import getAssigneeNames from 'utilities/getAssigneeNames';
import { MAX_SIDEBAR_WIDTH_REM } from 'common/sectionStyles';
import { getRelatedOpportunities, resetRelatedOpportunities } from 'modules/opportunities/opportunities.actions';
import {
  selectIsLoadingRelatedOpportunities,
  selectRelatedArchivedOpportunities,
  useUnderwriters,
} from 'modules/opportunities/opportunities.selectors';

function RelatedOpportunities() {
  const { container, headerStyle } = useStyles();
  const dispatch = useDispatch();
  const { opportunityId } = useParams();
  const { isLoadingUnderwriters, underwriters } = useUnderwriters();
  const loadingRelatedOpportunities = useSelector(selectIsLoadingRelatedOpportunities);
  const relatedArchivedOpportunities = useSelector(selectRelatedArchivedOpportunities);

  const [relatedOpportunitiesFetched, setRelatedOpportunitiesFetched] = useState(false);

  useEffect(() => {
    if (opportunityId && !relatedOpportunitiesFetched) {
      setRelatedOpportunitiesFetched(true);
      dispatch(getRelatedOpportunities(opportunityId));
    }

    return () => {
      if (relatedOpportunitiesFetched) {
        dispatch(resetRelatedOpportunities());
      }
    };
  }, [dispatch, relatedOpportunitiesFetched, opportunityId]);

  const stateFilterOptions = useMemo(
    () =>
      uniq(relatedArchivedOpportunities.map(o => o.stateCodes).flat())
        .sort()
        .map((s, i) => ({ id: i, description: s })),
    [relatedArchivedOpportunities]
  );

  /* eslint-disable react/prop-types */
  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerStyle: { padding: headerStyle },
        title: 'Opportunity Name',
        render: rowData => (
          <Link target="blank" component={RouterLink} to={`/opportunities/${rowData.id}/information`}>
            {rowData.name}
          </Link>
        ),
      },
      {
        field: 'numberOfPhysicians',
        headerStyle: { padding: headerStyle },
        title: 'Number of Physicians',
      },
      {
        field: 'numberOfApp',
        headerStyle: { padding: headerStyle },
        title: 'Number of APP',
      },
      {
        field: 'brokerAgent',
        headerStyle: { padding: headerStyle },
        title: 'Broker/Agency',
      },
      {
        field: 'activeStatusCode',
        headerStyle: { padding: headerStyle },
        title: 'Status',
      },
      {
        field: 'stateCodes',
        headerStyle: { padding: headerStyle },
        title: 'State',
        filterComponent: ({ onFilterChanged, columnDef }) => (
          <DataTableStateCodeFilter
            columnDef={columnDef}
            onFilterChanged={onFilterChanged}
            options={stateFilterOptions}
          />
        ),
        customFilterAndSearch: customStateFilter,
      },
      {
        field: 'specialty',
        headerStyle: { padding: headerStyle },
        title: 'Specialty',
      },
      {
        field: 'targetEffectiveDate',
        headerStyle: { padding: headerStyle },
        title: 'Target Eff Date',
        type: 'date',
        render: rowData => <div>{getDisplayDateValueFromIso(rowData.targetEffectiveDate)}</div>,
        customFilterAndSearch: (term, rowData) =>
          moment(term).isSame(getDisplayDateValueFromIso(rowData.targetEffectiveDate), 'day'),
      },
      {
        field: 'assignees',
        headerStyle: { padding: headerStyle },
        title: 'Assignment',
        render: ({ assignees }) => <div>{getAssigneeNames(assignees) || 'Unassigned'}</div>,
      },
    ],
    [headerStyle, stateFilterOptions, underwriters]
  );
  /* eslint-enable react/prop-types */

  const loading = useMemo(() => loadingRelatedOpportunities || isLoadingUnderwriters, [
    loadingRelatedOpportunities,
    isLoadingUnderwriters,
  ]);

  return (
    <SectionContainer title="Related Opportunities" borderRadius={BORDER_RADIUS.ROUNDED} className={container}>
      <Box p={2}>
        <OpportunitiesTable isLoading={loading} opportunities={relatedArchivedOpportunities} columns={columns} />
      </Box>
    </SectionContainer>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: `${MAX_SIDEBAR_WIDTH_REM + 1}rem`,
  },
  headerStyle: {
    padding: theme.spacing(1),
  },
}));

export default RelatedOpportunities;
