import React, { useState } from 'react';
import SectionContainer, { BORDER_RADIUS } from 'modules/layout/section.container';
import Loading from 'common/components/loading.component';
import Empty from 'common/components/empty.component';
import OpportunityActivityListEmails from 'modules/opportunities/opportunity/opportunityInformationStep/opportunityActivityListEmails.component';
import OpportunityActivityViewEmail from 'modules/opportunities/opportunity/opportunityInformationStep/opportunityActivityViewEmail.component';
import { useActivities } from 'modules/opportunities/opportunities.selectors';

const SECTION_TITLE = 'Salesforce Emails and Correspondence';

function OpportunityActivitySection() {
  const { emailActivity, isLoadingActivities } = useActivities();
  const [viewEmail, setViewEmail] = useState(null);

  // Handle loading state
  if (isLoadingActivities) {
    return (
      <SectionContainer borderRadius={BORDER_RADIUS.ROUNDED_BOTTOM} title={SECTION_TITLE}>
        <Loading />
      </SectionContainer>
    );
  }

  // Handle no activities
  if (emailActivity.every(a => a.threads.length === 0)) {
    return (
      <SectionContainer borderRadius={BORDER_RADIUS.ROUNDED_BOTTOM} title={SECTION_TITLE}>
        <Empty icon="fal fa-envelope-square" text="No Activity" />
      </SectionContainer>
    );
  }

  return (
    <>
      <SectionContainer borderRadius={BORDER_RADIUS.ROUNDED_BOTTOM} title={SECTION_TITLE} reduceTitleMargin>
        {emailActivity.map((a, i) => (
          <OpportunityActivityListEmails
            // No unique id exists, will not be reordered
            // eslint-disable-next-line react/no-array-index-key
            key={`case-${i}`}
            title={a.subject}
            threads={a.threads}
            handleOpenEmail={email => setViewEmail(email)}
          />
        ))}
      </SectionContainer>
      <OpportunityActivityViewEmail handleClose={() => setViewEmail(null)} open={!!viewEmail} email={viewEmail} />
    </>
  );
}

export default OpportunityActivitySection;
