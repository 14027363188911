import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Icon, Typography, Link } from '@material-ui/core';
import moment from 'moment';
import classNames from 'classnames';

export const emailDisplayDate = date => {
  const momentDate = moment(date);

  if (momentDate.isSame(moment(), 'day')) {
    return `${momentDate.format('h:mm a')} | Today`;
  }

  return `${momentDate.format('h:mm a')} | ${momentDate.format('MMM DD')}`;
};

// `Link` is rendering `button`, not an `a` -- rule disabled
/* eslint-disable jsx-a11y/anchor-is-valid */

function OpportunityActivityListEmail({ email, replies, handleOpenEmail }) {
  const classes = useStyles();

  const { createdDate, isNew, subject, displayFrom, toAddress, hasAttachment, textBody } = email;

  const displayDate = useMemo(() => emailDisplayDate(createdDate), [createdDate]);

  const displayReplies = useMemo(() => {
    if (replies === 0) {
      return null;
    }
    if (replies === 1) {
      return '1 Reply';
    }
    return `${replies} Replies`;
  }, [replies]);

  return (
    <div className={classes.container}>
      <Icon
        className={classNames('fal fa-envelope-square', classes.icon)}
        color="primary"
        classes={{ colorPrimary: isNew ? classes.unopened : classes.opened }}
      />

      <div className={classes.emailWrapper}>
        <div className={classes.emailRow}>
          <Link
            className={classNames(classes.overflowEllipsis, classes.emailSubjectWrapper)}
            component="button"
            variant="subtitle1"
            onClick={e => {
              e.preventDefault();
              handleOpenEmail(email);
            }}
          >
            {subject}
          </Link>
          <Typography variant="caption" noWrap className={classes.emailToFrom}>
            {`${displayFrom} to ${toAddress}`}
          </Typography>
          <Icon
            fontSize="small"
            className={classNames('fal fa-paperclip', { [classes.hidden]: !hasAttachment })}
            color="primary"
          />
          <Typography variant="caption" className={classes.emailDateTime}>
            {displayDate}
            <br />
            {displayReplies}
          </Typography>
        </div>

        <Typography variant="body2" noWrap className={classes.emailBody}>
          {textBody}
        </Typography>
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  emailWrapper: {
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  emailRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  overflowEllipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  emailSubjectWrapper: {
    /**
     * Setting a fixed width here:
     *   1. Allows `text-overflow: ellipsis` to work when email subjects are
     *      really long.
     *   2. Ensures the alignment of the next element in this row (email
     *      to/from) is consistently horizontally aligned no matter how long
     *      the email subject actually is.
     */
    width: '45%',
    textAlign: 'left',
    lineHeight: 1.45,
  },
  emailToFrom: {
    /**
     * Setting a fixed width here:
     *   1. Allows `text-overflow: ellipsis` to work when email to/from is
     *      really long.
     *   2. Ensures the alignment of the next element in this row (paperclip
     *      icon) is consistently horizontally aligned no matter how long the
     *      email to/from actually is.
     */
    width: '35%',
  },
  emailDateTime: {
    /**
     * Setting a `min-width` here ensures a consistent amount of space will be
     * allocated to the email date/time element to accommodate the variable
     * nature of text widths which depend on the date being displayed.
     */
    minWidth: '91px',
    /**
     * Setting a height here ensures a consistent height dimension whether or
     * not an email has `replies`. This keeps the height of email list item
     * consistent despite differences in what data is displayed.
     */
    height: 38,
    textAlign: 'right',
  },
  emailBody: {
    paddingTop: '0.3rem',
    /**
     * Setting a fixed width here:
     *   1. Allows `text-overflow: ellipsis` to work when email bodies are
     *      really long.
     *   2. Ensures a pleasing visual aesthethic which prevents the email body
     *      text from running too closely under the date/time data.
     */
    width: '85%',
  },
  icon: {
    margin: '0 0.3rem',
    alignSelf: 'start',
  },
  hidden: {
    /**
     * Using `visibility: hidden;` and not `display: none;` here ensures that a
     * consistent amount of space is allocated to the paperclip icon whether or
     * not an email has an attachment.
     */
    visibility: 'hidden',
  },
  unopened: {
    color: theme.palette.text.primary,
  },
  opened: {
    color: theme.palette.common.lightGray,
  },
}));

OpportunityActivityListEmail.propTypes = {
  email: PropTypes.object.isRequired,
  replies: PropTypes.number,
  handleOpenEmail: PropTypes.func.isRequired,
};

OpportunityActivityListEmail.defaultProps = {
  replies: 0,
};

export default OpportunityActivityListEmail;
