import { Typography, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useMemo } from 'react';

import Loading from 'common/components/loading.component';
import { downloadFile } from 'utilities/downloadFile';
import CuriButton from 'common/buttons/curiButton.component';
import {
  selectIsLoadingResults,
  selectIsLoadingRoster,
  selectResults,
  selectUnmatchedResults,
} from 'modules/search/search.selectors';
import SearchResultsTable from 'common/search/searchResultsTable.component';
import {
  selectQuerySearchResult,
  setQueryTermMatched,
  setQueryTermUnmatched,
  getRoster,
  GET_ROSTER_SUCCESS,
} from 'modules/search/search.actions';
import { handleToastMessage, TOAST_TYPES } from 'modules/layout/layout.actions';

export default function SearchResultsSection() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isLoadingResults = useSelector(selectIsLoadingResults);
  const isLoadingRoster = useSelector(selectIsLoadingRoster);
  const results = useSelector(selectResults);
  const unmatchedResults = useSelector(selectUnmatchedResults);

  const disableDownloadCsv = useMemo(
    () => isLoadingResults || isLoadingRoster || !Array.isArray(results) || results.length === 0,
    [isLoadingResults, isLoadingRoster, results]
  );

  const download = useCallback(async () => {
    const records = results.map(result => {
      const selected = result.results.find(r => r.isSelected);
      return selected ? selected.record : null;
    });

    const unmatchedTerms = unmatchedResults.map(result => result.query);

    const { type, response } = await dispatch(getRoster(records, unmatchedTerms));
    if (type === GET_ROSTER_SUCCESS && typeof response.rosterCsv === 'string') {
      const encoded = encodeURI(`data:text/csv;charset=utf-8,${response.rosterCsv}`);
      downloadFile('roster.csv', encoded);
    } else {
      dispatch(handleToastMessage('Failed to download roster. Please try again.', TOAST_TYPES.ERROR));
    }
  }, [dispatch, results, unmatchedResults]);

  const handleSelectResult = useCallback(
    (queryId, resultId, setAsMatched) => {
      if (setAsMatched) {
        dispatch(setQueryTermMatched(queryId));
      }
      dispatch(selectQuerySearchResult(queryId, resultId));
    },
    [dispatch]
  );

  const handleSetQueryTermUnmatched = useCallback(
    queryId => {
      dispatch(setQueryTermUnmatched(queryId));
    },
    [dispatch]
  );

  const handleSetQueryTermMatched = useCallback(
    queryId => {
      dispatch(setQueryTermMatched(queryId));
    },
    [dispatch]
  );

  return (
    <>
      <div className={classes.section}>
        <Typography variant="h5">Results</Typography>
        <SearchResultsTable
          isMatchedResults
          results={results}
          handleMatchCategoryChange={handleSetQueryTermUnmatched}
          onSelectResult={(queryId, resultId) => handleSelectResult(queryId, resultId, false)}
        />
      </div>
      {Array.isArray(unmatchedResults) && unmatchedResults.length > 0 && (
        <div className={classes.section}>
          <Typography variant="h5">Unmatched Results</Typography>
          <SearchResultsTable
            results={unmatchedResults}
            handleMatchCategoryChange={handleSetQueryTermMatched}
            onSelectResult={(queryId, resultId) => handleSelectResult(queryId, resultId, true)}
          />
        </div>
      )}
      <div className={classes.downloadContainer}>
        {isLoadingRoster && <Loading />}
        <CuriButton color="primary" disabled={disableDownloadCsv} onClick={download}>
          Download Roster
        </CuriButton>
      </div>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  section: {
    paddingBottom: theme.spacing(2),
  },
  downloadContainer: {
    padding: theme.spacing(1, 0),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
}));
