import { createSelector } from 'reselect';

import { LOGIN_STATE } from './login.actions';

export const selectLoginState = createSelector(
  state => state.login,
  loginState => loginState
);

export const selectUserId = createSelector(selectLoginState, loginState =>
  !loginState.user || !loginState.user.sub ? '' : loginState.user.sub
);

// bad naming must continue
export const selectLoginStateLoginState = createSelector(selectLoginState, loginState => loginState.loginState);

export const selectUserDisplayName = createSelector(selectLoginState, loginState =>
  loginState.loginState === LOGIN_STATE.LOGGED_IN ? loginState.user.name : null
);

export const selectUserGroups = createSelector(selectLoginState, loginState =>
  loginState.user && Array.isArray(loginState.user.groups) ? loginState.user.groups : []
);
