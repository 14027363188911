import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { Box, makeStyles, TextField, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { getDisplayDateValue, getDisplayDateValueFromIso } from 'common/dataTable/dataTable.utils';
import { isApiDateFormat } from 'common/dates/dates';

/**
 * Made explicitly for use as an `editComponent` in a `material-table` when
 * input validation is required for a date.
 */
function DataTableEditDate({ value, onChange, globalBlur, required, readOnly, readOnlyTooltip }) {
  const [blurred, setBlurred] = useState(false);
  const classes = useStyles();

  if (readOnly) {
    const formattedValue = String(value).endsWith('Z') ? getDisplayDateValueFromIso(value) : getDisplayDateValue(value);
    return readOnlyTooltip ? (
      <Tooltip title={readOnlyTooltip}>
        <Box display="flex" alignContent="center">
          <div className={classes.readOnlyText}>{formattedValue || 'UNKNOWN'}</div>
          <InfoIcon color="secondary" />
        </Box>
      </Tooltip>
    ) : (
      <div>{formattedValue || 'UNKNOWN'}</div>
    );
  }

  let formattedValue = value;
  if (String(value).endsWith('Z')) {
    formattedValue = getDisplayDateValueFromIso(value);
  } else if (isApiDateFormat(value)) {
    formattedValue = getDisplayDateValue(value);
  }

  return (
    <InputMask
      mask="m9/d9/y999"
      maskChar="_"
      alwaysShowMask={false}
      formatChars={{
        m: '[0-1]', // The first char of MM can only be a 0 or 1
        d: '[0-3]', // The first char of DD can only be 0, 1, 2 or 3
        y: '[1-2]', // The first char of YYYY can only be 1 or 2
        '9': '[0-9]',
      }}
      value={formattedValue}
      onChange={e => onChange(e.target.value)}
      onBlur={() => setBlurred(true)}
      error={
        (blurred || globalBlur) && required && moment(formattedValue, 'MM/DD/YYYY', true).format() === 'Invalid date'
      }
      placeholder="MM/DD/YYYY"
      InputProps={{
        style: {
          fontSize: 13,
        },
      }}
    >
      {inputProps => <TextField {...inputProps} />}
    </InputMask>
  );
}

const useStyles = makeStyles(theme => ({
  readOnlyText: {
    marginRight: theme.spacing(1),
  },
}));

DataTableEditDate.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  globalBlur: PropTypes.bool.isRequired,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  readOnlyTooltip: PropTypes.string,
};

DataTableEditDate.defaultProps = {
  value: '',
  required: false,
  readOnly: false,
  readOnlyTooltip: null,
};

export default DataTableEditDate;
