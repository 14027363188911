import React, { useEffect, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import { connect, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Typography, makeStyles } from '@material-ui/core';
import classNames from 'classnames';

import { handleToastMessage, TOAST_TYPES } from 'modules/layout/layout.actions';
import CuriButton from 'common/buttons/curiButton.component';
import { setUser, LOGIN_STATE } from 'modules/login/login.actions';
import PageContainer from 'modules/layout/pageContainer.container';
import Loading from 'common/components/loading.component';
import { useAuthz } from 'okta/authz';
import { getNavigablePage } from 'okta/navigablePages';
import { useOpportunityDataIsFetched, isInitiallyLoaded } from 'modules/opportunities/opportunities.selectors';
import { useMaintainContrastMode, selectHighContrastModeEnabled } from 'modules/layout/layout.selectors';
import { selectAppConfig } from 'modules/appConfig/appConfig.selectors';
import { getDiscussion } from 'modules/discussion/discussion.actions';
import FullPageError from 'common/components/fullPageError.component';

const LOGIN_REDIRECT_KEY = 'cpq_redirect';

function AuthContainer({
  children,
  setUser: setUserData,
  user,
  loginState,
  handleToastMessage: handleToast,
  getDiscussion: getDiscussionData,
}) {
  const classes = useStyles();
  const { pathname, search } = useLocation();
  const history = useHistory();
  const { authState, oktaAuth } = useOktaAuth();
  const {
    data: { oktaCallbackPath },
  } = useSelector(selectAppConfig);

  const isAuthenticated = authState ? authState.isAuthenticated : false;
  const isPending = authState ? authState.isPending : true;

  const { canNavigateTo, permissionsLoaded, navigablePagesPermissionsMap } = useAuthz();
  const opportunityDataIsFetched = useOpportunityDataIsFetched();
  const initialLoad = useSelector(isInitiallyLoaded);
  const highContrastModeEnabled = useSelector(selectHighContrastModeEnabled);

  // Handle contrast mode state
  useMaintainContrastMode();

  // Make sure login redirect is never `oktaCallbackPath`
  const currentPath = useMemo(() => (pathname === oktaCallbackPath ? '/' : pathname), [pathname, oktaCallbackPath]);

  // If `authState` ever has an error for any reason, redirect for login to reauthenticate
  useEffect(() => {
    if (authState && authState.error) {
      oktaAuth.signOut().catch();
    }
  }, [authState, oktaAuth]);

  const [ssoSessionExists, setSsoSessionExists] = useState(false);

  const refreshSsoSession = useCallback(async () => {
    if (
      document.visibilityState === 'visible' &&
      !isPending &&
      isAuthenticated &&
      pathname !== oktaCallbackPath &&
      permissionsLoaded &&
      initialLoad
    ) {
      try {
        // Check that an SSO session exists before refreshing session.
        // Okta authn takes a moment to reflect user's logged in SSO status, and
        // without this check we inadvertently redirect the user to log in again
        // when the refresh request fails.
        if (!ssoSessionExists) {
          const sessionExists = await oktaAuth.session.exists().catch(() => false);
          setSsoSessionExists(sessionExists);
        }

        if (ssoSessionExists) {
          // Refresh Okta SSO user session, will throw error if no/expired session
          await oktaAuth.session.refresh();
        }
      } catch (error) {
        await oktaAuth.signOut().catch();
      }
    }
  }, [
    oktaAuth,
    pathname,
    isPending,
    isAuthenticated,
    permissionsLoaded,
    initialLoad,
    ssoSessionExists,
    oktaCallbackPath,
  ]);

  // Between page navigations, ensure valid SSO session
  useEffect(() => {
    refreshSsoSession();
  }, [pathname, refreshSsoSession]);

  // When browser tab becomes active, ensure valid SSO session
  useEffect(() => {
    document.addEventListener('visibilitychange', refreshSsoSession);
    return () => {
      document.removeEventListener('visibilitychange', refreshSsoSession);
    };
  }, [refreshSsoSession]);

  // Prevent navigation to a page for which a user is unauthorized
  useEffect(() => {
    if (!isAuthenticated || !user || !permissionsLoaded) return undefined;

    return history.block(nextLocation => {
      const isAuthorizedForNavigation = canNavigateTo(getNavigablePage(nextLocation.pathname));
      if (!isAuthorizedForNavigation) {
        handleToast('Insufficient permissions.', TOAST_TYPES.ERROR);
      }
      return isAuthorizedForNavigation;
    });
  }, [history, isAuthenticated, user, permissionsLoaded, canNavigateTo, handleToast]);

  // Handle edge case where user is authenticated but stuck on implicit callback path
  useEffect(() => {
    if (isAuthenticated && pathname === oktaCallbackPath) {
      history.push('/');
    }
  }, [isAuthenticated, pathname, history, oktaCallbackPath]);

  const [isFetchingUserData, setIsFetchingUserData] = useState(false);

  // Get user data on intial load if authenticated and no user data exists
  useEffect(() => {
    const getUserData = async () => {
      setIsFetchingUserData(true);
      try {
        const userData = await oktaAuth.getUser();
        if (!userData) {
          await oktaAuth.signOut().catch();
        }
        setUserData(userData);

        const redirect = window.sessionStorage.getItem(LOGIN_REDIRECT_KEY);
        if (redirect) {
          history.replace(redirect);
          window.sessionStorage.removeItem(LOGIN_REDIRECT_KEY);
        }

        await getDiscussionData();
      } catch (error) {
        oktaAuth.signOut().catch();
      }
      setIsFetchingUserData(false);
    };

    if (
      isAuthenticated &&
      user === null &&
      loginState === LOGIN_STATE.UNDETERMINED &&
      pathname !== oktaCallbackPath &&
      !isFetchingUserData
    ) {
      getUserData();
    }
  }, [
    oktaAuth,
    isAuthenticated,
    setUserData,
    user,
    loginState,
    pathname,
    currentPath,
    isFetchingUserData,
    oktaCallbackPath,
    getDiscussionData,
  ]);

  // Log user out of Okta when logged out of redux
  useEffect(() => {
    if (isPending) return;

    if (isAuthenticated && loginState === LOGIN_STATE.NOT_LOGGED_IN) {
      oktaAuth.signOut().catch();
    }
  }, [isPending, oktaAuth, isAuthenticated, loginState]);

  // Automatic redirect to Okta when user not authenticated -- must compare `pathname` to `oktaCallbackPath` due to
  // momentary flash of `isAuthenticated: false` when user first lands in app after successful login/redirect from Okta.
  // Without this check, we perpetuate an infinite loop of login redirects.
  useEffect(() => {
    if (!isPending && !isAuthenticated && pathname !== oktaCallbackPath) {
      window.sessionStorage.setItem(LOGIN_REDIRECT_KEY, pathname + search);
      oktaAuth.signInWithRedirect(currentPath);
    }
  }, [isAuthenticated, pathname, currentPath, oktaAuth, isPending, oktaCallbackPath, search]);

  // If a user happens to navigate to an unauthorized page, show unauthorized page
  if (
    !isPending &&
    isAuthenticated &&
    pathname !== oktaCallbackPath &&
    permissionsLoaded &&
    !canNavigateTo(getNavigablePage(pathname))
  ) {
    return (
      <PageContainer className={classes.unauthorizedWrapper}>
        <Typography variant="h6" component="h1" className={classes.unauthorizedTitle}>
          Unauthorized
        </Typography>
        <Typography variant="body1" component="p">
          You do not have the required permissions to view this page. Please speak with your system administrator.
        </Typography>
        <CuriButton className={classes.returnHome} color="primary" href="/">
          Return To Home
        </CuriButton>
        <Typography variant="caption" display="block">
          Page:
          {pathname}
        </Typography>
        <Typography variant="caption" display="block">
          Required permissions:
          {JSON.stringify(navigablePagesPermissionsMap.get(getNavigablePage(pathname)), null, 2)}
        </Typography>
      </PageContainer>
    );
  }

  // Redirect user when authenticated but stuck on `oktaCallbackPath` path
  if (pathname === oktaCallbackPath && !isPending && isAuthenticated && permissionsLoaded) {
    history.push('/');
  }

  // Show error message when individual opportunity fetched and not found or request failed
  if (!isPending && pathname !== oktaCallbackPath && isAuthenticated && !opportunityDataIsFetched && initialLoad) {
    return (
      <FullPageError text="Something went wrong. Unable to request opportunity data. Please try refreshing the page." />
    );
  }

  // Show the loading page when:
  //   * Okta `authState` is pending, or
  //   * User is authenticated but the Okta SDK is still processing `LoginCallback`, or
  //   * User is authenticated but we're still waiting on required data for intial load, or
  //   * User is authenticated but permissions have not yet loaded, or
  //   * User is authenticated but not authorized to navigate to the current page and is waiting for redirect
  //   * User is not authenticated but is in the process of authentication redirects
  if (
    isPending ||
    pathname === oktaCallbackPath ||
    (isAuthenticated &&
      (!permissionsLoaded || !canNavigateTo(getNavigablePage(pathname)) || !opportunityDataIsFetched)) ||
    (!isAuthenticated && pathname !== oktaCallbackPath)
  ) {
    return (
      <PageContainer>
        {/* Shrink disabled due to: https://material-ui.com/components/progress/#limitations */}
        <Loading disableShrink />
      </PageContainer>
    );
  }

  // Render actual child routes when:
  //   1. User is authenticated, and
  //   2. User is authorized, and
  //   3. Redux state has user's access token (`api.js` needs it before fetching data), and
  //   4. Required data for initial render has been received (e.g. `Opportunities`)
  return (
    <div className={classNames(classes.fullHeight, { [classes.highConstrastScrollbars]: highContrastModeEnabled })}>
      {children}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  unauthorizedWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  unauthorizedTitle: {
    marginBottom: `${theme.spacing(2)}px`,
  },
  returnHome: {
    margin: `${theme.spacing(5.5)}px 0`,
  },
  fullHeight: {
    display: 'contents',
  },
  highConstrastScrollbars: {
    '@global::-webkit-scrollbar': {
      width: '1rem',
    },
    '@global::-webkit-scrollbar-track': {
      background: theme.palette.grey[200],
    },
    '@global::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
      borderWidth: '1rem',
      borderStyle: 'solid',
      borderRadius: 0,
    },
  },
}));

const mapStateToProps = state => {
  const { user, loginState } = state.login;
  return { user, loginState };
};

AuthContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  setUser: PropTypes.func.isRequired,
  getDiscussion: PropTypes.func.isRequired,
  user: PropTypes.object,
  loginState: PropTypes.number.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
};

AuthContainer.defaultProps = {
  user: null,
};

export default connect(mapStateToProps, { setUser, handleToastMessage, getDiscussion })(AuthContainer);
