import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Box } from '@material-ui/core';
import { Item } from 'common/components/grid.component';
import CuriTextValidator from 'common/formFields/curiTextValidator.component';
import CuriSelectValidator from 'common/formFields/curiSelectValidator.component';
import SectionContainer from 'modules/layout/section.container';
import Permissions from 'okta/permissions';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  useSelectedOpportunity,
  selectIsLoadingOpportunities,
  selectMetadata,
  useSelectedOpportunityIsReadOnly,
} from 'modules/opportunities/opportunities.selectors';
import { handleToastMessage, TOAST_TYPES } from 'modules/layout/layout.actions';
import { updateOpportunity, UPDATE_OPPORTUNITY_SUCCESS } from 'modules/opportunities/opportunities.actions';
import CuriButton from 'common/buttons/curiButton.component';
import { useAuthz } from 'okta/authz';

function OpportunityAddlInfoSection() {
  const classes = useStyles();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const { userHas } = useAuthz();
  const selectedOpportunity = useSelectedOpportunity();
  const isReadonly = useSelectedOpportunityIsReadOnly();
  const isLoading = useSelector(selectIsLoadingOpportunities);
  const { policyTypes: OpportunityPolicyTypes } = useSelector(selectMetadata);

  const POLICY_TYPE_OPTIONS = useMemo(
    () =>
      (OpportunityPolicyTypes.length ? [] : [{ name: 'Error: Could not load options', value: null }]).concat(
        OpportunityPolicyTypes.map(type => ({ name: type.label, value: type.id }))
      ),
    [OpportunityPolicyTypes]
  );

  const [legalNameText, setLegalNameText] = useState('');
  const [alsoKnownAsText, setAlsoKnownAsText] = useState('');
  const [policyTypesSelect, setPolicyTypesSelect] = useState([]);

  useEffect(() => {
    const { legalName, alsoKnownAs, policyTypes } = selectedOpportunity;
    setLegalNameText(legalName);
    setAlsoKnownAsText(alsoKnownAs);
    setPolicyTypesSelect(policyTypes);
  }, [selectedOpportunity]);

  const hasDirtyState = useMemo(() => {
    const { legalName, alsoKnownAs, policyTypes } = selectedOpportunity;

    return (
      legalNameText !== legalName ||
      alsoKnownAsText !== alsoKnownAs ||
      policyTypesSelect.length !== policyTypes.length ||
      !policyTypes.every(type => policyTypesSelect.includes(type))
    );
  }, [legalNameText, alsoKnownAsText, policyTypesSelect, selectedOpportunity]);

  const handleSubmit = useCallback(async () => {
    const form = formRef.current;
    const isValid = await form.isFormValid();
    if (isValid) {
      const response = await dispatch(
        updateOpportunity(selectedOpportunity.id, {
          legalName: legalNameText || null,
          alsoKnownAs: alsoKnownAsText || null,
          policyTypes: policyTypesSelect,
        })
      );

      if (response.type !== UPDATE_OPPORTUNITY_SUCCESS) {
        dispatch(handleToastMessage('Unable to update opportunity. Please try again.', TOAST_TYPES.ERROR));
      } else {
        dispatch(handleToastMessage('Opportunity updated.', TOAST_TYPES.SUCCESS));
      }
    }
  }, [dispatch, selectedOpportunity, legalNameText, alsoKnownAsText, policyTypesSelect]);

  const isDisabled = useMemo(() => isReadonly || !userHas(Permissions.UPDATE_OPPORTUNITY_INFO), [userHas, isReadonly]);

  return (
    <SectionContainer title="Policyholder Account">
      <ValidatorForm onSubmit={handleSubmit} ref={formRef}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Item sm={4}>
            <CuriTextValidator
              fullWidth
              hideAdornment
              label="Legal Entity Name"
              name="legalName"
              value={legalNameText}
              onChange={e => setLegalNameText(e.target.value)}
              disabled={isDisabled}
            />
          </Item>
          <Item sm={4} className={classes.akaSelectWrapper}>
            <CuriTextValidator
              fullWidth
              hideAdornment
              multiline
              label="Also Known As"
              name="alsoKnownAs"
              value={alsoKnownAsText}
              onChange={e => setAlsoKnownAsText(e.target.value)}
              disabled={isDisabled}
            />
          </Item>
          <Item sm={4} className={classes.test}>
            <CuriSelectValidator
              fullWidth
              label="Policy Type"
              name="policyType"
              noBlankOption
              options={POLICY_TYPE_OPTIONS}
              selectProps={{
                classes: {
                  select: classes.select,
                },
              }}
              multiple
              value={policyTypesSelect}
              onChange={e => setPolicyTypesSelect(e.target.value)}
              disabled={isDisabled}
            />
          </Item>
        </Box>
      </ValidatorForm>
      {!isDisabled && (
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <CuriButton
            color="secondary"
            onClick={handleSubmit}
            disabled={!hasDirtyState || isLoading}
            startIcon={isLoading ? <CircularProgress size={20} /> : <SaveIcon />}
          >
            Save
          </CuriButton>
        </Box>
      )}
    </SectionContainer>
  );
}

const useStyles = makeStyles(theme => ({
  chipInputRoot: {
    // important here is needed to override library padding top
    paddingTop: '1rem !important',
  },
  chip: {
    overflow: 'hidden',
  },
  select: {
    '&:focus': {
      backgroundColor: theme.palette.common.white,
    },
  },
  akaSelectWrapper: {
    padding: '0 0.5rem',
  },
}));

export default OpportunityAddlInfoSection;
