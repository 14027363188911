// `John Matthew Smith Sr MD` -> `Smith, John Matthew, Sr, MD`
export default function formatIndividualRiskName({ lastName, firstName, middleName, suffixes }, suffixOptions) {
  // roster may have uploaded with incomplete data
  let policyHolderName = lastName ? `${lastName}, ${firstName}` : firstName;
  if (middleName) {
    policyHolderName += ` ${middleName}`;
  }

  if (Array.isArray(suffixes) && suffixes.length > 0) {
    const displaySuffixes = suffixes.map(suffix => {
      const displaySuffix = suffixOptions.find(s => s.code === suffix);
      return displaySuffix ? displaySuffix.description : suffix;
    });
    policyHolderName += `, ${displaySuffixes.join(', ')}`;
  }
  return policyHolderName;
}
