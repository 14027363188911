export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';

export const SHOW_TOAST_MESSAGE = 'SHOW_TOAST_MESSAGE';
export const HIDE_TOAST_MESSAGE = 'HIDE_TOAST_MESSAGE';

export const TOGGLE_DRAWER_MENU = 'TOGGLE_DRAWER_MENU';

export const TOAST_TYPES = {
  ERROR: 'TOAST_ERROR',
  SUCCESS: 'TOAST_SUCCESS',
  WARNING: 'TOAST_WARNING',
};

export const SET_INITIAL_ROUTE = 'SET_INITIAL_ROUTE';

export const TOGGLE_HIGH_CONTRAST_MODE = 'TOGGLE_HIGH_CONTRAST_MODE';

export const setPageTitle = (title, breadcrumbLink, breadcrumbTitle) => {
  return {
    type: SET_PAGE_TITLE,
    payload: title,
    breadcrumb: breadcrumbLink,
    breadcrumbTitle,
  };
};

const showToast = (message, toastType) => {
  if (Array.isArray(message)) {
    message = message.join(', ');
  }

  return {
    type: SHOW_TOAST_MESSAGE,
    message,
    toastType,
  };
};

export const hideToast = () => {
  return {
    type: HIDE_TOAST_MESSAGE,
  };
};

let toastTimer;
const TOAST_TIMEOUT_DURATION = 10000;
export const handleToastMessage = (message, toastType = TOAST_TYPES.SUCCESS, override = true, persistent = false) => {
  return (dispatch, getState) => {
    /* Don't display the toast message if one is already being displayed and
    the override is false. */
    if (getState().layout.isShowToast && !override) return;

    /*
    Don't autoclose if the toast is persistent
    */
    if (persistent) {
      dispatch(showToast(message, toastType));
    } else {
      clearTimeout(toastTimer);
      dispatch(showToast(message, toastType));
      toastTimer = setTimeout(() => {
        dispatch(hideToast());
      }, TOAST_TIMEOUT_DURATION);
    }
  };
};

export const toggleDrawerMenu = () => {
  return {
    type: TOGGLE_DRAWER_MENU,
  };
};

export const setInitialRoute = (route, query) => {
  return {
    type: SET_INITIAL_ROUTE,
    route,
    query,
  };
};

export const toggleHighContrastMode = isToggledOn => ({
  type: TOGGLE_HIGH_CONTRAST_MODE,
  payload: isToggledOn,
});
