import React from 'react';
import PropTypes from 'prop-types';

import DataTableAutocompleteSelect from 'common/dataTable/dataTableAutocompleteSelect.component';

export const customStateFilter = (term, rowData) => {
  return term.length === 0 || rowData.stateCodes.some(opptState => term.includes(opptState));
};

function DataTableStateCodeFilter({ columnDef, onFilterChanged, options }) {
  return columnDef.tableData ? (
    <DataTableAutocompleteSelect
      isFilter
      multiple
      loading={false}
      value={columnDef.tableData.filterValue}
      options={options}
      onChange={val => onFilterChanged(columnDef.tableData.id, val)}
    />
  ) : null;
}

DataTableStateCodeFilter.propTypes = {
  columnDef: PropTypes.object.isRequired,
  options: PropTypes.array,
  onFilterChanged: PropTypes.func.isRequired,
};

DataTableStateCodeFilter.defaultProps = {
  options: [],
};

export default DataTableStateCodeFilter;
