import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, CircularProgress, TextField, Chip, Box } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';

function DataTableAutocompleteSelect({
  value,
  isFilter,
  placeholder,
  loading,
  globalBlur,
  options,
  multiple,
  onChange,
  inputMinWidth,
  required,
}) {
  const classes = useStyles();
  const [blur, setBlur] = useState(false);

  // Handle `onChange` events when no value received (e.g. `null` value emitted
  // when user clicks `select` but escapes before making a selection, or clears
  // the input)
  const handleOnChange = (_e, val) => (!val ? onChange('') : onChange(val.description));
  const handleMultiOnChange = (_e, vals) => (!vals ? onChange([]) : onChange(vals.map(val => val.description)));

  const getOptionLabel = option => option.description;

  const getOptionSelected = (option, val) => option.id === val.id;

  const renderOption = option => <span className={classes.renderOption}>{option.description}</span>;

  const valueIsInvalid = description => !options.some(option => option.description === description);

  const renderInput = params => (
    <TextField
      {...params}
      placeholder={placeholder}
      onBlur={() => !isFilter && setBlur(true)}
      error={!isFilter && (globalBlur || blur) && required && valueIsInvalid(value)}
      InputProps={{
        ...params.InputProps,
        style: {
          fontSize: isFilter ? '1rem' : `${13 / 16}rem`,
          paddingRight: isFilter ? null : 30,
          marginTop: 3,
          minWidth: inputMinWidth,
        },
        endAdornment: (
          <>
            {!isFilter && loading ? <CircularProgress color="inherit" size={15} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );

  if (multiple) {
    const renderChips = (val, getTagProps) => (
      <Box display="inline-block">
        {val.map((option, index) => (
          <Chip variant="outlined" label={option.description} {...getTagProps({ index })} />
        ))}
      </Box>
    );

    return (
      <Autocomplete
        multiple
        disableCloseOnSelect
        options={options}
        getOptionLabel={getOptionLabel}
        defaultValue={Array.isArray(value) ? value.map(val => options.find(o => o.id === val)).filter(Boolean) : []}
        renderTags={renderChips}
        renderInput={params => <TextField {...params} label="" />}
        onChange={handleMultiOnChange}
      />
    );
  }

  return (
    <Autocomplete
      size="small"
      disableClearable={!isFilter || !value}
      clearOnEscape
      autoComplete
      autoHighlight
      onChange={handleOnChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      options={options}
      defaultValue={{ description: value }}
      loading={loading}
      renderOption={renderOption}
      renderInput={renderInput}
      closeIcon={<Cancel classes={{ root: classes.cancelIcon }} />}
    />
  );
}

const useStyles = makeStyles(() => ({
  renderOption: {
    fontSize: `${13 / 16}rem`,
  },
  cancelIcon: {
    fontSize: '1.1rem',
    color: 'rgba(0, 0, 0, 0.23)',
  },
}));

DataTableAutocompleteSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]),
  isFilter: PropTypes.bool,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  globalBlur: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
    })
  ).isRequired,
  multiple: PropTypes.bool,
  inputMinWidth: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

DataTableAutocompleteSelect.defaultProps = {
  value: '',
  globalBlur: false,
  isFilter: false,
  placeholder: '',
  multiple: false,
  loading: false,
  inputMinWidth: 160,
  required: false,
};

export default DataTableAutocompleteSelect;
