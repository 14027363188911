import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, Tooltip, IconButton, ClickAwayListener } from '@material-ui/core';
import AlertListItem from 'modules/alerts/alertListItem.component';
import { selectAllAlerts } from 'modules/alerts/alerts.selectors';

function AlertListItemTooltip({ id, children }) {
  const classes = useStyles();
  const alerts = useSelector(selectAllAlerts);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const alert = useMemo(() => alerts.find(a => a.task.indicationId === id || a.task.opportunityId === id), [
    alerts,
    id,
  ]);

  return (
    <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
      <Tooltip
        classes={{
          tooltip: classes.tooltip,
        }}
        title={<AlertListItem alert={alert} />}
        arrow
        interactive
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement="right-start"
        PopperProps={{
          disablePortal: true,
        }}
        open={tooltipOpen}
        onClose={() => setTooltipOpen(false)}
      >
        <IconButton onClick={() => setTooltipOpen(true)}>{children}</IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
}

const useStyles = makeStyles(theme => ({
  tooltip: {
    maxWidth: 'none',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.main}`,
    textAlign: 'left',
    boxShadow: theme.shadows[16],
  },
  button: {
    minWidth: 0,
  },
}));

AlertListItemTooltip.propTypes = {
  // The id of the related opportunity or indication
  id: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default AlertListItemTooltip;
