import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { toggleHighContrastMode } from './layout.actions';

export const selectLayoutState = createSelector(
  state => state.layout,
  layoutState => layoutState
);

export const selectToastProperties = createSelector(selectLayoutState, layoutState => ({
  isShowToast: layoutState.isShowToast,
  toastMessage: layoutState.toastMessage,
  toastType: layoutState.toastType,
}));

export const selectHighContrastModeEnabled = createSelector(
  selectLayoutState,
  layoutState => layoutState.highContrastModeEnabled
);

export const selectIsDrawerMenuOpen = createSelector(selectLayoutState, layoutState => layoutState.isDrawerMenuOpen);

/**
 * A custom hook that retrieves the high contrast mode toggle state from local
 * storage and keeps local storage updated when redux state changes.
 * Hook should only be used once in the entire app.
 */
export const useMaintainContrastMode = () => {
  const dispatch = useDispatch();
  const HIGH_CONTRAST_MODE = 'HIGH_CONTRAST_MODE';
  const ON = '1';
  const OFF = '0';

  const reduxStateToggledOn = useSelector(selectHighContrastModeEnabled);

  const localStorageIsToggledOn = () => {
    try {
      return localStorage.getItem(HIGH_CONTRAST_MODE) === ON;
    } catch (error) {
      // Swallow error if local storage inaccessible
      return false;
    }
  };

  const setLocalStorageState = isToggledOn => {
    try {
      localStorage.setItem(HIGH_CONTRAST_MODE, isToggledOn ? ON : OFF);
    } catch (error) {
      // Swallow error if local storage inaccessible
    }
  };

  // Initialize redux state with local storage state
  useEffect(() => {
    if (localStorageIsToggledOn()) {
      dispatch(toggleHighContrastMode(true));
    }
  }, [dispatch]);

  // Maintain local storage state when redux state changes
  useEffect(() => {
    setLocalStorageState(reduxStateToggledOn);
  }, [reduxStateToggledOn]);
};
