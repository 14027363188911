import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Icon, IconButton, Box, Link, Typography, makeStyles } from '@material-ui/core';

import { acknowledgeAlert, ACKNOWLEDGE_ALERT_FAILURE } from 'modules/alerts/alerts.actions';
import { handleToastMessage, TOAST_TYPES } from 'modules/layout/layout.actions';
import { selectIsAcknowledging } from 'modules/alerts/alerts.selectors';
import { emailDisplayDate } from 'modules/opportunities/opportunity/opportunityInformationStep/opportunityActivityListEmail.component';

export const ALERT_WIDTH = '35rem';

function AlertListItem({ alert, handleDismiss }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isAcknowledging = useSelector(selectIsAcknowledging);

  const handleAcknowledgeAlert = useCallback(
    async id => {
      const response = await dispatch(acknowledgeAlert(id));
      if (response.type === ACKNOWLEDGE_ALERT_FAILURE) {
        dispatch(handleToastMessage('Unable to acknowledge alert. Please try again.', TOAST_TYPES.ERROR));
      }
    },
    [dispatch]
  );

  // Alerts relate to either an opportunity or an indication
  const getAlertLinkPath = useCallback(
    ({ opportunityId, indicationId }) =>
      !indicationId
        ? `/opportunities/${opportunityId}/information`
        : `/opportunities/${opportunityId}/indications/${indicationId}/risks`,
    []
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="stretch"
      minHeight="4rem"
      width={ALERT_WIDTH}
    >
      <Box flexGrow={1} display="block" overflow="hidden">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Link
            underline="always"
            variant="h6"
            component={RouterLink}
            to={getAlertLinkPath(alert.task)}
            onClick={handleDismiss}
          >
            {alert.task.subject}
          </Link>
          <Typography color="textPrimary" variant="body2">
            {emailDisplayDate(alert.createdDate)}
          </Typography>
        </Box>
        <Typography className={classes.alertText} color="textPrimary" noWrap component="p" variant="body2" gutterBottom>
          {alert.task.opportunityName}
          {alert.task.indicationName && `- ${alert.task.indicationName}`}
        </Typography>
        <Typography className={classes.alertText} color="textPrimary" component="p" variant="body1">
          {alert.task.description}
        </Typography>
      </Box>
      <Box width="4rem" display="flex" justifyContent="center" alignItems="center">
        <IconButton onClick={() => handleAcknowledgeAlert(alert.id)} disabled={isAcknowledging}>
          <Icon className="fal fa-times-circle" />
        </IconButton>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  alertText: {
    textOverflow: 'ellipsis',
  },
}));

AlertListItem.propTypes = {
  alert: PropTypes.object.isRequired,
  // A function that should handle hiding the alert list item, which is
  // different than acknowledging an alert.
  handleDismiss: PropTypes.func,
};

AlertListItem.defaultProps = {
  handleDismiss: () => {},
};

export default AlertListItem;
