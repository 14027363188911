import React from 'react';
import PropTypes from 'prop-types';
import { DropzoneArea } from 'material-ui-dropzone';
import classNames from 'classnames';
import './CuriDropzone.css';

function CuriDropzoneComponent({ acceptedFiles, dropzoneText, onChange, dropzoneClass, dropzoneParagraphClass }) {
  return (
    <DropzoneArea
      acceptedFiles={acceptedFiles}
      filesLimit={1}
      maxFileSize={100000000}
      dropzoneText={dropzoneText}
      dropzoneClass={classNames('curi-dropzone-container', dropzoneClass)}
      showPreviews={false}
      showPreviewsInDropzone={false}
      useChipsForPreview={false}
      showAlerts={false}
      dropzoneParagraphClass={classNames('curi-dropzone-paragraph', dropzoneParagraphClass)}
      showFileNamesInPreview={false}
      showFileNames={false}
      onChange={onChange}
    />
  );
}

CuriDropzoneComponent.propTypes = {
  acceptedFiles: PropTypes.arrayOf(PropTypes.string).isRequired,
  dropzoneText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  dropzoneClass: PropTypes.string,
  dropzoneParagraphClass: PropTypes.string,
};

CuriDropzoneComponent.defaultProps = {
  dropzoneClass: null,
  dropzoneParagraphClass: null,
};

export default CuriDropzoneComponent;
