import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';

function SectionHeader({ children, classes, reduceTitleMargin, noTitleMargin, noUnderline }) {
  return (
    <Typography
      variant="h6"
      color="secondary"
      className={classNames(classes.sectionHeading, {
        [classes.reducedMargin]: reduceTitleMargin,
        [classes.noMargin]: noTitleMargin,
        [classes.noUnderline]: noUnderline,
      })}
    >
      {children}
    </Typography>
  );
}

const styles = theme => ({
  sectionHeading: {
    borderBottom: `solid 2px ${theme.palette.grey[300]}`,
    color: theme.palette.primary.light,
    fontWeight: 'bold',
    lineHeight: 2,
    marginBottom: '1.5rem',
  },
  reducedMargin: {
    marginBottom: '0.7rem',
  },
  noMargin: {
    marginBottom: 0,
  },
  noUnderline: {
    borderBottom: 'none',
  },
});

SectionHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any,
  reduceTitleMargin: PropTypes.bool,
  noTitleMargin: PropTypes.bool,
  noUnderline: PropTypes.bool,
};

SectionHeader.defaultProps = {
  reduceTitleMargin: false,
  noTitleMargin: false,
  noUnderline: false,
};

SectionHeader.defaultProps = { children: null };

export default withStyles(styles)(SectionHeader);
