export const IndicationPagePaths = Object.freeze({
  PolicyInfo: 'policy',
  Risks: 'risks',
  Summary: 'summary',
  DocumentsAndNotes: 'documents',
});

/**
 * Constants used for the names of navigable pages
 */
export const NavigablePages = Object.freeze({
  // '/'
  ASSIGNED_OPPORTUNITIES: 'ASSIGNED_OPPORTUNITIES',

  // '/unassigned'
  UNASSIGNED_OPPORTUNITIES: 'UNASSIGNED_OPPORTUNITIES',

  // '/opportunities/:opportunityId/information'
  OPPORTUNITY_INFO: 'OPPORTUNITY_INFO',

  // '/opportunities/:opportunityId/timeline'
  OPPORTUNITY_TIMELINE: 'OPPORTUNITY_TIMELINE',

  // '/opportunities/:opportunityId/related'
  OPPORTUNITY_RELATED_OPPORTUNITIES: 'OPPORTUNITY_RELATED_OPPORTUNITIES',

  // '/opportunities/:opportunityId/indications'
  OPPORTUNITY_INDICATIONS: 'OPPORTUNITY_INDICATIONS',

  // '/opportunities/:opportunityId/indications/:indicationId/policy'
  INDICATION_POLICY_INFO: 'INDICATION_POLICY_INFO',

  // '/opportunities/:opportunityId/indications/:indicationId/risks'
  INDICATION_RISKS: 'INDICATION_RISKS',

  // '/opportunities/:opportunityId/indications/:indicationId/summary'
  INDICATION_SUMMARY: 'INDICATION_SUMMARY',

  // '/opportunities/:opportunityId/indications/:indicationId/documents'
  INDICATION_DOCUMENTS_NOTES: 'INDICATION_DOCUMENTS_NOTES',

  // '/search
  SEARCH: 'SEARCH',

  // '/redirect
  REDIRECT: 'REDIRECT',

  // 404
  UNKNOWN: 'UNKNOWN',
});

/**
 * A helper function that translates a given `pathname` into the name of the
 * associated navigable page. Intended to be used with `authz`'s
 * `canNavigateTo` helper method
 *
 * @param {string} pathname the `pathname` as provided by `react-router`'s
 * `location` object
 *
 * @returns {keyof NavigablePages} a string constant which indicates the name of
 * the page which is associated with the parameter `pathname`
 *
 * ## Example
 *
 * ```jsx
 * import { getNavigablePage } from 'okta/navigablePages';
 * import { useAuthz } from 'okta/authz';
 *
 * const MyComponent = () => {
 *   const { canNavigateTo } = useAuthz();
 *   const { pathname } = useLocation();
 *
 *   console.log(canNavigateTo(getNavigablePage(pathname))); // <boolean>
 * }
 * ```
 */
export function getNavigablePage(pathname) {
  switch (true) {
    // path: '/opportunities'
    case /^\/opportunities$/.test(pathname):
      return NavigablePages.ASSIGNED_OPPORTUNITIES;

    // path: '/opportunities/unassigned'
    case /^\/opportunities\/unassigned$/.test(pathname):
      return NavigablePages.UNASSIGNED_OPPORTUNITIES;

    // path: '/opportunities/:opportunityId/information'
    case /^\/opportunities\/.*\/information$/.test(pathname):
      return NavigablePages.OPPORTUNITY_INFO;

    // path: '/opportunities/:opportunityId/timeline'
    case /^\/opportunities\/.*\/timeline$/.test(pathname):
      return NavigablePages.OPPORTUNITY_TIMELINE;

    // path: '/opportunities/:opportunityId/related'
    case /^\/opportunities\/.*\/related$/.test(pathname):
      return NavigablePages.OPPORTUNITY_RELATED_OPPORTUNITIES;

    // path: '/opportunities/:opportunityId/indications'
    case /^\/opportunities\/.*\/indications$/.test(pathname):
      return NavigablePages.OPPORTUNITY_INDICATIONS;

    // path: '/opportunities/:opportunityId/indications/:indicationId/policy'
    case /^\/opportunities\/.*\/indications\/.*\/policy$/.test(pathname):
      return NavigablePages.INDICATION_POLICY_INFO;

    // path: '/opportunities/:opportunityId/indications/:indicationId/risks'
    case /^\/opportunities\/.*\/indications\/.*\/risks$/.test(pathname):
      return NavigablePages.INDICATION_RISKS;

    // path: '/opportunities/:opportunityId/indications/:indicationId/summary'
    case /^\/opportunities\/.*\/indications\/.*\/summary$/.test(pathname):
      return NavigablePages.INDICATION_SUMMARY;

    // path: '/opportunities/:opportunityId/indications/:indicationId/documents'
    case /^\/opportunities\/.*\/indications\/.*\/documents$/.test(pathname):
      return NavigablePages.INDICATION_DOCUMENTS_NOTES;

    case /^\/search$/.test(pathname):
      return NavigablePages.SEARCH;

    case /^\/redirect$/.test(pathname):
      return NavigablePages.REDIRECT;

    default:
      if (pathname !== '/') {
        console.warn('could not match pathname:', pathname);
      }
      return NavigablePages.UNKNOWN;
  }
}
