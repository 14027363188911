import { combineReducers } from 'redux';

import alerts from 'modules/alerts/alerts.reducer';
import appConfig from 'modules/appConfig/appConfig.reducer';
import indication from 'modules/indication/indication.reducer';
import layout from 'modules/layout/layout.reducer';
import login from 'modules/login/login.reducer';
import opportunities from 'modules/opportunities/opportunities.reducer';
import search from 'modules/search/search.reducer';
import discussion from 'modules/discussion/discussion.reducer';

const appReducer = combineReducers({
  alerts,
  appConfig,
  indication,
  layout,
  login,
  opportunities,
  search,
  discussion,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT') {
    state = { appConfig: state.appConfig };
  }

  return appReducer(state, action);
};

export default rootReducer;
