import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { makeStyles, Icon, Link, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';

import { getDisplayDateValueFromIso } from 'common/dataTable/dataTable.utils';
import { selectIndicationStatuses, selectOpportunityStatuses } from 'modules/opportunities/opportunities.selectors';
import AlertListItemTooltip from 'modules/alerts/alertListItemTooltip.component';
import DataTableStateCodeFilter, { customStateFilter } from 'common/dataTable/dataTableStateCodeFilter.component';
import getAssigneeNames from 'utilities/getAssigneeNames';
import useStateFilterOptions from 'modules/opportunities/useStateFilterOptions';
import OpportunitiesTable from 'modules/opportunities/opportunitiesTable.component';
import OpportunityStatus, { CURRENT_OPPORTUNITIES } from 'modules/opportunities/opportunityStatus';
import { useAlertTaskOpportunityIds } from 'modules/alerts/alerts.selectors';

function AssignedOpportunities({ opportunities, isLoading, selectedStatus }) {
  const theme = useTheme();
  const { headerStyle, alertIcon } = useStyles();
  const history = useHistory();
  const stateFilterOptions = useStateFilterOptions(opportunities);
  const alertsTaskOpportunityIds = useAlertTaskOpportunityIds();
  const opportunityStatuses = useSelector(selectOpportunityStatuses);
  const indicationStatuses = useSelector(selectIndicationStatuses);

  const statusFilterOptions = useMemo(
    () =>
      opportunityStatuses
        .filter(status => status.code !== OpportunityStatus.Archived)
        .reduce((acc, cur) => ({ ...acc, [cur.code]: cur.label }), {}),
    [opportunityStatuses]
  );

  const actions = useMemo(
    () => [
      {
        icon: 'View',
        position: 'row',
        onClick: rowData => history.push(`/opportunities/${rowData.id}/information`),
        children: 'View',
      },
    ],
    [history]
  );

  /* eslint-disable react/prop-types */
  const columns = useMemo(
    () => [
      {
        field: '',
        headerStyle: { padding: 0, background: theme.palette.background.light },
        width: 25,
        cellStyle: { paddingRight: 0, paddingLeft: 0, textAlign: 'right' },
        title: '',
        filtering: false,
        render: rowData =>
          alertsTaskOpportunityIds.includes(rowData.id) && (
            <AlertListItemTooltip id={rowData.id}>
              <Icon className={classNames('fal fa-exclamation-circle', alertIcon)} />
            </AlertListItemTooltip>
          ),
      },
      {
        field: 'name',
        headerStyle: { padding: headerStyle, background: theme.palette.background.light },
        title: 'Opportunity Name',
        render: rowData => (
          <Link component={RouterLink} to={`/opportunities/${rowData.id}/information`} underline="hover">
            {rowData.name}
          </Link>
        ),
      },
      {
        field: 'numberOfPhysicians',
        headerStyle: { padding: headerStyle, background: theme.palette.background.light },
        title: 'Number of Physicians',
      },
      {
        field: 'numberOfApp',
        headerStyle: { padding: headerStyle, background: theme.palette.background.light },
        title: 'Number of APP',
      },
      {
        field: 'agency',
        headerStyle: { padding: headerStyle, background: theme.palette.background.light },
        title: 'Brokerage/Agency',
      },
      {
        field: 'activeStatusCode',
        headerStyle: { padding: headerStyle, background: theme.palette.background.light },
        title: 'Status',
        lookup: statusFilterOptions,
        filtering: selectedStatus === CURRENT_OPPORTUNITIES,
      },
      {
        field: 'indicationStatuses',
        width: 150,
        headerStyle: { padding: headerStyle, background: theme.palette.background.light },
        title: 'Substatus',
        filtering: false,
        sorting: false,
        render: rowData => {
          if (!Array.isArray(rowData.indicationStatuses) || rowData.indicationStatuses.length === 0) {
            return 'N/A';
          }

          return rowData.indicationStatuses.map(status => {
            const metadataStatus = indicationStatuses.find(s => s.code === status.activeStatusCode);
            if (!metadataStatus) {
              return null;
            }

            return (
              <div key={status.indicationId}>
                <Link
                  component={RouterLink}
                  to={`/opportunities/${rowData.id}/indications/${status.indicationId}/policy`}
                  underline="hover"
                >
                  {metadataStatus.label}
                </Link>
              </div>
            );
          });
        },
      },
      {
        field: 'stateCodes',
        headerStyle: { padding: headerStyle, background: theme.palette.background.light },
        title: 'State',
        filterComponent: ({ onFilterChanged, columnDef }) => (
          <DataTableStateCodeFilter
            columnDef={columnDef}
            onFilterChanged={onFilterChanged}
            options={stateFilterOptions}
          />
        ),
        customFilterAndSearch: customStateFilter,
      },
      {
        field: 'specialty',
        headerStyle: { padding: headerStyle, background: theme.palette.background.light },
        title: 'Specialty',
      },
      {
        field: 'targetEffectiveDate',
        headerStyle: { padding: headerStyle, background: theme.palette.background.light },
        title: 'Target Eff Date',
        type: 'date',
        render: rowData => <div>{getDisplayDateValueFromIso(rowData.targetEffectiveDate)}</div>,
        customFilterAndSearch: (term, rowData) =>
          moment(term).isSame(getDisplayDateValueFromIso(rowData.targetEffectiveDate), 'day'),
      },
      {
        field: 'assignees',
        headerStyle: { padding: headerStyle, background: theme.palette.background.light },
        title: 'Assignment',
        render: ({ assignees }) => <div>{getAssigneeNames(assignees)}</div>,
        customFilterAndSearch: (term, { assignees }) => {
          const assigneeNames = getAssigneeNames(assignees);
          return assigneeNames.toLowerCase().includes(term.toLowerCase());
        },
      },
    ],
    // eslint-disable-next-line
    [headerStyle, alertIcon, alertsTaskOpportunityIds, stateFilterOptions, statusFilterOptions]
  );
  /* eslint-enable react/prop-types */

  return <OpportunitiesTable actions={actions} isLoading={isLoading} opportunities={opportunities} columns={columns} />;
}

const useStyles = makeStyles(theme => ({
  headerStyle: {
    padding: theme.spacing(1),
  },
  alertIcon: {
    color: theme.palette.success.main,
  },
}));

AssignedOpportunities.propTypes = {
  opportunities: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  selectedStatus: PropTypes.string.isRequired,
};

AssignedOpportunities.defaultProps = {
  isLoading: false,
};

export default AssignedOpportunities;
