import React from 'react';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import IndicationStatusHeader from 'modules/indication/indicationStatusHeader.component';
import IndicationPolicyInfo from 'modules/indication/indicationPolicyInfo';
import IndicationRisks from 'modules/indication/indicationRisks.component';
import IndicationSummary from 'modules/indication/indicationSummary.component';
import { IndicationPagePaths } from 'okta/navigablePages';
import { makeStyles } from '@material-ui/core';
import sectionStyles from 'common/sectionStyles';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectIsSidebarOpen } from 'modules/opportunities/opportunities.selectors';
import IndicationDocuments from './indicationDocuments.component';

function Indication() {
  const { path } = useRouteMatch();
  const classes = useStyles();
  const isSidebarOpen = useSelector(selectIsSidebarOpen);

  return (
    <div className={classNames({ [classes.margin]: isSidebarOpen })}>
      <IndicationStatusHeader />
      <Switch>
        <Route path={`${path}/${IndicationPagePaths.PolicyInfo}`}>
          <IndicationPolicyInfo />
        </Route>
        <Route path={`${path}/${IndicationPagePaths.Risks}`}>
          <IndicationRisks />
        </Route>
        <Route path={`${path}/${IndicationPagePaths.DocumentsAndNotes}`}>
          <IndicationDocuments />
        </Route>
        <Route path={`${path}/${IndicationPagePaths.Summary}`}>
          <IndicationSummary />
        </Route>
        <Redirect to={`${path}/${IndicationPagePaths.PolicyInfo}`} />
      </Switch>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  ...sectionStyles(theme),
}));

export default Indication;
