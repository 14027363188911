import { ValidatorForm } from 'react-material-ui-form-validator';
import { Box, Typography, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CuriSelectValidator from 'common/formFields/curiSelectValidator.component';
import PageContainer from 'modules/layout/pageContainer.container';
import SectionContainer from 'modules/layout/section.container';
import CuriText from 'common/formFields/curiText.component';
import Loading from 'common/components/loading.component';
import CuriButton from 'common/buttons/curiButton.component';
import { getMetadata } from 'modules/opportunities/opportunities.actions'; // may want to move out, post-poc
import { selectStates } from 'modules/opportunities/opportunities.selectors';
import { handleToastMessage, hideToast, TOAST_TYPES } from 'modules/layout/layout.actions';
import SearchResultsSection from 'modules/search/searchResultsSection.component';
import { selectIsLoadingResults, selectLastParameters, selectResults } from './search.selectors';
import { getSearchResults, GET_SEARCH_RESULTS_SUCCESS, resetSearch } from './search.actions';

const ANY_OPTION = {
  name: 'Any',
  value: 'any',
};

export default function Search() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isLoadingResults = useSelector(selectIsLoadingResults);
  const results = useSelector(selectResults);
  const states = useSelector(selectStates);
  const lastParameters = useSelector(selectLastParameters);

  const licenseStateOptions = useMemo(
    () => (Array.isArray(states) ? states.map(s => ({ name: s.description, value: s.code })) : []),
    [states]
  );
  const [text, setText] = useState(Array.isArray(lastParameters.text) ? lastParameters.text.join('\n') : '');
  const [licenseStateCode, setLicenseStateCode] = useState(lastParameters.licenseStateCode || ANY_OPTION.value);

  useEffect(() => {
    if (!Array.isArray(states)) {
      dispatch(getMetadata());
    }
  }, [dispatch, states]);

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      dispatch(hideToast());

      const useLicenseStateCode = licenseStateCode === ANY_OPTION.value ? undefined : licenseStateCode;
      const response = await dispatch(
        getSearchResults({
          text: text
            .split('\n')
            .map(s => s.trim())
            .filter(s => s !== ''),
          licenseStateCode: useLicenseStateCode,
          size: 10,
        })
      );

      if (response.type !== GET_SEARCH_RESULTS_SUCCESS) {
        dispatch(handleToastMessage('Unable to search. Please try again.', TOAST_TYPES.ERROR));
      }
    },
    [dispatch, text, licenseStateCode]
  );

  const reset = useCallback(() => {
    setText('');
    setLicenseStateCode('');
    dispatch(hideToast());
    dispatch(resetSearch());
  }, [setText, setLicenseStateCode, dispatch]);

  return (
    <PageContainer className={classes.pageContainer}>
      <SectionContainer className={classes.sectionContainer}>
        <Typography variant="h5" gutterBottom>
          Search
        </Typography>
        <ValidatorForm id="roster-query-form" onSubmit={handleSubmit}>
          <CuriText
            label="Query"
            fullWidth
            value={text}
            onChange={e => setText(e.target.value)}
            disabled={isLoadingResults}
            multiline
            rows={Math.max(5, text.split('\n').length)}
          />
          <Box mt={2} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <CuriSelectValidator
              name="licenseStateCode"
              label="License State"
              className={classes.select}
              onChange={e => setLicenseStateCode(e.target.value)}
              value={licenseStateCode}
              options={licenseStateOptions}
              disabled={licenseStateOptions.length === 0}
              blankOption={ANY_OPTION}
            />
            <div className={classes.buttonContainer}>
              <CuriButton className={classes.button} color="primary" type="submit" disabled={isLoadingResults || !text}>
                Submit
              </CuriButton>
              <CuriButton className={classes.button} onClick={reset}>
                Reset
              </CuriButton>
            </div>
          </Box>
        </ValidatorForm>
        <div className={classes.results}>
          {isLoadingResults && <Loading />}
          {results && <SearchResultsSection />}
        </div>
      </SectionContainer>
    </PageContainer>
  );
}

const useStyles = makeStyles(theme => ({
  pageContainer: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0, 2, 6),
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    boxShadow: 'none',
    overflow: 'hidden',
    padding: theme.spacing(2),
    width: '100%',
  },
  buttonContainer: {
    marginBottom: '1.5rem', // simplest way to align with select
  },
  results: {
    width: '100%',
    flex: 1,
    paddingTop: theme.spacing(2),
    overflow: 'scroll',
  },
  select: {
    marginBottom: 0,
  },
  button: {
    marginRight: theme.spacing(),
  },
}));
