import { Box, CircularProgress, Icon, InputLabel, Link, makeStyles } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import classNames from 'classnames';

import { Container, Item } from 'common/components/grid.component';
import { getDisplayDateValueFromIso } from 'common/dataTable/dataTable.utils';
import {
  selectIsLoadingOpportunities,
  selectMetadata,
  useSelectedOpportunity,
  useSelectedOpportunityIsReadOnly,
} from 'modules/opportunities/opportunities.selectors';
import { useDispatch, useSelector } from 'react-redux';
import CuriButton from 'common/buttons/curiButton.component';
import CuriReadOnlyText from 'common/formFields/curiReadOnlyText.component';
import SectionContainer, { BORDER_RADIUS } from 'modules/layout/section.container';
import { useAuthz } from 'okta/authz';
import { refreshOpportunity, REFRESH_OPPORTUNITY_SUCCESS } from 'modules/opportunities/opportunities.actions';
import { handleToastMessage, TOAST_TYPES } from 'modules/layout/layout.actions';
import Permissions from 'okta/permissions';
import { selectAppConfigData } from 'modules/appConfig/appConfig.selectors';

export default function OpportunityReviewSection() {
  const dispatch = useDispatch();
  const { userHas } = useAuthz();
  const isLoading = useSelector(selectIsLoadingOpportunities);
  const isReadonly = useSelectedOpportunityIsReadOnly();
  const appConfig = useSelector(selectAppConfigData);
  const submissionInputUiDomain = appConfig ? appConfig.submissionInputUiDomain : null;

  const classes = useStyles();
  const selectedOpportunity = useSelectedOpportunity();
  const selectedOpportunityId = selectedOpportunity.id;
  const metadata = useSelector(selectMetadata);
  const type = useMemo(() => {
    if (!metadata || !selectedOpportunity) return '';
    return metadata.opportunityTypes.find(t => t.code === selectedOpportunity.type);
  }, [selectedOpportunity, metadata]);

  const showRefreshButton =
    userHas(Permissions.UPDATE_INDICATION_INFO) && !isReadonly && !selectedOpportunity.submissionId;

  const handleRefresh = useCallback(async () => {
    const response = await dispatch(refreshOpportunity(selectedOpportunityId));
    if (response.type !== REFRESH_OPPORTUNITY_SUCCESS) {
      dispatch(handleToastMessage('Unable to refresh opportunity. Please try again.', TOAST_TYPES.ERROR));
    } else {
      dispatch(handleToastMessage('Opportunity refreshed.', TOAST_TYPES.SUCCESS));
    }
  }, [dispatch, selectedOpportunityId]);

  const submissionInputHref =
    submissionInputUiDomain && selectedOpportunity.submittedId
      ? `https://${submissionInputUiDomain}/submissions/${selectedOpportunity.submittedId}`
      : null;

  return (
    <SectionContainer borderRadius={BORDER_RADIUS.ROUNDED_TOP} title="Opportunity Review">
      <Container className={classes.container} spacing={2}>
        <Item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CuriReadOnlyText multiline label="Opportunity Account" value={selectedOpportunity.accountName} />
        </Item>
        <Item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CuriReadOnlyText
            startAdornmentValue="$"
            label="Opportunity Premium"
            value={selectedOpportunity.targetPremium ? selectedOpportunity.targetPremium.toLocaleString() : ''}
          />
        </Item>
        <Item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CuriReadOnlyText
            startAdornmentValue="$"
            label="Expiring Premium"
            value={selectedOpportunity.expiringPremium ? selectedOpportunity.expiringPremium.toLocaleString() : ''}
          />
        </Item>
        <Item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CuriReadOnlyText label="Specialty" value={selectedOpportunity.specialty} />
        </Item>
        <Item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CuriReadOnlyText multiline label="Issue State" value={selectedOpportunity.stateCodes.join(', ')} />
        </Item>
        <Item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CuriReadOnlyText
            label="Target Effective Date"
            value={getDisplayDateValueFromIso(selectedOpportunity.targetEffectiveDate)}
          />
        </Item>
        <Item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CuriReadOnlyText label="Number of Physicians" value={selectedOpportunity.numberOfPhysicians} />
        </Item>
        <Item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CuriReadOnlyText label="Number of APP" value={selectedOpportunity.numberOfApp} />
        </Item>
        <Item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CuriReadOnlyText label="Broker / Agent" value={selectedOpportunity.brokerAgent} />
        </Item>
        <Item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CuriReadOnlyText multiline label="Brokerage/Agency" value={selectedOpportunity.agency} />
        </Item>
        <Item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CuriReadOnlyText
            label="Loss Ratio"
            value={
              selectedOpportunity.lossRatio !== null ? selectedOpportunity.lossRatio + String.fromCharCode(37) : 'N/A'
            }
          />
        </Item>
        <Item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CuriReadOnlyText label="Type" value={type ? type.label : ''} />
        </Item>
      </Container>
      <Container className={classes.container} spacing={2}>
        <Item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CuriReadOnlyText multiline label="Claims (Losses / Shock trends)" value={selectedOpportunity.claims} />
        </Item>
        <Item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CuriReadOnlyText label="Network" value={selectedOpportunity.network} />
        </Item>
        <Item xs={12} sm={6} md={4} lg={3} xl={2}>
          <CuriReadOnlyText label="Agency Code" value={selectedOpportunity.agencyCode} />
        </Item>
        <Item xs={12}>
          <CuriReadOnlyText
            multiline
            label="Pain Points / Broker Comments"
            value={selectedOpportunity.painPointsBrokerComments}
          />
        </Item>
        {submissionInputHref && (
          <Item xs={12}>
            <InputLabel shrink>Submission Link</InputLabel>
            <Link className={classes.externalLink} href={submissionInputHref} target="_blank">
              {submissionInputHref}
              <Icon className={classNames('fal fa-external-link', classes.externalLinkIcon)} fontSize="small" />
            </Link>
          </Item>
        )}
      </Container>
      {showRefreshButton && (
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <CuriButton
            color="secondary"
            onClick={handleRefresh}
            disabled={isLoading}
            startIcon={isLoading ? <CircularProgress size={20} /> : <RefreshIcon />}
          >
            Refresh
          </CuriButton>
        </Box>
      )}
    </SectionContainer>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(2),
  },
  externalLink: {
    fontSize: theme.typography.body1.fontSize,
  },
  externalLinkIcon: {
    marginLeft: theme.spacing(1),
  },
}));
