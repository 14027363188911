import { makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

import CuriSelect from 'common/formFields/curiSelect.component';
import OpportunityStatus, { CURRENT_OPPORTUNITIES } from 'modules/opportunities/opportunityStatus';

export const STATUS_SELECT_OPTIONS = [
  { name: 'Open', value: OpportunityStatus.Open },
  { name: 'In Review', value: OpportunityStatus.InReview },
  { name: 'Paused', value: OpportunityStatus.Paused },
  { name: 'Sent', value: OpportunityStatus.Sent },
  { name: 'Archived', value: OpportunityStatus.Archived },
];

export const ASSIGNED_STATUS_FILTER_OPTIONS = [
  { name: 'Current Opportunities', value: CURRENT_OPPORTUNITIES },
  ...STATUS_SELECT_OPTIONS,
];

function OpportunityStatusSelect({ value, onChange, isDisabled, options }) {
  const classes = useStyles();

  return (
    <CuriSelect
      className={classes.select}
      label="Status"
      noBlankOption
      onChange={e => onChange(e.target.value)}
      value={value}
      options={options}
      disabled={isDisabled}
    />
  );
}

const useStyles = makeStyles(() => ({
  select: {
    margin: 0,
    flex: 'none',
    minWidth: '12.5rem',
  },
}));

OpportunityStatusSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

OpportunityStatusSelect.defaultProps = {
  isDisabled: false,
  options: STATUS_SELECT_OPTIONS,
};

export default OpportunityStatusSelect;
