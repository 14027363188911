import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

export default function DataView({ data }) {
  const classes = useDataViewStyles();

  if (!data) {
    return null;
  }

  return (
    <div>
      {Object.keys(data)
        .sort((a, b) => a.localeCompare(b))
        .map(key => (
          <div key={key}>
            <span className={classes.key}>{`${key}:`}</span>
            {data[key]}
          </div>
        ))}
    </div>
  );
}

const useDataViewStyles = makeStyles(theme => ({
  key: {
    fontWeight: theme.typography.fontWeightBold,
    paddingRight: theme.spacing(0.5),
    marginBottom: theme.spacing(),
    display: 'inline-block',
  },
}));

DataView.propTypes = {
  data: PropTypes.object,
};

DataView.defaultProps = {
  data: null,
};
