import { createSelector } from 'reselect';

export const selectSearchState = createSelector(
  state => state.search,
  searchState => searchState
);

export const selectIsLoadingResults = createSelector(selectSearchState, searchState => searchState.isLoadingResults);

export const selectResults = createSelector(selectSearchState, searchState => searchState.results);

export const selectUnmatchedResults = createSelector(selectSearchState, searchState => searchState.unmatchedResults);

export const selectIsLoadingRoster = createSelector(selectSearchState, searchState => searchState.isLoadingRoster);

export const selectRosterCsv = createSelector(selectSearchState, searchState => searchState.rosterCsv);

export const selectLastParameters = createSelector(selectSearchState, searchState => searchState.lastParameters);
