import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Box } from '@material-ui/core';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import PageContainer from 'modules/layout/pageContainer.container';
import OpportunityTitleBar from 'modules/opportunities/opportunity/opportunityTitleBar.component';
import OpportunityDetails from 'modules/opportunities/opportunity/opportunityInformationStep/opportunityDetails.component';
import { getOpportunityIndications } from 'modules/indication/indication.actions';
import Indications from 'modules/opportunities/opportunity/indicationsStep/indications.component';
import Indication from 'modules/indication/indication.component';
import CuriSidebar from 'common/sidebar/curiSidebar.container';
import OpportunityTimeline from 'modules/opportunities/opportunity/opportunityTimeline.container';
import RelatedOpportunities from 'modules/opportunities/opportunity/relatedOpportunities.component';
import {
  selectIsSidebarOpen,
  selectOpportunityStatuses,
  useSelectedOpportunity,
} from 'modules/opportunities/opportunities.selectors';
import { selectIndicationsState } from 'modules/indication/indication.selectors';
import { getMetadata, refreshOpportunityAttachments } from 'modules/opportunities/opportunities.actions';

export const HEADER_HEIGHT_REM = 4;
export const OPPORTUNITY_TITLE_BAR_REM = 3.25;
export const PAGE_PADDING_BOTTOM_REM = 4;
// Ensure sidebar wide enough to prevent text-truncation or word wrap
export const SIDEBAR_WIDTH_REM = 15;
export const ROUTABLE_VIEW_HEIGHT = `calc(100vh - ${HEADER_HEIGHT_REM +
  OPPORTUNITY_TITLE_BAR_REM +
  PAGE_PADDING_BOTTOM_REM}rem)`;

function OpportunityContainer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const selectedOpportunity = useSelectedOpportunity();
  const { indications } = useSelector(selectIndicationsState);
  const opportunityStatuses = useSelector(selectOpportunityStatuses);
  const isSidebarOpen = useSelector(selectIsSidebarOpen);

  // Fetch relevant data specific to an opportunity on load
  useEffect(
    () => {
      const { id } = selectedOpportunity;

      if (indications === null || !indications.every(i => i.opportunityId === id)) {
        dispatch(getOpportunityIndications(id));
      }

      dispatch(refreshOpportunityAttachments(id));

      if (opportunityStatuses.length === 0) {
        dispatch(getMetadata());
      }
      // eslint-disable-next-line
    }, [/* Intentionally blank */]
  );

  return (
    <PageContainer className={classes.detailsWrapper}>
      <OpportunityTitleBar title={selectedOpportunity.name} />
      <Box color="white" flex={1} padding="0 1.5rem" position="relative" width="100%">
        {isSidebarOpen && <CuriSidebar />}
        <Switch>
          <Route exact path={`${path}/information`}>
            <OpportunityDetails />
          </Route>
          <Route exact path={`${path}/timeline`}>
            <OpportunityTimeline />
          </Route>
          <Route exact path={`${path}/related`}>
            <RelatedOpportunities />
          </Route>
          <Route exact path={`${path}/indications`}>
            <Indications />
          </Route>
          <Route path={`${path}/indications/:indicationId`}>
            <Indication />
          </Route>
          <Redirect from="*" to={`${path}/information`} />
        </Switch>
      </Box>
    </PageContainer>
  );
}

const useStyles = makeStyles(theme => ({
  detailsWrapper: {
    backgroundColor: theme.palette.background.light,
  },
}));

export default OpportunityContainer;
