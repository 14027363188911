import { uniqueId } from 'lodash';
import produce from 'immer';

import {
  GET_ROSTER_FAILURE,
  GET_ROSTER_REQUEST,
  GET_ROSTER_SUCCESS,
  GET_SEARCH_RESULTS_FAILURE,
  GET_SEARCH_RESULTS_REQUEST,
  GET_SEARCH_RESULTS_SUCCESS,
  RESET_SEARCH,
  SELECT_QUERY_SEARCH_RESULT,
  SET_QUERY_TERM_MATCHED,
  SET_QUERY_TERM_UNMATCHED,
} from './search.actions';

const initialState = {
  lastParameters: {
    text: null,
    fields: null,
    licenseStateCode: null,
    size: null,
  },
  results: null,
  unmatchedResults: [],
  isLoadingResults: false,
  rosterCsv: null,
  isLoadingRoster: false,
};

// eslint-disable-next-line default-param-last
export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_SEARCH_RESULTS_REQUEST:
      draft.isLoadingResults = true;
      draft.lastParameters = action.payload;
      draft.results = null;
      break;

    case GET_SEARCH_RESULTS_SUCCESS: {
      const resultData = parseSearchResponse(action.response);
      draft.results = resultData.results;
      draft.unmatchedResults = resultData.unmatchedResults;
      draft.isLoadingResults = false;
      break;
    }

    case GET_SEARCH_RESULTS_FAILURE:
      draft.isLoadingResults = false;
      break;

    case GET_ROSTER_REQUEST:
      draft.isLoadingRoster = true;
      draft.errorMessage = null;
      break;

    case GET_ROSTER_SUCCESS:
      draft.rosterCsv = action.response;
      draft.isLoadingRoster = false;
      break;

    case GET_ROSTER_FAILURE:
      draft.isLoadingRoster = false;
      break;

    case RESET_SEARCH:
      Object.assign(draft, initialState);
      break;

    case SELECT_QUERY_SEARCH_RESULT: {
      // NOTE: assumes you are only selecting a match if record is in matched results
      const { queryId, resultId } = action.payload;
      const queryData = draft.results.find(r => r.id === queryId);
      if (!queryData) {
        return draft;
      }

      queryData.results = queryData.results.map(result => ({
        ...result,
        isSelected: result.id === resultId,
      }));
      break;
    }

    case SET_QUERY_TERM_MATCHED: {
      const { queryId } = action.payload;
      const queryData = draft.unmatchedResults.find(r => r.id === queryId);
      if (!queryData) {
        return draft;
      }

      draft.unmatchedResults = sortSearchResults(draft.unmatchedResults.filter(r => r.id !== queryId));
      draft.results = sortSearchResults([...draft.results, queryData]);
      break;
    }

    case SET_QUERY_TERM_UNMATCHED: {
      const { queryId } = action.payload;
      const queryData = draft.results.find(r => r.id === queryId);
      if (!queryData) {
        return draft;
      }

      draft.results = sortSearchResults(draft.results.filter(r => r.id !== queryId));
      draft.unmatchedResults = sortSearchResults([...draft.unmatchedResults, queryData]);
      break;
    }

    default:
      break;
  }

  return draft;
}, initialState);

function parseSearchResponse(response) {
  return formatSearchResults(response).reduce(
    (data, searchResult) => {
      if (Array.isArray(searchResult.results) && searchResult.results.length > 0) {
        data.results.push(searchResult);
      } else {
        data.unmatchedResults.push(searchResult);
      }
      return data;
    },
    { results: [], unmatchedResults: [] }
  );
}

function formatSearchResults(searchResults) {
  return searchResults.map(searchResult => ({
    ...searchResult,
    id: uniqueId('query_'), // unique handle for easier manipulation
    results: searchResult.results.map((result, i) => ({
      ...result,
      score: Math.round((result.score + Number.EPSILON) * 1000) / 1000,
      id: uniqueId('result_'),
      isSelected: i === 0,
    })),
  }));
}

// assumes you've already created an artificial id
function sortSearchResults(searchResults) {
  return [...searchResults].sort((a, b) => {
    const aIndex = parseInt(a.id.split('_').pop(), 10);
    const bIndex = parseInt(b.id.split('_').pop(), 10);
    return aIndex - bIndex;
  });
}
