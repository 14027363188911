import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback } from 'react';

import { useAuthz } from 'okta/authz';
import Permissions from 'okta/permissions';

import { updateOpportunityNotes, UPDATE_OPPORTUNITY_NOTES_SUCCESS } from 'modules/opportunities/opportunities.actions';
import { handleToastMessage, TOAST_TYPES } from 'modules/layout/layout.actions';
import {
  useSelectedOpportunity,
  useSelectedOpportunityIsReadOnly,
  selectIsLoadingOpportunityNotes,
} from 'modules/opportunities/opportunities.selectors';
import Notes from 'common/components/notes.component';
import SectionContainer from 'modules/layout/section.container';
import sectionStyles from 'common/sectionStyles';

export default function OpportunityNotesSection() {
  const classes = useStyles();
  const { userHas } = useAuthz();
  const hasUpdatePermission = userHas(Permissions.UPDATE_OPPORTUNITY_INFO);

  const dispatch = useDispatch();
  const opportunity = useSelectedOpportunity();
  const opportunityIsReadonly = useSelectedOpportunityIsReadOnly();
  const isLoading = useSelector(selectIsLoadingOpportunityNotes);

  const handleSaveNote = useCallback(
    async notes => {
      const trimmedNotes = notes.trim();
      const response = await dispatch(
        updateOpportunityNotes(
          opportunity.id,
          // Empty string causes validation error, must instead send null
          trimmedNotes === '' ? null : trimmedNotes
        )
      );

      if (response.type === UPDATE_OPPORTUNITY_NOTES_SUCCESS) {
        dispatch(handleToastMessage('Opportunity notes updated.', TOAST_TYPES.SUCCESS));
      } else {
        dispatch(handleToastMessage('Could not save notes. Please try again.', TOAST_TYPES.ERROR));
      }
    },
    [dispatch, opportunity]
  );

  return (
    <SectionContainer title="Notes">
      <div className={classes.padding}>
        <Notes
          previousNotes={opportunity ? opportunity.notes : ''}
          isLoading={isLoading}
          onSave={handleSaveNote}
          isReadonly={opportunityIsReadonly || !hasUpdatePermission}
        />
      </div>
    </SectionContainer>
  );
}

const useStyles = makeStyles(theme => ({
  ...sectionStyles(theme),
}));
