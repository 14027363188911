import { CALL_API } from 'middleware/api';

export const GET_OPPORTUNITY_INDICATIONS_FAILURE = 'GET_OPPORTUNITY_INDICATIONS_FAILURE';
export const GET_OPPORTUNITY_INDICATIONS_REQUEST = 'GET_OPPORTUNITY_INDICATIONS_REQUEST';
export const GET_OPPORTUNITY_INDICATIONS_SUCCESS = 'GET_OPPORTUNITY_INDICATIONS_SUCCESS';

export const CREATE_INDICATION_FAILURE = 'CREATE_INDICATION_FAILURE';
export const CREATE_INDICATION_REQUEST = 'CREATE_INDICATION_REQUEST';
export const CREATE_INDICATION_SUCCESS = 'CREATE_INDICATION_SUCCESS';

export const UPDATE_INDICATION_FAILURE = 'UPDATE_INDICATION_FAILURE';
export const UPDATE_INDICATION_REQUEST = 'UPDATE_INDICATION_REQUEST';
export const UPDATE_INDICATION_SUCCESS = 'UPDATE_INDICATION_SUCCESS';

export const DELETE_INDICATION_FAILURE = 'DELETE_INDICATION_FAILURE';
export const DELETE_INDICATION_REQUEST = 'DELETE_INDICATION_REQUEST';
export const DELETE_INDICATION_SUCCESS = 'DELETE_INDICATION_SUCCESS';

export const CREATE_OASIS_QUOTE_FAILURE = 'CREATE_OASIS_QUOTE_FAILURE';
export const CREATE_OASIS_QUOTE_REQUEST = 'CREATE_OASIS_QUOTE_REQUEST';
export const CREATE_OASIS_QUOTE_SUCCESS = 'CREATE_OASIS_QUOTE_SUCCESS';

export const UPDATE_INDICATION_NOTES_FAILURE = 'UPDATE_INDICATION_NOTES_FAILURE';
export const UPDATE_INDICATION_NOTES_REQUEST = 'UPDATE_INDICATION_NOTES_REQUEST';
export const UPDATE_INDICATION_NOTES_SUCCESS = 'UPDATE_INDICATION_NOTES_SUCCESS';

export const CLONE_INDICATION_FAILURE = 'CLONE_INDICATION_FAILURE';
export const CLONE_INDICATION_REQUEST = 'CLONE_INDICATION_REQUEST';
export const CLONE_INDICATION_SUCCESS = 'CLONE_INDICATION_SUCCESS';

export const GET_INDICATION_INDIVIDUAL_RISKS_FAILURE = 'GET_INDICATION_INDIVIDUAL_RISKS_FAILURE';
export const GET_INDICATION_INDIVIDUAL_RISKS_REQUEST = 'GET_INDICATION_INDIVIDUAL_RISKS_REQUEST';
export const GET_INDICATION_INDIVIDUAL_RISKS_SUCCESS = 'GET_INDICATION_INDIVIDUAL_RISKS_SUCCESS';

export const GET_INDICATION_ORGANIZATION_RISKS_FAILURE = 'GET_INDICATION_ORGANIZATION_RISKS_FAILURE';
export const GET_INDICATION_ORGANIZATION_RISKS_REQUEST = 'GET_INDICATION_ORGANIZATION_RISKS_REQUEST';
export const GET_INDICATION_ORGANIZATION_RISKS_SUCCESS = 'GET_INDICATION_ORGANIZATION_RISKS_SUCCESS';

export const CREATE_INDICATION_INDIVIDUAL_RISK_FAILURE = 'CREATE_INDICATION_INDIVIDUAL_RISK_FAILURE';
export const CREATE_INDICATION_INDIVIDUAL_RISK_REQUEST = 'CREATE_INDICATION_INDIVIDUAL_RISK_REQUEST';
export const CREATE_INDICATION_INDIVIDUAL_RISK_SUCCESS = 'CREATE_INDICATION_INDIVIDUAL_RISK_SUCCESS';

export const CREATE_INDICATION_ORGANIZATION_RISK_FAILURE = 'CREATE_INDICATION_ORGANIZATION_RISK_FAILURE';
export const CREATE_INDICATION_ORGANIZATION_RISK_REQUEST = 'CREATE_INDICATION_ORGANIZATION_RISK_REQUEST';
export const CREATE_INDICATION_ORGANIZATION_RISK_SUCCESS = 'CREATE_INDICATION_ORGANIZATION_RISK_SUCCESS';

export const UPDATE_INDICATION_INDIVIDUAL_RISK_FAILURE = 'UPDATE_INDICATION_INDIVIDUAL_RISK_FAILURE';
export const UPDATE_INDICATION_INDIVIDUAL_RISK_REQUEST = 'UPDATE_INDICATION_INDIVIDUAL_RISK_REQUEST';
export const UPDATE_INDICATION_INDIVIDUAL_RISK_SUCCESS = 'UPDATE_INDICATION_INDIVIDUAL_RISK_SUCCESS';

export const UPDATE_INDICATION_INDIVIDUAL_RISKS_FAILURE = 'UPDATE_INDICATION_INDIVIDUAL_RISKS_FAILURE';
export const UPDATE_INDICATION_INDIVIDUAL_RISKS_REQUEST = 'UPDATE_INDICATION_INDIVIDUAL_RISKS_REQUEST';
export const UPDATE_INDICATION_INDIVIDUAL_RISKS_SUCCESS = 'UPDATE_INDICATION_INDIVIDUAL_RISKS_SUCCESS';

export const UPDATE_INDICATION_ORGANIZATION_RISK_FAILURE = 'UPDATE_INDICATION_ORGANIZATION_RISK_FAILURE';
export const UPDATE_INDICATION_ORGANIZATION_RISK_REQUEST = 'UPDATE_INDICATION_ORGANIZATION_RISK_REQUEST';
export const UPDATE_INDICATION_ORGANIZATION_RISK_SUCCESS = 'UPDATE_INDICATION_ORGANIZATION_RISK_SUCCESS';

export const DELETE_INDICATION_INDIVIDUAL_RISK_FAILURE = 'DELETE_INDICATION_INDIVIDUAL_RISK_FAILURE';
export const DELETE_INDICATION_INDIVIDUAL_RISK_REQUEST = 'DELETE_INDICATION_INDIVIDUAL_RISK_REQUEST';
export const DELETE_INDICATION_INDIVIDUAL_RISK_SUCCESS = 'DELETE_INDICATION_INDIVIDUAL_RISK_SUCCESS';

export const DELETE_INDICATION_ORGANIZATION_RISK_FAILURE = 'DELETE_INDICATION_ORGANIZATION_RISK_FAILURE';
export const DELETE_INDICATION_ORGANIZATION_RISK_REQUEST = 'DELETE_INDICATION_ORGANIZATION_RISK_REQUEST';
export const DELETE_INDICATION_ORGANIZATION_RISK_SUCCESS = 'DELETE_INDICATION_ORGANIZATION_RISK_SUCCESS';

export const DELETE_INDICATION_INDIVIDUAL_RISKS_FAILURE = 'DELETE_INDICATION_INDIVIDUAL_RISKS_FAILURE';
export const DELETE_INDICATION_INDIVIDUAL_RISKS_REQUEST = 'DELETE_INDICATION_INDIVIDUAL_RISKS_REQUEST';
export const DELETE_INDICATION_INDIVIDUAL_RISKS_SUCCESS = 'DELETE_INDICATION_INDIVIDUAL_RISKS_SUCCESS';

export const DELETE_INDICATION_ORGANIZATION_RISKS_FAILURE = 'DELETE_INDICATION_ORGANIZATION_RISKS_FAILURE';
export const DELETE_INDICATION_ORGANIZATION_RISKS_REQUEST = 'DELETE_INDICATION_ORGANIZATION_RISKS_REQUEST';
export const DELETE_INDICATION_ORGANIZATION_RISKS_SUCCESS = 'DELETE_INDICATION_ORGANIZATION_RISKS_SUCCESS';

export const UPLOAD_ROSTER_DRYRUN_FAILURE = 'UPLOAD_ROSTER_DRYRUN_FAILURE';
export const UPLOAD_ROSTER_DRYRUN_REQUEST = 'UPLOAD_ROSTER_DRYRUN_REQUEST';
export const UPLOAD_ROSTER_DRYRUN_SUCCESS = 'UPLOAD_ROSTER_DRYRUN_SUCCESS';

export const UPLOAD_ROSTER_FAILURE = 'UPLOAD_ROSTER_FAILURE';
export const UPLOAD_ROSTER_REQUEST = 'UPLOAD_ROSTER_REQUEST';
export const UPLOAD_ROSTER_SUCCESS = 'UPLOAD_ROSTER_SUCCESS';

export const UPLOAD_INDICATION_ATTACHMENT_FAILURE = 'UPLOAD_INDICATION_ATTACHMENT_FAILURE';
export const UPLOAD_INDICATION_ATTACHMENT_REQUEST = 'UPLOAD_INDICATION_ATTACHMENT_REQUEST';
export const UPLOAD_INDICATION_ATTACHMENT_SUCCESS = 'UPLOAD_INDICATION_ATTACHMENT_SUCCESS';

export const GET_INDICATION_ATTACHMENTS_FAILURE = 'GET_INDICATION_ATTACHMENTS_FAILURE';
export const GET_INDICATION_ATTACHMENTS_REQUEST = 'GET_INDICATION_ATTACHMENTS_REQUEST';
export const GET_INDICATION_ATTACHMENTS_SUCCESS = 'GET_INDICATION_ATTACHMENTS_SUCCESS';

export const DELETE_INDICATION_ATTACHMENT_FAILURE = 'DELETE_INDICATION_ATTACHMENT_FAILURE';
export const DELETE_INDICATION_ATTACHMENT_REQUEST = 'DELETE_INDICATION_ATTACHMENT_REQUEST';
export const DELETE_INDICATION_ATTACHMENT_SUCCESS = 'DELETE_INDICATION_ATTACHMENT_SUCCESS';

export const UPDATE_INDICATION_ATTACHMENTS_FAILURE = 'UPDATE_INDICATION_ATTACHMENTS_FAILURE';
export const UPDATE_INDICATION_ATTACHMENTS_REQUEST = 'UPDATE_INDICATION_ATTACHMENTS_REQUEST';
export const UPDATE_INDICATION_ATTACHMENTS_SUCCESS = 'UPDATE_INDICATION_ATTACHMENTS_SUCCESS';

export const RESET_INDICATION_RISKS = 'RESET_INDICATION_RISKS';

export const GET_INDICATION_STATES_FAILURE = 'GET_INDICATION_STATES_FAILURE';
export const GET_INDICATION_STATES_REQUEST = 'GET_INDICATION_STATES_REQUEST';
export const GET_INDICATION_STATES_SUCCESS = 'GET_INDICATION_STATES_SUCCESS';

export const GET_INDICATION_RISK_COUNTIES_FAILURE = 'GET_INDICATION_RISK_COUNTIES_FAILURE';
export const GET_INDICATION_RISK_COUNTIES_REQUEST = 'GET_INDICATION_RISK_COUNTIES_REQUEST';
export const GET_INDICATION_RISK_COUNTIES_SUCCESS = 'GET_INDICATION_RISK_COUNTIES_SUCCESS';

export const GET_INDICATION_RISK_SPECIALTIES_FAILURE = 'GET_INDICATION_RISK_SPECIALTIES_FAILURE';
export const GET_INDICATION_RISK_SPECIALTIES_REQUEST = 'GET_INDICATION_RISK_SPECIALTIES_REQUEST';
export const GET_INDICATION_RISK_SPECIALTIES_SUCCESS = 'GET_INDICATION_RISK_SPECIALTIES_SUCCESS';

export const RESET_INDICATION_RISK_SPECIALTIES = 'RESET_INDICATION_RISK_SPECIALTIES';

export const VERIFY_INDIVIDUAL_RISKS_FAILURE = 'VERIFY_INDIVIDUAL_RISKS_FAILURE';
export const VERIFY_INDIVIDUAL_RISKS_REQUEST = 'VERIFY_INDIVIDUAL_RISKS_REQUEST';
export const VERIFY_INDIVIDUAL_RISKS_SUCCESS = 'VERIFY_INDIVIDUAL_RISKS_SUCCESS';

export const VERIFY_ORGANIZATIONAL_RISKS_FAILURE = 'VERIFY_ORGANIZATIONAL_RISKS_FAILURE';
export const VERIFY_ORGANIZATIONAL_RISKS_REQUEST = 'VERIFY_ORGANIZATIONAL_RISKS_REQUEST';
export const VERIFY_ORGANIZATIONAL_RISKS_SUCCESS = 'VERIFY_ORGANIZATIONAL_RISKS_SUCCESS';

export const GET_OASIS_POLICIES_FAILURE = 'GET_OASIS_POLICIES_FAILURE';
export const GET_OASIS_POLICIES_REQUEST = 'GET_OASIS_POLICIES_REQUEST';
export const GET_OASIS_POLICIES_SUCCESS = 'GET_OASIS_POLICIES_SUCCESS';

export const RESET_INDICATION_OASIS_POLICIES = 'RESET_INDICATION_OASIS_POLICIES';

export const RESET_INDICATIONS = 'RESET_INDICATIONS';

// new indication attachment flow, three step process
// get the signed url for upload
export const GET_INDICATION_ATTACHMENT_UPLOAD_URL_REQUEST = 'GET_INDICATION_ATTACHMENT_UPLOAD_URL_REQUEST';
export const GET_INDICATION_ATTACHMENT_UPLOAD_URL_SUCCESS = 'GET_INDICATION_ATTACHMENT_UPLOAD_URL_SUCCESS';
export const GET_INDICATION_ATTACHMENT_UPLOAD_URL_FAILURE = 'GET_INDICATION_ATTACHMENT_UPLOAD_URL_FAILURE';

// upload to s3
export const UPLOAD_INDICATION_ATTACHMENT_S3_FAILURE = 'UPLOAD_INDICATION_ATTACHMENT_S3_FAILURE';
export const UPLOAD_INDICATION_ATTACHMENT_S3_REQUEST = 'UPLOAD_INDICATION_ATTACHMENT_S3_REQUEST';
export const UPLOAD_INDICATION_ATTACHMENT_S3_SUCCESS = 'UPLOAD_INDICATION_ATTACHMENT_S3_SUCCESS';

// create the final attachment in the db
export const CREATE_INDICATION_ATTACHMENT_FAILURE = 'CREATE_INDICATION_ATTACHMENT_FAILURE';
export const CREATE_INDICATION_ATTACHMENT_REQUEST = 'CREATE_INDICATION_ATTACHMENT_REQUEST';
export const CREATE_INDICATION_ATTACHMENT_SUCCESS = 'CREATE_INDICATION_ATTACHMENT_SUCCESS';

export const GET_INDICATION_ATTACHMENT_DOWNLOAD_URL_REQUEST = 'GET_INDICATION_ATTACHMENT_DOWNLOAD_URL_REQUEST';
export const GET_INDICATION_ATTACHMENT_DOWNLOAD_URL_SUCCESS = 'GET_INDICATION_ATTACHMENT_DOWNLOAD_URL_SUCCESS';
export const GET_INDICATION_ATTACHMENT_DOWNLOAD_URL_FAILURE = 'GET_INDICATION_ATTACHMENT_DOWNLOAD_URL_FAILURE';

export const PUSH_INDICATION_ATTACHMENTS_TO_ONBASE_REQUEST = 'PUSH_INDICATION_ATTACHMENTS_TO_ONBASE_REQUEST';
export const PUSH_INDICATION_ATTACHMENTS_TO_ONBASE_SUCCESS = 'PUSH_INDICATION_ATTACHMENTS_TO_ONBASE_SUCCESS';
export const PUSH_INDICATION_ATTACHMENTS_TO_ONBASE_FAILURE = 'PUSH_INDICATION_ATTACHMENTS_TO_ONBASE_FAILURE';

// update manually without refetching
export const UPDATE_LOCAL_INDICATION_ATTACHMENT = 'UPDATE_LOCAL_INDICATION_ATTACHMENT';

export const getOpportunityIndications = opportunityId => {
  return {
    [CALL_API]: {
      types: [
        GET_OPPORTUNITY_INDICATIONS_REQUEST,
        GET_OPPORTUNITY_INDICATIONS_SUCCESS,
        GET_OPPORTUNITY_INDICATIONS_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications`,
      method: 'GET',
    },
  };
};

export const createIndication = opportunityId => {
  return {
    [CALL_API]: {
      types: [CREATE_INDICATION_REQUEST, CREATE_INDICATION_SUCCESS, CREATE_INDICATION_FAILURE],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications`,
      method: 'POST',
    },
  };
};

export const updateIndication = (opportunityId, indicationId, nextIndication) => {
  return {
    [CALL_API]: {
      types: [UPDATE_INDICATION_REQUEST, UPDATE_INDICATION_SUCCESS, UPDATE_INDICATION_FAILURE],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}`,
      method: 'PATCH',
      payload: nextIndication,
    },
  };
};

export const deleteIndication = (opportunityId, indicationId) => {
  return {
    [CALL_API]: {
      types: [DELETE_INDICATION_REQUEST, DELETE_INDICATION_SUCCESS, DELETE_INDICATION_FAILURE],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}`,
      method: 'DELETE',
      actionMetadata: {
        id: indicationId,
      },
    },
  };
};

export const updateIndicationNotes = (opportunityId, indicationId, notes) => {
  return {
    [CALL_API]: {
      types: [UPDATE_INDICATION_NOTES_REQUEST, UPDATE_INDICATION_NOTES_SUCCESS, UPDATE_INDICATION_NOTES_FAILURE],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/notes`,
      method: 'PUT',
      payload: {
        notes,
      },
    },
  };
};

export const createOasisQuote = (opportunityId, indicationId) => {
  return {
    [CALL_API]: {
      types: [CREATE_OASIS_QUOTE_REQUEST, CREATE_OASIS_QUOTE_SUCCESS, CREATE_OASIS_QUOTE_FAILURE],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/quote`,
      method: 'POST',
    },
  };
};

export const cloneIndication = (opportunityId, indicationId) => {
  return {
    [CALL_API]: {
      types: [CLONE_INDICATION_REQUEST, CLONE_INDICATION_SUCCESS, CLONE_INDICATION_FAILURE],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/clone`,
      method: 'POST',
    },
  };
};

export const getIndicationIndividualRisks = (opportunityId, indicationId) => {
  return {
    [CALL_API]: {
      types: [
        GET_INDICATION_INDIVIDUAL_RISKS_REQUEST,
        GET_INDICATION_INDIVIDUAL_RISKS_SUCCESS,
        GET_INDICATION_INDIVIDUAL_RISKS_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/individualRisks`,
      method: 'GET',
    },
  };
};

export const getIndicationOrganizationRisks = (opportunityId, indicationId) => {
  return {
    [CALL_API]: {
      types: [
        GET_INDICATION_ORGANIZATION_RISKS_REQUEST,
        GET_INDICATION_ORGANIZATION_RISKS_SUCCESS,
        GET_INDICATION_ORGANIZATION_RISKS_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/organizationalRisks`,
      method: 'GET',
    },
  };
};

export const createIndividualRisk = (opportunityId, indicationId, data) => {
  return {
    [CALL_API]: {
      types: [
        CREATE_INDICATION_INDIVIDUAL_RISK_REQUEST,
        CREATE_INDICATION_INDIVIDUAL_RISK_SUCCESS,
        CREATE_INDICATION_INDIVIDUAL_RISK_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/individualRisks`,
      method: 'POST',
      payload: data,
    },
  };
};

export const createOrganizationRisk = (opportunityId, indicationId, data) => {
  return {
    [CALL_API]: {
      types: [
        CREATE_INDICATION_ORGANIZATION_RISK_REQUEST,
        CREATE_INDICATION_ORGANIZATION_RISK_SUCCESS,
        CREATE_INDICATION_ORGANIZATION_RISK_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/organizationalRisks`,
      method: 'POST',
      payload: data,
    },
  };
};

export const updateIndicationIndividualRisk = (opportunityId, indicationId, riskId, nextRisk) => {
  return {
    [CALL_API]: {
      types: [
        UPDATE_INDICATION_INDIVIDUAL_RISK_REQUEST,
        UPDATE_INDICATION_INDIVIDUAL_RISK_SUCCESS,
        UPDATE_INDICATION_INDIVIDUAL_RISK_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/individualRisks/${riskId}`,
      method: 'PATCH',
      payload: nextRisk,
    },
  };
};

export const updateIndicationIndividualRisks = (opportunityId, indicationId, patchedRisks) => {
  return {
    [CALL_API]: {
      types: [
        UPDATE_INDICATION_INDIVIDUAL_RISKS_REQUEST,
        UPDATE_INDICATION_INDIVIDUAL_RISKS_SUCCESS,
        UPDATE_INDICATION_INDIVIDUAL_RISKS_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/individualRisks`,
      method: 'PATCH',
      payload: patchedRisks,
    },
  };
};

export const updateIndicationOrganizationRisk = (opportunityId, indicationId, riskId, nextRisk) => {
  return {
    [CALL_API]: {
      types: [
        UPDATE_INDICATION_ORGANIZATION_RISK_REQUEST,
        UPDATE_INDICATION_ORGANIZATION_RISK_SUCCESS,
        UPDATE_INDICATION_ORGANIZATION_RISK_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/organizationalRisks/${riskId}`,
      method: 'PATCH',
      payload: nextRisk,
    },
  };
};

export const deleteIndicationIndividualRisk = (opportunityId, indicationId, riskId) => {
  return {
    [CALL_API]: {
      types: [
        DELETE_INDICATION_INDIVIDUAL_RISK_REQUEST,
        DELETE_INDICATION_INDIVIDUAL_RISK_SUCCESS,
        DELETE_INDICATION_INDIVIDUAL_RISK_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/individualRisks/${riskId}`,
      method: 'DELETE',
      actionMetadata: {
        id: riskId,
      },
    },
  };
};

export const deleteIndicationOrganizationRisk = (opportunityId, indicationId, riskId) => {
  return {
    [CALL_API]: {
      types: [
        DELETE_INDICATION_ORGANIZATION_RISK_REQUEST,
        DELETE_INDICATION_ORGANIZATION_RISK_SUCCESS,
        DELETE_INDICATION_ORGANIZATION_RISK_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/organizationalRisks/${riskId}`,
      method: 'DELETE',
      actionMetadata: {
        id: riskId,
      },
    },
  };
};

export const deleteIndicationIndividualRisks = (opportunityId, indicationId) => {
  return {
    [CALL_API]: {
      types: [
        DELETE_INDICATION_INDIVIDUAL_RISKS_REQUEST,
        DELETE_INDICATION_INDIVIDUAL_RISKS_SUCCESS,
        DELETE_INDICATION_INDIVIDUAL_RISKS_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/individualRisks`,
      method: 'DELETE',
    },
  };
};

export const deleteIndicationOrganizationRisks = (opportunityId, indicationId) => {
  return {
    [CALL_API]: {
      types: [
        DELETE_INDICATION_ORGANIZATION_RISKS_REQUEST,
        DELETE_INDICATION_ORGANIZATION_RISKS_SUCCESS,
        DELETE_INDICATION_ORGANIZATION_RISKS_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/organizationalRisks`,
      method: 'DELETE',
    },
  };
};

export const uploadRosterDryRun = (opportunityId, indicationId, csvFile) => {
  return {
    [CALL_API]: {
      types: [UPLOAD_ROSTER_DRYRUN_REQUEST, UPLOAD_ROSTER_DRYRUN_SUCCESS, UPLOAD_ROSTER_DRYRUN_FAILURE],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/individualRisks/upload`,
      method: 'POST',
      payload: csvFile,
    },
  };
};

export const uploadRoster = (opportunityId, indicationId, csvFile, mode) => {
  return {
    [CALL_API]: {
      types: [UPLOAD_ROSTER_REQUEST, UPLOAD_ROSTER_SUCCESS, UPLOAD_ROSTER_FAILURE],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/individualRisks/upload?dryRun=false&mode=${mode}`,
      method: 'POST',
      payload: csvFile,
    },
  };
};

export const getIndicationAttachmentUploadUrl = (opportunityId, indicationId) => {
  return {
    [CALL_API]: {
      types: [
        GET_INDICATION_ATTACHMENT_UPLOAD_URL_REQUEST,
        GET_INDICATION_ATTACHMENT_UPLOAD_URL_SUCCESS,
        GET_INDICATION_ATTACHMENT_UPLOAD_URL_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/attachments/uploadUrl`,
      method: 'GET',
    },
  };
};

export const getIndicationAttachmentDownloadUrl = (opportunityId, indicationId, attachmentId) => {
  return {
    [CALL_API]: {
      types: [
        GET_INDICATION_ATTACHMENT_DOWNLOAD_URL_REQUEST,
        GET_INDICATION_ATTACHMENT_DOWNLOAD_URL_SUCCESS,
        GET_INDICATION_ATTACHMENT_DOWNLOAD_URL_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/attachments/${attachmentId}/downloadUrl`,
      method: 'GET',
    },
  };
};

export const uploadIndicationAttachmentS3 = (signedUrl, attachment) => async dispatch => {
  dispatch({ type: UPLOAD_INDICATION_ATTACHMENT_S3_REQUEST });
  try {
    const uploadResponse = await fetch(signedUrl, {
      method: 'PUT',
      body: attachment,
    });
    if (uploadResponse.status !== 200) {
      throw new Error('Upload response status is not 200');
    }

    dispatch({ type: UPLOAD_INDICATION_ATTACHMENT_S3_SUCCESS });
    return { type: UPLOAD_INDICATION_ATTACHMENT_S3_SUCCESS };
  } catch (err) {
    console.error('Failed to upload to s3', err);
    dispatch({ type: UPLOAD_INDICATION_ATTACHMENT_S3_FAILURE });
    return { type: UPLOAD_INDICATION_ATTACHMENT_S3_FAILURE };
  }
};

export const createIndicationAttachment = (opportunityId, indicationId, attachment) => {
  return {
    [CALL_API]: {
      types: [
        CREATE_INDICATION_ATTACHMENT_REQUEST,
        CREATE_INDICATION_ATTACHMENT_SUCCESS,
        CREATE_INDICATION_ATTACHMENT_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/attachments`,
      method: 'POST',
      payload: attachment,
    },
  };
};

export const getIndicationAttachments = (opportunityId, indicationId) => {
  return {
    [CALL_API]: {
      types: [
        GET_INDICATION_ATTACHMENTS_REQUEST,
        GET_INDICATION_ATTACHMENTS_SUCCESS,
        GET_INDICATION_ATTACHMENTS_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/attachments`,
      method: 'GET',
      actionMetadata: { opportunityId, indicationId },
    },
  };
};

export const deleteIndicationAttachment = (opportunityId, indicationId, attachmentId) => {
  return {
    [CALL_API]: {
      types: [
        DELETE_INDICATION_ATTACHMENT_REQUEST,
        DELETE_INDICATION_ATTACHMENT_SUCCESS,
        DELETE_INDICATION_ATTACHMENT_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/attachments/${attachmentId}`,
      method: 'DELETE',
      actionMetadata: {
        id: attachmentId,
      },
    },
  };
};

export const updateIndicationAttachments = (opportunityId, indicationId, attachments) => {
  return {
    [CALL_API]: {
      types: [
        UPDATE_INDICATION_ATTACHMENTS_REQUEST,
        UPDATE_INDICATION_ATTACHMENTS_SUCCESS,
        UPDATE_INDICATION_ATTACHMENTS_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/attachments`,
      method: 'PATCH',
      payload: attachments,
    },
  };
};

export const pushIndicationAttachmentsToOnBase = (opportunityId, indicationId) => {
  return {
    [CALL_API]: {
      types: [
        PUSH_INDICATION_ATTACHMENTS_TO_ONBASE_REQUEST,
        PUSH_INDICATION_ATTACHMENTS_TO_ONBASE_SUCCESS,
        PUSH_INDICATION_ATTACHMENTS_TO_ONBASE_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/attachments/push`,
      method: 'POST',
    },
  };
};

export const getIndicationStates = () => {
  return {
    [CALL_API]: {
      types: [GET_INDICATION_STATES_REQUEST, GET_INDICATION_STATES_SUCCESS, GET_INDICATION_STATES_FAILURE],
      authenticated: true,
      endpoint: `v1/referenceData/states`,
      method: 'GET',
    },
  };
};

export const getIndicationRiskCounties = stateCodes => {
  const stateCodesString = Array.isArray(stateCodes) ? stateCodes.join(',') : stateCodes;
  return {
    [CALL_API]: {
      types: [
        GET_INDICATION_RISK_COUNTIES_REQUEST,
        GET_INDICATION_RISK_COUNTIES_SUCCESS,
        GET_INDICATION_RISK_COUNTIES_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/referenceData/counties?stateCodes=${stateCodesString}`,
      method: 'GET',
    },
  };
};

export const getIndicationRiskSpecialties = (stateCodes, issueCompanyId, policyTypeCode, indicationId) => {
  const stateCodesString = Array.isArray(stateCodes) ? stateCodes.join(',') : stateCodes;
  return {
    [CALL_API]: {
      types: [
        GET_INDICATION_RISK_SPECIALTIES_REQUEST,
        GET_INDICATION_RISK_SPECIALTIES_SUCCESS,
        GET_INDICATION_RISK_SPECIALTIES_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/referenceData/specialties?stateCodes=${stateCodesString}&policyIssueCompanyId=${issueCompanyId}&policyCode=${policyTypeCode}`,
      method: 'GET',
      actionMetadata: {
        id: indicationId,
        stateCodes,
      },
    },
  };
};

export const resetIndicationRiskSpecialties = indicationId => ({
  type: RESET_INDICATION_RISK_SPECIALTIES,
  payload: indicationId,
});

export const verifyIndividualRisks = (opportunityId, indicationId) => {
  return {
    [CALL_API]: {
      types: [VERIFY_INDIVIDUAL_RISKS_REQUEST, VERIFY_INDIVIDUAL_RISKS_SUCCESS, VERIFY_INDIVIDUAL_RISKS_FAILURE],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/individualRisks/verify`,
      method: 'POST',
    },
  };
};

export const verifyOrganizationalRisks = (opportunityId, indicationId) => {
  return {
    [CALL_API]: {
      types: [
        VERIFY_ORGANIZATIONAL_RISKS_REQUEST,
        VERIFY_ORGANIZATIONAL_RISKS_SUCCESS,
        VERIFY_ORGANIZATIONAL_RISKS_FAILURE,
      ],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/organizationalRisks/verify`,
      method: 'POST',
    },
  };
};

export const getOasisPolicies = (opportunityId, indicationId) => {
  return {
    [CALL_API]: {
      types: [GET_OASIS_POLICIES_REQUEST, GET_OASIS_POLICIES_SUCCESS, GET_OASIS_POLICIES_FAILURE],
      authenticated: true,
      endpoint: `v1/opportunities/${opportunityId}/indications/${indicationId}/policies`,
      method: 'GET',
      actionMetadata: {
        id: indicationId,
      },
    },
  };
};

export const resetIndicationOasisPolicies = indicationId => ({
  type: RESET_INDICATION_OASIS_POLICIES,
  payload: indicationId,
});

export const resetIndicationRisks = () => ({
  type: RESET_INDICATION_RISKS,
});

export const resetIndications = () => ({
  type: RESET_INDICATIONS,
});

export const updateLocalIndicationAttachment = attachment => ({
  type: UPDATE_LOCAL_INDICATION_ATTACHMENT,
  payload: attachment,
});
