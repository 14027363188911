import moment from 'moment';
import { SHORT_DATE_PATTERN, API_DATE_PATTERN, TIME_PATTERN } from 'common/dates/dates';

export const getDisplayDateValueFromIso = value => {
  // Must add 1 day to account for JS handling of `Z` in date-time strings
  // https://momentjs.com/guides/#/lib-concepts/js-date/
  return moment(value)
    .add(1, 'day')
    .format(SHORT_DATE_PATTERN);
};

// Try to parse and format date, but if unparsable display empty string
export const getDisplayDateValue = value => {
  const parsedDate = moment(value, API_DATE_PATTERN, true).format(SHORT_DATE_PATTERN);
  return parsedDate === 'Invalid date' ? '' : parsedDate;
};

// Try to parse and format date, but if unparsable use current value
export const getEditDateValue = value => {
  const parsedDate = moment(value, API_DATE_PATTERN, true).format(SHORT_DATE_PATTERN);
  return parsedDate === 'Invalid date' ? value : parsedDate;
};

// Try to parse and format date, but if unparsable display empty string
export const getDisplayTimeValueFromIso = value => {
  const parsedValue = moment(value)
    .add(1, 'day')
    .format(TIME_PATTERN);
  return parsedValue === 'Invalid date' ? '' : parsedValue;
};

// Convert `null` values to `undefined` because `material-table` requires
// inputs to not be `null`.
export const normalizeIndicationRisk = risk => {
  const normalizedRisk = {};
  Object.keys(risk).forEach(key => {
    normalizedRisk[key] = risk[key] === null ? undefined : risk[key];
  });
  return normalizedRisk;
};

// Convert `''` values to `null` because API requires `null` values to wipe existing data
export const prepareRiskForApi = risk =>
  Object.keys(risk).reduce((cleaned, key) => {
    if (cleaned[key] === '') {
      cleaned[key] = null;
    }
    return cleaned;
  }, risk);
