import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import OpportunityReviewSection from 'modules/opportunities/opportunity/opportunityInformationStep/opportunityReviewSection.component';
import OpportunityAddlInfoSection from 'modules/opportunities/opportunity/opportunityInformationStep/opportunityAddlInfoSection.component';
import OpportunityAttachmentsSection from 'modules/opportunities/opportunity/opportunityInformationStep/opportunityAttachmentsSection.component';
import OpportunityActivitySection from 'modules/opportunities/opportunity/opportunityInformationStep/opportunityActivitySection.component';
import {
  getOpportunityAttachments,
  getOpportunityActivity,
  getOpportunity,
} from 'modules/opportunities/opportunities.actions';
import { selectIsSidebarOpen } from 'modules/opportunities/opportunities.selectors';
import OpportunityNotesSection from 'modules/opportunities/opportunity/opportunityInformationStep/opportunityNotesSection.component';
import sectionStyles from 'common/sectionStyles';
import OpportunityDiscussionSection from './opportunityDiscussionSection.component';

function OpportunityDetails() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const { opportunityId } = useParams();

  useEffect(() => {
    dispatch(getOpportunity(opportunityId));
    dispatch(getOpportunityActivity(opportunityId));
    dispatch(getOpportunityAttachments(opportunityId));
  }, [dispatch, opportunityId]);

  return (
    <div className={classNames({ [classes.margin]: isSidebarOpen })}>
      <OpportunityReviewSection />
      <OpportunityAddlInfoSection />
      <OpportunityNotesSection />
      <OpportunityAttachmentsSection />
      <OpportunityActivitySection />
      <OpportunityDiscussionSection />
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  ...sectionStyles(theme),
}));

export default OpportunityDetails;
