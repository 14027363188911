import React, { useMemo } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { useSelector } from 'react-redux';

import { getOktaAuth } from 'okta/config';
import OpportunitiesContainer from 'modules/opportunities/opportunities.container';
import OpportunityContainer from 'modules/opportunities/opportunity/opportunity.container';
import AuthContainer from 'modules/layout/auth.container';
import SearchComponent from 'modules/search/search.component';
import { useJiraReportBugWidget } from 'utilities/jiraReportBugWidget';
import { selectAppConfig } from 'modules/appConfig/appConfig.selectors';
import SalesforceOpportunityLookup from 'modules/opportunities/salesforceOpportunityLookup.component';

const restoreOriginalUri = () => '/';

function Routes() {
  useJiraReportBugWidget(false);

  const { data: appConfig } = useSelector(selectAppConfig);
  const oktaAuth = useMemo(() => getOktaAuth(appConfig), [appConfig]);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {/* Okta callback path must be direct child of `<Security>` */}
      <Route path={appConfig.oktaCallbackPath} component={LoginCallback} />

      <AuthContainer>
        <Switch>
          <SecureRoute exact path="/opportunities" component={OpportunitiesContainer} />
          <SecureRoute path="/opportunities/:opportunityId" component={OpportunityContainer} />
          {/* using a generic redirect even though we only redirect one thing for now */}
          <SecureRoute path="/redirect" component={SalesforceOpportunityLookup} />
          <SecureRoute path="/search" component={SearchComponent} />
          <Redirect from="*" to="/opportunities" />
        </Switch>
      </AuthContainer>
    </Security>
  );
}

export default Routes;
