import { Snackbar, makeStyles } from '@material-ui/core';
import { amber } from '@material-ui/core/colors';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';

import { hideToast, TOAST_TYPES } from './layout.actions';
import { selectToastProperties } from './layout.selectors';

export default function Toast() {
  const { isShowToast, toastMessage, toastType } = useSelector(selectToastProperties);
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClose = useCallback(() => dispatch(hideToast()), [dispatch]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isShowToast}
      onClose={handleClose}
      ContentProps={{
        'aria-describedby': 'message-id',
        className: classNames(
          { [classes.error]: toastType === TOAST_TYPES.ERROR },
          { [classes.warning]: toastType === TOAST_TYPES.WARNING },
          { [classes.success]: toastType === TOAST_TYPES.SUCCESS }
        ),
      }}
      message={<span id="message-id">{toastMessage}</span>}
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
          <CloseIcon />
        </IconButton>,
      ]}
      ClickAwayListenerProps={{
        // this is set here to prevent users from being able to clickaway the toast
        // potentially should add a prop to allow modifying this
        onClickAway: () => {},
      }}
    />
  );
}

const useStyles = makeStyles(theme => ({
  error: {
    backgroundColor: theme.palette.error.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  success: {
    backgroundColor: theme.palette.primary.main,
  },
}));
