import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, List, ListItem, ListItemIcon, ListItemText, Divider, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import Loading from 'common/components/loading.component';
import { DOCUMENT_DATE_FORMAT } from 'common/components/curiDownloadableListItem.component';

import AttachmentListItem from 'common/components/attachmentListItem.component';

export default function IndicationDocumentsList({
  attachments,
  opportunityId,
  indicationId,
  isUploading,
  isLoading,
  disableUpdate,
  onChange,
  onDelete,
  individualRisks,
  organizationalRisks,
  showDelete,
  disableDelete,
  isReadOnly,
}) {
  const classes = useStyles();
  return isLoading ? (
    <div className={classes.flexCenter}>
      <Loading />
    </div>
  ) : (
    <List>
      {[]
        .concat(
          isUploading ? (
            <Fragment key="isUploading">
              <ListItem button component="a" href="#">
                <ListItemIcon>
                  <CircularProgress size={20} />
                </ListItemIcon>
                <ListItemText primary="Loading..." secondary={moment().format(DOCUMENT_DATE_FORMAT)} />
              </ListItem>
              {attachments.length !== 0 && <Divider />}
            </Fragment>
          ) : (
            []
          )
        )
        .concat(
          attachments.map((attachment, i) => (
            <Fragment key={attachment.id}>
              <AttachmentListItem
                opportunityId={opportunityId}
                indicationId={indicationId}
                attachment={attachment}
                onDelete={() => onDelete(attachment)}
                showDelete={showDelete}
                disableDelete={disableUpdate || disableDelete}
                isReadOnly={disableUpdate || isReadOnly}
                onChange={patchData => onChange({ ...patchData, id: attachment.id })}
                individualRisks={individualRisks}
                organizationalRisks={organizationalRisks}
              />
              {attachments.length !== i + 1 && <Divider />}
            </Fragment>
          ))
        )}
    </List>
  );
}

const useStyles = makeStyles(() => ({
  flexCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

IndicationDocumentsList.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.object).isRequired,
  opportunityId: PropTypes.string.isRequired,
  indicationId: PropTypes.string.isRequired,
  isUploading: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  disableUpdate: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  individualRisks: PropTypes.array.isRequired,
  organizationalRisks: PropTypes.array.isRequired,
  showDelete: PropTypes.bool,
  disableDelete: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

IndicationDocumentsList.defaultProps = {
  disableUpdate: false,
  showDelete: true,
  disableDelete: false,
  isReadOnly: false,
};
