import React from 'react';
import PropTypes from 'prop-types';
import { Box, Icon, Typography, makeStyles } from '@material-ui/core';
import classNames from 'classnames';

import { getDisplayTimeValueFromIso } from 'common/dataTable/dataTable.utils';

function TimelineCardLayout({ icon, title, children, description, timestamp }) {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="start"
      alignItems="stretch"
      bgcolor="common.white"
      color="text.secondary"
      mb="1rem"
      mr="0.25rem"
      boxShadow={3}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        padding="1rem"
        minHeight="4.75rem"
      >
        <Icon className={classNames('fal', icon, classes.icon)} />
        <Box flex={1}>
          <Typography component="p" gutterBottom={Boolean(description)}>
            {title}
          </Typography>
          {description && (
            <Typography component="p" variant="body2">
              {description}
            </Typography>
          )}
        </Box>
        {timestamp && <Typography component="p">{getDisplayTimeValueFromIso(timestamp)}</Typography>}
      </Box>
      {children && (
        <Box display="block" pl="4.5rem" pb="1rem">
          {children}
        </Box>
      )}
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
    width: 'initial',
  },
}));

TimelineCardLayout.propTypes = {
  icon: PropTypes.oneOf(['fa-star', 'fa-question', 'fa-envelope-square']).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  timestamp: PropTypes.string,
};

TimelineCardLayout.defaultProps = {
  children: null,
  description: null,
  timestamp: null,
};

export default TimelineCardLayout;
