/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, makeStyles, Tabs, Tab, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import CuriSelect from 'common/formFields/curiSelect.component';
import AssignedOpportunities from 'modules/opportunities/assignedOpportunities.component';
import PageContainer from 'modules/layout/pageContainer.container';
import SectionContainer from 'modules/layout/section.container';
import UnassignedOpportunities from 'modules/opportunities/unassignedOpportunities.component';
import TabPanel, { a11yProps } from 'common/components/tabPanel.component';
import RefreshButton from 'common/buttons/refreshButton.component';
import OpportunityStatusSelect, {
  STATUS_SELECT_OPTIONS,
  ASSIGNED_STATUS_FILTER_OPTIONS,
} from 'modules/opportunities/opportunityStatusSelect.component';
import {
  ASSIGNED_TAB,
  UNASSIGNED_TAB,
  getUnderwriters,
  getOpportunities,
  GET_OPPORTUNITIES_FAILURE,
  getMetadata,
  setOpportunitiesCurrentTab,
  setAssignedStatusFilter,
  setUnassignedStatusFilter,
  setAssigneeFilter,
} from 'modules/opportunities/opportunities.actions';
import {
  selectIsLoadingOpportunities,
  selectOpportunitiesCurrentTab,
  selectAssignedStatusFilter,
  selectAssignedUnderwriterFilter,
  selectUnassignedStatusFilter,
  useOpportunities,
  useUnderwriters,
} from 'modules/opportunities/opportunities.selectors';
import { useAuthz } from 'okta/authz';
import Permissions from 'okta/permissions';
import OpportunityStatus, {
  CURRENT_OPPORTUNITIES,
  OpportunityAssignmentStatus,
  OpportunityStage,
} from 'modules/opportunities/opportunityStatus';
import { resetIndications } from 'modules/indication/indication.actions';
import { selectUserGroups, selectUserId } from 'modules/login/login.selectors';
import { handleToastMessage, TOAST_TYPES } from 'modules/layout/layout.actions';
import { selectAppConfig } from 'modules/appConfig/appConfig.selectors';

const VIEW_ALL_ASSIGNEES_OPTION = { name: 'All Assignees', value: 'VIEW_ALL' };
// ensure the array reference is stable
const VIEW_ALL_ASSIGNEES_SELECTION = [VIEW_ALL_ASSIGNEES_OPTION.value];
const CURRENT_OPPORTUNITY_STATUSES = [
  OpportunityStatus.Open,
  OpportunityStatus.InReview,
  OpportunityStatus.Sent,
  OpportunityStatus.Paused,
];

function OpportunitiesContainer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const opportunities = useOpportunities();
  const location = useLocation();
  const history = useHistory();

  const { userHas } = useAuthz();
  const userId = useSelector(selectUserId);
  // ensure array reference is stable
  const ownUserSelection = useMemo(() => [userId], [userId]);

  const userGroups = useSelector(selectUserGroups);
  const {
    data: { oktaUnderwritingManagerGroupName },
  } = useSelector(selectAppConfig);
  const userIsUnderwritingManager = userGroups.some(group => group === oktaUnderwritingManagerGroupName);

  const isLoading = useSelector(selectIsLoadingOpportunities);
  const { isLoadingUnderwriters, underwriters } = useUnderwriters();
  const isLoadingState = isLoading || isLoadingUnderwriters;

  const currentTab = useSelector(selectOpportunitiesCurrentTab);

  const assigneeFilter = useSelector(selectAssignedUnderwriterFilter);
  const assignedStatusFilter = useSelector(selectAssignedStatusFilter);
  const unassignedStatusFilter = useSelector(selectUnassignedStatusFilter);

  const statusSelect = useMemo(() => (currentTab === ASSIGNED_TAB ? assignedStatusFilter : unassignedStatusFilter), [
    currentTab,
    assignedStatusFilter,
    unassignedStatusFilter,
  ]);
  const selectedStage =
    statusSelect === OpportunityStatus.Archived ? OpportunityStage.Archived : OpportunityStage.Active;

  const statusSelectOptions = useMemo(
    () => (currentTab === ASSIGNED_TAB ? ASSIGNED_STATUS_FILTER_OPTIONS : STATUS_SELECT_OPTIONS),
    [currentTab]
  );

  useEffect(() => {
    dispatch(getMetadata());
    dispatch(resetIndications());
  }, [dispatch]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (currentTab !== UNASSIGNED_TAB && String(searchParams.get('view')).toUpperCase() === UNASSIGNED_TAB) {
      dispatch(setOpportunitiesCurrentTab(UNASSIGNED_TAB));
      // clear query params to avoid confusion
      history.replace('/opportunities');
    }
  }, [dispatch, location, currentTab, history]);

  useEffect(() => {
    if (userHas(Permissions.READ_UNDERWRITERS) && !underwriters.length) {
      dispatch(getUnderwriters());
    } else if (!userHas(Permissions.READ_UNDERWRITERS)) {
      dispatch(setAssigneeFilter(ownUserSelection));
    }
  }, [dispatch, userHas, underwriters.length]);

  const handleTabChange = (_, newTab) => {
    dispatch(setOpportunitiesCurrentTab(newTab));
  };

  const handleStatusFilterChange = useCallback(
    status => {
      if (currentTab === ASSIGNED_TAB) {
        dispatch(setAssignedStatusFilter(status));
      } else {
        dispatch(setUnassignedStatusFilter(status));
      }
    },
    [dispatch, currentTab]
  );

  const handleAssigneeFilterChange = useCallback(
    assigneeIds => {
      const viewAllValue = VIEW_ALL_ASSIGNEES_OPTION.value;
      // user just selected "view all"
      if ([...assigneeIds].pop() === viewAllValue) {
        dispatch(setAssigneeFilter(VIEW_ALL_ASSIGNEES_SELECTION));
      } else {
        const otherValues = assigneeIds.filter(val => val !== viewAllValue);
        dispatch(setAssigneeFilter(otherValues));
      }
    },
    [dispatch]
  );

  const refreshOpportunities = useCallback(async () => {
    const params =
      currentTab === ASSIGNED_TAB
        ? {
          assigneeIds: assigneeFilter === VIEW_ALL_ASSIGNEES_SELECTION ? null : assigneeFilter,
          assignmentStatus: OpportunityAssignmentStatus.Assigned,
          stage: selectedStage,
        }
        : {
          assignmentStatus: OpportunityAssignmentStatus.Unassigned,
          stage: selectedStage,
        };

    const { type } = await dispatch(getOpportunities(params));
    if (type === GET_OPPORTUNITIES_FAILURE) {
      dispatch(handleToastMessage('Unable to load opportunities. Please try again.', TOAST_TYPES.ERROR));
    }
  }, [dispatch, assigneeFilter, currentTab, selectedStage]);

  // refreshes opportunities on initial load and when filters change
  useEffect(() => {
    // wait for underwriters to load
    if (assigneeFilter.length === 0) {
      return;
    }

    refreshOpportunities();
  }, [userHas, assigneeFilter, currentTab, selectedStage]);

  const underwriterSelectOptions = useMemo(
    () => [
      VIEW_ALL_ASSIGNEES_OPTION,
      ...underwriters.map(u => ({ name: `${u.profile.firstName} ${u.profile.lastName}`, value: u.id })),
    ],
    [underwriters]
  );

  // Set filter values if previously set
  useEffect(() => {
    if (userIsUnderwritingManager) {
      dispatch(setAssigneeFilter(VIEW_ALL_ASSIGNEES_SELECTION));
    } else if (underwriters.length) {
      // default to 'All Assignees' if user is not an underwriter
      const initialAssignees = underwriters.some(u => u.id === ownUserSelection[0])
        ? ownUserSelection
        : VIEW_ALL_ASSIGNEES_SELECTION;
      dispatch(setAssigneeFilter(initialAssignees));
    }
  }, [underwriters, ownUserSelection]);

  const assignedOpportunities = useMemo(() => opportunities.filter(o => o.assignees.length), [opportunities]);
  const unassignedOpportunities = useMemo(() => opportunities.filter(o => !o.assignees.length), [opportunities]);

  const filteredAssignedOpportunities = useMemo(
    () =>
      assignedOpportunities
        .filter(o =>
          statusSelect === CURRENT_OPPORTUNITIES
            ? CURRENT_OPPORTUNITY_STATUSES.includes(o.activeStatusCode)
            : o.activeStatusCode === statusSelect
        )
        .filter(o => assigneeFilter.some(u => u === VIEW_ALL_ASSIGNEES_OPTION.value || o.assignees.map(a => a.id).includes(u))),
    [assignedOpportunities, statusSelect, assigneeFilter]
  );

  const filteredUnassignedOpportunities = useMemo(
    () => unassignedOpportunities.filter(o => o.activeStatusCode === statusSelect),
    [unassignedOpportunities, statusSelect]
  );

  return (
    <PageContainer className={classes.pageContainer}>
      <SectionContainer className={classes.sectionContainer}>
        <Typography variant="h6" className={classes.pageTitle} color="primary">
          Opportunities
        </Typography>

        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <Tabs
            classes={{ root: classes.tabsRoot }}
            value={currentTab}
            onChange={handleTabChange}
            aria-label="Opportunity Selector"
          >
            <Tab label="Assigned" value={ASSIGNED_TAB} {...a11yProps(ASSIGNED_TAB)} />
            <Tab label="Unassigned" value={UNASSIGNED_TAB} {...a11yProps(UNASSIGNED_TAB)} />
          </Tabs>
          <Box display="flex" mb={2} flexGrow={1} justifyContent="flex-end">
            {currentTab === ASSIGNED_TAB && userHas(Permissions.READ_OPPORTUNITIES_OTHERS) && (
              <CuriSelect
                multiple
                className={classes.select}
                label="Assigned"
                noBlankOption
                onChange={e => handleAssigneeFilterChange(e.target.value)}
                value={assigneeFilter}
                options={underwriterSelectOptions}
                disabled={isLoadingState}
              />
            )}
            <OpportunityStatusSelect
              value={statusSelect}
              options={statusSelectOptions}
              onChange={val => handleStatusFilterChange(val)}
              isDisabled={isLoadingState}
            />
            <RefreshButton onClick={refreshOpportunities} disabled={isLoading} />
          </Box>
        </Box>
        <TabPanel value={currentTab} id={ASSIGNED_TAB}>
          <AssignedOpportunities
            isLoading={isLoadingState}
            opportunities={filteredAssignedOpportunities}
            selectedStatus={statusSelect}
          />
        </TabPanel>
        <TabPanel value={currentTab} id={UNASSIGNED_TAB}>
          <UnassignedOpportunities isLoading={isLoadingState} opportunities={filteredUnassignedOpportunities} />
        </TabPanel>
      </SectionContainer>
    </PageContainer>
  );
}

const useStyles = makeStyles(theme => ({
  pageTitle: {
    fontWeight: 600,
  },
  tabsRoot: {
    marginBottom: theme.spacing(2),
  },
  pageContainer: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0, 2),
    alignItems: 'flex-start',
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    boxShadow: 'none',
    overflow: 'hidden',
    padding: theme.spacing(2),
    width: '100%',
  },
  select: {
    minWidth: '16rem',
    maxWidth: '60vw',
    marginRight: theme.spacing(2),
  },
}));

export default OpportunitiesContainer;
