import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Icon, withStyles } from '@material-ui/core';
import classNames from 'classnames';

function Empty({ classes, icon, text }) {
  return (
    <div className={classes.emptyWrapper}>
      {typeof icon === 'string' ? (
        <Icon
          classes={{
            root: classes.icon,
          }}
          className={classNames(icon)}
        />
      ) : (
        icon
      )}
      <Typography className={classes.emptyText}>{text}</Typography>
    </div>
  );
}

const styles = theme => ({
  emptyWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  emptyText: {
    marginTop: '1rem',
    textAlign: 'center',
  },
  icon: {
    color: theme.palette.grey[500],
    fontSize: '2.25rem',
  },
});

Empty.propTypes = {
  classes: PropTypes.object.isRequired,

  icon: PropTypes.any,
  text: PropTypes.string.isRequired,
};

Empty.defaultProps = {
  icon: '',
};

export default withStyles(styles)(Empty);
