import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, withStyles } from '@material-ui/core';
import CuriButton from 'common/buttons/curiButton.component';

function DeleteRisksDialogComponent({ open, handleCancel, handleConfirm, count, isLoading, classes }) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleConfirm}>
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <DialogContentText>{`Are you sure you want to delete all ${count} indication risks?`}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <CuriButton variant="text" onClick={handleCancel} disabled={isLoading}>
          Cancel
        </CuriButton>
        <CuriButton onClick={handleConfirm} color="secondary" disabled={isLoading} className={classes.deleteButton}>
          {isLoading ? 'Deleting Risks' : 'Confirm Delete'}
        </CuriButton>
      </DialogActions>
    </Dialog>
  );
}

const styles = () => ({
  deleteButton: {
    width: 158,
  },
});

DeleteRisksDialogComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(DeleteRisksDialogComponent);
