import PropTypes from 'prop-types';
import React from 'react';

import { getDisplayDateValue, getDisplayDateValueFromIso } from './dataTable.utils';

export default function DataTableDate({ value }) {
  const formattedValue = String(value).endsWith('Z') ? getDisplayDateValueFromIso(value) : getDisplayDateValue(value);
  return <div>{formattedValue || 'UNKNOWN'}</div>;
}

DataTableDate.propTypes = {
  value: PropTypes.string,
};

DataTableDate.defaultProps = {
  value: '',
};
