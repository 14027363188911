import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAuthz } from 'okta/authz';
import { getNavigablePage } from 'okta/navigablePages';
import CuriSidebarLink from './curiSidebarLink.component';

const CuriSidebarLinks = ({ urlSegments, getLinkFullPath }) => {
  const { canNavigateTo } = useAuthz();

  const links = useMemo(
    () =>
      [
        { text: 'Opportunity Details', path: 'information' },
        { text: 'Timeline', path: 'timeline' },
        { text: 'Related Opportunities', path: 'related' },
        { text: 'Indications/Quotes', path: 'indications' },
      ].map(link => ({ ...link, fullPath: getLinkFullPath(link.path) })),
    [getLinkFullPath]
  );

  const isActiveRoute = useCallback(path => urlSegments.includes(path), [urlSegments]);

  return links.map(
    (link, i) =>
      canNavigateTo(getNavigablePage(link.fullPath)) && (
        <CuriSidebarLink
          key={link.path}
          text={link.text}
          isActive={isActiveRoute(link.path)}
          link={link.fullPath}
          showVerticalLine={i !== links.length - 1}
        />
      )
  );
};

CuriSidebarLinks.propTypes = {
  urlSegments: PropTypes.arrayOf(PropTypes.string).isRequired,
  getLinkFullPath: PropTypes.func.isRequired,
};

export default CuriSidebarLinks;
