import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, List, ListItem, ListItemText, ListItemIcon, Collapse, Typography } from '@material-ui/core';
import { ChevronRight, KeyboardArrowDown } from '@material-ui/icons';
import OpportunityActivityListEmailThread from 'modules/opportunities/opportunity/opportunityInformationStep/opportunityActivityListEmailThread.component';
import moment from 'moment';

function OpportunityActivityListEmails({ title, threads, handleOpenEmail }) {
  const classes = useStyles();

  const [open, setOpen] = useState(true);

  const sortedThreads = useMemo(() => {
    // Sort by `createdDate` in nested arrays
    const sortedInnerThreads = threads.map(thread =>
      thread.slice().sort((a, b) => moment(a.createdDate).diff(b.createdDate) * -1)
    );
    // Sort by earliest `createdDate` in top level array
    return sortedInnerThreads.sort((a, b) => moment(a[0].createdDate).diff(b[0].createdDate) * -1);
  }, [threads]);

  return (
    <List>
      <ListItem dense button onClick={() => setOpen(!open)}>
        <ListItemIcon classes={{ root: classes.icon }}>{open ? <KeyboardArrowDown /> : <ChevronRight />}</ListItemIcon>
        <ListItemText primary={<Typography className={classes.text}>{title}</Typography>} />
      </ListItem>
      <Collapse in={open} timeout="auto" className={classes.collapseContent} unmountOnExit>
        <List component="div" disablePadding>
          {sortedThreads.map((thread, i) => (
            <OpportunityActivityListEmailThread
              // No unique id exists, will not be reordered
              // eslint-disable-next-line react/no-array-index-key
              key={`thread-${i}`}
              thread={thread}
              handleOpenEmail={handleOpenEmail}
            />
          ))}
        </List>
      </Collapse>
    </List>
  );
}

const useStyles = makeStyles(theme => ({
  icon: {
    minWidth: 35,
  },
  text: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },
  collapseContent: {
    paddingLeft: '2rem',
  },
}));

OpportunityActivityListEmails.propTypes = {
  title: PropTypes.string,
  threads: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  handleOpenEmail: PropTypes.func.isRequired,
};

OpportunityActivityListEmails.defaultProps = {
  title: 'Case',
  threads: [],
};

export default OpportunityActivityListEmails;
