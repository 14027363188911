import { CALL_API } from 'middleware/api';

export const GET_ALERTS_REQUEST = 'GET_ALERTS_REQUEST';
export const GET_ALERTS_SUCCESS = 'GET_ALERTS_SUCCESS';
export const GET_ALERTS_FAILURE = 'GET_ALERTS_FAILURE';

export const ACKNOWLEDGE_ALERT_REQUEST = 'ACKNOWLEDGE_ALERT_REQUEST';
export const ACKNOWLEDGE_ALERT_SUCCESS = 'ACKNOWLEDGE_ALERT_SUCCESS';
export const ACKNOWLEDGE_ALERT_FAILURE = 'ACKNOWLEDGE_ALERT_FAILURE';

export const getAlerts = () => {
  return {
    [CALL_API]: {
      types: [GET_ALERTS_REQUEST, GET_ALERTS_SUCCESS, GET_ALERTS_FAILURE],
      authenticated: true,
      endpoint: 'v1/alerts',
      method: 'GET',
    },
  };
};

export const acknowledgeAlert = id => {
  return {
    [CALL_API]: {
      types: [ACKNOWLEDGE_ALERT_REQUEST, ACKNOWLEDGE_ALERT_SUCCESS, ACKNOWLEDGE_ALERT_FAILURE],
      authenticated: true,
      endpoint: `v1/alerts/${id}`,
      method: 'PATCH',
      payload: {
        isAcknowledged: true,
      },
    },
  };
};
