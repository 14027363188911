import React, { useState } from 'react';
import { makeStyles, IconButton, Icon, Menu, ListItem, Box, Typography } from '@material-ui/core';
import { useAlertsPoll } from 'modules/alerts/alertsPoll';
import AlertListItem, { ALERT_WIDTH } from 'modules/alerts/alertListItem.component';

function AlertList() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { alerts } = useAlertsPoll();

  return (
    <>
      <IconButton
        className={alerts.length > 0 ? classes.alertButtonActive : classes.alertButton}
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        <Icon className="fas fa-bell-exclamation" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        variant="menu"
      >
        {alerts.length === 0 ? (
          <ListItem>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              minHeight="4rem"
              width={ALERT_WIDTH}
            >
              <Typography component="p" variant="body1">
                No new alerts
              </Typography>
            </Box>
          </ListItem>
        ) : (
          alerts.map(alert => (
            <ListItem key={alert.id}>
              <AlertListItem alert={alert} handleDismiss={() => setAnchorEl(null)} />
            </ListItem>
          ))
        )}
      </Menu>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  alertButton: {
    color: theme.palette.common.lightGray,
    opacity: '25%',
  },
  alertButtonActive: {
    color: theme.palette.success.main,
  },
}));

export default AlertList;
