import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Link, makeStyles, useTheme } from '@material-ui/core';
import {
  selectLoadingIndicationOasisPolicies,
  useSelectedIndicationOasisPolicies,
} from 'modules/indication/indication.selectors';
import { getOasisPolicies, resetIndicationOasisPolicies } from 'modules/indication/indication.actions';
import { getDisplayDateValue } from 'common/dataTable/dataTable.utils';
import DataTable from 'common/dataTable/dataTable.component';
import DataTableActionButton from 'common/dataTable/dataTableActionButton.component';
import { selectProgramCodes } from 'modules/opportunities/opportunities.selectors';
import { selectAppConfig } from 'modules/appConfig/appConfig.selectors';

function OasisQuoteVersionsTable({ opportunityId, indication }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [policiesFetched, setPoliciesFetched] = useState(false);

  const oasisPolicies = useSelectedIndicationOasisPolicies();
  // Must clone b/c `material-table` mutates data
  const oasisPoliciesClone = useMemo(() => cloneDeep(oasisPolicies || []), [oasisPolicies]);
  const isLoadingOasisPolicies = useSelector(selectLoadingIndicationOasisPolicies);
  const programCodes = useSelector(selectProgramCodes);
  const {
    data: { oasisHostPrefix },
  } = useSelector(selectAppConfig);

  useEffect(() => {
    if (indication) {
      dispatch(resetIndicationOasisPolicies(indication.id));
    }

    if (!policiesFetched && indication && indication.policyId) {
      setPoliciesFetched(true);
      dispatch(getOasisPolicies(opportunityId, indication.id));
    }
  }, [dispatch, opportunityId, indication, policiesFetched]);

  /* eslint-disable react/prop-types */
  const columns = useMemo(
    () => [
      {
        field: 'policyId',
        headerStyle: { padding: theme.spacing(1) },
        title: 'Indication/Quote Number',
        render: rowData => (
          <Link
            href={`${oasisHostPrefix}/ePolicy/PM/policymgr/maintainPolicy.do?process=loadPolicyDetail&policyNo=${rowData.policyId}`}
            target="_blank"
          >
            {rowData.policyId}
          </Link>
        ),
      },
      {
        field: 'contractStartDate',
        headerStyle: { padding: theme.spacing(1) },
        title: 'Term Effective',
        type: 'date',
        render: rowData => <div>{getDisplayDateValue(rowData.contractStartDate)}</div>,
      },
      {
        field: 'policyStatusCode',
        headerStyle: { padding: theme.spacing(1) },
        title: 'Status',
      },
      {
        field: 'currentTermAmount',
        headerStyle: { padding: theme.spacing(1) },
        title: 'Written Premium',
        render: ({ currentTermAmount }) => (
          <div>{currentTermAmount !== null && String.fromCharCode(36) + currentTermAmount.toLocaleString()}</div>
        ),
      },
      {
        field: 'transactionStatusCode',
        headerStyle: { padding: theme.spacing(1) },
        title: 'WIP/Official',
      },
      {
        field: 'programCode',
        headerStyle: { padding: theme.spacing(1) },
        title: 'Type',
        render: ({ programCode }) => {
          const programCodeById = (programCodes || []).find(o => o.id === programCode);
          return <div>{programCodeById ? programCodeById.label : programCode}</div>;
        },
      },
    ],
    [theme, programCodes, oasisHostPrefix]
  );
  /* eslint-enable react/prop-types */

  return (
    <DataTable
      options={{ filtering: false, actionsColumnIndex: columns.length }}
      data={oasisPoliciesClone}
      columns={columns}
      components={{ Action: DataTableActionButton(classes.button) }}
      isLoading={isLoadingOasisPolicies}
    />
  );
}

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

OasisQuoteVersionsTable.propTypes = {
  opportunityId: PropTypes.string.isRequired,
  indication: PropTypes.object,
};

OasisQuoteVersionsTable.defaultProps = {
  indication: null,
};

export default OasisQuoteVersionsTable;
