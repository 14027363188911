import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import React from 'react';
import PropTypes from 'prop-types';

function CuriDialogComponent({ open, onClose, title, text, children, maxWidth }) {
  return (
    <Dialog fullWidth maxWidth={maxWidth} open={open} onClose={onClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {text && (
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
      )}
      {children && <DialogActions>{children}</DialogActions>}
    </Dialog>
  );
}

CuriDialogComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
};

CuriDialogComponent.defaultProps = {
  title: null,
  text: null,
  children: null,
  maxWidth: 'xs',
};

export default CuriDialogComponent;
