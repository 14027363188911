import React from 'react';
import PropTypes from 'prop-types';
import CuriButton from 'common/buttons/curiButton.component';

function DataTableActionButton(className) {
  // eslint-disable-next-line react/prop-types
  return function DataTableActionButtonComponent({ action: { onClick, children, isDisabled }, data, disabled }) {
    return (
      <CuriButton
        className={className}
        color="secondary"
        disabled={isDisabled || disabled}
        onClick={() => onClick(data)}
      >
        {children}
      </CuriButton>
    );
  };
}

DataTableActionButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};

DataTableActionButton.defaultProps = {
  className: '',
};

export default DataTableActionButton;
