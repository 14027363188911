import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { LOGIN_STATE } from 'modules/login/login.actions';
import { DRAWER_WIDTH } from 'modules/layout/drawer/utils';
import { selectShowTestBanner } from 'modules/appConfig/appConfig.selectors';
import { selectLoginStateLoginState } from 'modules/login/login.selectors';
import { HEADER_HEIGHT } from './header.container';
import { selectIsDrawerMenuOpen } from './layout.selectors';

export const PAGE_BOTTOM_PADDING = '3rem';

export default function PageContainer({ children, className }) {
  const classes = useStyles();
  const isDrawerMenuOpen = useSelector(selectIsDrawerMenuOpen);
  const loginState = useSelector(selectLoginStateLoginState);
  const showTestBanner = useSelector(selectShowTestBanner);

  return (
    <div
      className={classNames(
        {
          [classes.drawerMenuOpen]: isDrawerMenuOpen === true && loginState === LOGIN_STATE.LOGGED_IN,
          [classes.drawerMenuClosed]: isDrawerMenuOpen !== true && loginState === LOGIN_STATE.LOGGED_IN,
          [classes.testEnvBanner]: showTestBanner,
        },
        classes.pageWrapper,
        className
      )}
    >
      {children}
    </div>
  );
}

const useStyles = makeStyles((/* theme */) => ({
  pageWrapper: {
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    marginTop: HEADER_HEIGHT,
    padding: `0 0 ${PAGE_BOTTOM_PADDING} 0`, // add padding to bottom of page contents
  },
  drawerMenuOpen: {
    marginLeft: DRAWER_WIDTH,
  },
  drawerMenuClosed: {
    marginLeft: 0,
  },
  testEnvBanner: {
    marginTop: '8rem',
  },
}));

PageContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

PageContainer.defaultProps = {
  className: '',
};
