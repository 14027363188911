import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles, Box } from '@material-ui/core';

import CuriButton from 'common/buttons/curiButton.component';
import PageContainer from 'modules/layout/pageContainer.container';

export default function FullPageError({ text }) {
  const classes = useStyles();

  return (
    <PageContainer>
      <Box margin="auto" textAlign="center">
        <Typography>{text}</Typography>
        <CuriButton className={classes.returnHome} color="primary" href="/">
          Return To Home
        </CuriButton>
      </Box>
    </PageContainer>
  );
}

FullPageError.propTypes = {
  text: PropTypes.string,
};

FullPageError.defaultProps = {
  text: 'Something went wrong. Please try refreshing the page.',
};

const useStyles = makeStyles(theme => ({
  returnHome: {
    margin: `${theme.spacing(5.5)}px 0`,
  },
}));
