import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Icon } from '@material-ui/core';
import DataTable from 'common/dataTable/dataTable.component';
import moment from 'moment';
import DataTableCell from 'common/dataTable/dataTableCell.component';
import DataTableActionButton from 'common/dataTable/dataTableActionButton.component';
import { compose } from 'recompose';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { cloneIndication } from 'modules/indication/indication.actions';
import { handleToastMessage } from 'modules/layout/layout.actions';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import Permissions from 'okta/permissions';
import classNames from 'classnames';
import AlertListItemTooltip from 'modules/alerts/alertListItemTooltip.component';
import DataTablePagination from 'common/dataTable/dataTablePagination.component';
import { selectIndicationStatuses } from 'modules/opportunities/opportunities.selectors';

class IndicationsTable extends Component {
  constructor(props) {
    super(props);

    const { theme, classes } = props;
    this.tableRef = createRef();

    this.state = {
      columns: [
        {
          field: '',
          headerStyle: { padding: 0 },
          width: 25,
          cellStyle: { paddingRight: 0, paddingLeft: 0, textAlign: 'right' },
          title: '',
          filtering: false,
          render: rowData =>
            props.alertsTaskIndicationIds.includes(rowData.id) && (
              <AlertListItemTooltip id={rowData.id}>
                <Icon className={classNames('fal fa-exclamation-circle', classes.alertIcon)} />
              </AlertListItemTooltip>
            ),
        },
        {
          field: 'name',
          headerStyle: { padding: theme.spacing(2) },
          title: 'Name',
        },
        {
          field: 'policyTypeDescription',
          headerStyle: { padding: theme.spacing(2) },
          title: 'Policy Type',
        },
        {
          field: 'policyId',
          headerStyle: { padding: theme.spacing(2) },
          title: 'Oasis Policy ID',
        },
        {
          field: 'updatedDate',
          headerStyle: { padding: theme.spacing(2) },
          title: 'Last Updated Date',
          type: 'date',
          customFilterAndSearch: (term, rowData) => moment(term).isSame(rowData.updatedDate, 'day'),
        },
      ],
    };

    this.handleEditIndication = this.handleEditIndication.bind(this);
    this.getRowActions = this.getRowActions.bind(this);
  }

  componentWillUnmount() {
    /*
      When unmounting the table doesn't clear it's tabledata and stores the reference,
      so if the columns stay the same the filter values stick around. Here we're
      using a tableRef in order to clear all column filters unless
      the table isn't currently filtered
    */
    const { state, dataManager } = this.tableRef.current;

    if (dataManager.filtered) {
      state.columns.forEach(column => {
        column.tableData.filterValue = undefined;
      });
    }
  }

  handleEditIndication(indication) {
    const { history, match } = this.props;
    history.push(`${match.url}/${indication.id}`);
  }

  getRowActions() {
    const { userHas } = this.props;

    const actions = [];

    if (userHas(Permissions.UPDATE_OPPORTUNITY_INDICATIONS)) {
      actions.push({
        icon: 'edit',
        position: 'row',
        onClick: this.handleEditIndication,
        children: 'edit',
      });
    }

    return actions;
  }

  render() {
    const { classes, isLoading, indications, indicationStatuses } = this.props;
    const { columns } = this.state;

    // can't create this one in the constructor because it needs async props
    // wouldn't be a problem with a functional component, but not rewriting yet
    const statusColumn = {
      field: 'status',
      width: 150,
      title: 'Status',
      filtering: false,
      sorting: false,
      render: rowData => {
        const status = indicationStatuses.find(s => s.code === rowData.activeStatusCode);
        return status ? <div>{status.label}</div> : null;
      },
    };
    const allColumns = [...columns, statusColumn];

    return (
      <DataTable
        ref={this.tableRef}
        actions={this.getRowActions()}
        columns={allColumns}
        /*
          Cloning here is required as the table library's data manager
          tries to manipulate the redux opportunity object
        */
        data={cloneDeep(indications)}
        isLoading={isLoading}
        localization={{
          header: {
            // Sets the actions column header to empty
            actions: '',
          },
        }}
        options={{ actionsColumnIndex: allColumns.length }}
        components={{
          Action: indicationAction => {
            const { issueCompanyId, stateCode, processLocationCode, policyTypeCode } = indicationAction.data;
            const { children } = indicationAction.action;
            const missingIndicationPolicyInfo =
              !issueCompanyId || !stateCode || !processLocationCode || !policyTypeCode;
            return DataTableActionButton(
              classes.button,
              false
            )({
              ...indicationAction,
              disabled: children === 'clone' && missingIndicationPolicyInfo,
            });
          },
          Cell: DataTableCell,
          Container: props => <div style={{ ...props.style }}>{props.children}</div>,
          Pagination: props => (
            <DataTablePagination
              {...props}
              dataTableRef={this.tableRef.current}
              viewAllCount={this.props.indications.length}
            />
          ),
        }}
      />
    );
  }
}

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
    width: '5rem',
  },
  alertIcon: {
    color: theme.palette.success.main,
  },
});

IndicationsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  theme: PropTypes.object.isRequired,
  alertsTaskIndicationIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  indicationStatuses: PropTypes.array.isRequired,

  isLoading: PropTypes.bool.isRequired,
  indications: PropTypes.array.isRequired,

  cloneIndication: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  userHas: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  indicationStatuses: selectIndicationStatuses(state),
});

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    cloneIndication,
    handleToastMessage,
  })
)(IndicationsTable);
