import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  ListItem,
  ListItemIcon,
  CircularProgress,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { handleToastMessage, TOAST_TYPES } from 'modules/layout/layout.actions';
import { makeSelectOpportunityById, selectUnderwriters } from 'modules/opportunities/opportunities.selectors';
import { assignOpportunity, ASSIGN_OPPORTUNITY_SUCCESS } from 'modules/opportunities/opportunities.actions';
import CuriButton from 'common/buttons/curiButton.component';

function AssignOpportunityMenu({ opportunityId, color, disabled, className }) {
  const { menuItem } = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loadingId, setLoadingId] = useState(null);

  // Memoize selector factory so each component instance manages its own selector instance
  const selectOpportunityById = useMemo(makeSelectOpportunityById, []);
  const selectedOpportunity = useSelector(state => selectOpportunityById(state, opportunityId));

  const currentAssigneeIds = useMemo(
    () =>
      selectedOpportunity && Array.isArray(selectedOpportunity.assignees)
        ? selectedOpportunity.assignees.map(a => a.id)
        : [],
    [selectedOpportunity]
  );
  const possibleAssignees = useSelector(selectUnderwriters);
  const [selectedAssigneeIds, setSelectedAssigneeIds] = useState([]);

  // Propagate checkbox list values
  useEffect(() => {
    setSelectedAssigneeIds(currentAssigneeIds);
  }, [currentAssigneeIds]);

  const handleAssigneeChange = useCallback(
    assigneeId => async () => {
      let nextAssigneeIds;
      if (selectedAssigneeIds.includes(assigneeId)) {
        nextAssigneeIds = selectedAssigneeIds.filter(id => id !== assigneeId);
      } else {
        nextAssigneeIds = selectedAssigneeIds.concat(assigneeId);
      }
      setSelectedAssigneeIds(nextAssigneeIds);
      setLoadingId(assigneeId);
      const response = await dispatch(assignOpportunity(opportunityId, nextAssigneeIds));
      if (response.type === ASSIGN_OPPORTUNITY_SUCCESS) {
        dispatch(handleToastMessage('Opportunity assignees updated.', TOAST_TYPES.SUCCESS));
      } else {
        dispatch(handleToastMessage('Unable to update assignees. Please try again.', TOAST_TYPES.ERROR));
      }
      setLoadingId(null);
    },
    [dispatch, selectedAssigneeIds, opportunityId]
  );

  const menuItemDisabled = useCallback(
    assigneeId => !!loadingId || (selectedAssigneeIds.length === 1 && selectedAssigneeIds[0] === assigneeId),
    [loadingId, selectedAssigneeIds]
  );

  const showTooltip = useCallback(
    assigneeId => !loadingId && selectedAssigneeIds.length === 1 && selectedAssigneeIds[0] === assigneeId,
    [loadingId, selectedAssigneeIds]
  );

  const menuItems = useMemo(
    () =>
      !possibleAssignees.length ? (
        <MenuItem disabled>No Assignees Found</MenuItem>
      ) : (
        [
          <MenuItem key="cpq" disabled>
            Assign To:
          </MenuItem>,
        ].concat(
          possibleAssignees.map(({ id, profile: { firstName, lastName } }) => (
            <Tooltip
              key={id}
              title={
                showTooltip(id)
                  ? 'Opportunity must be assigned to another user before you will be allowed to unassign this user'
                  : ''
              }
              placement="top-start"
              arrow
            >
              {/* `span` wrapper allows tooltip to work when nested list item is disabled */}
              <span>
                <ListItem
                  dense
                  button
                  onClick={handleAssigneeChange(id)}
                  disabled={menuItemDisabled(id)}
                  className={menuItem}
                >
                  <ListItemIcon>
                    {loadingId && loadingId === id ? (
                      <CircularProgress size={24} />
                    ) : (
                      <Checkbox edge="start" checked={selectedAssigneeIds.includes(id)} disableRipple color="primary" />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={`${firstName} ${lastName}`} />
                </ListItem>
              </span>
            </Tooltip>
          ))
        )
      ),
    [possibleAssignees, selectedAssigneeIds, handleAssigneeChange, menuItemDisabled, loadingId, menuItem, showTooltip]
  );

  return (
    <>
      <CuriButton color={color} onClick={e => setAnchorEl(e.target)} disabled={disabled} className={className}>
        {selectedAssigneeIds.length ? 'REASSIGN' : 'ASSIGN'}
      </CuriButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        marginThreshold={45}
        PaperProps={{
          style: {
            maxHeight: 600,
          },
        }}
      >
        {menuItems}
      </Menu>
    </>
  );
}

const useStyles = makeStyles({
  menuItem: {
    height: '50px',
  },
});

AssignOpportunityMenu.propTypes = {
  opportunityId: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['default', 'secondary']),
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

AssignOpportunityMenu.defaultProps = {
  color: 'default',
  disabled: false,
  className: null,
};

export default AssignOpportunityMenu;
