import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Link, Typography, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { TIME_PATTERN } from 'common/dates/dates';

// `Link` is rendering `button`, not an `a` -- rule disabled
/* eslint-disable jsx-a11y/anchor-is-valid */

function TimelineEmailListItem({ email, handleOpenEmail }) {
  const { createdDate, subject, displayFrom, toAddress, textBody } = email;
  const classes = useStyles();

  const displayTime = useMemo(() => moment(createdDate).format(TIME_PATTERN), [createdDate]);

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
        <Link
          align="left"
          component="button"
          variant="subtitle1"
          noWrap
          display="inline"
          className={classes.emailSubject}
          onClick={() => handleOpenEmail(email)}
        >
          {subject}
        </Link>
        <Typography noWrap className={classes.addresses}>
          {`${displayFrom} to ${toAddress}`}
        </Typography>
        <Typography noWrap align="right" className={classes.displayTime}>
          {displayTime}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" width="85%" mb="1.5rem">
        <Typography display="block" noWrap variant="body2">
          {textBody}
        </Typography>
      </Box>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  emailSubject: {
    width: '40%',
  },
  addresses: {
    marginRight: 'auto',
    paddingLeft: theme.spacing(3),
  },
  displayTime: {
    minWidth: '5rem',
    paddingRight: theme.spacing(2),
  },
}));

TimelineEmailListItem.propTypes = {
  email: PropTypes.object.isRequired,
  handleOpenEmail: PropTypes.func.isRequired,
};

export default TimelineEmailListItem;
