import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { ValidatorForm } from 'react-material-ui-form-validator';
import CuriSelectValidator from 'common/formFields/curiSelectValidator.component';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';

import {
  useSelectedIndication,
  selectIsLoadingIndication,
  selectIndicationStates,
  useIndicationIsInOasis,
} from 'modules/indication/indication.selectors';
import {
  selectIsLoadingMetadata,
  selectIsLoadingPolicyHierarchy,
  selectPolicyHierarchy,
  selectProgramCodes,
  useSelectedOpportunity,
  useSelectedOpportunityIsReadOnly,
} from 'modules/opportunities/opportunities.selectors';
import { Container, Item } from 'common/components/grid.component';
import { handleToastMessage, TOAST_TYPES } from 'modules/layout/layout.actions';
import { API_DATE_PATTERN } from 'common/dates/dates';
import { useAuthz } from 'okta/authz';
import Permissions from 'okta/permissions';
import { selectHighContrastModeEnabled } from 'modules/layout/layout.selectors';
import highContrastStyles from 'styles/highContrastStyles';
import { mapToSelectOptions } from 'utilities/mappers';
import { getPolicyHierarchy } from 'modules/opportunities/opportunities.actions';
import {
  getIndicationStates,
  updateIndication,
  resetIndicationRiskSpecialties,
  UPDATE_INDICATION_SUCCESS,
} from './indication.actions';
import IndicationTransactionTypes from './indicationTransactionTypes';
import IndicationPolicyGroupsTypes from './indicationPolicyGroupsTypes';

const TYPE_OF_BUSINESS_OPTIONS = [IndicationTransactionTypes.New, IndicationTransactionTypes.Rewrite];

function IndicationPolicyInfoForm({ setFormDirty, setFormValid }) {
  const formRef = useRef(null);
  const { formField, selectField, highContrastInputLabel } = useStyles();
  const dispatch = useDispatch();
  const { selectedIndication } = useSelectedIndication();
  const selectedOpportunity = useSelectedOpportunity();
  const isReadonly = useSelectedOpportunityIsReadOnly();
  const isLoadingUpdatePolicyInfoForm = useSelector(selectIsLoadingIndication);
  const states = useSelector(selectIndicationStates);
  const indicationIsInOasis = useIndicationIsInOasis();
  const { userHas } = useAuthz();
  const highContrastModeEnabled = useSelector(selectHighContrastModeEnabled);
  const isLoadingMetadata = useSelector(selectIsLoadingMetadata);

  const isLoadingPolicyHierarchy = useSelector(selectIsLoadingPolicyHierarchy);
  const policyHierarchy = useSelector(selectPolicyHierarchy);

  const programCodes = useSelector(selectProgramCodes);
  const programCodeOptions = useMemo(() => mapToSelectOptions(programCodes), [programCodes]);

  useEffect(() => {
    if (!isLoadingPolicyHierarchy && !policyHierarchy.length) {
      dispatch(getPolicyHierarchy());
    }

    if (!states.length) {
      dispatch(getIndicationStates());
    }
  }, [dispatch, states, isLoadingPolicyHierarchy, policyHierarchy]);

  const [isDirty, setIsDirty] = useState(false);
  useEffect(() => {
    setFormDirty(isDirty);
  }, [isDirty, setFormDirty]);

  const companyOptions = useMemo(
    () => policyHierarchy.map(p => ({ name: p.issueCompanyDescription, value: p.issueCompanyId })),
    [policyHierarchy]
  );
  const [company, setCompany] = useState(null);
  const selectedCompany = useMemo(() => policyHierarchy.find(p => p.issueCompanyId === company), [
    policyHierarchy,
    company,
  ]);

  const [stateOptions, setStateOptions] = useState([]);
  const [state, setState] = useState(null);

  const [organizationTypeOptions, setOrganizationTypeOptions] = useState([]);
  const [organizationType, setOrganizationType] = useState(null);

  useEffect(() => {
    if (selectedCompany) {
      setStateOptions(
        selectedCompany.issueStates
          .filter(s => selectedOpportunity.stateCodes.includes(s.stateCode))
          .map(s => ({ name: s.stateDescription, value: s.stateCode }))
      );
      setOrganizationTypeOptions(selectedCompany.organizationTypes.map(o => ({ name: o.description, value: o.code })));
    }
  }, [selectedCompany, selectedOpportunity]);

  const selectedState = useMemo(() => selectedCompany && selectedCompany.issueStates.find(s => s.stateCode === state), [
    selectedCompany,
    state,
  ]);

  const [regionalOfficeOptions, setRegionalOfficeOptions] = useState([]);
  const [regionalOffice, setRegionalOffice] = useState(null);
  useEffect(() => {
    if (selectedState) {
      const options = selectedState.processLocations.map(p => ({
        name: p.processLocationDescription,
        value: p.processLocationCode,
      }));
      setRegionalOfficeOptions(options);
      // Must allow time for options to be set before setting value
      setTimeout(() => setRegionalOffice(options[0].value));
    }
  }, [selectedState]);

  const selectedRegionalOffice = useMemo(
    () => selectedState && selectedState.processLocations.find(p => p.processLocationCode === regionalOffice),
    [selectedState, regionalOffice]
  );

  const [policyGroupTypeOptions] = useState([
    IndicationPolicyGroupsTypes.Solo,
    IndicationPolicyGroupsTypes.Organization,
  ]);
  const [policyGroupType, setPolicyGroupType] = useState(null);

  const [policyTypeOptions, setPolicyTypeOptions] = useState([]);
  const [policyType, setPolicyType] = useState(null);
  useEffect(() => {
    if (selectedRegionalOffice && policyGroupType) {
      const options = selectedRegionalOffice.policyTypes.map(p => ({
        name: p.description,
        value: p.code,
        groupType: p.groupType,
      }));
      setPolicyTypeOptions(options.filter(o => o.groupType === policyGroupType));
    }
  }, [policyGroupType, selectedRegionalOffice]);

  const [typeOfBusiness, setTypeOfBusiness] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [programCode, setProgramCode] = useState(null);

  // Clear nested inputs on change
  useEffect(() => {
    setState(null);
    setRegionalOffice(null);
    setPolicyGroupType(null);
    setPolicyType(null);
    setOrganizationType(null);
  }, [company]);

  useEffect(() => {
    setRegionalOffice(null);
    setPolicyGroupType(null);
    setPolicyType(null);
  }, [state]);

  useEffect(() => {
    setPolicyGroupType(null);
    setPolicyType(null);
  }, [regionalOffice]);

  useEffect(() => {
    setPolicyType(null);
  }, [policyGroupType]);

  // Propagate form values on initial load
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (selectedIndication && !isDirty) {
      const {
        issueCompanyId,
        processLocationCode,
        stateCode,
        policyGroupType: indicationPolicyGroupType,
        policyTypeCode,
        contractStartDate,
        contractEndDate,
        transactionType,
        policyProgramCode,
        policyOrganizationType,
      } = selectedIndication;

      // Prevent further form value propagation after initial load complete
      setTimeout(() => setIsLoaded(true));

      // Allow time for possible options to be set before setting values
      setTimeout(() => {
        setCompany(issueCompanyId || null);
        setState(stateCode || null);
        setRegionalOffice(processLocationCode || null);
        setPolicyGroupType(indicationPolicyGroupType || null);
        setPolicyType(policyTypeCode);
        setTypeOfBusiness(transactionType || IndicationTransactionTypes.New.value);
        // Remove `Z` so `moment` correctly parses as local date
        setStartDate(contractStartDate.replace(/Z/, '') || null);
        setEndDate(contractEndDate.replace(/Z/, '') || null);
        setOrganizationType(policyOrganizationType);
        setProgramCode(policyProgramCode);
        setIsDirty(false);
      });
    }
  }, [dispatch, selectedIndication, isDirty]);

  // Enable `Save` button when form valid & dirty
  useEffect(() => {
    if (selectedIndication) {
      const momentStartDate = moment(startDate);
      const momentEndDate = moment(endDate);

      const formIsValid =
        company &&
        state &&
        regionalOffice &&
        policyType &&
        policyGroupType &&
        typeOfBusiness &&
        momentStartDate.isValid() &&
        momentEndDate.isValid() &&
        momentStartDate.isBefore(momentEndDate);

      const {
        issueCompanyId,
        processLocationCode,
        stateCode,
        policyGroupType: indicationPolicyGroupType,
        policyTypeCode,
        contractStartDate,
        contractEndDate,
        transactionType,
        policyProgramCode,
        policyOrganizationType,
      } = selectedIndication;

      const formIsDirty =
        issueCompanyId !== company ||
        processLocationCode !== regionalOffice ||
        stateCode !== state ||
        indicationPolicyGroupType !== policyGroupType ||
        policyTypeCode !== policyType ||
        contractStartDate.replace(/Z/, '') !== startDate ||
        contractEndDate.replace(/Z/, '') !== endDate ||
        transactionType !== typeOfBusiness ||
        policyProgramCode !== programCode ||
        policyOrganizationType !== organizationType;

      setFormValid(formIsValid);
      setIsDirty(formIsDirty);
    }
  }, [
    dispatch,
    company,
    state,
    regionalOffice,
    policyGroupType,
    policyType,
    typeOfBusiness,
    startDate,
    endDate,
    programCode,
    organizationType,
    selectedIndication,
    setFormValid,
  ]);

  // Submit event triggered by `Save` button in parent
  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      if (indicationIsInOasis || isReadonly) return;

      const response = await dispatch(
        updateIndication(selectedOpportunity.id, selectedIndication.id, {
          issueCompanyId: company.toString(),
          stateCode: state,
          processLocationCode: regionalOffice,
          policyGroupType,
          policyTypeCode: policyType,
          transactionType: typeOfBusiness,
          contractStartDate: moment(startDate).format(API_DATE_PATTERN),
          contractEndDate: moment(endDate).format(API_DATE_PATTERN),
          policyProgramCode: programCode,
          policyOrganizationType: organizationType,
        })
      );

      if (response.type !== UPDATE_INDICATION_SUCCESS) {
        dispatch(handleToastMessage('Unable to update indication. Please try again.', TOAST_TYPES.ERROR));
      } else {
        setIsDirty(false);
        dispatch(handleToastMessage('Indication updated.', TOAST_TYPES.SUCCESS));
      }
    },
    [
      dispatch,
      selectedOpportunity,
      selectedIndication,
      company,
      endDate,
      state,
      policyGroupType,
      policyType,
      regionalOffice,
      startDate,
      typeOfBusiness,
      indicationIsInOasis,
      isReadonly,
      programCode,
      organizationType,
    ]
  );

  const dateError = useMemo(() => moment(startDate).isSameOrAfter(moment(endDate)), [startDate, endDate]);

  const disableFormInputs = useMemo(
    () =>
      isReadonly ||
      isLoadingUpdatePolicyInfoForm ||
      !!indicationIsInOasis ||
      !userHas(Permissions.UPDATE_INDICATION_POLICY_INFO) ||
      isLoadingMetadata,
    [isLoadingUpdatePolicyInfoForm, indicationIsInOasis, userHas, isReadonly, isLoadingMetadata]
  );

  const handleIssueStateChange = useCallback(
    issueState => {
      setState(issueState);
      dispatch(resetIndicationRiskSpecialties(selectedIndication.id));
    },
    [dispatch, selectedIndication]
  );

  const handlePolicyTypeChange = useCallback(
    type => {
      setPolicyType(type);
      dispatch(resetIndicationRiskSpecialties(selectedIndication.id));
    },
    [dispatch, selectedIndication]
  );

  return (
    <ValidatorForm id="indication-policy-form" onSubmit={handleSubmit} ref={formRef}>
      <Container spacing={2}>
        <Item xs={12} sm={8} md={6}>
          <CuriSelectValidator
            name="company"
            validators={['required']}
            label="Company"
            noBlankOption
            className={formField}
            onChange={e => setCompany(e.target.value)}
            value={company}
            options={companyOptions}
            disabled={disableFormInputs}
          />
          <CuriSelectValidator
            name="state"
            validators={['required']}
            label="Issue State"
            noBlankOption
            className={formField}
            onChange={e => handleIssueStateChange(e.target.value)}
            value={state}
            options={stateOptions}
            disabled={disableFormInputs || !company}
          />
          <CuriSelectValidator
            name="regionalOffice"
            validators={['required']}
            label="Regional Office"
            noBlankOption
            className={formField}
            onChange={e => setRegionalOffice(e.target.value)}
            value={regionalOffice}
            options={regionalOfficeOptions}
            disabled={disableFormInputs}
          />
          <CuriSelectValidator
            name="policyGroupType"
            validators={['required']}
            label="Group/Solo"
            noBlankOption
            className={formField}
            onChange={e => setPolicyGroupType(e.target.value)}
            value={policyGroupType}
            options={policyGroupTypeOptions}
            disabled={disableFormInputs || !regionalOffice || !state}
          />
          <CuriSelectValidator
            name="policyType"
            validators={['required']}
            label="Policy Type"
            noBlankOption
            className={formField}
            onChange={e => handlePolicyTypeChange(e.target.value)}
            value={policyType}
            options={policyTypeOptions}
            disabled={disableFormInputs || !policyGroupType}
          />
        </Item>
        <Item xs={12} sm={8} md={6}>
          <CuriSelectValidator
            name="typeOfBusiness"
            validators={['required']}
            label="Type Of Business"
            noBlankOption
            className={formField}
            onChange={e => setTypeOfBusiness(e.target.value)}
            value={typeOfBusiness}
            options={TYPE_OF_BUSINESS_OPTIONS}
            disabled={disableFormInputs}
          />
          <KeyboardDatePicker
            className={classNames(formField, selectField)}
            disableToolbar
            variant="inline"
            format="MM/DD/YYYY"
            error={dateError || (isLoaded && !startDate)}
            helperText={dateError && 'Start date must be before end date'}
            label="Start Date"
            value={startDate}
            onChange={date => setStartDate(date)}
            inputVariant="outlined"
            margin="none"
            disabled={disableFormInputs}
            InputLabelProps={{
              className: highContrastModeEnabled ? classNames(highContrastInputLabel) : undefined,
            }}
          />
          <KeyboardDatePicker
            className={classNames(formField, selectField)}
            disableToolbar
            variant="inline"
            format="MM/DD/YYYY"
            error={dateError || (isLoaded && !endDate)}
            helperText={dateError && 'End date must be after start date'}
            label="End Date"
            value={endDate}
            onChange={date => setEndDate(date)}
            inputVariant="outlined"
            margin="none"
            disabled={disableFormInputs}
            InputLabelProps={{
              className: highContrastModeEnabled ? classNames(highContrastInputLabel) : undefined,
            }}
          />
          <CuriSelectValidator
            name="programCode"
            validators={[]}
            label="Indication / Quote"
            noBlankOption
            className={formField}
            onChange={e => setProgramCode(e.target.value)}
            value={programCode}
            options={programCodeOptions}
            disabled={disableFormInputs}
          />
          <CuriSelectValidator
            name="organizationType"
            validators={[]}
            label="Program"
            noBlankOption
            className={formField}
            onChange={e => setOrganizationType(e.target.value)}
            value={organizationType}
            options={organizationTypeOptions}
            disabled={disableFormInputs || !company}
          />
        </Item>
      </Container>
    </ValidatorForm>
  );
}

const useStyles = makeStyles(theme => ({
  formField: {
    maxWidth: '25rem',
    width: '100%',
  },
  selectField: {
    display: 'flex',
    marginBottom: theme.spacing(3),
  },
  ...highContrastStyles(theme),
}));

IndicationPolicyInfoForm.propTypes = {
  setFormDirty: PropTypes.func.isRequired,
  setFormValid: PropTypes.func.isRequired,
};

export default IndicationPolicyInfoForm;
