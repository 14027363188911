import { makeStyles, Link } from '@material-ui/core';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import moment from 'moment';

import { getDisplayDateValueFromIso } from 'common/dataTable/dataTable.utils';
import { useAuthz } from 'okta/authz';
import DataTableStateCodeFilter, { customStateFilter } from 'common/dataTable/dataTableStateCodeFilter.component';
import OpportunitiesTable from 'modules/opportunities/opportunitiesTable.component';
import Permissions from 'okta/permissions';
import useStateFilterOptions from 'modules/opportunities/useStateFilterOptions';

function UnassignedOpportunities({ isLoading, opportunities }) {
  const classes = useStyles();
  const history = useHistory();
  const stateFilterOptions = useStateFilterOptions(opportunities);
  const { userHas } = useAuthz();

  const { headerStyle } = classes;

  const actions = useMemo(() => {
    const rowActions = [
      {
        icon: 'View',
        position: 'row',
        onClick: rowData => history.push(`/opportunities/${rowData.id}/information`),
        children: 'View',
      },
    ];

    if (userHas(Permissions.ASSIGN_OPPORTUNITY)) {
      rowActions.push({
        icon: 'Assign',
        position: 'row',
        onClick: () => {}, // handled at a lower level
        children: 'Assign',
      });
    }

    return rowActions;
  }, [history, userHas]);

  /* eslint-disable react/prop-types */
  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerStyle: { padding: headerStyle },
        title: 'Opportunity Name',
        render: rowData => (
          <Link component={RouterLink} to={`/opportunities/${rowData.id}/information`} underline="hover">
            {rowData.name}
          </Link>
        ),
      },
      {
        field: 'numberOfPhysicians',
        headerStyle: { padding: headerStyle },
        title: 'Number of Physicians',
      },
      {
        field: 'numberOfApp',
        headerStyle: { padding: headerStyle },
        title: 'Number of APP',
      },
      {
        field: 'agency',
        headerStyle: { padding: headerStyle },
        title: 'Brokerage/Agency',
      },
      {
        field: 'stateCodes',
        headerStyle: { padding: headerStyle },
        title: 'State',
        filterComponent: ({ onFilterChanged, columnDef }) => (
          <DataTableStateCodeFilter
            columnDef={columnDef}
            onFilterChanged={onFilterChanged}
            options={stateFilterOptions}
          />
        ),
        customFilterAndSearch: customStateFilter,
      },
      {
        field: 'specialty',
        headerStyle: { padding: headerStyle },
        title: 'Specialty',
      },
      {
        field: 'targetEffectiveDate',
        headerStyle: { padding: headerStyle },
        title: 'Target Eff Date',
        type: 'date',
        render: rowData => <div>{getDisplayDateValueFromIso(rowData.targetEffectiveDate)}</div>,
        customFilterAndSearch: (term, rowData) =>
          moment(term).isSame(getDisplayDateValueFromIso(rowData.targetEffectiveDate), 'day'),
      },
    ],
    [headerStyle, stateFilterOptions]
  );
  /* eslint-enable react/prop-types */

  return <OpportunitiesTable actions={actions} isLoading={isLoading} opportunities={opportunities} columns={columns} />;
}

const useStyles = makeStyles(theme => ({
  headerStyle: {
    padding: theme.spacing(2),
  },
}));

UnassignedOpportunities.propTypes = {
  isLoading: PropTypes.bool,
  opportunities: PropTypes.array.isRequired,
};

UnassignedOpportunities.defaultProps = {
  isLoading: false,
};

export default UnassignedOpportunities;
