import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';
import CuriSelectValidator from 'common/formFields/curiSelectValidator.component';
import CuriTextValidator from 'common/formFields/curiTextValidator.component';
import { makeStyles } from '@material-ui/core';
import {
  useSelectedIndication,
  selectIsLoadingIndication,
  selectRiskCounties,
  selectIndicationStates,
  useIndicationIsInOasis,
} from 'modules/indication/indication.selectors';
import {
  useSelectedOpportunity,
  useSelectedOpportunityIsReadOnly,
} from 'modules/opportunities/opportunities.selectors';
import { Container, Item } from 'common/components/grid.component';
import { handleToastMessage, TOAST_TYPES } from 'modules/layout/layout.actions';
import { useAuthz } from 'okta/authz';
import Permissions from 'okta/permissions';
import { isValidAddress } from 'utilities/validators';
import {
  getIndicationStates,
  getIndicationRiskCounties,
  updateIndication,
  UPDATE_INDICATION_SUCCESS,
} from './indication.actions';

function IndicationAddressInfoForm({ setFormDirty, setFormValid }) {
  const formRef = useRef(null);
  const { formField, stateZipWrapper } = useStyles();
  const dispatch = useDispatch();
  const { selectedIndication } = useSelectedIndication();
  const selectedOpportunity = useSelectedOpportunity();
  const isReadonly = useSelectedOpportunityIsReadOnly();
  const isLoadingUpdateAddressInfoForm = useSelector(selectIsLoadingIndication);
  const riskCounties = useSelector(selectRiskCounties);
  const states = useSelector(selectIndicationStates);
  const indicationIsInOasis = useIndicationIsInOasis();
  const { userHas } = useAuthz();

  useEffect(() => {
    if (!states.length) {
      dispatch(getIndicationStates());
    }
  }, [dispatch, states]);

  const addressStateOptions = useMemo(() => states.map(s => ({ name: s.description, value: s.code })), [states]);

  const [isDirty, setIsDirty] = useState(false);
  useEffect(() => {
    setFormDirty(isDirty);
  }, [isDirty, setFormDirty]);

  const [addressStreet, setAddressStreet] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressCity, setAddressCity] = useState('');
  const [addressCountyCode, setAddressCountyCode] = useState('');
  const [addressStateCode, setAddressStateCode] = useState('');
  const [addressZipCode, setAddressZipCode] = useState('');

  const addressCountyOptions = useMemo(
    () =>
      !addressStateCode || !riskCounties[addressStateCode]
        ? []
        : riskCounties[addressStateCode].map(c => ({ name: c.description, value: c.code })),
    [addressStateCode, riskCounties]
  );

  useEffect(() => {
    if (addressStateCode && !riskCounties[addressStateCode]) {
      dispatch(getIndicationRiskCounties([addressStateCode]));
    }
  }, [dispatch, addressStateCode, riskCounties]);

  useEffect(() => {
    if (selectedIndication && !isDirty) {
      const {
        policyHolderAddressStreet,
        policyHolderAddressLine2,
        policyHolderAddressCity,
        policyHolderAddressCountyCode,
        policyHolderAddressStateCode,
        policyHolderAddressZipCode,
      } = selectedIndication;

      setAddressStreet(policyHolderAddressStreet || '');
      setAddressLine2(policyHolderAddressLine2 || '');
      setAddressCity(policyHolderAddressCity || '');
      setAddressCountyCode(policyHolderAddressCountyCode || '');
      setAddressStateCode(policyHolderAddressStateCode || '');
      setAddressZipCode(policyHolderAddressZipCode || '');

      setIsDirty(false);
    }
  }, [dispatch, selectedIndication, isDirty]);

  useEffect(() => {
    if (selectedIndication) {
      const formIsValid = isValidAddress({
        addressStreet,
        addressCity,
        addressCountyCode,
        addressStateCode,
        addressZipCode,
        riskCounties,
      });

      const {
        policyHolderAddressStreet,
        policyHolderAddressLine2,
        policyHolderAddressCity,
        policyHolderAddressCountyCode,
        policyHolderAddressStateCode,
        policyHolderAddressZipCode,
      } = selectedIndication;

      const formIsDirty =
        // treat empty strings as null
        policyHolderAddressStreet !== (addressStreet || null) ||
        policyHolderAddressLine2 !== (addressLine2 || null) ||
        policyHolderAddressCity !== (addressCity || null) ||
        policyHolderAddressCountyCode !== (addressCountyCode || null) ||
        policyHolderAddressStateCode !== (addressStateCode || null) ||
        policyHolderAddressZipCode !== (addressZipCode || null);

      setFormValid(formIsValid);
      setIsDirty(formIsDirty);
    }
  }, [
    dispatch,
    addressStreet,
    addressLine2,
    addressCity,
    addressCountyCode,
    addressStateCode,
    addressZipCode,
    selectedIndication,
    setFormValid,
    riskCounties,
  ]);

  // Submit event triggered by `Save` button in parent
  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      if (indicationIsInOasis || isReadonly) return;
      const response = await dispatch(
        updateIndication(selectedOpportunity.id, selectedIndication.id, {
          policyHolderAddressStreet: addressStreet || null,
          policyHolderAddressLine2: addressLine2 || null,
          policyHolderAddressCity: addressCity || null,
          policyHolderAddressCountyCode: addressCountyCode || null,
          policyHolderAddressStateCode: addressStateCode || null,
          policyHolderAddressZipCode: addressZipCode || null,
        })
      );

      if (response.type !== UPDATE_INDICATION_SUCCESS) {
        dispatch(handleToastMessage('Unable to update indication. Please try again.', TOAST_TYPES.ERROR));
      } else {
        setIsDirty(false);
        dispatch(handleToastMessage('Indication updated.', TOAST_TYPES.SUCCESS));
      }
    },
    [
      dispatch,
      selectedOpportunity,
      selectedIndication,
      addressStreet,
      addressLine2,
      addressCity,
      addressCountyCode,
      addressStateCode,
      addressZipCode,
      indicationIsInOasis,
      isReadonly,
    ]
  );

  const disableFormInputs = useMemo(
    () =>
      isReadonly ||
      isLoadingUpdateAddressInfoForm ||
      !!indicationIsInOasis ||
      !userHas(Permissions.UPDATE_INDICATION_POLICY_INFO),
    [isLoadingUpdateAddressInfoForm, indicationIsInOasis, userHas, isReadonly]
  );

  return (
    <ValidatorForm id="indication-address-form" onSubmit={handleSubmit} ref={formRef}>
      <Container spacing={2}>
        <Item xs={12} sm={8} md={6}>
          <CuriTextValidator
            name="addressStreet"
            validators={['required']}
            label="Street"
            className={formField}
            onChange={e => setAddressStreet(e.target.value)}
            value={addressStreet}
            disabled={disableFormInputs}
          />
        </Item>

        <Item xs={12} sm={8} md={6}>
          <CuriTextValidator
            name="addressLine2"
            validators={[]}
            label="Address Line 2"
            className={formField}
            onChange={e => setAddressLine2(e.target.value)}
            value={addressLine2}
            disabled={disableFormInputs}
          />
        </Item>

        <Item xs={12} sm={8} md={6}>
          <CuriTextValidator
            name="addressCity"
            validators={['required']}
            label="City"
            className={formField}
            onChange={e => setAddressCity(e.target.value)}
            value={addressCity}
            disabled={disableFormInputs}
          />
        </Item>

        <Item item xs={12} sm={8} md={6}>
          <Container className={stateZipWrapper} spacing={1}>
            <Item xs={6}>
              <CuriSelectValidator
                name="addressState"
                validators={['required']}
                label="State"
                noBlankOption
                className={formField}
                onChange={e => {
                  setAddressStateCode(e.target.value);
                  setAddressCountyCode('');
                }}
                value={addressStateCode}
                options={addressStateOptions}
                disabled={disableFormInputs}
              />
            </Item>
            <Item xs={6}>
              <CuriTextValidator
                name="addressZip"
                validators={['required', 'minStringLength:5', 'maxStringLength:5']}
                label="Zip Code"
                className={formField}
                onChange={e => setAddressZipCode(e.target.value.slice(0, 5).replace(/\D+/g, ''))}
                value={addressZipCode}
                disabled={disableFormInputs}
              />
            </Item>
          </Container>
        </Item>

        <Item xs={12} sm={8} md={6}>
          <CuriSelectValidator
            name="addressCounty"
            validators={[]}
            label="County"
            className={formField}
            onChange={e => setAddressCountyCode(e.target.value)}
            value={addressCountyCode}
            options={addressCountyOptions}
            disabled={disableFormInputs}
          />
        </Item>
      </Container>
    </ValidatorForm>
  );
}

const useStyles = makeStyles(() => ({
  formField: {
    maxWidth: '25rem',
    width: '100%',
  },
  stateZipWrapper: {
    maxWidth: '25rem',
  },
}));

IndicationAddressInfoForm.propTypes = {
  setFormDirty: PropTypes.func.isRequired,
  setFormValid: PropTypes.func.isRequired,
};

export default IndicationAddressInfoForm;
