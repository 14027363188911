import React, { useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import SaveIcon from '@material-ui/icons/Save';

import CuriTextValidator from 'common/formFields/curiTextValidator.component';
import CuriButton from 'common/buttons/curiButton.component';
import { useSelectedIndication } from 'modules/indication/indication.selectors';

function IndicationRenameModal({ open, loading, handleClose, handleSubmit }) {
  const { formField } = useStyles();
  const formRef = useRef(null);
  const [indicationName, setIndicationName] = useState('');
  const [formIsValid, setFormIsValid] = useState(false);
  const { selectedIndication } = useSelectedIndication();

  const selectedIndicationName = useMemo(
    () => (selectedIndication && selectedIndication.name ? selectedIndication.name : ''),
    [selectedIndication]
  );

  const submitIsDisabled = useMemo(() => loading || !formIsValid || indicationName === selectedIndicationName, [
    loading,
    formIsValid,
    indicationName,
    selectedIndicationName,
  ]);

  // Handle setting initial form value
  useEffect(() => {
    if (open && selectedIndicationName) {
      setIndicationName(selectedIndicationName);
    }
  }, [open, selectedIndicationName]);

  // Handle form validation
  useEffect(() => {
    const checkFormValidity = async () => {
      if (formRef && formRef.current) {
        const form = formRef.current;
        const isValid = await form.isFormValid();
        setFormIsValid(isValid);
      }
    };

    checkFormValidity();
  }, [indicationName]);

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose} disableBackdropClick={loading}>
      <DialogTitle>Rename Indication</DialogTitle>
      <DialogContent>
        <ValidatorForm onSubmit={() => handleSubmit(indicationName)} ref={formRef}>
          <CuriTextValidator
            autoFocus
            className={formField}
            label="Indication Name"
            name="indication-name"
            validators={['required']}
            value={indicationName}
            onChange={e => setIndicationName(e.target.value)}
          />
        </ValidatorForm>
      </DialogContent>
      <DialogActions>
        <CuriButton color="primary" variant="outlined" disabled={loading} type="submit" onClick={handleClose}>
          Cancel
        </CuriButton>
        <CuriButton
          color="primary"
          disabled={submitIsDisabled}
          type="submit"
          onClick={() => handleSubmit(indicationName)}
          startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
        >
          Update Name
        </CuriButton>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(() => ({
  formField: {
    width: '100%',
  },
}));

IndicationRenameModal.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default IndicationRenameModal;
