import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Prompt, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import CuriText from 'common/formFields/curiText.component';
import CuriButton from 'common/buttons/curiButton.component';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import UnsavedChangesConfirmDialogComponent from 'common/components/unsavedChangesConfirmDialog.component';

export default function NotesComponent({ previousNotes, isLoading, onSave, isReadonly }) {
  const classes = useStyles();
  const history = useHistory();

  const [notes, setNotes] = useState(previousNotes);

  useEffect(() => {
    setNotes(previousNotes);
  }, [previousNotes]);

  const hasDirtyState = value =>
    // Current value is different than original value...
    value !== previousNotes &&
    // ...unless current value is empty string and original value was null
    !(value === '' && previousNotes === null);

  // Handle unsaved changes
  const [nextRoute, setNextRoute] = useState(null);
  const [unsavedChangesConfirmed, setUnsavedChangesConfirmed] = useState(false);

  const handleUnsavedChanges = useCallback(
    nextLocation => {
      if (unsavedChangesConfirmed) {
        return true;
      }
      setNextRoute(nextLocation);
      return false;
    },
    [unsavedChangesConfirmed]
  );

  const confirmUnsavedChanges = useCallback(() => {
    setNextRoute(null);
    setUnsavedChangesConfirmed(true);
    setTimeout(() => {
      history.push(nextRoute);
    });
  }, [history, nextRoute]);

  const cancelUnsavedChanges = useCallback(() => {
    setNextRoute(null);
    setUnsavedChangesConfirmed(false);
  }, []);

  return (
    <>
      <CuriText
        fullWidth
        hideAdornment
        label=""
        multiline
        rows={(notes || '').split('\n').length + 1}
        value={notes}
        variant="standard"
        onChange={e => setNotes(e.target.value)}
        placeholder={isReadonly ? '' : 'Notes'}
        readOnly={isReadonly}
      />
      {!isReadonly && (
        <>
          <div className={classes.buttonWrapper}>
            <CuriButton
              color="secondary"
              onClick={() => onSave(notes)}
              disabled={isLoading || !hasDirtyState(notes)}
              startIcon={isLoading ? <CircularProgress size={20} /> : <SaveIcon />}
            >
              Save Notes
            </CuriButton>
          </div>
          <Prompt when={hasDirtyState(notes)} message={handleUnsavedChanges} />
          <UnsavedChangesConfirmDialogComponent
            open={!!nextRoute}
            onCancel={cancelUnsavedChanges}
            onSubmit={confirmUnsavedChanges}
          />
        </>
      )}
    </>
  );
}

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 5,
  },
}));

NotesComponent.propTypes = {
  previousNotes: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  isReadonly: PropTypes.bool,
};

NotesComponent.defaultProps = {
  previousNotes: '',
  isReadonly: false,
};
