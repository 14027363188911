import moment from 'moment';

import { SHORT_DATE_PATTERN } from 'common/dates/dates';
import { downloadFile } from 'utilities/downloadFile';

const ROSTER_CSV_HEADERS =
  'First Name,Middle Name/Initial,Last Name,Suffix,Type,Specialty,License No,Practicing State,Practicing County,Limits,NPI,Insured Since Date,Loss Free Date,Internal Use,Internal Use,Internal Use';

export default function downloadRoster(risks, metadata) {
  console.log('downloadRoster', risks, metadata);
  const riskLines = risks.map(risk => {
    return [
      getCsvStringValue(risk.firstName),
      getCsvStringValue(risk.middleName),
      getCsvStringValue(risk.lastName),
      getCsvArrayValue(risk.suffixes),
      getCsvStringValue(risk.classification),
      getCsvSpecialtyValue(risk, metadata.specialties),
      getCsvStringValue(risk.licenseNumber),
      getCsvStringValue(risk.stateCode),
      getCsvCountyValue(risk, metadata.counties),
      getCsvStringValue(risk.limits),
      getCsvNumberValue(risk.npi),
      getCsvDateValue(risk.insuredSinceDate),
      getCsvDateValue(risk.lossFreeDate),
      getCsvStringValue(risk.oasisClientId),
      getCsvNumberValue(risk.oasisNumberId),
      // don't mark as new if they were already created by CPQ
      getCsvBooleanValue(risk.isConfirmedNew && !risk.oasisClientId),
    ].join(',');
  });

  const allLines = [ROSTER_CSV_HEADERS, ...riskLines].join('\r\n');
  console.log(allLines);

  const blob = new Blob([allLines]);
  const url = URL.createObjectURL(blob);
  downloadFile('roster.csv', url);
  URL.revokeObjectURL(url);
}

function getCsvStringValue(initialValue) {
  const value = typeof initialValue === 'string' ? initialValue.replace(/"/g, '\\"') : '';
  return `"${value}"`;
}

function getCsvNumberValue(initialValue) {
  return typeof initialValue === 'number' ? initialValue : '';
}

function getCsvArrayValue(suffixes) {
  const value = Array.isArray(suffixes) ? suffixes.join(',') : '';
  return `"${value}"`;
}

function getCsvDateValue(initialValue) {
  if (initialValue === undefined || initialValue === null) {
    return '';
  }

  const value = moment.utc(initialValue).format(SHORT_DATE_PATTERN);
  return value === 'Invalid date' ? '' : `"${value}"`;
}

function getCsvBooleanValue(initialValue) {
  return typeof initialValue === 'boolean' ? `"${initialValue}"` : '';
}

function getCsvSpecialtyValue(risk, specialtyMetadata) {
  const stateMeta = specialtyMetadata[risk.stateCode];
  if (!Array.isArray(stateMeta)) {
    return '';
  }

  const specialty = stateMeta.find(s => s.code === risk.specialtyCode);
  if (specialty) {
    return `"${specialty.description}"`;
  }

  if (risk.uploadedSpecialty) {
    return `"${risk.uploadedSpecialty}"`;
  }

  return '';
}

function getCsvCountyValue(risk, countyMetadata) {
  const stateMeta = countyMetadata[risk.stateCode];
  if (!Array.isArray(stateMeta)) {
    return '';
  }

  const county = stateMeta.find(s => s.code === risk.countyCode);
  if (county) {
    return `"${county.description}"`;
  }

  if (risk.uploadedCounty) {
    return `"${risk.uploadedCounty}"`;
  }

  return '';
}
