import { CALL_API } from 'middleware/api';

export const GET_DISCUSSION_FAILURE = 'GET_DISCUSSION_FAILURE';
export const GET_DISCUSSION_REQUEST = 'GET_DISCUSSION_REQUEST';
export const GET_DISCUSSION_SUCCESS = 'GET_DISCUSSION_SUCCESS';

export const getDiscussion = () => {
  return {
    [CALL_API]: {
      types: [GET_DISCUSSION_REQUEST, GET_DISCUSSION_SUCCESS, GET_DISCUSSION_FAILURE],
      authenticated: true,
      endpoint: `v1/discussions/token`,
      method: 'GET',
    },
  };
};
