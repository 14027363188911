/* eslint-disable react/jsx-one-expression-per-line */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Dialog,
  DialogTitle,
  Icon,
  Typography,
  DialogContent,
  DialogContentText,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import sanitizeHtml from 'sanitize-html';
import { emailDisplayDate } from './opportunityActivityListEmail.component';

function OpportunityActivityViewEmail({ handleClose, open, email }) {
  const classes = useStyles();

  const displayDate = useMemo(() => email && emailDisplayDate(email.createdDate), [email]);

  const sanitizedHtmlBody = useMemo(() => sanitizeHtml((email && email.htmlBody) || ''), [email]);

  if (!email) return null;

  const { displayFrom, toAddress, ccAddress, subject, textBody } = email;

  return (
    <Dialog fullWidth maxWidth="md" onClose={handleClose} open={open}>
      <DialogTitle disableTypography className={classes.title}>
        <Icon className={classNames('fal fa-envelope-square', classes.icon)} fontSize="large" />
        <Typography variant="h6" color="primary" className={classes.emailSubject}>
          {subject}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.emailBodyHeader}>
          <Typography variant="subtitle2" className={classNames(classes.overflowEllipsis, classes.emailAddresses)}>
            From: {displayFrom}
            <br />
            To: {toAddress}
            {ccAddress ? `, ${ccAddress}` : null}
          </Typography>
          <Typography variant="subtitle2">{displayDate}</Typography>
        </div>
        {sanitizedHtmlBody ? (
          <DialogContentText
            classes={{ root: classes.htmlBody }}
            dangerouslySetInnerHTML={{ __html: sanitizedHtmlBody }}
          />
        ) : (
          <DialogContentText classes={{ root: classes.textBody }}>{textBody}</DialogContentText>
        )}
      </DialogContent>
    </Dialog>
  );
}

const DIALOG_TITLE_INDENTION = '6rem';
const DIALOG_BODY_INDENTION = '9rem';

const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: DIALOG_TITLE_INDENTION,
    marginRight: '0.5rem',
    color: theme.palette.text.secondary,
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    textTransform: 'none',
  },
  closeButton: {
    position: 'absolute',
    right: '1rem',
  },
  dialogContent: {
    minHeight: 400,
  },
  emailSubject: {
    paddingRight: '2rem',
  },
  emailBodyHeader: {
    paddingLeft: DIALOG_BODY_INDENTION,
    marginBottom: '2rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  emailAddresses: {
    width: '75%',
  },
  overflowEllipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  htmlBody: {
    paddingLeft: DIALOG_BODY_INDENTION,
  },
  textBody: {
    paddingLeft: DIALOG_BODY_INDENTION,
    whiteSpace: 'pre-line', // Preserve line breaks
  },
}));

OpportunityActivityViewEmail.propTypes = {
  email: PropTypes.object,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

OpportunityActivityViewEmail.defaultProps = {
  email: null,
};

export default OpportunityActivityViewEmail;
