import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { makeStyles, useTheme, Typography, Icon } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';

import { API_DATE_PATTERN } from 'common/dates/dates';
import { handleToastMessage, TOAST_TYPES } from 'modules/layout/layout.actions';
import {
  createIndividualRisk,
  updateIndicationIndividualRisk,
  updateIndicationIndividualRisks,
  deleteIndicationIndividualRisk,
  deleteIndicationIndividualRisks,
  getIndicationRiskCounties,
  getIndicationRiskSpecialties,
  uploadRosterDryRun,
  uploadRoster,
  getIndicationIndividualRisks,
  verifyIndividualRisks,
  CREATE_INDICATION_INDIVIDUAL_RISK_FAILURE,
  UPDATE_INDICATION_INDIVIDUAL_RISK_FAILURE,
  UPDATE_INDICATION_INDIVIDUAL_RISKS_FAILURE,
  DELETE_INDICATION_INDIVIDUAL_RISK_FAILURE,
  DELETE_INDICATION_INDIVIDUAL_RISKS_FAILURE,
  UPLOAD_ROSTER_DRYRUN_FAILURE,
  VERIFY_INDIVIDUAL_RISKS_FAILURE,
  UPLOAD_ROSTER_SUCCESS,
} from 'modules/indication/indication.actions';
import DataTableAutocompleteSelect from 'common/dataTable/dataTableAutocompleteSelect.component';
import DataTableEditDate from 'common/dataTable/dataTableEditDate.component';
import DataTableDate from 'common/dataTable/dataTableDate.component';
import { prepareRiskForApi } from 'common/dataTable/dataTable.utils';
import IndicationRiskTable, { EmptySelectField } from 'modules/indication/indicationRiskTable.component';
import CuriButton from 'common/buttons/curiButton.component';
import UploadRosterDialog from 'modules/indication/uploadRosterDialog.component';
import { useAuthz } from 'okta/authz';
import Permissions from 'okta/permissions';
import IndicationRiskClassification from 'modules/indication/indicationRiskClassification';
import { keyBy } from 'lodash';
import OasisPartyIconButton from 'modules/indication/oasisPartyIconButton.component';
import { selectSuffixes, useSelectedOpportunityIsReadOnly } from 'modules/opportunities/opportunities.selectors';
import {
  useIndicationIsInOasis,
  selectKeyedRisks,
  selectRiskSpecialties,
  selectRiskCounties,
} from 'modules/indication/indication.selectors';
import PropagateRiskDataDialog from 'modules/indication/propagateRiskDataDialog.component';
import formatIndividualRiskName from 'utilities/formatIndividualRiskName';
import downloadRoster from './downloadRoster';

export const allNumbersRE = /^\d+$/;

function renderSuffixes(suffixOptions, suffixes = []) {
  const displaySuffixes = suffixes.map(suffix => {
    const suffixById = suffixOptions.find(o => o.id === suffix);
    return suffixById ? suffixById.description : suffix;
  });
  return <div>{displaySuffixes.join(', ')}</div>;
}

function IndicationRisksIndividualTableContainer({
  isLoadingIndicationRiskIndividuals,
  indicationRiskIndividuals,
  isDeletingRiskIndividuals,
  isLoadingRiskCounties,
  getIndicationRiskCounties: getCounties,
  getIndicationRiskSpecialties: getSpecialties,
  isLoadingIndicationStates,
  isLoadingRiskSpecialties,
  isLoadingIndicationRisksDryRun,
  indicationRisksDryRun,
  handleToastMessage: handleToast,
  createIndividualRisk: createRisk,
  updateIndicationIndividualRisk: updateRisk,
  updateIndicationIndividualRisks: updateRisks,
  deleteIndicationIndividualRisk: deleteRisk,
  deleteIndicationIndividualRisks: deleteRisks,
  uploadRosterDryRun: uploadDryRun,
  uploadRoster: uploadRosterCsv,
  getIndicationIndividualRisks: getRisks,
  verifyIndividualRisks: verifyOasisIndividualRisks,
  indicationStatesOptions,
  isValidStateCode,
  countiesAlreadyFetched,
  specialtiesAlreadyFetched,
  riskSpecialtyFilterOptions,
  getRiskSpecialtyOptions,
  riskSpecialties,
  getSpecialtyName,
  riskCountyFilterOptions,
  getRiskCountyOptions,
  getCountyName,
  indication,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { userHas } = useAuthz();
  const indicationIsInOasis = useIndicationIsInOasis();
  const metadataSuffixes = useSelector(selectSuffixes);
  const metadataSpecialties = useSelector(selectRiskSpecialties);
  const metadataCounties = useSelector(selectRiskCounties);
  const keyedRisks = useSelector(selectKeyedRisks);
  const isReadonly = useSelectedOpportunityIsReadOnly();
  const disableUpdate = useMemo(() => indicationIsInOasis || isReadonly, [indicationIsInOasis, isReadonly]);

  const isLoading =
    isLoadingIndicationRiskIndividuals ||
    isLoadingIndicationStates ||
    isLoadingRiskSpecialties ||
    isLoadingRiskCounties;

  const suffixOptions = useMemo(() => metadataSuffixes.map(s => ({ id: s.code, description: s.description })), [
    metadataSuffixes,
  ]);

  // toggled `true` when `add risk` input attempted
  const [globalBlur, setGlobalBlur] = useState(false);

  const individualRiskClassificationOptions = useMemo(
    () =>
      Object.values(IndicationRiskClassification).filter(c => c.id !== IndicationRiskClassification.MedicalGroup.id),
    []
  );

  const keyedClassifications = useMemo(() => keyBy(IndicationRiskClassification, c => c.id), []);

  const getClassificationName = useCallback(
    id => (keyedClassifications[id] ? keyedClassifications[id].description : ''),
    [keyedClassifications]
  );

  const [verifyOasisParty, setVerifyOasisParty] = useState(null);

  const { opportunityId, indicationId } = useParams();

  // Remember the last `Specialty` display name in the event the `Practicing State` value changes,
  // which otherwise causes the mismatched specialty code (ex: `138`) to be displayed.
  const [lastSpecialtyDisplayValue, setLastSpecialtyDisplayValue] = useState('');

  const [lastStateCode, setLastStateCode] = useState(null);

  /* eslint-disable react/prop-types */
  const columns = useMemo(
    () => [
      {
        field: 'oasisClientId',
        headerStyle: { padding: theme.spacing(1), paddingRight: 0 },
        width: 25,
        cellStyle: { paddingRight: 0 },
        filtering: false,
        sorting: false,
        title: 'Oasis Client ID',
        render: rowData => (
          <OasisPartyIconButton indicationRisk={rowData} handleClick={() => setVerifyOasisParty(rowData)} />
        ),
        editComponent: ({ rowData }) => <OasisPartyIconButton disabled indicationRisk={rowData} />,
      },
      {
        field: 'firstName',
        headerStyle: { padding: theme.spacing(1) },
        title: 'First Name',
        required: true,
      },
      {
        field: 'middleName',
        headerStyle: { padding: theme.spacing(1) },
        title: 'Middle Name/Initial',
      },
      {
        field: 'lastName',
        headerStyle: { padding: theme.spacing(1) },
        title: 'Last Name',
        required: true,
      },
      {
        field: 'suffixes',
        headerStyle: { padding: theme.spacing(1) },
        title: 'Suffix',
        filtering: false,
        render: ({ suffixes }) => {
          return renderSuffixes(suffixOptions, suffixes);
        },
        editComponent: ({ onChange, columnDef, value }) => {
          const suffixes = (value || []).map(suffix => {
            const suffixById = suffixOptions.find(o => o.id === suffix);
            return suffixById ? suffixById.description : suffix;
          });
          return (
            <DataTableAutocompleteSelect
              multiple
              inputMinWidth={80}
              value={suffixes}
              placeholder={columnDef.title}
              loading={isLoadingIndicationStates}
              globalBlur={globalBlur}
              options={suffixOptions}
              onChange={vals => {
                const ids = vals.map(val => suffixOptions.find(o => o.description === val).id);
                onChange(ids);
              }}
            />
          );
        },
      },
      {
        field: 'classification',
        headerStyle: { padding: theme.spacing(1) },
        title: 'Type',
        render: ({ classification }) => {
          const classificationName = getClassificationName(classification);
          const invalidClassification =
            !classification || !Object.values(IndicationRiskClassification).some(c => c.id === classification);
          return (
            <div className={classNames({ [classes.textRed]: invalidClassification })}>
              {classificationName || 'Unknown Classification'}
            </div>
          );
        },
        filterComponent: ({ onFilterChanged, columnDef }) => (
          <DataTableAutocompleteSelect
            isFilter
            inputMinWidth={80}
            value={columnDef.tableData.filterValue}
            loading={isLoadingRiskSpecialties}
            options={individualRiskClassificationOptions}
            onChange={val => {
              const riskClassification = Object.values(IndicationRiskClassification).find(o => o.description === val);
              return onFilterChanged(columnDef.tableData.id, riskClassification ? riskClassification.id : '');
            }}
          />
        ),
        editComponent: ({ onChange, columnDef, value }) => (
          <DataTableAutocompleteSelect
            required
            value={getClassificationName(value) || value}
            placeholder={columnDef.title}
            globalBlur={globalBlur}
            options={individualRiskClassificationOptions}
            onChange={onChange}
          />
        ),
        initialEditValue: IndicationRiskClassification.Physician.id,
      },
      {
        field: 'specialtyCode',
        headerStyle: { padding: theme.spacing(1) },
        title: 'Specialty',
        render: rowData => {
          const { specialtyCode, stateCode, uploadedSpecialty } = rowData;
          const specialtyName = getSpecialtyName(stateCode, specialtyCode);
          const invalidSpecialty = !isLoadingRiskSpecialties && !specialtyName;
          const isLoadingData =
            isLoadingIndicationRiskIndividuals || isLoadingIndicationStates || isLoadingRiskSpecialties;
          const displayValue = isLoadingData ? '' : specialtyName || uploadedSpecialty || 'Unknown Specialty';
          return <div className={classNames({ [classes.textRed]: invalidSpecialty })}>{displayValue}</div>;
        },
        filterComponent: ({ onFilterChanged, columnDef }) => (
          <DataTableAutocompleteSelect
            isFilter
            inputMinWidth={140}
            value={columnDef.tableData.filterValue}
            loading={isLoadingRiskSpecialties}
            options={riskSpecialtyFilterOptions}
            onChange={val => {
              const riskSpecialty = riskSpecialtyFilterOptions.find(s => s.description === val);
              return onFilterChanged(columnDef.tableData.id, riskSpecialty ? riskSpecialty.id : '');
            }}
          />
        ),
        editComponent: ({ onChange, columnDef, value, rowData }) => {
          const { stateCode, classification } = rowData;
          // `classification` could be id or string, e.g. 'PHYSICIAN' or 'Physician'
          const classificationByName = Object.values(IndicationRiskClassification).find(
            c => c.description === classification
          );

          // Default to Physician in the case of `add` risk row
          let classificationById = IndicationRiskClassification.Physician.id;
          if (classification && keyedClassifications[classification]) {
            classificationById = classification;
          } else if (classificationByName) {
            classificationById = classificationByName.id;
          }

          const riskSpecialtyOptions = getRiskSpecialtyOptions(stateCode, classificationById);
          const specialty = riskSpecialtyOptions.find(s => s.id === value);
          // `value` could be a `specialtyCode` or a text string (e.g. '682824' or 'Heart Doctor')
          let displayValue = specialty ? specialty.description : value;
          // If a user changes the `Practicing State` when a specialty is already set, that specialty may not be found in that
          // state's specialty list (obviously). That has a side effect of displaying the raw specialty code in the input field.
          // The below if/else will make sure we display the last known specialty by its friendly display name so we never
          // show what appears to be random numbers to the user in the `Specialty` field.
          if (allNumbersRE.test(displayValue)) {
            displayValue = lastSpecialtyDisplayValue;
          } else {
            // Must allow time for component to render before setting state
            setTimeout(() => {
              setLastSpecialtyDisplayValue(displayValue);
            });
          }
          return (
            <DataTableAutocompleteSelect
              required
              value={displayValue}
              placeholder={columnDef.title}
              loading={isLoadingRiskSpecialties}
              globalBlur={globalBlur}
              options={riskSpecialtyOptions}
              onChange={val => {
                const riskSpecialty = riskSpecialtyOptions.find(s => s.description === val);
                onChange(riskSpecialty ? riskSpecialty.id : '');
              }}
            />
          );
        },
      },
      {
        field: 'licenseNumber',
        headerStyle: { padding: theme.spacing(1) },
        title: 'License No',
      },
      {
        field: 'stateCode',
        headerStyle: { padding: theme.spacing(1) },
        title: 'Practicing State',
        render: rowData => {
          const { stateCode } = rowData;
          const invalidStateCode = !isLoadingIndicationStates && !isValidStateCode(stateCode);
          return (
            <div className={classNames({ [classes.textRed]: invalidStateCode })}>
              {isLoadingIndicationRiskIndividuals || isLoadingIndicationStates ? '' : stateCode || 'Unknown State'}
            </div>
          );
        },
        filterComponent: ({ onFilterChanged, columnDef }) => (
          <DataTableAutocompleteSelect
            isFilter
            inputMinWidth={60}
            value={columnDef.tableData.filterValue}
            loading={isLoadingIndicationStates}
            options={indicationStatesOptions}
            onChange={val => onFilterChanged(columnDef.tableData.id, val)}
          />
        ),
        editComponent: ({ onChange, columnDef, value }) => (
          <DataTableAutocompleteSelect
            required
            value={value}
            placeholder={columnDef.title}
            loading={isLoadingIndicationStates}
            globalBlur={globalBlur}
            options={indicationStatesOptions}
            onChange={stateCode => {
              if (isValidStateCode(stateCode) && !countiesAlreadyFetched(stateCode)) {
                getCounties([stateCode]);
              }
              if (isValidStateCode(stateCode) && !specialtiesAlreadyFetched(stateCode)) {
                getSpecialties([stateCode], indication.issueCompanyId, indication.policyTypeCode, indication.id);
              }
              onChange(stateCode);
            }}
          />
        ),
        initialEditValue: indication ? indication.stateCode : '',
      },
      {
        field: 'countyCode',
        headerStyle: { padding: theme.spacing(1) },
        title: 'Practicing County',
        render: rowData => {
          const { countyCode, stateCode, uploadedCounty } = rowData;
          const countyName = getCountyName(stateCode, countyCode);
          const invalidCounty = !isLoadingRiskCounties && !countyName;
          const isLoadingData =
            isLoadingIndicationRiskIndividuals || isLoadingIndicationStates || isLoadingRiskCounties;
          const displayValue = isLoadingData ? '' : countyName || uploadedCounty || '';
          return <div className={classNames({ [classes.textRed]: invalidCounty })}>{displayValue}</div>;
        },
        filterComponent: ({ onFilterChanged, columnDef }) => (
          <DataTableAutocompleteSelect
            isFilter
            inputMinWidth={120}
            value={columnDef.tableData.filterValue}
            loading={isLoadingRiskCounties}
            options={riskCountyFilterOptions}
            onChange={val => {
              const riskCounty = riskCountyFilterOptions.find(c => c.description === val);
              return onFilterChanged(columnDef.tableData.id, riskCounty ? riskCounty.code : '');
            }}
          />
        ),
        editComponent: ({ onChange, columnDef, value, rowData: { stateCode } }) => {
          const riskCountyOptions = getRiskCountyOptions(stateCode);
          const county = riskCountyOptions.find(c => c.id === value);
          // `value` could be a `countyCode` or text string ('3292' or 'Baltimore')
          const displayValue = county ? county.description : EmptySelectField.description;

          // Anytime a practicing state is changed or a practicing county cannot be found in that state,
          // we should set the practicing county to `N/A`
          if (stateCode !== lastStateCode && lastStateCode === null) {
            setTimeout(() => {
              setLastStateCode(stateCode);
            });
          } else if ((!value && displayValue === EmptySelectField.description) || stateCode !== lastStateCode) {
            // Must allow time for component to render before setting state
            setTimeout(() => {
              setLastStateCode(stateCode);
              // Must allow time for state to be set before manually calling `onChange`
              setTimeout(() => {
                onChange(EmptySelectField.id);
              });
            });
          }

          return (
            <DataTableAutocompleteSelect
              value={displayValue}
              placeholder={columnDef.title}
              loading={isLoadingRiskCounties}
              globalBlur={globalBlur}
              options={riskCountyOptions}
              onChange={onChange}
              required={value && !county}
            />
          );
        },
        initialEditValue: indication ? indication.policyHolderAddressCountyCode : EmptySelectField.description,
      },
      {
        field: 'insuredSinceDate',
        headerStyle: { padding: theme.spacing(1) },
        title: 'Insured Since',
        render: ({ insuredSinceDate }) => <DataTableDate value={insuredSinceDate} />,
        editComponent: ({ onChange, columnDef, value, rowData }) => (
          <DataTableEditDate
            value={value}
            placeholder={columnDef.title}
            loading={isLoadingIndicationStates}
            globalBlur={globalBlur}
            onChange={onChange}
            readOnly={Boolean(rowData.oasisClientId)}
            readOnlyTooltip="Value pulled from Oasis for matched risk, cannot be edited here"
          />
        ),
        initialEditValue: indication ? indication.contractStartDate : '',
        filtering: false,
      },
      {
        field: 'lossFreeDate',
        headerStyle: { padding: theme.spacing(1) },
        title: 'Loss Free',
        render: ({ lossFreeDate }) => <DataTableDate value={lossFreeDate} />,
        editComponent: ({ onChange, columnDef, value, rowData }) => (
          <DataTableEditDate
            value={value}
            placeholder={columnDef.title}
            loading={isLoadingIndicationStates}
            globalBlur={globalBlur}
            onChange={onChange}
            readOnly={Boolean(rowData.oasisClientId)}
            readOnlyTooltip="Value pulled from Oasis for matched risk, cannot be edited here"
          />
        ),
        initialEditValue: indication ? indication.contractStartDate : '',
        filtering: false,
      },
      {
        field: 'limits',
        headerStyle: { padding: theme.spacing(1) },
        title: 'Limits',
      },
    ],
    [
      classes.textRed,
      globalBlur,
      individualRiskClassificationOptions,
      getClassificationName,
      isLoadingRiskCounties,
      isLoadingRiskSpecialties,
      isLoadingIndicationStates,
      indicationStatesOptions,
      isValidStateCode,
      countiesAlreadyFetched,
      specialtiesAlreadyFetched,
      keyedClassifications,
      getCounties,
      getSpecialties,
      getCountyName,
      isLoadingIndicationRiskIndividuals,
      riskCountyFilterOptions,
      riskSpecialtyFilterOptions,
      getRiskCountyOptions,
      lastStateCode,
      getSpecialtyName,
      getRiskSpecialtyOptions,
      theme,
      indication,
      suffixOptions,
      lastSpecialtyDisplayValue,
    ]
  );
  /* eslint-enable react/prop-types */

  const riskIsInvalid = useCallback(
    risk => {
      const { firstName, lastName, classification, specialtyCode, countyCode, stateCode } = risk;
      const classificationIsInvalid = !keyedClassifications[classification];
      const specialtyCodeIsInvalid =
        specialtyCode && !getRiskSpecialtyOptions(stateCode, classification).some(s => s.id === specialtyCode);
      const countyIsInvalid =
        countyCode &&
        !getRiskCountyOptions(stateCode).some(c => c.id === countyCode) &&
        countyCode !== EmptySelectField.id;
      const stateCodeIsInvalid = !isValidStateCode(stateCode);
      return (
        !firstName ||
        !lastName ||
        classificationIsInvalid ||
        !specialtyCode ||
        specialtyCodeIsInvalid ||
        countyIsInvalid ||
        !stateCode ||
        stateCodeIsInvalid
      );
    },
    [getRiskCountyOptions, isValidStateCode, getRiskSpecialtyOptions, keyedClassifications]
  );

  const getRiskValidationErrorMessage = useCallback(
    risk => {
      const { firstName, lastName, classification, specialtyCode, stateCode, countyCode } = risk;
      const errorFields = [];

      const specialtyCodeIsInvalid =
        specialtyCode && !getRiskSpecialtyOptions(stateCode, classification).some(s => s.id === specialtyCode);
      const riskCountyOptions = getRiskCountyOptions(stateCode);
      const countyCodeCleared = countyCode === EmptySelectField.id;

      if (!firstName) errorFields.push('"First Name"');
      if (!lastName) errorFields.push('"Last Name"');
      if (!classification) errorFields.push('"Type"');
      if (!specialtyCode || specialtyCodeIsInvalid) errorFields.push('"Specialty"');
      if (!stateCode) errorFields.push('"Praticing State"');
      if (countyCode && !riskCountyOptions.find(c => c.id === countyCode) && !countyCodeCleared) {
        errorFields.push('"Practicing County"');
      }

      // Trigger red borders on input fields
      if (errorFields.length) setGlobalBlur(true);

      return errorFields.length
        ? `Invalid field${errorFields.length > 1 ? 's' : ''}: ${errorFields.join(', ')}.`
        : 'Risk data is incomplete.';
    },
    [getRiskSpecialtyOptions, getRiskCountyOptions]
  );

  const normalizeRisk = useCallback(
    newRisk => {
      try {
        const {
          classification,
          stateCode,
          countyCode,
          specialtyCode,
          uploadedSpecialty,
          uploadedCounty,
          suffixes,
          insuredSinceDate = '', // may not be set if from Oasis
          lossFreeDate = '', // may not be set if from Oasis
          ...restProps
        } = newRisk;

        // `classification` could be id or string, e.g. 'PHYSICIAN' or 'Physician'
        const classificationByName = individualRiskClassificationOptions.find(c => c.description === classification);

        // `countyCode` could be code or string, e.g. '3292' or `Baltimore`
        const riskCountyOptions = getRiskCountyOptions(stateCode);
        const countyByName = riskCountyOptions.find(c => c.description === countyCode);
        const countyCodeCleared = countyCode === EmptySelectField.id || countyCode === EmptySelectField.description;

        // `specialtyCode` could be code or string, e.g. '682651' or `Pain Management`
        const riskSpecialtyOptions = getRiskSpecialtyOptions(stateCode, classification);
        const specialtyByName = riskSpecialtyOptions.find(s => s.description === specialtyCode);

        // Remove `Z` so `moment` correctly parses as local date
        const formattedInsuredSinceDate = moment(insuredSinceDate.replace(/Z$/, '')).format(API_DATE_PATTERN);
        const formattedLossFreeDate = moment(lossFreeDate.replace(/Z$/, '')).format(API_DATE_PATTERN);

        return prepareRiskForApi({
          ...restProps,
          classification: classificationByName ? classificationByName.id : classification,
          stateCode: stateCode || indication.stateCode,
          // eslint-disable-next-line no-nested-ternary
          countyCode: countyCodeCleared ? null : countyByName ? countyByName.id : countyCode,
          uploadedCounty: countyCodeCleared ? null : uploadedCounty,
          specialtyCode: specialtyByName ? specialtyByName.id : specialtyCode,
          suffixes: suffixes === EmptySelectField.id ? null : suffixes,
          insuredSinceDate: formattedInsuredSinceDate === 'Invalid date' ? null : formattedInsuredSinceDate,
          lossFreeDate: formattedLossFreeDate === 'Invalid date' ? null : formattedLossFreeDate,
        });
      } catch (err) {
        console.error('failed to normalize risk', newRisk, err);
        throw err;
      }
    },
    [getRiskCountyOptions, getRiskSpecialtyOptions, individualRiskClassificationOptions, indication]
  );

  const handleAddRisk = useCallback(
    async newRisk => {
      if (disableUpdate) return;
      const normalizedRisk = normalizeRisk(newRisk);
      if (riskIsInvalid(normalizedRisk)) {
        handleToast(`${getRiskValidationErrorMessage(normalizedRisk)} Please try again.`, TOAST_TYPES.ERROR);
        // Must throw error to `reject()` promise and keep row in edit mode.
        throw new Error('User input incomplete data');
      } else {
        const response = await createRisk(opportunityId, indicationId, normalizedRisk);
        if (response.type === CREATE_INDICATION_INDIVIDUAL_RISK_FAILURE) {
          handleToast('Unable to create risk. Please try again.', TOAST_TYPES.ERROR);
        }
      }
    },
    [
      createRisk,
      handleToast,
      indicationId,
      opportunityId,
      riskIsInvalid,
      normalizeRisk,
      getRiskValidationErrorMessage,
      disableUpdate,
    ]
  );

  const [propagateRiskDataDialog, setPropagateRiskDataDialog] = useState(null);

  const handlePropagateRiskData = useCallback(async () => {
    const response = await updateRisks(opportunityId, indicationId, propagateRiskDataDialog.risksToPropagate);
    if (response.type === UPDATE_INDICATION_INDIVIDUAL_RISKS_FAILURE) {
      handleToast('Unable to propagate risk specialties.', TOAST_TYPES.ERROR);
    } else {
      handleToast('Risk specialties propagated.', TOAST_TYPES.SUCCESS);
    }
    setPropagateRiskDataDialog(null);
  }, [updateRisks, handleToast, opportunityId, propagateRiskDataDialog, indicationId]);

  const handleGetPropagateRiskData = useCallback(
    (previousRisk, updatedRisk) => {
      const risksToPropagate = [];
      const { specialtyCode, uploadedSpecialty } = previousRisk;
      if (specialtyCode || !uploadedSpecialty) return risksToPropagate;
      const remainingRisks = indicationRiskIndividuals.filter(r => r.id !== updatedRisk.id);
      remainingRisks.forEach(r => {
        const riskStateOffersSpecialty =
          r.stateCode &&
          riskSpecialties[r.indicationId] &&
          riskSpecialties[r.indicationId][r.stateCode] &&
          riskSpecialties[r.indicationId][r.stateCode].find(s => s.code === updatedRisk.specialtyCode);
        if (!r.specialtyCode && r.uploadedSpecialty === uploadedSpecialty && riskStateOffersSpecialty) {
          risksToPropagate.push({ ...r, specialtyCode: updatedRisk.specialtyCode });
        }
      });
      return risksToPropagate;
    },
    [indicationRiskIndividuals, riskSpecialties]
  );

  const handleUpdateRisk = useCallback(
    async updatedRisk => {
      if (disableUpdate) return;

      const previousRisk = keyedRisks[updatedRisk.id];
      const normalizedRisk = normalizeRisk(updatedRisk);
      if (riskIsInvalid(normalizedRisk)) {
        handleToast(`${getRiskValidationErrorMessage(normalizedRisk)} Please try again.`, TOAST_TYPES.ERROR);
        // Must throw error to `reject()` promise and keep row in edit mode.
        throw new Error('User input incomplete data');
      } else {
        const response = await updateRisk(opportunityId, indicationId, updatedRisk.id, normalizedRisk);
        if (response.type === UPDATE_INDICATION_INDIVIDUAL_RISK_FAILURE) {
          handleToast('Unable to update risk. Please try again.', TOAST_TYPES.ERROR);
          return;
        }
        // When a risk is updated, if a 'specialty' was previously invalid but is now valid,
        // we should prompt the user for whether they'd like to propagate that change to other risks
        // of the same previously-invalid name.
        const risksToPropagate = handleGetPropagateRiskData(previousRisk, normalizedRisk);
        if (risksToPropagate.length) {
          const { lastName, firstName, middleName, suffixes, stateCode, specialtyCode } = normalizedRisk;
          const updatedRiskName = formatIndividualRiskName(
            {
              lastName,
              firstName,
              middleName,
              suffixes,
            },
            suffixOptions
          );
          setPropagateRiskDataDialog({
            updatedRiskName,
            oldSpecialty: previousRisk.uploadedSpecialty,
            newSpecialty: getSpecialtyName(stateCode, specialtyCode),
            risksToPropagate,
          });
        } else {
          handleToast('Risk updated.', TOAST_TYPES.SUCCESS);
        }
      }
    },
    [
      updateRisk,
      handleToast,
      indicationId,
      opportunityId,
      riskIsInvalid,
      normalizeRisk,
      getRiskValidationErrorMessage,
      disableUpdate,
      handleGetPropagateRiskData,
      keyedRisks,
      getSpecialtyName,
      suffixOptions,
    ]
  );

  const handleDeleteRisk = useCallback(
    async deletedRisk => {
      if (disableUpdate) return;
      const response = await deleteRisk(opportunityId, indicationId, deletedRisk.id);
      if (response.type === DELETE_INDICATION_INDIVIDUAL_RISK_FAILURE) {
        handleToast('Unable to delete risk. Please try again later.', TOAST_TYPES.ERROR);
      }
    },
    [deleteRisk, handleToast, indicationId, opportunityId, disableUpdate]
  );

  const handleDeleteRisks = useCallback(async () => {
    if (disableUpdate) return;
    const response = await deleteRisks(opportunityId, indicationId);
    if (response.type === DELETE_INDICATION_INDIVIDUAL_RISKS_FAILURE) {
      handleToast('Unable to delete risks. Please try again later.', TOAST_TYPES.ERROR);
    }
  }, [deleteRisks, handleToast, opportunityId, indicationId, disableUpdate]);

  const [uploadCsvModalOpen, setUploadCsvModalOpen] = useState(false);

  const [csvFile, setCsvFile] = useState(null);

  const handleFileUploadDryRun = useCallback(
    async csv => {
      if (disableUpdate) return;
      const formData = new FormData();
      formData.append('roster', csv);
      const response = await uploadDryRun(opportunityId, indicationId, formData);
      if (response.type === UPLOAD_ROSTER_DRYRUN_FAILURE) {
        handleToast('Unable to upload risks. Please try again.', TOAST_TYPES.ERROR);
      }
    },
    [uploadDryRun, handleToast, indicationId, opportunityId, disableUpdate]
  );

  const handleFileUploadAppend = useCallback(
    async csv => {
      if (disableUpdate) return;
      const formData = new FormData();
      formData.append('roster', csv);
      const response = await uploadRosterCsv(opportunityId, indicationId, formData, 'append');
      setUploadCsvModalOpen(false);
      if (response.type === UPLOAD_ROSTER_SUCCESS) {
        setCsvFile(csv);
        getRisks(opportunityId, indicationId);
      } else {
        setCsvFile(null);
        // Handle specific case where there was an issue with OnBase
        if (response.status === 415) {
          handleToast('Unable to upload roster csv to OnBase. Please try again.', TOAST_TYPES.ERROR);
        } else {
          handleToast('Unable to append risks. Please try again.', TOAST_TYPES.ERROR);
        }
      }
    },
    [getRisks, handleToast, indicationId, opportunityId, uploadRosterCsv, disableUpdate]
  );

  const handleFileUploadReplace = useCallback(
    async csv => {
      if (disableUpdate) return;
      const formData = new FormData();
      formData.append('roster', csv);
      const response = await uploadRosterCsv(opportunityId, indicationId, formData, 'overwrite');
      setUploadCsvModalOpen(false);
      if (response.type === UPLOAD_ROSTER_SUCCESS) {
        setCsvFile(csv);
        getRisks(opportunityId, indicationId);
      } else {
        setCsvFile(null);
        // Handle specific case where there was an issue with OnBase
        if (response.status === 415) {
          handleToast('Unable to upload roster csv to OnBase. Please try again.', TOAST_TYPES.ERROR);
        } else {
          handleToast('Unable to replace risks. Please try again.', TOAST_TYPES.ERROR);
        }
      }
    },
    [getRisks, handleToast, indicationId, opportunityId, uploadRosterCsv, disableUpdate]
  );

  const uploadedFileName = (
    <div className={classes.fileName}>
      <Icon classes={{ root: classes.icon }} className={classNames('fas fa-file-excel')} />
      <Typography noWrap>{`${csvFile && csvFile.name}`}</Typography>
      <Typography>&nbsp;loaded</Typography>
    </div>
  );

  const [isLoadingOasisParties, setIsLoadingOasisParties] = useState(false);

  const handleVerifyOasisParties = useCallback(async () => {
    if (disableUpdate) return;
    setIsLoadingOasisParties(true);
    const response = await verifyOasisIndividualRisks(opportunityId, indicationId);
    if (response.type === VERIFY_INDIVIDUAL_RISKS_FAILURE) {
      handleToast('Unable to verify all Oasis parties. Please try again.', TOAST_TYPES.ERROR);
      // refresh risks
      await getRisks(opportunityId, indicationId);
    }
    setIsLoadingOasisParties(false);
  }, [verifyOasisIndividualRisks, handleToast, opportunityId, indicationId, disableUpdate, getRisks]);

  const handleClearOasisId = useCallback(async () => {
    if (disableUpdate) return;
    const response = await updateRisk(opportunityId, indicationId, verifyOasisParty.id, {
      oasisClientId: null,
      oasisNumberId: null,
    });
    if (response.type === UPDATE_INDICATION_INDIVIDUAL_RISK_FAILURE) {
      handleToast('Unable to clear Oasis party ID. Please try again.', TOAST_TYPES.ERROR);
    }
    setVerifyOasisParty(null);
  }, [updateRisk, handleToast, opportunityId, indicationId, verifyOasisParty, disableUpdate]);

  const handleConfirmNewOasisParty = useCallback(async () => {
    if (disableUpdate) return;
    const response = await updateRisk(opportunityId, indicationId, verifyOasisParty.id, {
      isConfirmedNew: true,
    });
    if (response.type === UPDATE_INDICATION_INDIVIDUAL_RISK_FAILURE) {
      handleToast('Unable to clear Oasis party ID. Please try again.', TOAST_TYPES.ERROR);
    }
    setVerifyOasisParty(null);
  }, [handleToast, opportunityId, indicationId, updateRisk, verifyOasisParty, disableUpdate]);

  const handleSelectOasisParty = useCallback(
    async ({ clientId, oasisNumberId }) => {
      if (disableUpdate) return;
      const response = await updateRisk(opportunityId, indicationId, verifyOasisParty.id, {
        oasisClientId: clientId,
        oasisNumberId,
      });
      if (response.type === UPDATE_INDICATION_INDIVIDUAL_RISK_FAILURE) {
        handleToast('Unable to set Oasis party ID. Please try again.', TOAST_TYPES.ERROR);
      }
      setVerifyOasisParty(null);
    },
    [updateRisk, handleToast, opportunityId, indicationId, verifyOasisParty, disableUpdate]
  );

  const getPropagateRiskDataDialogProps = useCallback(() => {
    if (propagateRiskDataDialog) {
      return {
        updatedRiskName: propagateRiskDataDialog.updatedRiskName,
        oldSpecialty: propagateRiskDataDialog.oldSpecialty,
        newSpecialty: propagateRiskDataDialog.newSpecialty,
        risksToPropagate: propagateRiskDataDialog.risksToPropagate,
      };
    }

    return {
      updatedRiskName: '',
      oldSpecialty: '',
      newSpecialty: '',
      risksToPropagate: [],
    };
  }, [propagateRiskDataDialog]);

  const disableDownloadRoster =
    isLoading || indicationRiskIndividuals.length === 0 || isLoadingRiskCounties || isLoadingRiskSpecialties;

  const handleDownloadRoster = useCallback(() => {
    if (!indicationId || disableDownloadRoster || !metadataSpecialties || !metadataCounties) {
      return;
    }

    downloadRoster(indicationRiskIndividuals, {
      specialties: metadataSpecialties[indicationId],
      counties: metadataCounties,
    });
  }, [disableDownloadRoster, indicationRiskIndividuals, metadataCounties, metadataSpecialties, indicationId]);

  return (
    <>
      <IndicationRiskTable
        columns={columns}
        risks={indicationRiskIndividuals}
        isLoading={isLoading}
        isDeletingRisks={isDeletingRiskIndividuals}
        isLoadingOasisParties={isLoadingOasisParties}
        handleAddRisk={handleAddRisk}
        handleUpdateRisk={handleUpdateRisk}
        handleDeleteRisk={handleDeleteRisk}
        handleDeleteRisks={handleDeleteRisks}
        globalBlur={globalBlur}
        setGlobalBlur={setGlobalBlur}
        showVerifyOasisPartiesModal={Boolean(verifyOasisParty)}
        verifyOasisParty={verifyOasisParty}
        handleCloseOasisModal={() => setVerifyOasisParty(null)}
        handleClearOasisId={handleClearOasisId}
        handleSelectOasisParty={handleSelectOasisParty}
        handleVerifyOasisParties={handleVerifyOasisParties}
        handleConfirmNewOasisParty={handleConfirmNewOasisParty}
        canAdd={!disableUpdate && userHas(Permissions.CREATE_INDIVIDUAL_RISK)}
        canEdit={!disableUpdate && userHas(Permissions.UPDATE_INDIVIDUAL_RISK)}
        canDelete={!disableUpdate && userHas(Permissions.DELETE_INDIVIDUAL_RISK)}
        canDeleteAll={!disableUpdate && userHas(Permissions.DELETE_INDIVIDUAL_RISKS)}
        uploadedFileName={csvFile ? uploadedFileName : ''}
        riskIsInvalid={riskIsInvalid}
      >
        <UploadRosterDialog
          isLoadingIndicationRisksDryRun={isLoadingIndicationRisksDryRun}
          indicationRisksDryRun={indicationRisksDryRun}
          isLoadingIndicationRiskIndividuals={isLoadingIndicationRiskIndividuals}
          indicationRiskIndividuals={indicationRiskIndividuals}
          open={uploadCsvModalOpen}
          handleCancel={() => setUploadCsvModalOpen(false)}
          handleAppend={handleFileUploadAppend}
          handleReplace={handleFileUploadReplace}
          handleUpload={handleFileUploadDryRun}
        />
        {userHas(Permissions.CREATE_INDIVIDUAL_RISKS) && (
          <CuriButton
            // className={classes.uploadRoster}
            disabled={isLoading || disableUpdate}
            color="secondary"
            onClick={() => setUploadCsvModalOpen(true)}
          >
            Upload New Roster
          </CuriButton>
        )}
      </IndicationRiskTable>
      <br />
      <CuriButton disabled={disableDownloadRoster} color="secondary" onClick={handleDownloadRoster}>
        Download Roster
      </CuriButton>
      <PropagateRiskDataDialog
        {...getPropagateRiskDataDialogProps()}
        suffixes={metadataSuffixes}
        open={Boolean(propagateRiskDataDialog)}
        handleCancel={() => setPropagateRiskDataDialog(null)}
        handleConfirm={handlePropagateRiskData}
        isLoading={isLoadingIndicationRiskIndividuals}
      />
    </>
  );
}

const useStyles = makeStyles(theme => ({
  textRed: {
    color: theme.palette.error.main,
  },
  fileName: {
    display: 'flex',
    alignItems: 'center',
    width: '35%',
  },
  flexGrow: {
    flex: '1 1 auto',
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: '2.25rem',
    marginRight: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
  },
  uploadRoster: {
    marginRight: theme.spacing(2),
  },
}));

const mapStateToProps = state => {
  const {
    isLoadingIndicationRiskIndividuals,
    indicationRiskIndividuals,
    isDeletingRiskIndividuals,
    isLoadingIndicationStates,
    isLoadingRiskCounties,
    riskCounties,
    isLoadingRiskSpecialties,
    isLoadingIndicationRisksDryRun,
    indicationRisksDryRun,
  } = state.indication;

  return {
    isLoadingIndicationRiskIndividuals,
    indicationRiskIndividuals,
    isDeletingRiskIndividuals,
    isLoadingIndicationStates,
    isLoadingRiskCounties,
    riskCounties,
    isLoadingRiskSpecialties,
    isLoadingIndicationRisksDryRun,
    indicationRisksDryRun,
  };
};

IndicationRisksIndividualTableContainer.propTypes = {
  isLoadingIndicationRiskIndividuals: PropTypes.bool.isRequired,
  indicationRiskIndividuals: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDeletingRiskIndividuals: PropTypes.bool.isRequired,
  isLoadingRiskCounties: PropTypes.bool.isRequired,
  isLoadingIndicationStates: PropTypes.bool.isRequired,
  isLoadingRiskSpecialties: PropTypes.bool.isRequired,
  isLoadingIndicationRisksDryRun: PropTypes.bool.isRequired,
  indicationRisksDryRun: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  createIndividualRisk: PropTypes.func.isRequired,
  updateIndicationIndividualRisk: PropTypes.func.isRequired,
  updateIndicationIndividualRisks: PropTypes.func.isRequired,
  deleteIndicationIndividualRisk: PropTypes.func.isRequired,
  getIndicationRiskCounties: PropTypes.func.isRequired,
  deleteIndicationIndividualRisks: PropTypes.func.isRequired,
  uploadRosterDryRun: PropTypes.func.isRequired,
  uploadRoster: PropTypes.func.isRequired,
  getIndicationIndividualRisks: PropTypes.func.isRequired,
  verifyIndividualRisks: PropTypes.func.isRequired,
  indicationStatesOptions: PropTypes.array.isRequired,
  riskSpecialtyFilterOptions: PropTypes.array.isRequired,
  getRiskSpecialtyOptions: PropTypes.func.isRequired,
  riskSpecialties: PropTypes.object.isRequired,
  specialtiesAlreadyFetched: PropTypes.func.isRequired,
  getIndicationRiskSpecialties: PropTypes.func.isRequired,
  riskCountyFilterOptions: PropTypes.array.isRequired,
  isValidStateCode: PropTypes.func.isRequired,
  countiesAlreadyFetched: PropTypes.func.isRequired,
  getSpecialtyName: PropTypes.func.isRequired,
  getRiskCountyOptions: PropTypes.func.isRequired,
  getCountyName: PropTypes.func.isRequired,
  indication: PropTypes.object,
};

IndicationRisksIndividualTableContainer.defaultProps = {
  indication: null,
};

export default connect(mapStateToProps, {
  handleToastMessage,
  createIndividualRisk,
  updateIndicationIndividualRisk,
  updateIndicationIndividualRisks,
  deleteIndicationIndividualRisk,
  deleteIndicationIndividualRisks,
  getIndicationRiskCounties,
  getIndicationRiskSpecialties,
  uploadRosterDryRun,
  uploadRoster,
  getIndicationIndividualRisks,
  verifyIndividualRisks,
})(IndicationRisksIndividualTableContainer);
