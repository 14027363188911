// Mapping of Okta `permissions` attributes
export default Object.freeze({
  // Opportunities
  READ_OPPORTUNITIES_UNASSIGNED: 'read:opportunities:unassigned', // unassigned opportunities page
  READ_OPPORTUNITIES_SELF: 'read:opportunities:assigned-self', // assigned opportunities page
  READ_OPPORTUNITIES_OTHERS: 'read:opportunities:assigned-others', // assigned opportunities page
  READ_UNDERWRITERS: 'read:users:underwriters',

  // Opportunity
  READ_OPPORTUNITY_INFO: 'read:opportunity:info', // opportunity info page
  UPDATE_OPPORTUNITY_INFO: 'update:opportunity:info',
  DECLINE_OPPORTUNITY: 'update:opportunity:decline',
  REVIEW_OPPORTUNITY: 'update:opportunity:review',
  ASSIGN_OPPORTUNITY: 'update:opportunity:assign',
  REQUEST_OPPORTUNITY_INFO: 'update:opportunity:need-info',
  REASSIGN_OPPORTUNITY: 'update:opportunity:reassign',
  SEARCH_FOR_OPPORTUNITY_RELATED_OPPORTUNITIES: 'read:related-files:search', // opportunity related quotes page
  DELETE_OPPORTUNITY_RELATED_OPPORTUNITIES: 'update:related-files:remove',

  // Indications
  READ_OPPORTUNITY_INDICATIONS: 'read:opportunity:indications', // indications page
  UPDATE_OPPORTUNITY_INDICATIONS: 'update:opportunity:indications',
  ADD_INDICATION: 'create:opportunity:indication',
  CLONE_INDICATION: 'create:indication:clone',
  DELETE_INDICATION: 'delete:opportunity:indication',
  UPDATE_INDICATION_STATUS_EXP_RATING: 'update:indication:sent-for-experience-rating',
  UPDATE_INDICATION_STATUS_READY_FOR_DELIVERY: 'update:indication:ready-to-be-delivered',

  // Indication
  READ_INDICATION_POLICY_INFO: 'read:indication:policy-info', // indication policy info page
  UPDATE_INDICATION_POLICY_INFO: 'update:indication:policy-info',
  UPDATE_INDICATION_INFO: 'update:indication:info',
  READ_INDICATION_DOCUMENTS: 'read:indication:documents', // indication documents/notes page
  READ_INDICATION_NOTES: 'read:indication:notes', // indication documents/notes page
  UPLOAD_INDICATION_DOCUMENT: 'create:indication:document',
  DELETE_INDICATION_DOCUMENT: 'delete:indication:document',
  UPDATE_INDICATION_NOTES: 'update:indication:notes',
  READ_INDICATION_SUMMARY: 'read:indication:rating', // indication summary/rating page
  APPROVE_INDICATION: 'create:indication:approval',
  SEND_TO_OASIS: 'update:indication:send-to-oasis',

  // Risks
  READ_INDICATION_RISKS: 'read:indication:risks', // indication roster risks page
  CREATE_ORGANIZATION_RISK: 'create:risk:organization',
  UPDATE_ORGANIZATION_RISK: 'update:risk:organization',
  DELETE_ORGANIZATION_RISK: 'delete:risk:organization',
  DELETE_ORGANIZATION_RISKS: 'delete:risks:organization',
  CREATE_INDIVIDUAL_RISKS: 'create:risks:individual', // CSV upload
  CREATE_INDIVIDUAL_RISK: 'create:risk:individual',
  UPDATE_INDIVIDUAL_RISK: 'update:risk:individual',
  DELETE_INDIVIDUAL_RISK: 'delete:risk:individual',
  DELETE_INDIVIDUAL_RISKS: 'delete:risks:individual',
});
