import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import MainLayout from 'modules/layout/main.layout';

function Root({ store }) {
  return (
    <Provider store={store}>
      <Route path="/" component={MainLayout} />
    </Provider>
  );
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;
