import React from 'react';
import PropTypes from 'prop-types';

import CuriDialogComponent from 'common/components/curiDialog.component';
import CuriButton from 'common/buttons/curiButton.component';

export default function IndicationRiskCountWarningDialog({ open, onSubmit }) {
  return (
    <CuriDialogComponent
      open={open}
      title="Creating client records"
      text="Due to the large number of risks on this roster, please wait for up to 5 minutes while client records are created in Oasis."
      onClose={onSubmit}
    >
      <CuriButton color="secondary" onClick={onSubmit} autoFocus>
        Ok
      </CuriButton>
    </CuriDialogComponent>
  );
}

IndicationRiskCountWarningDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
