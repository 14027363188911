export default Object.freeze({
  Physician: {
    id: 'PHYSICIAN',
    description: 'Physician',
  },
  Ancillary: {
    id: 'ANCILLARY',
    description: 'Ancillary',
  },
  MedicalGroup: {
    id: 'MEDGROUP',
    description: 'Medical Group',
  },
});
