import React, { useRef, useState } from 'react';
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  makeStyles,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function DropdownButton({ label, options, onClick, isLoading, disabled }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleMenuItemClick = (event, option) => {
    onClick(option);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  if (options.length === 0) {
    return null;
  }

  if (options.length === 1) {
    return (
      <Button disabled={disabled || isLoading} color="primary" size="small" onClick={() => onClick(options[0])}>
        <span className={classNames({ [classes.label]: isLoading })}>{label}</span>
        {isLoading && <CircularProgress size={18} />}
      </Button>
    );
  }

  return (
    <>
      <Button
        ref={anchorRef}
        color="primary"
        size="small"
        aria-controls={open ? 'dropdown-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label={label}
        aria-haspopup="menu"
        onClick={handleToggle}
        disabled={disabled || isLoading}
      >
        <span className={classes.label}>{label}</span>
        {isLoading ? <CircularProgress size={18} /> : <ArrowDropDownIcon />}
      </Button>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="dropdown-button-menu">
                  {options.map(option => (
                    <MenuItem
                      key={option.id}
                      onClick={event => handleMenuItemClick(event, option)}
                      disabled={option.isDisabled}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  label: {
    marginRight: theme.spacing(1),
  },
  popper: {
    zIndex: theme.zIndex.snackbar,
  },
}));

DropdownButton.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isDisabled: PropTypes.bool,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

DropdownButton.defaultProps = {
  isLoading: false,
  disabled: false,
};
