import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { makeStyles } from '@material-ui/core';

import DataTable from 'common/dataTable/dataTable.component';
import DataTableActionButton from 'common/dataTable/dataTableActionButton.component';
import DataTableCell from 'common/dataTable/dataTableCell.component';
import AssignOpportunityMenu from 'modules/opportunities/assignOpportunityMenu.component';
import DataTablePagination from 'common/dataTable/dataTablePagination.component';

function OpportunitiesTable({ actions, isLoading, opportunities, columns }) {
  const tableRef = useRef(null);
  const classes = useStyles();

  /* eslint-disable react/prop-types */
  return (
    <div className={classes.tableWrapper}>
      <DataTable
        ref={tableRef}
        actions={actions}
        isLoading={isLoading}
        data={cloneDeep(opportunities)}
        columns={columns}
        options={{ actionsColumnIndex: columns.length }}
        components={{
          Cell: DataTableCell,
          Action: props =>
            props.action.children !== 'Assign' ? (
              DataTableActionButton(classes.button)(props)
            ) : (
              <AssignOpportunityMenu
                className={classes.button}
                opportunityId={props.data.id}
                color="secondary"
                disabled={props.data.isArchived}
              />
            ),
          Pagination: props => (
            <DataTablePagination {...props} dataTableRef={tableRef.current} viewAllCount={opportunities.length} />
          ),
        }}
      />
    </div>
  );
  /* eslint-enable react/prop-types */
}

const useStyles = makeStyles(theme => ({
  tableWrapper: {
    boxShadow: `0 0 .4rem ${theme.palette.grey[500]}`,
  },
  button: {
    margin: theme.spacing(1, 1, 1, 0),
    width: '5rem',
  },
}));

OpportunitiesTable.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool.isRequired,
  opportunities: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

OpportunitiesTable.defaultProps = {
  actions: [],
};

export default OpportunitiesTable;
