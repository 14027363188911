import { useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PageContainer from 'modules/layout/pageContainer.container';
import Loading from 'common/components/loading.component';
import FullPageError from 'common/components/fullPageError.component';
import { selectIsLoadingOpportunities } from './opportunities.selectors';
import { GET_OPPORTUNITY_BY_SF_FAILURE, getOpportunityBySalesforceId } from './opportunities.actions';

export default function SalesforceOpportunityLookup() {
  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoadingOpportunities);

  const [failedToLoad, setFailedToLoad] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    async function fetchOpportunity(id) {
      const { response, type } = await dispatch(getOpportunityBySalesforceId(id));
      if (type === GET_OPPORTUNITY_BY_SF_FAILURE) {
        setFailedToLoad(true);
      } else {
        history.replace(`/opportunities/${response.id}/information`);
      }
    }

    const searchParams = new URLSearchParams(location.search);
    const salesforceId = searchParams.get('salesforceId');
    if (!salesforceId) {
      setFailedToLoad(true);
    } else if (!isLoading && !failedToLoad && !hasFetched) {
      fetchOpportunity(salesforceId);
      setHasFetched(true);
    }
  }, [dispatch, isLoading, failedToLoad, location, hasFetched]);

  if (failedToLoad) {
    return (
      <FullPageError text="Something went wrong. Unable to request opportunity data. Please try refreshing the page." />
    );
  }

  return (
    <PageContainer>
      {/* Shrink disabled due to: https://material-ui.com/components/progress/#limitations */}
      <Loading disableShrink />
    </PageContainer>
  );
}
