import React from 'react';
import { Icon, IconButton, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

function RefreshButton({ onClick, disabled }) {
  const classes = useStyles();

  return (
    <IconButton className={classes.button} onClick={onClick} disabled={disabled}>
      <Icon className="fal fa-redo" />
    </IconButton>
  );
}

const useStyles = makeStyles(() => ({
  button: {
    width: 56,
  },
}));

RefreshButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

RefreshButton.defaultProps = {
  onClick: () => undefined,
  disabled: false,
};

export default RefreshButton;
