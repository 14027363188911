import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, CircularProgress } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import CuriButton from 'common/buttons/curiButton.component';

function IndicationDeleteModal({ open, loading, handleClose, handleSubmit }) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose} disableBackdropClick={loading}>
      <DialogTitle>Delete Indication</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Are you sure you want to delete this indication? This action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <CuriButton color="primary" variant="outlined" disabled={loading} type="submit" onClick={handleClose}>
          Cancel
        </CuriButton>
        <CuriButton
          color="primary"
          disabled={loading}
          type="submit"
          onClick={handleSubmit}
          startIcon={loading ? <CircularProgress size={20} /> : <DeleteIcon />}
        >
          Yes, delete
        </CuriButton>
      </DialogActions>
    </Dialog>
  );
}

IndicationDeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default IndicationDeleteModal;
