import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import SectionContainer, { BORDER_RADIUS } from 'modules/layout/section.container';
import IndicationsTable from 'modules/opportunities/opportunity/indicationsStep/indicationsTable.component';
import { makeStyles, Box } from '@material-ui/core';
import CuriButton from 'common/buttons/curiButton.component';
import sectionStyles from 'common/sectionStyles';
import { useAuthz } from 'okta/authz';
import Permissions from 'okta/permissions';
import { createIndication, CREATE_INDICATION_SUCCESS } from 'modules/indication/indication.actions';
import { useSelectedOpportunity } from 'modules/opportunities/opportunities.selectors';
import { handleToastMessage, TOAST_TYPES } from 'modules/layout/layout.actions';
import { useIndications } from 'modules/indication/indication.selectors';
import { selectAlertsTaskIndicationIds } from 'modules/alerts/alerts.selectors';
import OpportunityStatus from 'modules/opportunities/opportunityStatus';

function Indications() {
  const classes = useStyles();
  const { userHas } = useAuthz();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedOpportunity = useSelectedOpportunity();
  const { indications, isLoadingIndications } = useIndications();
  const alertsTaskIndicationIds = useSelector(selectAlertsTaskIndicationIds);

  const handleAdd = useCallback(async () => {
    const response = await dispatch(createIndication(selectedOpportunity.id));
    if (response.type === CREATE_INDICATION_SUCCESS) {
      const { id: indicationId } = response.response;
      history.push(`${location.pathname}/${indicationId}/policy`);
    } else {
      dispatch(handleToastMessage('Unable to add an indication. Please try again.', TOAST_TYPES.ERROR));
    }
  }, [dispatch, selectedOpportunity, location.pathname, history]);

  const addIndicationDisabled = useMemo(
    () => isLoadingIndications || selectedOpportunity.activeStatusCode !== OpportunityStatus.InReview,
    [selectedOpportunity, isLoadingIndications]
  );

  return (
    <SectionContainer title="Indications / Quotes" borderRadius={BORDER_RADIUS.ROUNDED} className={classes.margin}>
      <div className={classes.padding}>
        <IndicationsTable
          isLoading={isLoadingIndications}
          indications={indications}
          userHas={userHas}
          alertsTaskIndicationIds={alertsTaskIndicationIds}
        />
        <Box display="flex" justifyContent="flex-end" pt={3}>
          {userHas(Permissions.ADD_INDICATION) && (
            <CuriButton color="secondary" onClick={handleAdd} disabled={addIndicationDisabled}>
              add
            </CuriButton>
          )}
        </Box>
      </div>
    </SectionContainer>
  );
}

const useStyles = makeStyles(theme => ({
  ...sectionStyles(theme),
}));

export default Indications;
