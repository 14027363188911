import React, { useEffect, useCallback, useMemo, useRef, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Tooltip } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { isEqual } from 'lodash';
import classNames from 'classnames';
import moment from 'moment';

import IndicationDocumentsList from 'modules/indication/indicationDocumentsList.component';
import SectionContainer, { BORDER_RADIUS } from 'modules/layout/section.container';
import sectionStyles from 'common/sectionStyles';
import IndicationNotes from 'modules/indication/indicationNotes.component';
import {
  useSelectedOpportunity,
  useSelectedOpportunityIsReadOnly,
} from 'modules/opportunities/opportunities.selectors';
import CuriButton from 'common/buttons/curiButton.component';
import CuriDropzoneComponent from 'common/fileUpload/CuriDropzone.component';
import { handleToastMessage, hideToast, TOAST_TYPES } from 'modules/layout/layout.actions';
import Empty from 'common/components/empty.component';
import { useAuthz } from 'okta/authz';
import Permissions from 'okta/permissions';
import {
  selectIndividualRisks,
  selectIsCreatingIndicationAttachment,
  selectIsDeletingIndicationAttachment,
  selectIsLoadingUploadUrl,
  selectIsPushingAttachmentsToOnBase,
  selectIsUpdatingIndicationAttachments,
  selectIsUploadingIndicationAttachment,
  selectOrganizationRisks,
  useIndicationAttachments,
  useIndicationIsInOasis,
} from 'modules/indication/indication.selectors';
import {
  getIndicationAttachments,
  getIndicationIndividualRisks,
  getIndicationOrganizationRisks,
  updateIndicationAttachments,
  UPDATE_INDICATION_ATTACHMENTS_FAILURE,
  uploadIndicationAttachmentS3,
  getIndicationAttachmentUploadUrl,
  GET_INDICATION_ATTACHMENT_UPLOAD_URL_FAILURE,
  UPLOAD_INDICATION_ATTACHMENT_S3_FAILURE,
  createIndicationAttachment,
  CREATE_INDICATION_ATTACHMENT_FAILURE,
  deleteIndicationAttachment,
  DELETE_INDICATION_ATTACHMENT_FAILURE,
  pushIndicationAttachmentsToOnBase,
  PUSH_INDICATION_ATTACHMENTS_TO_ONBASE_FAILURE,
} from 'modules/indication/indication.actions';

const UPLOAD_FAILURE_MESSAGE = 'Unable to upload document. Please try again.';

export default function IndicationDocuments() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { opportunityId, indicationId } = useParams();
  const { userHas } = useAuthz();
  const formRef = useRef(null);

  const { attachments, isLoadingAttachments } = useIndicationAttachments();
  const isUploadingIndicationAttachment = useSelector(selectIsUploadingIndicationAttachment);
  const isLoadingUploadUrl = useSelector(selectIsLoadingUploadUrl);
  const isCreatingIndicationAttachment = useSelector(selectIsCreatingIndicationAttachment);
  const isUpdatingAttachments = useSelector(selectIsUpdatingIndicationAttachments);
  const isDeletingAttachment = useSelector(selectIsDeletingIndicationAttachment);
  const indicationIsInOasis = useIndicationIsInOasis();
  const selectedOpportunity = useSelectedOpportunity();
  const isReadOnly = useSelectedOpportunityIsReadOnly();
  const showDelete = useMemo(() => !indicationIsInOasis && !isReadOnly, [indicationIsInOasis, isReadOnly]);
  const individualRisks = useSelector(selectIndividualRisks);
  const organizationalRisks = useSelector(selectOrganizationRisks);
  const canUpdateMetadata = useMemo(() => userHas(Permissions.UPDATE_INDICATION_INFO), [userHas]);
  const isPushingAttachmentsToOnBase = useSelector(selectIsPushingAttachmentsToOnBase);

  const attachmentCount = attachments.length;
  const isUploadingAttachment = isUploadingIndicationAttachment || isLoadingUploadUrl || isCreatingIndicationAttachment;
  const isLegacy = selectedOpportunity && selectedOpportunity.useLegacyDocumentFlow;

  // need to track local version of attachments here to stage updates before the Save button is clicked
  const [stagedAttachments, setStagedAttachments] = useState(attachments);
  useEffect(() => {
    setStagedAttachments(
      [...(attachments || [])].sort((a, b) =>
        a.createdDate !== b.createdDate ? moment(a.createdDate).diff(b.createdDate) * -1 : a.name.localeCompare(b.name)
      )
    );
  }, [attachments]);

  useEffect(() => {
    dispatch(getIndicationAttachments(opportunityId, indicationId));
    dispatch(getIndicationIndividualRisks(opportunityId, indicationId));
    dispatch(getIndicationOrganizationRisks(opportunityId, indicationId));
  }, [dispatch, opportunityId, indicationId]);

  const handleChange = useCallback(attachmentPatch => {
    setStagedAttachments(prevStagedAttachments => {
      const attachmentIndex = (prevStagedAttachments || []).findIndex(prev => prev.id === attachmentPatch.id);
      if (attachmentIndex < 0) {
        return prevStagedAttachments;
      }

      return [
        ...prevStagedAttachments.slice(0, attachmentIndex),
        { ...prevStagedAttachments[attachmentIndex], ...attachmentPatch },
        ...prevStagedAttachments.slice(attachmentIndex + 1),
      ];
    });
  }, []);

  const areAttachmentsDirty = useMemo(() => {
    return stagedAttachments.some(stagedAttachment => {
      const originalAttachment = attachments.find(attachment => attachment.id === stagedAttachment.id);
      return !isEqual(stagedAttachment, originalAttachment);
    });
  }, [attachments, stagedAttachments]);

  const canPushToOnBase = indicationIsInOasis && !isLegacy && !isReadOnly;
  const allAttachmentsInOnBase = useMemo(() => attachments.every(a => a.onBaseId), [attachments]);
  let pushToOnBaseTooltip = 'Send new documents to OnBase';
  if (allAttachmentsInOnBase) {
    pushToOnBaseTooltip = 'All attachments are already in OnBase';
  } else if (areAttachmentsDirty) {
    pushToOnBaseTooltip = 'Save local changes before pushing to OnBase';
  }

  const handleSaveAttachments = useCallback(async () => {
    formRef.current.submit();
    dispatch(hideToast());

    const isValid = await formRef.current.isFormValid();
    if (!isValid) {
      dispatch(handleToastMessage('Please check that all required fields are provided.', TOAST_TYPES.ERROR));
      return;
    }

    const updatedAttachments = stagedAttachments.filter(stagedAttachment => {
      const originalAttachment = attachments.find(attachment => attachment.id === stagedAttachment.id);
      return !originalAttachment || (originalAttachment && !isEqual(stagedAttachment, originalAttachment));
    });

    const response = await dispatch(updateIndicationAttachments(opportunityId, indicationId, updatedAttachments));
    if (response.type === UPDATE_INDICATION_ATTACHMENTS_FAILURE) {
      dispatch(handleToastMessage('Unable to update document. Please try again.', TOAST_TYPES.ERROR));
    }
  }, [dispatch, opportunityId, indicationId, attachments, stagedAttachments, formRef]);

  const handleUpload = useCallback(
    async files => {
      const uploadUrlResponse = await dispatch(getIndicationAttachmentUploadUrl(opportunityId, indicationId));
      if (uploadUrlResponse.type === GET_INDICATION_ATTACHMENT_UPLOAD_URL_FAILURE) {
        dispatch(handleToastMessage(UPLOAD_FAILURE_MESSAGE, TOAST_TYPES.ERROR));
        return;
      }

      const { url, attachmentId } = uploadUrlResponse.response;
      const file = files[0];
      const uploadResponse = await dispatch(uploadIndicationAttachmentS3(url, file));
      if (uploadResponse.type === UPLOAD_INDICATION_ATTACHMENT_S3_FAILURE) {
        dispatch(handleToastMessage(UPLOAD_FAILURE_MESSAGE, TOAST_TYPES.ERROR));
        return;
      }

      const createAttachmentResponse = await dispatch(
        createIndicationAttachment(opportunityId, indicationId, {
          id: attachmentId,
          name: file.name,
        })
      );
      if (createAttachmentResponse.type === CREATE_INDICATION_ATTACHMENT_FAILURE) {
        dispatch(handleToastMessage(UPLOAD_FAILURE_MESSAGE, TOAST_TYPES.ERROR));
      }
    },
    [dispatch, opportunityId, indicationId]
  );

  const handleDelete = useCallback(
    async attachment => {
      const { type } = await dispatch(deleteIndicationAttachment(opportunityId, indicationId, attachment.id));
      if (type === DELETE_INDICATION_ATTACHMENT_FAILURE) {
        dispatch(handleToastMessage('Unable to delete document. Please try again.', TOAST_TYPES.ERROR));
      }
    },
    [dispatch, opportunityId, indicationId]
  );

  const handlePushToOnBase = useCallback(async () => {
    const { type, response } = await dispatch(pushIndicationAttachmentsToOnBase(opportunityId, indicationId));
    // would like to improve server responses to include more information so we don't need to check here
    const allSuccess =
      Array.isArray(response) && response.every(a => a.onBaseId) && response.length === attachmentCount;
    if (type === PUSH_INDICATION_ATTACHMENTS_TO_ONBASE_FAILURE || !allSuccess) {
      dispatch(handleToastMessage('Unable to push all documents to OnBase. Please try again.', TOAST_TYPES.ERROR));
    } else {
      dispatch(handleToastMessage('All documents pushed to OnBase', TOAST_TYPES.SUCCESS));
    }
  }, [dispatch, opportunityId, indicationId, attachmentCount]);

  const handleSubmit = e => {
    e.preventDefault();
  };

  return (
    <>
      {!isReadOnly && userHas(Permissions.UPLOAD_INDICATION_DOCUMENT) && (
        <SectionContainer
          title="Upload Documents for Indication"
          borderRadius={BORDER_RADIUS.ROUNDED_TOP}
          reduceTitleMargin
        >
          <CuriDropzoneComponent
            acceptedFiles={
              [
                /* Empty array allows all file types */
              ]
            }
            dropzoneText="Drag and drop a file here or click."
            onChange={handleUpload}
            dropzoneClass="curi-dropzone-document-container"
            dropzoneParagraphClass="curi-dropzone-document-paragraph"
          />
        </SectionContainer>
      )}
      <SectionContainer
        title={
          <div className={classes.sectionTitle}>
            <span>Indication Documents</span>
            <span>
              {canPushToOnBase && (
                <Tooltip title={pushToOnBaseTooltip}>
                  {/* Needed for disabled hover event */}
                  <span>
                    <CuriButton
                      className={classes.actionButton}
                      disabled={
                        isPushingAttachmentsToOnBase ||
                        isUpdatingAttachments ||
                        areAttachmentsDirty ||
                        allAttachmentsInOnBase
                      }
                      onClick={handlePushToOnBase}
                      isLoading={isPushingAttachmentsToOnBase}
                    >
                      Push to OnBase
                    </CuriButton>
                  </span>
                </Tooltip>
              )}
              {!isReadOnly && (
                <CuriButton
                  className={classes.actionButton}
                  customColor="success"
                  disabled={isUpdatingAttachments || !areAttachmentsDirty}
                  onClick={handleSaveAttachments}
                >
                  Save
                </CuriButton>
              )}
            </span>
          </div>
        }
        reduceTitleMargin
      >
        <div className={classNames(classes.documentList, classes.forceScrollbar)}>
          {(!attachments || attachments.length === 0) && !isUploadingAttachment && !isLoadingAttachments ? (
            <div className={classes.center}>
              <Empty icon="fal fa-file-pdf" text="No Documents" />
            </div>
          ) : (
            <ValidatorForm id="indication-documents-form" onSubmit={handleSubmit} ref={formRef}>
              <IndicationDocumentsList
                attachments={stagedAttachments}
                opportunityId={opportunityId}
                indicationId={indicationId}
                isUploading={isUploadingAttachment}
                isLoading={isLoadingAttachments}
                disableUpdate={isReadOnly || !canUpdateMetadata || isDeletingAttachment}
                showDelete={showDelete}
                disableDelete={areAttachmentsDirty}
                onChange={handleChange}
                onDelete={handleDelete}
                individualRisks={individualRisks}
                organizationalRisks={organizationalRisks}
                isReadOnly={isReadOnly}
              />
            </ValidatorForm>
          )}
        </div>
      </SectionContainer>
      <SectionContainer title="Underwriter Notes" borderRadius={BORDER_RADIUS.ROUNDED_BOTTOM}>
        <div className={classes.padding}>
          <IndicationNotes />
        </div>
      </SectionContainer>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  ...sectionStyles(theme),
  forceScrollbar: {
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: 7,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0, 0, 0, .5)',
      boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
  documentList: {
    minHeight: 262,
    maxHeight: 400,
    overflowY: 'scroll',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  actionButton: {
    color: 'initial',
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
}));
